import React, { Component } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { inject, observer } from 'mobx-react';
import moment from "moment";

//components
import applicationRouter from '../../../hoc/withLocalization';
import withLocalization from '../../../hoc/withLocalization';
import Button from '../../../components/CustomButton/CustomButton.jsx';

//elements
import Select from "../../../elements/Select";
import LoadingSpinner from '../../../elements/LoadingSpinner';

//utils
import { dateToTimeFormat, getShortDateFormat } from "../../../utils/dateFormat";

const parseAndCreateCostUnitAndTypeCombineMap = (data) => {
    const costUnitsAndTypesMap = {};
    Object.values(data).reduce((a, e) => {
        e.costUnits.forEach(cu => a[`${e.identifier}_${cu.identifier}`] = {
            dimensionIdentifier: e.identifier,
            id: cu.identifier,
            value: cu.name,
            hasExpired: checkIfCostUnitHasExpired(e.validFrom, e.validTo) || checkIfCostUnitHasExpired(cu.validFrom, cu.validTo)
        });
        return a;
    }, costUnitsAndTypesMap);
    return costUnitsAndTypesMap;
}

const getSelectedCostUnits = (value, parsedData) => {
    const selectedCostUnits = [], selectedCostUnitsWithError = [];
    if (!value) return { selectedCostUnits, selectedCostUnitsWithError };
    const costUnits = JSON.parse(value);
    costUnits.forEach(cu => {
        const id = `${cu.dimensionIdentifier}_${cu.id}`;
        const costUnit = parsedData[id];
        if (costUnit && !costUnit.hasExpired) {
            selectedCostUnits.push(id);
        } else {
            selectedCostUnitsWithError.push(id);
        }
    });
    return {
        selectedCostUnits, selectedCostUnitsWithError
    }
}

const checkIfCostUnitHasExpired = (validFrom, validTo) => {
    const today = moment(new Date()).format('YYYY-MM-DD');
    if (validFrom && moment(validFrom).isValid() && moment(today).isBefore(validFrom))
        return true;
    if (validTo && moment(validTo).isValid() && moment(today).isAfter(validTo))
        return true;
    return false;
}

const formatCostUnitsForSelect = (data, identifier, shortDateFormat, parsedData) => {
    const formattedData = {
        options: [], placeholderMessage: "No cost unit available"
    }
    if (!data) return formattedData;
    data.forEach(cu => {
        const id = `${identifier}_${cu.identifier}`;
        const costUnit = parsedData[id];
        if (!costUnit.hasExpired)
            formattedData.placeholderMessage = "Select Cost Unit";

        if (costUnit) {
            formattedData.options.push({
                label: `${cu.name} ${!!(cu.validFrom || cu.validTo) ? `( ${cu.validFrom ? moment(cu.validFrom).format(shortDateFormat) : ''} ${cu.validTo ? '-' + moment(cu.validTo).format(shortDateFormat) : ''})` : ''}`,
                value: id,
                isDisabled: costUnit.hasExpired
            });
        }
    })
    return formattedData;
}

const CostUnitsAndTypesWithMobx = inject('commonStore')(applicationRouter(withLocalization(observer(class CostUnitsAndTypes extends Component {
    constructor(props) {
        super(props);
        const { data, last_updated } = props.commonStore.costUnitsAndTypes;
        this.parsedData = parseAndCreateCostUnitAndTypeCombineMap(data || {});
        const { selectedCostUnits, selectedCostUnitsWithError } = getSelectedCostUnits(props.value, this.parsedData);
        if (selectedCostUnitsWithError?.length && props.hasErrorCallBack) {
            const data = this.getSelecetedCostUnitsFromParsedData(selectedCostUnits);
            props.hasErrorCallBack(JSON.stringify(data), true);
        }
        this.state = {
            costUnitsAndTypes: data,
            selectedCostUnits: selectedCostUnits,
            lastUpdated: last_updated,
            isLoading: true,
            hasError: !!(selectedCostUnitsWithError.length)
        }
    }

    componentDidMount() {
        if (!this.state.costUnitsAndTypes)
            this.refetchCostUnitsAndTypes(false);
        else this.setState({ isLoading: false })
    }

    hanldleCostUnitChange(selectedItem, identifier) {
        let __selectedCostUnits = this.state.selectedCostUnits.filter(v => v.split("_")[0] !== identifier);
        if (selectedItem) {
            const { value } = selectedItem;
            __selectedCostUnits.push(value);
        }
        this.setState({ selectedCostUnits: [...__selectedCostUnits] },
            () => {
                const data = this.getSelecetedCostUnitsFromParsedData(this.state.selectedCostUnits);
                this.props.onChange(JSON.stringify(data));
            }
        );
    }

    getSelecetedCostUnitsFromParsedData(selectedCostUnits) {
        let costUnits = [];
        Object.keys(this.parsedData).forEach(key => {
            const costUnit = { ...this.parsedData[key] };
            if (selectedCostUnits.includes(key) && !costUnit.hasExpired) {
                delete costUnit.hasExpired;
                costUnits.push(costUnit);
            }
        })
        return costUnits;
    }

    refetchCostUnitsAndTypes(refetch) {
        this.setState({ isLoading: true }, () => this.fetchCostUnitsAndTypes(refetch));
    }

    async fetchCostUnitsAndTypes(refetch) {
        const { commonStore, t } = this.props;
        try {
            const response = await commonStore.getCostUnitsAndTypes({ refetch });
            if (!response || !response.last_updated || !response.data)
                throw new Error('Something went wrong');
            const { data, last_updated } = response;
            this.parsedData = parseAndCreateCostUnitAndTypeCombineMap(data);
            const { selectedCostUnits, selectedCostUnitsWithError } = getSelectedCostUnits(this.props.value, this.parsedData);
            if (selectedCostUnitsWithError?.length && this.props.hasErrorCallBack) {
                const data = this.getSelecetedCostUnitsFromParsedData(selectedCostUnits);
                this.props.hasErrorCallBack(JSON.stringify(data), true);
            }
            this.setState({
                costUnitsAndTypes: data,
                lastUpdated: last_updated,
                selectedCostUnits: selectedCostUnits,
                hasError: !!(selectedCostUnitsWithError?.length),
                isLoading: false
            });
        } catch (err) {
            commonStore.addNotification(t(err), null, 'error');
            console.error(err);
            this.setState({ isLoading: false });
        }
    }

    renderCostUnits(costUnitType, shortDateFormat) {
        const { t } = this.props;
        const { selectedCostUnits } = this.state;
        const { options, placeholderMessage } = formatCostUnitsForSelect(costUnitType.costUnits, costUnitType.identifier, shortDateFormat, this.parsedData)
        return (
            <Select
                placeholder={t(placeholderMessage)}
                options={options}
                value={CostUnitsAndTypes.checkIfValueExists(costUnitType.costUnits, costUnitType.identifier, selectedCostUnits, shortDateFormat)}
                onChange={(s) => this.hanldleCostUnitChange(s, costUnitType.identifier)}
                noOptionsCustomMessage={t("No cost unit available")}
                isClearable
            />
        )
    }

    static checkIfValueExists(costUnits, identifier, selectedCostUnits, shortDateFormat) {
        if (!selectedCostUnits || !selectedCostUnits.length) return null;
        for (let i = 0; i < costUnits.length; i++) {
            const cu = costUnits[i];
            if (selectedCostUnits.includes(`${identifier}_${cu.identifier}`)) {
                return {
                    label: `${cu.name} ${!!(cu.validFrom || cu.validTo) ? `( ${cu.validFrom ? moment(cu.validFrom).format(shortDateFormat) : ''} ${cu.validTo ? '-' + moment(cu.validTo).format(shortDateFormat) : ''})` : ''}`,
                    value: `${identifier}_${cu.identifier}`
                };
            }
        }
        return null;
    }

    render() {
        const { t, commonStore } = this.props;
        const { costUnitsAndTypes, lastUpdated, isLoading, hasError } = this.state;
        if (isLoading) return <LoadingSpinner />;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data &&
            commonStore.config.client.data.dateTimeRules
            ? commonStore.config.client.data.dateTimeRules : false;
        const shortDateFormat = getShortDateFormat(dateTimeRules);
        const __costUnitsAndTypes = Object.values(costUnitsAndTypes);


        return (
            <>
                <Row className="my-1">
                    <Col xs={6}>
                        <span className="font-size-12">
                            <strong>{t("Note")}: &nbsp;{t("Please make sure to import the latest data")}</strong>
                        </span>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    <>
                                        <span className="font-size-12"><strong>{t("Cost Units Tips")}</strong></span>
                                        <div className="d-flex justify-content-start">
                                            <ul style={{ margin: 0, paddingLeft: '1rem' }} className="font-size-10 text-start">
                                                <li>{t("Changes made over the payroll are not directly reflected, if there is somethings missing then please click on refetch icon and re-sync the cost units")}.</li>
                                                <li>{t("Please make sure that you always use cost units that have valid dates")}.</li>
                                                <li>{t("Removing Cost Units will not impact the past timesheets, if needed then please reset approval and updated accordingly")}.</li>
                                            </ul>
                                        </div>
                                    </>
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted m-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                        {hasError &&
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        {t('Some of the previously selected cost-units had expired, system removed them to avoid inconsistency, if needed update on payroll and then refetch here')}.
                                    </Tooltip>
                                }
                            >
                                <i className="fa fa-exclamation-circle text-danger m-2" aria-hidden="true"></i>
                            </OverlayTrigger>
                        }
                    </Col>
                    <Col xs={6} className="text-right">
                        <span className="font-size-12 me-2">
                            {t('Last Updated')}: {moment(lastUpdated).format(shortDateFormat)} - {dateToTimeFormat(lastUpdated, dateTimeRules)}
                        </span>
                        <Button icon_sm fill me2
                            onClick={() => this.refetchCostUnitsAndTypes(true)}
                            title={t("Refetch cost units")}
                        >
                            <i className="fa fa-sync fa-xl" />
                        </Button>
                    </Col>
                </Row>
                {!__costUnitsAndTypes.length && (
                    <div className="my-1 h-25 d-flex flex-column justify-content-center align-items-center">
                        <span className="font-size-15 fw-bold my-2">
                            {t("No cost unit available")}, {t("Please refetch cost units from payroll and try again")}
                        </span>
                        <span>
                            <Button wd fill onClick={() => this.refetchCostUnitsAndTypes(true)}>
                                {t("Import Cost Units")}
                            </Button>
                        </span>
                    </div>
                )}
                <Row className="fw-bold font-size-15" style={{ color: "#2550ac" }}>
                    <Col xs={6}>{t("Cost Unit Types")}</Col>
                    <Col xs={6}>{t("Cost Units")}</Col>
                </Row>
                {__costUnitsAndTypes.map(e => (
                    <Row key={e.identifier} className="mt-1">
                        <Col xs={6} className="align-self-center">
                            <span className="font-size-15 fw-bold">
                                {e.identifier} | {e.name}
                                {!!(e.validFrom || e.validTo) &&
                                    <span className="font-size-12 ms-2">
                                        ( {t("From")} {moment(e.validFrom).format(shortDateFormat)} {e.validTo ? `${t("to")} ${moment(e.validTo).format(shortDateFormat)}` : ''} )
                                    </span>
                                }
                            </span>
                        </Col>
                        <Col xs={6}>{this.renderCostUnits(e, shortDateFormat)}</Col>
                    </Row>
                ))}
            </>
        );
    }
}))));

export const CostUnitsAndTypeWidget = observer(class CostUnitsAndTypeWidget extends Component {
    render() {
        return <CostUnitsAndTypesWithMobx {...this.props} />
    }
})
