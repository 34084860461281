import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Modal } from 'react-bootstrap';

//components
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import TableWidget from '../../TableWidget.js';
import AddOvertimeSetting from './addEditOvertimeSettings.js';
import Button from '~/components/CustomButton/CustomButton';

//elements
import LoadingSpinner from '~/elements/LoadingSpinner';

//assets
import CloseModalIcon from '~/assets/img/CloseApproveModalIcon.svg';

const formatData = function (data, t) {
    return data.map(item => ({
        ...item,
        integrated_wagetype: item?.integrated_wagetype?.label || '',
    }));
}

class OvertimeSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            overtimeSettingList: [],
            isEdit: false,
            editData: {},
            payroll_wagetypes: props.commonStore.getWageTypesAccordingToIntegrations(props.commonStore?.config?.integration_details, 'at') || [],
            loading: false
        }
        this.updateData = this.updateData.bind(this);
        this.deleteOvertime = this.deleteOvertime.bind(this);
    }

    updateData = async (data) => {
        const { commonStore, t, clientStore } = this.props;
        if (data.hasError)
            return commonStore.addNotification(t(data.errors[0].error), null, 'warning');

        this.setState({ loading: true }, async () => {
            try {
                if (this.state?.editData?.id)
                    await clientStore.editOvertimeSetting(this.state.editData.id, data);
                else
                    await clientStore.saveOvertimeSetting(data);
                commonStore.addNotification(t('Overtime setting saved successfully!'), null, 'success');
                this.loadData()
            } catch (err) {
                console.error(err)
                this.setState({ loading: false })
            }
        })
    }

    componentDidMount() {
        this.setState({ loading: true }, this.loadData);
    }

    loadData = async () => {
        const { clientStore } = this.props;
        try {
            const result = await clientStore.getOvertimeSetting();
            this.setState({
                overtimeSettingList: result.overtimeSettingList,
                isEdit: false,
                loading: false,
            });
        } catch (err) {
            console.error(err);
            this.setState({ loading: false });
        }
    }

    deleteOvertime = async (id) => {
        const { clientStore, commonStore, t } = this.props;
        try {
            await clientStore.deleteOvertimeSetting(id, false);
            commonStore.addNotification(t('Overtime setting deleted successfully!'), null, 'success');
            this.loadData()
        } catch (err) {
            console.error(err);
            this.setState({ loading: false });
        }
    }

    handleDelete = (index) => {
        const data = this.state.overtimeSettingList[index];
        const id = data?.id

        if (!id) return;
        this.setState({ loading: true }, () => {
            return this.props.handleDelete(id, (id) => {
                return this.deleteOvertime(id);
            }, null, () => this.setState({ loading: false }));
        })
    }

    editOvertime = (index) => {
        const data = this.state.overtimeSettingList[index];
        this.toggleModal(true, data);
    }

    toggleModal = (value, data) => {
        this.setState({ isEdit: value, editData: data });
    }

    render() {
        const { t } = this.props;
        const { isEdit, loading } = this.state

        if (loading) return <LoadingSpinner />

        return (
            <>
                <Row>
                    <Col xs={8}>
                        <h6>{t('Overtime Settings')}</h6>
                    </Col>
                    <Col xs={4} className='d-flex justify-content-end'>
                        <Button wd fill onClick={() => this.toggleModal(true, {})}>
                            <i className="fa fa-plus-circle fa-xl margin-right-10" /> {this.props.t('Add new')}
                        </Button>
                    </Col>
                </Row>
                <TableWidget
                    headers={['Name', 'Overtime', 'Payroll', 'Ratio']}
                    metaData={[
                        { field: 'name', type: 'text' },
                        { field: 'overtime', type: 'number' },
                        { field: 'integrated_wagetype', type: 'text' },
                        { field: 'ratio', type: 'text' }
                    ]}
                    data={formatData(this.state.overtimeSettingList, t)}
                    onChange={_ => { }}
                    minRemoveIndex={0}
                    customDeleteHandler={this.handleDelete}
                    page={'overtimeSettings'}
                    customFormHandler={this.editOvertime}
                />
                <Modal
                    className={"modal right fade modal_revised"}
                    backdrop="static"
                    show={isEdit}
                    onHide={() => this.toggleModal(false, {})}
                >
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">{t('Overtime Settings')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            alt='close_button'
                            onClick={() => this.toggleModal(false, {})}
                        />
                    </Modal.Header>
                    <Modal.Body className='position-relative'>
                        <AddOvertimeSetting
                            values={this.state.editData}
                            updateData={this.updateData}
                            cancel={() => this.toggleModal(false, {})}
                            payroll_wagetypes={this.state.payroll_wagetypes}
                            t={this.props.t}
                        />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
export default inject('clientStore', 'commonStore', 'userStore')(withLocalization(withRemoveDialog(observer(OvertimeSetting))));
