import React, { useMemo, useState } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { inject } from 'mobx-react';

//components
import withRemoveDialog from '~/hoc/withRemoveDialog';
import TimePickerInput from '~/components/TimePicker';
import InputTime from '~/components/GenericForm/Widgets/InputTime';
import Button from '~/components/CustomButton/CustomButton';
import TableWidget from '../../TableWidget.js';
import AddCompanyShiftOvertime from './addCompanyShiftOvertime.js'

//elements
import Select from '~/elements/Select.js';
import RequiredStar from '~/elements/RequiredStar.js';
import { Input } from "~/elements/Input";
import { NumberInput } from "../../../../../elements/Input";
import SwitchWidget from '../../SwitchWidget.js';
import LoadingSpinner from '~/elements/LoadingSpinner';

//utils
import { getMinutesFromTimeInput, minutesToTimeInput } from '~/utils/timeCalc';
import { translateLabels, validateRequestPayload } from '~/utils/commonMethods';
import { timeFormat } from '~/utils/dateFormat';
import { validateStartAndEndTime } from "~/utils/commonMethods.js";

const breakMinOptions = [{ value: 0, label: '0 minute' }, { value: 30, label: '30 minutes' },
{ value: 60, label: '60 minutes' }, { value: 90, label: '90 minutes' }];

const periods = [
    { value: 'day', label: 'Day', title: 'Day', key: 'Day' },
    { value: 'week', label: 'Week', title: 'Week', key: 'Week' },
    {
        value: 'government',
        label: 'Hours in excess of nine hours in the course of 24 hours and 40 hours in the course of seven days',
        title: 'Hours in excess of nine hours in the course of 24 hours and 40 hours in the course of seven days',
        key: 'Hours in excess of nine hours in the course of 24 hours and 40 hours in the course of seven days'
    }
];

const formatData = (data, dateTimeRules) => {
    const formattedData = [];
    data?.forEach(e => {
        formattedData.push({
            name: e.Overtime_Setting.name,
            from: timeFormat(e.from, dateTimeRules),
            to: timeFormat(e.to, dateTimeRules)
        })
    })
    return formattedData;
}

const hasDataChanged = (prev, current, isOvertimeMode) => {
    if (isOvertimeMode) {
        return (prev.maximum_work_limit_in_day !== getMinutesFromTimeInput(current.maximumWorkLimitInDay)
            || prev.maximum_work_limit_in_week !== parseInt(current.maximumWorkLimitInWeek) * 60)
            || prev.overtime_threshold !== current.overtimeThreshold;
    } else {
        return ((prev.start_time !== getMinutesFromTimeInput(current.startTime))
            || (prev.end_time !== getMinutesFromTimeInput(current.endTime))
            || (prev.break_time_minutes !== current.breakTimeMinutes)
            || (prev.buffer_time !== getMinutesFromTimeInput(current.bufferTime))
            || (prev.rest_between_shifts !== getMinutesFromTimeInput(current.minimumDiffBetweenShift))
        )
    }
}

const AddShift = ({ val, overtimeSettingList, updateData, cancel, t, dateTimeRules, commonStore, clientStore, handleDelete }) => {
    const [shiftName, setShiftName] = useState(val?.shift_name);
    const [startTime, setStartTime] = useState(minutesToTimeInput(val?.start_time || 0));
    const [endTime, setEndTime] = useState(minutesToTimeInput(val?.end_time || 0));
    const [breakTimeMinutes, setBreakTimeMinutes] = useState(!isNaN(val.break_time_minutes) ? val.break_time_minutes : 30);
    const [bufferTime, setBufferTime] = useState(minutesToTimeInput(val?.buffer_time || 0));
    const [maximumWorkLimitInDay, setMaximumWorkLimitInDay] = useState(minutesToTimeInput(val?.maximum_work_limit_in_day || 0));
    const [maximumWorkLimitInWeek, setmaximumWorkLimitInWeek] = useState(val?.maximum_work_limit_in_week / 60 || 40);
    const [minimumDiffBetweenShift, setMinimumDiffBetweenShift] = useState(minutesToTimeInput(val?.rest_between_shifts || 660));
    const [overtimeThreshold, setOvertimeThreshold] = useState(val.overtimeThreshold ? val.overtimeThreshold : 15);
    const [period, setPeriod] = useState(val?.period || 'day');
    const [allowAverageOvertime, setAllowAverageOvertime] = useState(val?.allow_average_overtime || false);
    const [companyShiftOvertimes, setCompanyShiftOvertimes] = useState(val?.Company_Shift_Overtimes || [])
    const [currentOvertimeSetting, setCurrentOvertimeSetting] = useState(null);
    const [showOvertimeForm, setShowOvertimeForm] = useState(false);
    const [isOvertimeEditMode, setIsOvertimeEditMode] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const normalWorkDay = useMemo(() => {
        if (!startTime || !endTime) return "00:00";
        const startTotalMinutes = getMinutesFromTimeInput(startTime);
        const endTotalMinutes = getMinutesFromTimeInput(endTime);

        const totalTime = endTotalMinutes < startTotalMinutes ?
            endTotalMinutes + 1440 - startTotalMinutes - breakTimeMinutes :
            endTotalMinutes - startTotalMinutes - breakTimeMinutes;

        return minutesToTimeInput(totalTime);
    }, [startTime, endTime, breakTimeMinutes]);


    const saveData = (saveAndReload = false) => {
        const __bufferTime = getMinutesFromTimeInput(bufferTime);
        const __normalWorkDay = getMinutesFromTimeInput(normalWorkDay);
        let __maximumWorkLimitInDay = getMinutesFromTimeInput(maximumWorkLimitInDay);
        const __startTime = getMinutesFromTimeInput(startTime);
        const __endTime = getMinutesFromTimeInput(endTime);

        const timeValidation = validateStartAndEndTime(__startTime, __endTime);
        if (timeValidation.hasError) {
            return updateData({ hasError: true, errors: [{ error: timeValidation.error }] });
        }

        if (__bufferTime > __normalWorkDay)
            return updateData({ hasError: true, errors: [{ "error": "Buffer time should be less then equal to normal work day" }] });

        if (maximumWorkLimitInWeek > 100 || maximumWorkLimitInWeek < 1)
            return updateData({ hasError: true, errors: [{ "error": "Maximum works hours should be between 1 to 100" }] });

        if (__maximumWorkLimitInDay && __maximumWorkLimitInDay < __normalWorkDay) {
            if (isOvertimeEditMode)
                return updateData({ hasError: true, errors: [{ "error": "The daily work hours limit cannot be less than standard work hours" }] });
            else
                __maximumWorkLimitInDay = __normalWorkDay;
        }

        const shift = {
            id: val?.id || null,
            shift_name: shiftName,
            start_time: __startTime,
            end_time: __endTime,
            break_time_minutes: breakTimeMinutes,
            buffer_time: __bufferTime,
            normal_work_day: __normalWorkDay,
            maximum_work_limit_in_day: __maximumWorkLimitInDay ? __maximumWorkLimitInDay : __normalWorkDay,
            maximum_work_limit_in_week: maximumWorkLimitInWeek * 60,
            overtime_threshold: overtimeThreshold,
            rest_between_shifts: getMinutesFromTimeInput(minimumDiffBetweenShift),
            allow_average_overtime: allowAverageOvertime,
            period: period,
        }

        const validFields = ["shift_name", "maximum_work_limit_in_day","maximum_work_limit_in_week", "period"];
        const validMessage = ["Shift Name", "Maximum Work Limit In Day","Maximum Work Limit In Week", "Period"];
        const errors = validateRequestPayload(shift, validFields, validMessage, t);

        if (errors)
            return updateData({ hasError: true, errors: errors });
        updateData(shift, saveAndReload);
    }

    const checkIfDataModified = (cb, param) => {
        const isDataModified = hasDataChanged(val, {
            startTime, endTime, breakTimeMinutes,
            bufferTime, maximumWorkLimitInDay,
            maximumWorkLimitInWeek, minimumDiffBetweenShift, overtimeThreshold
        }, isOvertimeEditMode);
        if (isDataModified) {
            confirmAlert({
                title: t('Unsaved changes!'),
                message: t('Current changes are not saved, please save and then switch edit mode'),
                buttons: [
                    {
                        label: t('Save Changes'),
                        onClick: () => saveData(true)
                    },
                    {
                        label: t('Cancel'),
                        onClick: cancel
                    }
                ],
            });
        } else {
            cb(param);
        }
    }

    const handleCompanyShiftOvertimeDelete = (idx) => {
        if (!isOvertimeEditMode)
            return commonStore.addNotification(t('Please enable overtime editing first'), null, 'warning');
        const __currentOvertimeSetting = companyShiftOvertimes[idx];
        if (!__currentOvertimeSetting || !__currentOvertimeSetting.id) return;
        setLoading(true);
        deleteOvertime(__currentOvertimeSetting.id);
    }

    const deleteOvertime = (id) => {
        handleDelete(id, async (id) => {
            try {
                await clientStore.deleteCompanyShiftOvertime(id);
                const __companyShiftOvertimes = companyShiftOvertimes.filter(e => e.id !== id);
                setCompanyShiftOvertimes(__companyShiftOvertimes);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                console.error(err)
            }
        }, null, () => {
            setLoading(false);
        })
    }

    const handleCompanyShiftOvertimeForm = (index, isAdd = false) => {
        if (!isOvertimeEditMode)
            return commonStore.addNotification(t('Please enable overtime editing first'), null, 'warning');
        if (index === null && !isAdd) {
            setCurrentOvertimeSetting(null);
            setShowOvertimeForm(false);
        }
        else {
            const data = {
                id: null,
                overtime_setting_id: null,
                from: 0,
                to: 0
            };
            if (!isAdd) {
                const _currentShiftOvertime = companyShiftOvertimes[index];
                data.id = _currentShiftOvertime.id;
                data.overtime_setting_id = _currentShiftOvertime.overtime_setting_id
                data.from = _currentShiftOvertime.from
                data.to = _currentShiftOvertime.to
            }
            setCurrentOvertimeSetting(data)
            setShowOvertimeForm(true);
        }
    }

    const handleCompanyShiftOvertime = (data) => {
        try {
            if (data.hasError)
                return commonStore.addNotification(t(data.errors[0].error), null, 'warning');
            data.company_shift_id = val.id;
            setLoading(true);
            saveShiftOvertime(data);
        } catch (err) {
            return commonStore.addNotification(t(err.message || 'Something went wrong!'), null, 'error');
        }
    }

    const saveShiftOvertime = async (data) => {
        try {
            let result;
            if (data.id)
                result = await clientStore.updateCompanyShiftOvertime(data.id, data);
            else
                result = await clientStore.saveCompanyShiftOvertime(data);
            const { companyShiftOvertime } = result;
            const __companyShiftOvertimes = companyShiftOvertimes.filter(e => e.id !== companyShiftOvertime.id);
            __companyShiftOvertimes.push(companyShiftOvertime);
            setCompanyShiftOvertimes(__companyShiftOvertimes);
            setLoading(false);
            handleCompanyShiftOvertimeForm(null, null);
            return commonStore.addNotification(t('Successfully added overtime'), null, 'success');
        } catch (err) {
            setLoading(false);
            return commonStore.addNotification(t(err.message || 'Something went wrong!'), null, 'error');
        }
    }

    const handleModeChange = (isOvertimeEdit) => {
        checkIfDataModified(setIsOvertimeEditMode, isOvertimeEdit)
    }

    translateLabels(periods, t, 'label');

    if (isLoading)
        return <LoadingSpinner />

    return (
        <>
            <Row className="my-4">
                <Col sm={4} xs={6}>
                    <label className="control-label">{t('Name')} <RequiredStar /></label>
                    <Input
                        placeholder={t('Enter name')}
                        value={shiftName}
                        onChange={(e) => setShiftName(e.target.value)}
                    />
                </Col>
                {commonStore.config?.client?.data?.basicRules?.allowWeeklyCalculation &&
                    commonStore.config?.client?.data?.basicRules?.weeklyCalculationSettings?.allowAverageOvertimeCalculation &&
                    <Col sm={8} xs={6} className="d-flex mt-4 pt-10">
                        <SwitchWidget
                            value={allowAverageOvertime}
                            onChange={checked => setAllowAverageOvertime(checked)}
                        />
                        <label className="control-label ms-3">{t('Include Average Overtime function to this shift')}</label>
                        <div className='ms-2'>
                            <OverlayTrigger
                                key="allowAverageOvertime"
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        {t("Enabling this feature will automatically set the Overtime Period to week. The Average Overtime Calculation function calculates the average overtime worked over a specified period. It helps track and manage overtime hours, ensuring compliance with company policies and labor regulations and it includes in all future shifts")}
                                    </Tooltip>
                                }>
                                <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                            </OverlayTrigger>
                        </div>
                    </Col>
                }
            </Row>
            <h3 >{t('Standard Hours')}
                {isOvertimeEditMode &&
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="tooltip-top">
                                {t("Enable Standard hours editing")}
                            </Tooltip>
                        }
                    >
                        <i
                            style={{ color: "#2550ac" }}
                            className="fa fa-edit ms-2 font-size-15 cursor-pointer"
                            onClick={() => handleModeChange(false)}
                        />
                    </OverlayTrigger>
                }
            </h3>
            <Row className="my-4">
                <Col sm={2} xs={6}>
                    <label className="control-label">{t('Start time')} <RequiredStar />
                        <span className="text-muted "><em> (hh:mm)</em></span></label>
                    <TimePickerInput
                        style={{ width: 150 }}
                        disabled={isOvertimeEditMode}
                        value={startTime}
                        format={dateTimeRules.time_format === "hh:mm" ? "HH:mm" : dateTimeRules.time_format}
                        onChange={(e) => { setStartTime(e) }}
                    />
                </Col>
                <Col sm={2} xs={6}>
                    <label className="control-label">{t('End time')} <RequiredStar />
                        <span className="text-muted "><em> (hh:mm)</em></span></label>
                    <TimePickerInput
                        style={{ width: 150 }}
                        disabled={isOvertimeEditMode}
                        value={endTime}
                        format={dateTimeRules.time_format === "hh:mm" ? "HH:mm" : dateTimeRules.time_format}
                        onChange={(e) => setEndTime(e)}
                    />
                </Col>
                <Col sm={2} xs={6}>
                    <label className="control-label">{t('Break time')} <RequiredStar /></label>
                    <Select
                        options={breakMinOptions}
                        isDisabled={isOvertimeEditMode}
                        value={breakMinOptions.find(item => item.value === breakTimeMinutes)}
                        onChange={evt => {
                            setBreakTimeMinutes(evt.value)
                        }}
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col sm={{ span: 3, offset: 1 }} xs={6}>
                    <label className="control-label">{t('Normal Work Day')} <RequiredStar />
                        <span className="text-muted "><em> (hh:mm)</em></span>
                    </label>
                    <InputTime
                        width={"auto"}
                        disabled
                        value={normalWorkDay}
                    />
                </Col>
            </Row>
            <Row className="my-3">
                <Col sm={3} xs={4}>
                    <label className="control-label">{t('Buffer time')}
                        <span className="text-muted "><em> (hh:mm)</em></span></label>
                    <InputTime
                        width={"auto"}
                        disabled={isOvertimeEditMode}
                        value={bufferTime}
                        onChange={(e) => setBufferTime(e)}
                    />
                </Col>
                <Col sm={3} xs={4}>
                    <label className="control-label">{t('Rest time between shifts')}
                        <span className="text-muted "><em> (hh:mm)</em></span></label>
                    <InputTime
                        width={"auto"}
                        disabled={isOvertimeEditMode}
                        value={minimumDiffBetweenShift}
                        onChange={(e) => setMinimumDiffBetweenShift(e)}
                    />
                </Col>
            </Row>
            <h3 >{t('Overtime Settings')}
                {!isOvertimeEditMode &&
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Tooltip id="tooltip-top">
                                {t("Enable Overtime Editing")}
                            </Tooltip>
                        }
                    >
                        <i
                            style={{ color: "#2550ac" }}
                            className="fa fa-edit ms-2 font-size-15 cursor-pointer"
                            onClick={() => handleModeChange(true)}
                        />
                    </OverlayTrigger>
                }
            </h3>
            <Row className="mb-5">
                <Col sm={4} xs={5}>
                    <label className="control-label">{t('Maximum Works hours in day')} <RequiredStar />
                        <span className="text-muted "><em> (hh:mm)</em></span></label>
                    <InputTime
                        width={"auto"}
                        value={maximumWorkLimitInDay || normalWorkDay}
                        onChange={e => { setMaximumWorkLimitInDay(e) }}
                        disabled={!isOvertimeEditMode}
                    />
                </Col>
                <Col sm={6} xs={12}>
                    <label className="control-label">{t('Overtime threshold')} <RequiredStar /></label>
                    <div className="slider-parent">
                        <input type="range" min="1" max="60"
                            onChange={event => setOvertimeThreshold(event.target.value)}
                            value={overtimeThreshold}
                            disabled={!isOvertimeEditMode}
                        />
                        <div className="buble">
                            {overtimeThreshold} {t('Minutes')}
                        </div>
                    </div>
                </Col>
                {allowAverageOvertime ?
                    <div className="text-danger mt-2">
                        <i>
                            {t('Weekly overtime limits be overwritten by average overtime settings')}
                        </i>
                    </div>
                    : <></>}
                <Col sm={4} >
                    <label className="control-label">{t('Maximum Works hours in week')} <RequiredStar /></label>
                    <NumberInput
                        width={"auto"}
                        value={maximumWorkLimitInWeek}
                        onChange={e => { setmaximumWorkLimitInWeek(e.target.value) }}
                        disabled={!isOvertimeEditMode || allowAverageOvertime}
                    />
                </Col>
                <Col sm={4}>
                    <label className="control-label">{t('Period')} <RequiredStar /></label>
                    <Select
                        options={periods}
                        isDisabled={true}
                        value={periods.find(item => item.value === period)}
                        onChange={evt => {
                            setPeriod(evt.value)
                        }}
                        placeholder={`${t('Select')} ${t('Period')}`}
                        style={{ width: '100%' }}
                    />
                </Col>
                {val.id &&
                    <Col sm={12}>
                        <hr />
                        <Row className='mt-3 align-items-center'>
                            <Col xs={8}>
                                <label className="control-label">{t('Assigned Overtimes')} <RequiredStar /></label>
                            </Col>
                            <Col xs={4} className='d-flex justify-content-end'>
                                <Button wd fill
                                    disabled={!isOvertimeEditMode}
                                    onClick={() => handleCompanyShiftOvertimeForm(null, true)}>
                                    <i className="fa fa-plus-circle fa-xl margin-right-10" />
                                </Button>
                            </Col>
                        </Row>
                        <TableWidget
                            headers={['Overtime', 'From', 'To']}
                            metaData={[
                                { field: 'name', type: 'text' },
                                { field: 'from', type: 'text' },
                                { field: 'to', type: 'text' }
                            ]}
                            data={formatData(companyShiftOvertimes, dateTimeRules)}
                            minRemoveIndex={0}
                            onChange={_ => { }}
                            page={'companyOvertimeSetting'}
                            customDeleteHandler={handleCompanyShiftOvertimeDelete}
                            customFormHandler={(index) => handleCompanyShiftOvertimeForm(index, false)}
                        />
                    </Col>
                }
            </Row>
            <div className="position-fixed" style={{ bottom: 10, backgroundColor: "white" }}>
                <Button wd me2 fill onClick={() => saveData(false)}>{t("Save")}</Button>
                <Button wd ms2 onClick={() => checkIfDataModified(cancel)}>{t("Cancel")}</Button>
            </div>
            {showOvertimeForm &&
                <AddCompanyShiftOvertime
                    values={currentOvertimeSetting}
                    submit={(data) => handleCompanyShiftOvertime(data)}
                    cancel={() => handleCompanyShiftOvertimeForm(null, false)}
                    dateTimeRules={dateTimeRules}
                    t={t}
                    overtimeSettingList={overtimeSettingList}
                />
            }
        </>
    )
}

export default inject('commonStore', 'clientStore')(withRemoveDialog(AddShift));