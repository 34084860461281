import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter';
import { inject, observer } from 'mobx-react';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import _ from 'lodash';
import { statusMap } from '~/utils/normalStatuses';
import withLocalization from '~/hoc/withLocalization';
import { Row, Col } from 'react-bootstrap';
import { getMinutesFromTimeInput } from '../../../utils/timeCalc';

class VacationsTotals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedUser: null,
        };
    }

    loadData() {
        if (!this.props.user) {
            this.setState({ loadedUser: this.props.userStore.currentUser });
        } else {
            this.props.userStore.pullUserById(this.props.user).then((user) => {
                this.setState({ loadedUser: user });
            });
        }
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.user, this.props.user)) {
            this.loadData();
        }
    }

    render() {
        const { t, commonStore, userStore } = this.props;
        const { yearlyTotalVacation } = userStore;
        const { loadedUser } = this.state;
        const basicRules = commonStore?.config?.client?.data?.basicRules;
        const totalShiftTime = getMinutesFromTimeInput(commonStore?.config?.client?.data?.basicRules?.startTimeRules?.total);
        const totalHourSaved = loadedUser?.timeBankHours?.total_hours_saved;
        if (!loadedUser) {
            return <div>...</div>;
        }
        return (
            <Row className="mb-4 align-items-center">
                <Col md={9} lg={9} className={'mt-2'}>
                    <Row>
                        <Col md={2} sm={4} xs={6} className={'mt-2'}>
                            <div className="vacation-panel__item vacation-panel__divider">
                                <span className="vacation-panel__value">{moment(new Date()).year()}</span>
                                <span title={t('Year')} className="vacation-panel__label">{t('Year')}</span>
                            </div>
                        </Col>
                        <Col md={2} sm={4} xs={6} className={'mt-2'}>
                            <div className="vacation-panel__item">
                                <span className="vacation-panel__value">{loadedUser.employee_percent}%</span>
                                <span title={t('Employment')} className="vacation-panel__label">{t('Employment')}</span>
                            </div>
                        </Col>
                        <br />
                        <Col md={2} sm={4} xs={6} className={'mt-2'}>
                            <div className="vacation-panel__item">
                                <span className="vacation-panel__value">{yearlyTotalVacation.vacationsTotal}</span>
                                <span title={t('Total Days')} className="vacation-panel__label vacation-panel__gradient_underline">{t('Total Days')}</span>
                            </div>
                        </Col>
                        <Col md={2} sm={4} xs={6} className={'mt-2'}>
                            <div className="vacation-panel__item">
                                <span className="vacation-panel__value">{yearlyTotalVacation.vacationDays}</span>
                                <span title={t('approved')} className="vacation-panel__label vacation-panel__gradient_underline">{t('approved')}</span>
                            </div>
                        </Col>
                        <Col md={2} sm={4} xs={6} className={'mt-2'}>
                            <div className="vacation-panel__item">
                                <span className="vacation-panel__value">{yearlyTotalVacation.pendingVacation}</span>
                                <span title={t('Pending')} className="vacation-panel__label vacation-panel__gradient_underline">{t('Pending')}</span>
                            </div>
                        </Col>
                        <Col md={2} sm={4} xs={6} className={'mt-2'}>
                            <div className="vacation-panel__item">
                                <span className="vacation-panel__value">
                                    {yearlyTotalVacation.vacationsTotal -
                                        yearlyTotalVacation.vacationDays -
                                        yearlyTotalVacation.pendingVacation}
                                </span>
                                <span title={t('Remaining')} className="vacation-panel__label vacation-panel__gradient_underline">{t('Remaining')}</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={3} lg={3} className={'mt-3'}>
                    <Row className='d-flex align-items-center'>
                        {basicRules?.timeBankSettings?.allowTimeBank &&
                            <Col>
                                <div className="vacation-panel__item">
                                    <span className="vacation-panel__value fs-5">
                                        {totalHourSaved ? `${(Math.floor(totalHourSaved / totalShiftTime))}d ${Math.floor((totalHourSaved % totalShiftTime) / 60)}h ${((totalHourSaved % totalShiftTime) % 60)}m` : '0d 0h 0m'}
                                    </span>
                                    <span title={t('Time Bank')} className="vacation-panel__label vacation-panel__gradient_underline">{t('Time Bank')}</span>
                                </div>
                            </Col>
                        }
                        {this.props.currentvacation &&
                            <Col>
                                <div
                                    className={`status-block status-block__fixed-width status-${this.props.currentvacation ? this.props.currentvacation.vacation.status : null
                                        }`}
                                >
                                    {t(this.props.currentvacation ? statusMap[this.props.currentvacation.vacation.status] : null)}
                                </div>
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>
        );
    }
}
export default inject('absenceStore', 'userStore', 'commonStore')(applicationRouter(withLocalization(observer(VacationsTotals))));
