import { observable, action, makeObservable } from 'mobx';
import moment from 'moment/min/moment-with-locales';

import agent from '../agent';
import commonStore from './commonStore';

let socket = null;

class UserStore {
    constructor() {
        makeObservable(this, {
            currentUser: observable,
            loadingUser: observable,
            updatingUser: observable,
            updatingUserErrors: observable,
            currentMembersLookup: observable,
            lookupNeedsUpdate: observable,
            loadingLookup: observable,
            loadingLookupMode: observable,
            loadingLookupPromise: observable,
            usersById: observable,
            isNotificationDockOpen: observable,
            isSidebarOpen: observable,
            isMessageNotificationOpen: observable,
            newNotificationsCount: observable,
            messageNotificationsCount: observable,
            dockNotifications: observable,
            addPossibility: observable,
            yearlyTotalVacation: observable,
            hideNotificationDock: action,
            showNotificationDock: action,
            hideSidebar: action,
            showSidebar: action,
            hideMessageNotificationDock: action,
            showMessageNotificationDock: action,
            setupIo: action,
            loadNotifications: action,
            clearNotifications: action,
            filters: observable,
            appliedFilters: observable,
            setFilter: action,
            onFilter: action,
            pullUser: action,
            pullUserById: action,
            updateUser: action,
            forgetUser: action,
            currentList: observable,
            currentEntity: observable,
            updatingErrors: observable,
            updating: observable,
            loading: observable,
            requestParams: observable,
            deleteSuccess: observable,
            lastListLoadTime: observable,
            messageNotifications: observable,
            resetLastListLoadTime: action,
            loadList: action,
            returnDefaultNew: action,
            load: action,
            save: action,
            getMultipleAttachments: action,
            fileUpload: action,
            addAttachments: action,
            removeUserAttachment: action,
            remove: action,
            loadLookup: action,
            loadByProjectId: action,
            clearLookupValues: action,
            sendReport: action,
            getReportInfo: action,
            validateNewUser: action,
            validateAddPossible: action,
            increaseUserLimit: action,
            saveInviteUser: action,
            updateAttachmentName: action,
            setActiveInactive: action,
            readNotifications: action,
            saveIntoIntegration: action,
            checkIfValidLink: action,
            setSecurePassword: action,
            getAllEmployeeFromIntegration: action,
            saveMemberFromIntegration: action,
            removeMultiAdminToken: action,
            getUsersWithGroup: action,
            getPmMembersToAssign: action,
            assignMembersToPm: action,
            getUserPermissions: action,
            setUserPermissions: action,
            updateUserProfileOnBasisOfGroups: action
        });
    }

    static currentUserPermissions = null;
    currentUser = null;
    loadingUser = null;
    updatingUser = null;
    updatingUserErrors = null;
    currentMembersLookup = {};
    lookupNeedsUpdate = false;
    loadingLookup = null;
    loadingLookupMode = false;
    loadingLookupPromise = false;
    usersById = {};
    isNotificationDockOpen = false;
    isMessageNotificationOpen = false;
    isSidebarOpen = false;
    newNotificationsCount = 0;
    messageNotificationsCount = 0;
    dockNotifications = [];
    messageNotifications = [];
    addPossibility = {
        allowed: true,
    };
    yearlyTotalVacation = { vacationDays: 0, vacationsLeft: 0, vacationsTotal: 0, pendingVacation: 0 };
    filters = {
        status: '',
        name: '',
    };
    appliedFilters = {
        status: '',
        name: '',
    };
    currentList = [];
    currentEntity = {};
    updatingErrors = null;
    updating = false;
    loading = false;
    requestParams = null;
    deleteSuccess = false;
    lastListLoadTime = null;

    hideNotificationDock() {
        this.isNotificationDockOpen = false;
    }

    showNotificationDock() {
        this.isNotificationDockOpen = true;
    }

    hideSidebar() {
        this.isSidebarOpen = false;
    }

    showSidebar() {
        this.isSidebarOpen = true;
    }

    hideMessageNotificationDock() {
        this.isMessageNotificationOpen = false;
    }

    showMessageNotificationDock() {
        this.isMessageNotificationOpen = true;
    }

    setupIo(_socket) {
        socket = _socket;
        if (this.currentUser) {
            socket.removeAllListeners('notification-new');
            socket.on('notification-new', async (data) => {
                await this.loadNotifications();
            });
        }
    }

    static updateUserPermissions(userPermissions) {
        if (!userPermissions || !userPermissions.length) return;
        UserStore.currentUserPermissions = {};
        userPermissions.forEach(ele => UserStore.currentUserPermissions[ele.module] = ele.permissions);
    }

    static resetUserPermissions() {
        UserStore.currentUserPermissions = null;
    }

    static checkUserPermissioForModule(moduleName, actionName) {
        if (UserStore.currentUserPermissions[moduleName][actionName]) return true;
        return false;
    }

    async loadNotifications() {
        const notificationsResponse = await agent.Users.getNotifications();
        this.dockNotifications = notificationsResponse.notifications || [];
        this.messageNotifications = notificationsResponse.messageNotifications || [];
        if (this.dockNotifications) this.newNotificationsCount = this.dockNotifications.length ? this.dockNotifications.filter(ele => ele.is_unread === true).length : 0;
        if (this.messageNotifications) this.messageNotificationsCount = this.messageNotifications.length ? this.messageNotifications.filter(ele => ele.is_unread === true).length : 0;
    }


    async clearNotifications(type) {
        if (type === "notifications") {
            this.dockNotifications = [];
            this.newNotificationsCount = 0;
            this.isNotificationDockOpen = false;
            let currentUser = await agent.Users.clearNotifications({ notification_type: "notifications" });
            if (currentUser.user) currentUser = currentUser.user;
            this.currentUser = currentUser;
        }
        else {
            this.messageNotifications = [];
            this.messageNotificationsCount = 0;
            this.isMessageNotificationOpen = false;
            let currentUser = await agent.Users.clearNotifications({ notification_type: "chat" });
            if (currentUser.user) currentUser = currentUser.user;
            this.currentUser = currentUser;
        }
        UserStore.updateUserPermissions(this.currentUser?.userPermissions);
    }

    async readNotifications(id) {
        try {
            const result = await agent.Users.readNotification({ notification_id: id });
            if (result) {
                this.loadNotifications();
            }
        }
        catch (e) {
            console.error(e);
            commonStore.addNotification(e, null, 'error');
        }
    }


    setFilter(name, value, clearFilter) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
        if (clearFilter) {
            this.filters = {}
        }
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    pullUser() {
        this.loadingUser = true;
        return agent.Auth.current()
            .then(
                action(({ user }) => {
                    this.currentUser = user;
                    UserStore.updateUserPermissions(this.currentUser?.userPermissions);
                    if (this.currentUser.hasMultiAccountAdminAccess) {
                        window.sessionStorage.setItem('multiAdminJwt', window.sessionStorage.getItem('jwt'));
                    }
                })
            )
            .finally(
                action(() => {
                    this.loadingUser = false;
                })
            );
    }

    pullUserById(id) {
        this.loadingUser = true;
        return agent.Auth.loadById(id).then(
            action(({ user }) => {
                this.usersById[id] = user;
                this.yearlyTotalVacation.vacationDays = user.Totals && user.Totals.yearly && user.Totals.yearly.vacationDays ? user.Totals.yearly.vacationDays : 0;
                this.yearlyTotalVacation.vacationsLeft = user.Totals && user.Totals.yearly && user.Totals.yearly.vacationLeft ? user.Totals.yearly.vacationLeft : 0;
                this.yearlyTotalVacation.vacationsTotal = user.Totals && user.Totals.yearly && user.Totals.yearly.vacationsTotal ? user.Totals.yearly.vacationsTotal : 0;
                this.yearlyTotalVacation.pendingVacation = user.Totals && user.Totals.yearly && user.Totals.yearly.pendingVacation ? user.Totals.yearly.pendingVacation : 0;
                return user;
            })
        );
    }

    updateUser(newUser) {
        this.updatingUser = true;
        this.updatingUserErrors = null;
        return agent.Auth.save(newUser)
            .then(
                action(({ user }) => {
                    this.currentUser = user;
                    UserStore.updateUserPermissions(this.currentUser?.userPermissions);
                })
            )
            .catch(
                action((err) => {
                    this.updatingUser = false;
                    this.updatingUserErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    forgetUser() {
        this.currentUser = undefined;
        UserStore.resetUserPermissions();
        commonStore.setToken(undefined);
        window.sessionStorage.removeItem('jwt');
        window.sessionStorage.removeItem('multiAdminJwt');
    }

    removeMultiAdminToken() {
        window.sessionStorage.removeItem('multiAdminJwt');
    }

    resetLastListLoadTime(value) {
        this.lastListLoadTime = value;
    }

    loadList(params, mode) {
        const _params = Object.assign({}, params);
        _params.mode = mode;
        return agent.Users.list(_params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    returnDefaultNew(mode, client_id, additionalParams, user, vacation_days, country_code) {
        if (mode === 'superadmins') {
            this.currentEntity = {
                user_type: 'super-admin',
            };
        } else {
            this.currentEntity = {
                user_type: 'admin',
                client_id,
            };
            if (mode === 'members') {
                this.currentEntity.user_type = 'member';
            }
            if (mode === 'managers') {
                this.currentEntity.user_type = 'pm';
            }
            this.currentEntity.employee_percent = 100;
            this.currentEntity.vacation_days = vacation_days;
            // if (country_code) this.currentEntity.phone = country_code;
        }
        if (additionalParams) {
            Object.assign(this.currentEntity, additionalParams);
        }
        if (user && user.user_type === 'pm' && user.ManagesProjects) {
            this.currentEntity.MemberInProjects = user.ManagesProjects.join(',');
        }
        this.loading = false;
        this.currentEntity = { user: this.currentEntity };
    }

    load(id) {
        return agent.Users.load(id)
            .then(
                action((response) => {
                    if (!response.user.employee_percent) response.user.employee_percent = 100;
                    response.user.employee_percent = Number(response.user.employee_percent);
                    this.currentEntity = response;
                    this.currentEntity.user.generic_pin = +this.currentEntity.user.generic_pin;
                    this.currentEntity.user.timelog_start_from = this.currentEntity.user.timelog_start_from
                        ? moment(this.currentEntity.user.timelog_start_from).format('YYYY-MM-DD')
                        : null;
                    if (this.currentEntity.user.user_type === "super-admin") {
                        this.currentEntity.user.clientEnabledBillableHours = false;
                        this.loading = false;
                        return response;
                    }

                    return agent.Clients.load(this.currentEntity.user.client_id).then((response2) => {
                        this.currentEntity.user.clientEnabledBillableHours =
                            (response2 &&
                                response2.client &&
                                response2.client.data &&
                                response2.client.data.basicRules &&
                                response2.client.data.basicRules.trackBillableHours) ||
                            false;
                        this.loading = false;
                        return response;
                    });
                })
            )
            .catch(
                action((err) => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    save(values, isAdd, mode) {
        this.currentMembersLookup = {};
        this.updating = true;
        if (values.data && values.data.kids && mode === 'profile') {
            let kidsOK = true;
            values.data.kids.forEach((kid) => {
                if (!kid.date || moment(new Date()).diff(moment(new Date(kid.date)), 'years') > 18) {
                    this.updating = false;
                    kidsOK = false;
                    this.updatingErrors = { message: 'Kid age is above 18' };
                }
            });
            if (!kidsOK) return Promise.resolve(this.updatingErrors);
        }
        return agent.Users.save(values, isAdd)
            .then(
                action((user) => {
                    user.user.employee_percent = Number(user.user.employee_percent);
                    user.user.MemberInProjects = user.MemberInProjects;
                    this.currentEntity = user;
                    return user;
                })
            )
            .catch(
                action((err) => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    getMultipleAttachments(data) {
        return agent.Users.getMultipleAttachments(data)
            .then(
                action(response => {
                    return response;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    fileUpload(params) {
        if (params?.to_model === 'ServiceRequest' && !params?.user_id) {
            return agent.ServiceRequests.uploadFile(params).then(
                action((response) => {
                    return response.id;
                })
            );
        }
        return agent.Users.uploadImage(params).then(
            action((response) => {
                return response.id;
            })
        );
    }

    addAttachments(data) {
        return agent.Users.addAttachments(data)
            .then(
                action((response) => {
                    return response;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    async removeUserAttachment(id) {
        let result = await agent.Users.removeUserAttachment(id);
        return result;
    }


    async remove(id) {
        await agent.Users.remove(id);
        this.deleteSuccess = true;
        return 1;
    }

    async loadLookup(mode, name, project = "", includeInactiveUsers = false) {
        const key = `${mode || 'all'}${project}${name || ''}`;
        if (!name && this.currentMembersLookup[key]) {
            return this.currentMembersLookup[key];
        }
        if (this.loadingLookup && this.loadingLookupPromise && (this.loadingLookupMode && this.loadingLookupMode === mode)) {
            return this.loadingLookupPromise;
        }
        this.loadingLookup = true;
        this.loadingLookupMode = mode;
        this.loadingLookupPromise = new Promise((resolve) => {
            return agent.Users.lookupByName(mode, name, project, includeInactiveUsers).then((list) => {
                this.currentMembersLookup[key] = list;
                this.loadingLookup = false;
                this.loadingLookupMode = null;
                resolve(list);
                this.lookupNeedsUpdate = false;
                return list;
            });
        });
        return this.loadingLookupPromise;
    }

    async loadByProjectId(project_id) {
        return agent.Users.byProjectId(project_id)
            .then(
                action(response => {
                    return response;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    clearLookupValues() {
        this.currentMembersLookup = {};
        this.loadLookup('managers', '');
    }

    async sendReport(params) {
        const res = await agent.Users.sendReport(params);
        return res;
    }

    async getReportInfo(params) {
        const res = await agent.Users.getReportInfo(params);
        return res;
    }

    async validateNewUser(params) {
        const res = await agent.Users.validateNewUser(params);
        return res;
    }

    async validateAddPossible() {
        const res = await agent.Users.validateAddPossible();
        this.addPossibility = res;
        return res;
    }

    async increaseUserLimit() {
        const res = await agent.Users.increaseUserLimit();
        this.addPossibility = res;
        return res;
    }

    saveInviteUser(values) {
        this.updating = true;
        return agent.Users.saveInviteUser(values)
            .then(
                action(user => {
                    this.currentEntity = user;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    async updateAttachmentName(data) {
        return agent.Users.updateAttachmentName(data)
            .then(
                action((response) => {
                    return response;
                })
            ).catch(
                action((error) => {
                    throw error;
                })
            )
    }

    setActiveInactive(values) {
        this.updating = true;
        return agent.Users.setActiveInactive(values)
            .then(
                action(data => {
                    this.currentEntity = data;
                    return data;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    startLoading() {
        this.loading = true;
    }

    saveIntoIntegration(values) {
        return agent.Users.saveIntoIntegration(values)
            .then(
                action(user => {
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    desyncIntegration(values) {
        return agent.Users.desyncIntegration(values)
            .then(
                action(user => {
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    checkIfValidLink(id) {
        return agent.Users.checkIfValidLink(id)
            .then(
                action((response) => {
                    return response;
                })
            ).catch(
                action((error) => {
                    throw error;
                })
            )
    }

    setSecurePassword(data) {
        return agent.Users.setSecurePassword(data)
            .then(
                action((response) => {
                    return response;
                })
            ).catch(
                action((error) => {
                    throw error;
                })
            )
    }

    getDepartmentForIntegration() {
        return agent.Users.getDepartmentForIntegration()
            .then(
                action((response) => {
                    return response;
                })
            ).catch(
                action((error) => {
                    throw error;
                })
            )
    }

    resetUserSickLeaveStartDate(token) {
        const data = {
            sickLeaveResetToken: token
        }
        return agent.Users.resetUserSickLeaveStartDate(data)
            .then(
                action((response) => {
                    return response;
                })
            ).catch(
                action((error) => {
                    throw error;
                })
            )

    }
    getAllEmployeeFromIntegration(params) {
        return agent.Users.getAllEmployeeFromIntegration(params).then(
            action(UsersList => {
                return UsersList
            })
        ).catch(
            action(err => {
                this.updating = false;
                this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                throw err;
            })
        );
    }

    saveMemberFromIntegration(data) {
        return agent.Users.saveMemberFromIntegration(data).then(
            action(res => {
                return res
            })
        ).catch(
            action(err => {
                this.updating = false;
                this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                throw err;
            })
        )
    }

    getUsersWithGroup() {
        return agent.Users.getUsersWithGroup().then(
            action(res => {
                return res
            })
        ).catch(
            action(err => {
                this.updating = false;
                this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                throw err;
            })
        )
    }

    getPmMembersToAssign(pm_id) {
        return agent.Users.getPmMembersToAssign(pm_id).then(
            action(res => {
                return res;
            })
        ).catch(
            action(err => {
                throw err;
            })
        );
    }

    assignMembersToPm(pm_id, data) {
        return agent.Users.assignMembersToPm(pm_id, data).then(
            action(res => {
                return res;
            })
        ).catch(
            action(err => {
                throw err;
            })
        )
    }

    getUserPermissions(user_id) {
        return agent.Users.getUserPermissions(user_id).then(
            action(res => {
                return res;
            })
        ).catch(
            action(err => {
                throw err;
            })
        )
    }

    setUserPermissions(data) {
        return agent.Users.setUserPermissions(data).then(
            action(res => {
                return res;
            })
        ).catch(
            action(err => {
                throw err;
            })
        )
    }
    updateUserProfileOnBasisOfGroups(data) {
        return agent.Users.updateUserProfileOnBasisOfGroups(data).then(
            action(res => {
                return res;
            })
        ).catch(
            action(err => {
                throw err;
            })
        )
    }
}

const _UserStore = new UserStore()
export default _UserStore;
export const checkUserPermissions = (moduleName, actionName) => UserStore.checkUserPermissioForModule(moduleName, actionName);
export const getUserPermissions = () => UserStore.currentUserPermissions;