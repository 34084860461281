import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';
import GenericList from '../../../components/GenericList/GenericList.js';
import TaskTemplateForm from './TaskTemplateForm.jsx';
import TaskTemplateView from './TaskTemplateView.jsx';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import LoadingSpinner from '~/elements/LoadingSpinner';

//utils
import customerFeature from '~/utils/customerFeature';

//assets
import Edit from '../../../assets/img/editing.svg';
import Delete from '../../../assets/img/deleting.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';

class TaskTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectId: props.projectId,
            projectName: props.projectName,
            currentTaskTemplateId: 0,
            isAddEdit: false,
            loading: true,
            assignedIds: "",
            isView: false
        };
    }

    renderTableActionButtons = (uuid) => {
        const { user_type } = this.props.userStore.currentUser;
        const { viewTaskTemplates } = this.props;
        const allow = (user_type === 'pm' || user_type === 'admin') && customerFeature('pm_allow_editing_all');

        return (
            <div className="actions-center">
                {!viewTaskTemplates &&
                    <Button icon_sm fill onClick={() => this.handleTableButtonAction(uuid, 'view')}>
                        <i className="fa fa-eye actionblueicon" />
                    </Button>
                }
                <Button icon_sm fill onClick={() => this.handleTableButtonAction(uuid, 'edit')}>
                    <img src={Edit} alt={"edit button"} />
                </Button>
                {allow && viewTaskTemplates &&
                    <Button
                        icon_sm_delete
                        fill
                        onClick={() => this.handleTableButtonAction(uuid, 'remove')}
                    >
                        <img src={Delete} alt="delete button" />
                    </Button>
                }
            </div>
        );
    };

    handleTableButtonAction = (uuid, type) => {
        switch (type) {
            case 'add': this.setState({ currentTaskTemplateId: uuid, isAddEdit: true }); break;
            case 'edit': this.setState({ currentTaskTemplateId: uuid, isAddEdit: true }); break;
            case 'remove': this.handleRemove(uuid); break;
            default: this.setState({ currentTaskTemplateId: uuid, isView: true });

        }
    }

    handleRemove = (uuid) => {
        const { t, commonStore } = this.props;
        this.props.handleDelete(uuid, (uuid) => {
            return this.props.taskTemplateStore.remove(uuid).then((res) => {
                this.props.taskTemplateStore.resetLastListLoadTime();
                return commonStore.addNotification(t('Template Removed'), null, 'success');
            }); 
        });
    }

    componentDidMount() {
        this.props.taskTemplateStore.setFilter('project', this.state.projectId);
        this.props.taskTemplateStore.onFilter();
        this.setState({ loading: false });
    }


    componentWillUnmount() {
        this.setState({
            page: 0,
            isPageAddedOrEdited: false,
            projectId: null,
            isAddEdit: false,
            currentTaskTemplateId: null,
            projectName: null,
            assignedIds: "",
            isView: false
        })
    }

    handleCloseForm = (type) => {
        if (type === 'view')
            this.setState({ currentTaskTemplateId: 0, isView: false })
        else
            this.setState({ currentTaskTemplateId: 0, isAddEdit: false })
    }

    verifyChangesAndAssignTaskTemplates = (selected) => {
        let selectedIds = [];
        Object.keys(selected).forEach(key => {
            if (selected[key]) selectedIds.push(key)
        })
        const { taskTemplateStore, t } = this.props;
        const { currentList } = taskTemplateStore;
        if (!currentList.selected) return this.assignTaskTemplates(selectedIds);
        if (currentList.selected && currentList.selected.split(',').some((id) => selectedIds.indexOf(id) === -1)) {
            confirmAlert({
                title: t('Confirm Task Completion'),
                message: t(
                    'You have removed assigned task template from the project, this will update the task status to completed or remove it completely if tasks has zero logged hours, do you want to proceed?'
                ),
                buttons: [
                    {
                        label: t('Yes'),
                        onClick: () => this.assignTaskTemplates(selectedIds),
                    },
                    {
                        label: t('No'),
                        onClick: () => taskTemplateStore.resetLastListLoadTime(),
                    },
                ],
            });
        } else return this.assignTaskTemplates(selectedIds)
    }

    assignTaskTemplates = (selectedIds) => {
        const { t, commonStore, taskTemplateStore } = this.props;
        taskTemplateStore.assignTemplates({
            task_template_ids: selectedIds.join(','),
            project_id: this.state.projectId
        })
            .then(res => {
                if (res.status === 'ok')
                    commonStore.addNotification(t(res.message), null, 'success');
                else
                    commonStore.addNotification(t('Something went wrong'), null, 'error');

            }).catch(err => commonStore.addNotification(t(err), null, 'success')
            ).finally(() => this.props.taskTemplateStore.resetLastListLoadTime());
    }

    render() {
        const { projectId, loading, isAddEdit, currentTaskTemplateId, projectName, isView } = this.state;
        const { filters, appliedFilters } = this.props.taskTemplateStore;
        const { viewTaskTemplates } = this.props;

        if (!projectId && !viewTaskTemplates) return <>No id found</>

        if (loading) return <LoadingSpinner />

        if (isAddEdit) return (
            <TaskTemplateForm
                taskTemplateUUID={currentTaskTemplateId}
                handleCloseForm={this.handleCloseForm}
                projectId={projectId}
                mode={viewTaskTemplates ? "task_template" : "assign_task_template"}
            />
        );

        return (
            <>
                <Modal
                    size="lg"
                    show={isView && !!currentTaskTemplateId}
                    onHide={() => this.handleCloseForm('view')}
                    centered
                >
                    <Modal.Header >
                        <Modal.Title className="w-100 text-center ApproveModal__title m-0">
                            {this.props.t('Task Template View')}
                        </Modal.Title>
                        <img
                            alt={this.props.t('close')}
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={() => this.handleCloseForm('view')}
                        />
                    </Modal.Header>
                    <Modal.Body style={{ minHeight: '40vh' }}>
                        <TaskTemplateView projectId={projectId} taskTemplateId={currentTaskTemplateId} />
                    </Modal.Body>
                </Modal>

                <GenericList
                    columns={[
                        {
                            Header: 'Task Template Name',
                            id: 'name',
                            accessor: ({ uuid, name }) => (
                                <span title={name}>
                                    <a
                                        className="list-main-column"
                                        onClick={() => this.handleTableButtonAction(uuid, 'edit')}
                                    >
                                        {name}
                                    </a>
                                </span>
                            ),
                            isSortable: true,
                            maxWidth: 120,
                            minWidth: 80,
                            textAlign: "start"
                        },
                        {
                            Header: 'Location',
                            id: 'location',
                            sortable: false,
                            accessor: ({ address, post_place, post_number }) => (
                                <span>
                                    {`${address ? `${address} ` : ''}${post_place ? `${post_place} ` : ''}${post_number ? `${post_number} ` : ''}`}
                                </span>
                            ),
                            maxWidth: 150,
                            minWidth: 120
                        },
                        {
                            Header: 'Post Number',
                            id: 'post_number',
                            sortable: false,
                            accessor: ({ post_number }) => (
                                <span>
                                    {post_number}
                                </span>
                            ),
                            maxWidth: 80,
                            minWidth: 60
                        },
                        {
                            Header: 'Description',
                            id: 'description',
                            accessor: ({ description }) => (
                                <div className='description'>
                                    {description}
                                </div>
                            ),
                            isSortable: true,
                            maxWidth: 250,
                            minWidth: 170
                        },
                        {
                            Header: 'Actions',
                            id: 'actions',
                            sortable: false,
                            accessor: (full) => this.renderTableActionButtons(full.task_template_id),
                            maxWidth: 80,
                            minWidth: 60
                        },
                    ]}
                    header={
                        <GenericFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.taskTemplateStore.setFilter(name, value)}
                            onFilter={() => this.props.taskTemplateStore.onFilter()}
                        />
                    }
                    tableHeader={projectName}
                    allowCheckboxes={!viewTaskTemplates}
                    onChangeStatus={(selected) => this.verifyChangesAndAssignTaskTemplates(selected)}
                    showStatus={!viewTaskTemplates}
                    checkBoxButtonName={"Assign Templates"}
                    cutomCheckBoxClass={"top-position"}
                    checkboxField={"task_template_id"}
                    filters={appliedFilters}
                    lastListLoadTime={this.props.taskTemplateStore.lastListLoadTime}
                    requestData={(params) => this.props.taskTemplateStore.loadList(params)}
                >
                    {viewTaskTemplates && <Button fill wd onClick={() => this.handleTableButtonAction(0, 'add')}>
                        <i className="fa fa-plus-circle fa-xl margin-right-10" />
                        {this.props.t('Add new')}
                    </Button>}
                </GenericList>
            </>

        );
    }
}

export default inject('commonStore', 'taskTemplateStore', 'userStore')(applicationRouter(withLocalization(withRemoveDialog(observer(TaskTemplateList)))));
