import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Select from '~/elements/Select.js';
import RequiredStar from '~/elements/RequiredStar.js';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { longDateFormat } from '../../../utils/dateFormat';
import DatePicker from '../../../components/DatePicker/DatePicker';
import Table from 'react-bootstrap/Table';
import React from "react";
import _ClientStore from '../../../library/stores/clientStore';
import _UserStore from '../../../library/stores/userStore';
import { useEffect } from 'react';
import { useState } from 'react';
import _CommonStore from '../../../library/stores/commonStore';
import _ProjectStore from '../../../library/stores/projectStore';
import _UserGroupStore from "../../../library/stores/userGroupStore";
import _Roster from "../../../library/stores/rosterStore";
import _TaskStore from "../../../library/stores/taskStore";
import CustomMultiSelect from '../../../elements/CustomMultiSelect';
import { validateRosterData, isTwoWeeksOrMore, getDayValue } from "./validateRoster";
import { Input } from "~/elements/Input";
import ShowMorePeople from './showMorePeople';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { useTranslation } from "react-i18next";
import { shortDateFormat } from '~/utils/dateFormat';
import { confirmAlert } from 'react-confirm-alert';
import { Form } from "react-bootstrap";
import Toast from 'react-bootstrap/Toast';
import { translateLabels } from '~/utils/commonMethods';


function CreateRoster() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let [companyShift, setCompanyShift] = useState([]);
    let [project, setProject] = useState([]);
    let [getWeekStartDay, setWetWeekStartDay] = useState(1);
    let [mandatoryOvertimeLimitPerWeek, setMandatoryOvertimeLimitPerWeek] = useState(); //max hours in week
    let [averageOvertimeCalculation, setAverageOvertimeCalculation] = useState(false); 
    let [group, setGroup] = useState([])
    let [task, setTask] = useState([])
    let [userList, setUserList] = useState([])
    let [showGroup, setShowGroup] = useState("group")
    const [name, setName] = useState();
    const [publishType, setPublishType] = useState();
    const [employee, setEmployee] = useState();
    const [start_date, setStartDate] = useState();
    const [end_date, setEndDate] = useState();
    const [groupEmployeeList, setGroupEmployeeList] = useState([]);
    const [showMode, setShowMode] = useState(false);
    const [repeatSchedule, setRepeatSchedule] = useState(true);
    const [selectedUserList, setSelectedUserList] = useState([]);
    let [formValue, setFormValue] = useState([])
    let [formValueIndex, SetFormValueIndex] = useState()
    const [override, setOverride] = useState(false);
    const [conflictList, setConflictList] = useState([]);
    const [saveAndPublish, setSaveAndPublish] = useState(false)
    const [submitPublishType, setSubmitPublishType] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertMessageEmployee, setAlertMessageEmployee] = useState('')

    const handleToggle = () => {
        setOverride(!override);
    };

    useEffect(() => {
        getUserByGroup()
    }, [employee])



    useEffect(() => {
        getCompanyShift();
        loadLookup();
        getGroup();
        taskStore();
        getUser();
        getStartDay();
    }, [])

    const getUserByGroup = async () => {
        if (showGroup == 'group' && employee) {
            let list = await _Roster.getuserByGroupId(employee);
            let userList = list.user.map((e) => {
                return {
                    label: e.first_name + " " + e.last_name,
                    value: e.id
                }
            })
            setGroupEmployeeList(userList)
        }
    }

    const getCompanyShift = async () => {
        const result = await _ClientStore.getCompanyShift(_UserStore.currentUser.client_id);
        let comShift = [];
        for (let shift of result.companyShift) {
            let startHour = Math.floor(parseInt(shift.start_time) / 60);
            let startmins = parseInt(shift.start_time) % 60;

            let endHours = Math.floor(parseInt(shift.end_time) / 60);
            let endMins = parseInt(shift.end_time) % 60;
            let level = {
                label: shift.shift_name + "-" + (`(${String(startHour).padStart(2, '0')}:${String(startmins).padStart(2, '0')} - ${String(endHours).padStart(2, '0')}:${String(endMins).padStart(2, '0')})`),
                value: shift.id
            }
            comShift.push(level);
        }
        // Sort by start time
        comShift.sort((a, b) => getStartTimeInMinutes(a.label) - getStartTimeInMinutes(b.label));
        setCompanyShift(comShift)

    }

    // Function to extract start time and convert to minutes
    const getStartTimeInMinutes = (label) => {
        const timePart = label.match(/\((\d{2}):(\d{2})/);
        if (timePart) {
            const hours = parseInt(timePart[1]);
            const minutes = parseInt(timePart[2]);
            return hours * 60 + minutes;
        }
        return Infinity; // If no match, send to the end
    };

    const loadLookup = async () => {
        const projects = await _ProjectStore.loadLookup('all', '');
        let allProject = []
        for (let project of projects) {
            let label = {
                label: project.number + " | " + project.fullname,
                value: project.id
            }
            allProject.push(label);
        }
        setProject(allProject)
    }

    const getGroup = async () => {
        const params = {
            include_user_details: false
        }
        const userGroups = await _UserGroupStore.getGroupDetails(params);
        let usergroup = userGroups.map((e) => {
            return {
                label: e.name,
                value: e.code
            }
        })
       
        setGroup(usergroup)
    }

    const getUser = async () => {
        const userGroups = await _UserGroupStore.getUserList();
        let usergroup = userGroups.map((e) => {
            return {
                label: e.first_name + " " + e.last_name,
                value: e.id
            }
        })
        setUserList(usergroup)
    }


    const taskStore = async (id) => {
        const taskStoreGroups = await _TaskStore.loadLookup('');

        let taskgroup = taskStoreGroups
            .filter((e) => e.project_id == id)  // Ensure only matching items are kept
            .map((e) => ({
                label: e.name,
                value: e.id
            }));
        setTask(taskgroup)
    }




    useEffect(() => {
        try {
           
            if (start_date && end_date) {
                let isTwoDateAvl = isTwoWeeksOrMore(start_date, end_date);
                if (!isTwoDateAvl.valid) {
                    _CommonStore.addNotification(t('Please select atlease two weeks'), null, 'error');
                } else {
                    let weeks = [];
                    let weekStartAndEndDate = splitIntoWeeks(start_date, end_date)
                    for (let i = 0; i < isTwoDateAvl.difference; i++) {                       
                        weeks.push({
                            days: { MON: false, TUE: false, WED: false, THU: false, FRI: false, SAT: false, SUN: false },
                            start_week: weekStartAndEndDate[i].startWeek,
                            end_week: weekStartAndEndDate[i].endWeek,
                            shift: "",
                            project: "",
                            task: "",
                            additional: []
                        })
                    }
                    setFormValue(weeks)
                }

            }
        } catch (error) {
            _CommonStore.addNotification(t('Please select atlease two weeks'), null, 'error');
        }

    }, [start_date, end_date])


    function splitIntoWeeks(startDate, endDate) {
        const result = [];
        let currentStart = new Date(startDate);
        let finalEnd = new Date(endDate);
        while (currentStart <= finalEnd) {
            let currentEnd = new Date(currentStart);
            currentEnd.setDate(currentEnd.getDate() + 6); // One week range

            if (currentEnd > finalEnd) {
                currentEnd = new Date(finalEnd); // Ensure it doesn't exceed the end date
            }

            result.push({
                startWeek: currentStart.toLocaleDateString("en-CA"),
                endWeek: currentEnd.toLocaleDateString("en-CA")
            });

            currentStart.setDate(currentStart.getDate() + 7); // Move to the next week
        }
        return result;
    }



    const closePeopleModel = () => {
        setShowMode(false)
    }

    const showConflictShift = async (type) => {
        try {
            let date = end_date.toLocaleDateString("en-CA")
            let data = {
                roster: {
                    "name": name,
                    "selected_user_list": selectedUserList,
                    "repeat_schedule": repeatSchedule,
                    "publish_type": publishType,
                    "published": type,
                    "group": showGroup,
                    "employee": employee,
                    "start_date": start_date,
                    "end_date": end_date,
                    "override": override
                },
                rosterList: formValue
            }

            if (!override) {
                setSaveAndPublish(true);
                setSubmitPublishType(type)
            } else {
                let validate = validateRosterData(data)
                if (!validate.valid) {
                    _CommonStore.addNotification((validate.message), null, 'error');
                } else {
                    const conflictScheduleList = await _Roster.getConflictScheduleList(data);
                    if (conflictScheduleList?.status == 'fail') {
                        _CommonStore.addNotification((conflictScheduleList.message), null, 'error');
                    } else {
                        if (conflictScheduleList.conflictList.length == 0) {
                            submitForm(type);
                        } else {
                            setConflictList(conflictScheduleList.conflictList);
                            setShowMode(true)
                            setSubmitPublishType(type)
                        }
                    }


                }
            }
        } catch (e) {
            _CommonStore.addNotification(t('Something went wrong'), null, 'error');
        }


    }



    const submitForm = async (type) => {
        let title = 'Do you want to save and publish?';
        let message = 'After your confirmation scheduled will be created and it can not be undone!'

        if (type == 'unpublished') {
            title = 'Do you want to save and draft?';
            message = 'After your confirmation roaster will be saved as draft and scheduled will not be published!'
        }

        confirmAlert({
            title: title,
            message: message,
            buttons: [
                {
                    label: ('Yes'),
                    onClick: async () => {
                        try {
                            let data = {
                                roster: {
                                    "name": name,
                                    "selected_user_list": selectedUserList,
                                    "repeat_schedule": repeatSchedule,
                                    "publish_type": publishType,
                                    "published": type,
                                    "group": showGroup,
                                    "employee": employee,
                                    "start_date": start_date,
                                    "end_date": end_date,
                                    "override": override
                                },
                                rosterList: formValue
                            }


                            let validate = validateRosterData(data)
                            if (!validate.valid) {
                                _CommonStore.addNotification((validate.message), null, 'error');
                            } else {
                                const userGroups = await _Roster.createRoster(data);
                                if (userGroups.status && userGroups.status == "fail") {
                                    _CommonStore.addNotification((userGroups.message), null, 'error');
                                } else {
                                    _CommonStore.addNotification(t('Roster saved successfully!'), null, 'success');
                                    navigate("/admin/roster");
                                }
                            }
                        } catch (error) {
                            console.log("error==", error)
                            _CommonStore.addNotification((error.message), null, 'error');
                        }
                    },
                },
                {
                    label: ('No'),
                    onClick: () => { setShowMode(false) },
                },
            ],
        });
    }

    if (saveAndPublish) {
        setSaveAndPublish(false);
        submitForm(submitPublishType);
    }
    const closeForm = () => {
        navigate("/admin/roster");
    }

    // Function to update a specific value
    const updateValue = (index, key, newValue) => {
        if (key == 'project') {
            taskStore(newValue)
            setFormValue((prevData) =>
                prevData.map((item, i) =>
                    i === index ? { ...item, ['task']: "" } : item
                )
            );
        }
        if (key == "additional") {
            setFormValue((prevSchedule) => {
                return prevSchedule.map((item, i) => {
                    if (i === index) {
                        return {
                            ...item,
                            additional: newValue
                        };
                    }
                    return item;
                }
                )
            })
        } else {
            SetFormValueIndex(index)
            setFormValue((prevData) =>
                prevData.map((item, i) =>
                    i === index ? { ...item, [key]: newValue } : item
                )
            );
        }

    };

    // Function to update a specific day value
    const updateDayValue = (index, dayKey) => {
        SetFormValueIndex(index)
        setFormValue((prevData) =>
            prevData.map((item, i) =>
                i === index
                    ? { ...item, days: { ...item.days, [dayKey]: !item.days[dayKey] } }
                    : item
            )
        );
    };

    useEffect(() => {        
        if(formValueIndex >= 0){
            let shift = companyShift.filter((i) => {
                return i.value == formValue[formValueIndex].shift
            })
          
            if(shift.length>0){
                const match = shift[0].label.match(/\(([^)]+)\)/);
                let workingHour =  getTimeDifference(match ? match[1] : null)
                let days = formValue[formValueIndex].days;
                let selecteddayLength = Object.keys(days).filter(day => days[day]).length;
                let getTotalWorkingHoursOfWeek = (parseInt(selecteddayLength)*parseInt(workingHour))
                console.log("getTotalWorkingHoursOfWeek--",getTotalWorkingHoursOfWeek,mandatoryOvertimeLimitPerWeek*60);
                if(getTotalWorkingHoursOfWeek > mandatoryOvertimeLimitPerWeek*60){
                    SetFormValueIndex(-1);
                    setFormValue((prevSchedule) => {
                        return prevSchedule.map((item, i) => {
                            if (i === formValueIndex) {
                                return {
                                    ...item,
                                    exceedTime: true
                                };
                            }
                            return item;
                        }
                        )
                    })
                }else{
                    SetFormValueIndex(-1);
                    setFormValue((prevSchedule) => {
                        return prevSchedule.map((item, i) => {
                            if (i === formValueIndex) {
                                return {
                                    ...item,
                                    exceedTime: false
                                };
                            }
                            return item;
                        }
                        )
                    })
                }
            }
        }
        
        
    },[formValue])
    function getTimeDifference(timeRange) {
        // Extract start and end time using regex
        const timeMatch = timeRange.match(/(\d{2}):(\d{2}) - (\d{2}):(\d{2})/);
        
        if (timeMatch) {
            // Parse start and end times
            const startHours = parseInt(timeMatch[1]);
            const startMinutes = parseInt(timeMatch[2]);
            const endHours = parseInt(timeMatch[3]);
            const endMinutes = parseInt(timeMatch[4]);
    
            // Convert start and end times to total minutes
            const startTotal = (startHours * 60) + startMinutes;
            const endTotal = (endHours * 60) + endMinutes;
    
            // Calculate the difference in minutes

            let diff = endTotal - startTotal;
            if (diff < 0) {
                diff += 24 * 60; // Add 24 hours in minutes for overnight
            }
            
            // Convert difference back to hours and minutes
            const diffHours = Math.floor(diff / 60);
            const diffMinutes = diff % 60;
    
            return (diffHours*60)+diffMinutes;
        }
        return "Invalid time format";
    }

    const getStartDay = async () => {
        const promises = [_CommonStore.setAppLoaded(true)]
        await Promise.all(promises).then(result => {
            setWetWeekStartDay({ getWeekStartDay: result[0].client.data.basicRules.standardWeekStartDay })
            setMandatoryOvertimeLimitPerWeek(result[0].client.data.basicRules.weeklyCalculationSettings.mandatoryOvertimeLimitPerWeek);
            setAverageOvertimeCalculation(result[0].client.data.basicRules.allowWeeklyCalculation);

        }).catch(err => {
            console.error(err);
        })
    }

    function clarMessage() {
        setTimeout(() => {
            setAlertMessage('');
            setAlertMessageEmployee(alertMessageEmployee);
        }, 5000)
    }


    useEffect(() => {
        if (override) {
            let alertMEssage = <div class="alert alert-info d-flex justify-content-between" role="alert">
                <span style={{ "fontSize": "16px" }}>{t("All the upcomming overlaping shifts will get replaced within the selected duration!")}</span>
                <i className="fa fa-times" style={{ "fontSize": "28px", "cursor": "pointer" }} onClick={() => setAlertMessage('')}></i>
            </div>
            setAlertMessage(alertMEssage);
            clarMessage()
        } else {
            setAlertMessage('');
        }

    }, [override])

    useEffect(() => {
        if (employee) {
            let alertMessageEmployee = <div class="alert alert-info d-flex justify-content-between" role="alert">
                <span style={{ "fontSize": "16px" }}>{t("Only employees with 100% availability are eligible for shifts in the roster. Please manually assign shifts to employees who are partially on sick leave.!")}</span>
                <i className="fa fa-times" style={{ "fontSize": "28px", "cursor": "pointer" }} onClick={() => setAlertMessageEmployee('')}></i>
            </div>
            setAlertMessageEmployee(alertMessageEmployee);
            clarMessage()
        } else {
            setAlertMessageEmployee('');
        }


    }, [employee])


    let weekStartDay = (date) => date.getDay();

    const userStartDay = (date) => {
        return date.getDay() === getWeekStartDay.getWeekStartDay;
    };


    function showMaxWorkingHours(timeRange,days){
        if(timeRange){
            let getTimeDiff = getTimeDifference(timeRange.label);
            console.log("getTimeDiff--",getTimeDiff)
            let selecteddayLength = Object.keys(days).filter(day => days[day]).length;
            let getTotalWorkingHoursOfWeek = (parseInt(selecteddayLength)*parseInt(getTimeDiff))
            return getTotalWorkingHoursOfWeek/60
        }
        
        // return "Invalid time format";
    }

    const publishTypeOption = [{ value: 1, label: 'Bi-Weekely', key: 'Bi-Weekely' }, { value: 2, label: 'All At Once', key: 'All At Once' }];

    const additional = [{ value: 1, label: 'Bonus', key: 'Bonus' }, { value: 2, label: 'Shift Allowance', key: 'Shift Allowance' },
    { value: 3, label: 'Emergency Shift(On-call)', key: 'Emergency Shift(On-call)' }];

    const onChange = (selected, _returnObject) => {


        if (_returnObject) return onChange(selected)

        return onChange(selected.value);
    }
    useEffect(() => {
        if (!employee) {
            setEmployee(null)
        }
    }, [employee])



    const enableEndDate = (date) => {
        return date.getDay() === start_date.getDay();
    };

    const getNextAvailableDay = () => {
        const today = new Date();
        let dayOffset = (getWeekStartDay.getWeekStartDay - today.getDay() + 7) % 7;
        return new Date(today.setDate(today.getDate() + (dayOffset || 7)));
    };

    translateLabels(publishTypeOption, t, 'label');
    translateLabels(additional, t, 'label');

    return (
        <div
            className="modal show"
            style={{ display: 'block', position: 'initial' }}
        >

            {alertMessage}
            {alertMessageEmployee}

            <Modal.Dialog size="xl" >
                <Modal.Header className='modelStyle'>
                    <Modal.Title className='w-100 custom-modal-title'>{t("Create Rotational shift")}</Modal.Title>
                </Modal.Header>

                <Modal.Body className='overflow-scroll roaster-shift-body'>
                    <div className='d-flex gap-2'>
                        <input type='radio' name='group' onClick={() => { setShowGroup("group"); setEmployee(""); setSelectedUserList(0); setGroupEmployeeList([]) }} checked={showGroup === 'group'} /><label>{t("Select Group")}</label>
                        <input type='radio' name='group' className='ms-4' onClick={() => { setShowGroup("employee"); setEmployee(0); setSelectedUserList(0) }} checked={showGroup === 'employee'} /><label>{t("Select Signal Employees")} </label>
                    </div>
                    {/* {
                groupEmployeeList.length > 0 && showGroup == 'group' &&
                <div className="showSelectedGroupPeople" onClick={() => setShowMode(true)}>
                    <Button fill wd>
                            <i className="fa fa-eye fa-xl margin-right-10" /> {t('Users')}
                    </Button>
                </div>
            } */}

                    <Row className="mb-5 mt-4">
                        <Col sm={4}>
                            <label className="control-label">{t('Name')} <RequiredStar /></label>
                            <Input placeholder={t('Enter name')} value={name} onChange={(e) => setName(e.target.value)} />
                        </Col>
                        <Col sm={4}>
                            <label className="control-label">{t('Publish Type')} <RequiredStar /></label>
                            <Select
                                options={publishTypeOption}
                                placeholder={`${t('Select')}`}
                                style={{ width: '100%' }}
                                onChange={evt => {
                                    setPublishType(parseInt(evt.value))
                                }}
                            />
                        </Col>
                        <Col sm={4}>
                            {
                                showGroup == 'group' ?
                                    <>
                                        <label className="control-label">{t('Select Employee Group')} <RequiredStar />{groupEmployeeList.length > 0 ? groupEmployeeList.length + ` ${t('users in the group')}` : ""}</label>
                                        <Select
                                            options={group}
                                            style={{ width: '100%' }}
                                            value={group.find(item => item.value === employee)}
                                            placeholder={`${t('Select')}`}
                                            onChange={evt => {
                                                setEmployee(parseInt(evt.value))
                                            }}
                                        />
                                    </>
                                    :
                                    <>
                                        <label className="control-label">{t('Select User')} <RequiredStar /></label>
                                        <CustomMultiSelect
                                            options={userList}
                                            value={selectedUserList || []}
                                            onChange={evt => {
                                                setSelectedUserList(evt)
                                            }}
                                        />
                                        {/* <Select
                                options={userList}
                                value={userList.find(item => item.value === employee)}
                                style={{ width: '100%' }}
                                onChange={evt => {
                                    setEmployee(parseInt(evt.value))
                                }}
                            /> */}
                                    </>
                            }

                        </Col>
                        {
                            showGroup == 'group' &&
                            <Col sm={4}>
                                <label className="control-label">{t('Select User')} <RequiredStar />{selectedUserList.length > 0 ? selectedUserList.length + ` ${t('users selected')}` : ""}</label>
                                <CustomMultiSelect
                                    options={groupEmployeeList}
                                    value={selectedUserList || []}
                                    onChange={evt => {
                                        setSelectedUserList(evt)
                                    }}
                                    overrideStrings={{
                                        selectSomeItems: t('Select'),
                                        allItemsAreSelected: t('All Selected'),
                                        search: t('Search'),
                                        selectAll: t('Select All'),
                                    }}
                                />
                            </Col>
                        }

                        <Col sm={4}>
                            <div className={"form-group field field-string"}>
                                <label className="control-label fs15">{t('From Week')} <RequiredStar /></label>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            {t("Your roster will start from your next week start date")}.
                                        </Tooltip>
                                    }
                                >
                                    <i className="fa fa-info-circle text-muted ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                </OverlayTrigger>
                                <DatePicker
                                    selected={start_date}
                                    dateFormat={longDateFormat(new Date())}
                                    minDate={getNextAvailableDay()}
                                    onChange={evt => {
                                        setStartDate(evt);
                                        setEndDate('')
                                    }}
                                    filterDate={userStartDay}
                                />
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className={"form-group field field-string"}>
                                <label className="control-label fs15">{t('To Week')} <RequiredStar /></label>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            {t("Please select atlease two weeks")}.
                                        </Tooltip>
                                    }
                                >
                                    <i className="fa fa-info-circle text-muted ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                </OverlayTrigger>
                                <DatePicker
                                    selected={end_date}
                                    dateFormat={longDateFormat(new Date())}
                                    minDate={start_date ?? new Date()}
                                    onChange={evt => {
                                        setEndDate(evt)
                                    }}
                                    disabled={start_date ? false : true}
                                    filterDate={enableEndDate}
                                />
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className={"form-group field field-string"}>
                                <label className="control-label fs15">{t('Do you want to repeat?')} </label><br />
                                <input className="repeatCheckbox" type="checkbox" onClick={() => setRepeatSchedule(repeatSchedule ? false : true)} checked={repeatSchedule ? 'checked' : ''} />
                            </div>
                        </Col>
                        <Col sm={4}>
                            <label className="control-label fs15">{t('Do you want to override?')} </label>
                            <OverlayTrigger
                                key="override"
                                placement="top"
                                overlay={
                                    <Tooltip id="override">
                                        {t('Shift will not create for vacation and absent users')}
                                    </Tooltip>
                                }
                            >
                                <i className="fa fa-info-circle text-muted ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                            </OverlayTrigger>
                            <br />

                            <div className="d-flex align-items-center p-3">
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={override}
                                    onChange={handleToggle}
                                    style={{ transform: "scale(2.5)", "marginLeft": "10px" }}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Table bordered hover size="sm">
                        <thead className='weekdaysTableHeading'>
                            <tr>
                                <th>{t('Weeks')}</th>
                                <th>{t('Days')}</th>
                                {
                                    averageOvertimeCalculation == true ? <th>{t('Total Hours')}</th> : ""
                                }
                                <th>{t("Shift")}</th>
                                <th>{t('Project')}</th>
                                <th>{t('Task')}</th>
                                <th>{t('Additionals')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                formValue?.map((e, i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>
                                                    <p>{t('Week')} {i + 1}</p>
                                                    <p className="startEndWeekDays">{shortDateFormat(e.start_week, "")}-{shortDateFormat(e.end_week, '')}</p>
                                                </td>
                                                <td>
                                                    <div className='d-flex'>
                                                        <div className={`days ${formValue[i]["days"].MON ? 'actveDays' : ""}`} onClick={() => updateDayValue(i, "MON")}>M</div>
                                                        <div className={`days ${formValue[i]["days"].TUE ? 'actveDays' : ""}`} onClick={() => updateDayValue(i, "TUE")}>T</div>
                                                        <div className={`days ${formValue[i]["days"].WED ? 'actveDays' : ""}`} onClick={() => updateDayValue(i, "WED")}>W</div>
                                                        <div className={`days ${formValue[i]["days"].THU ? 'actveDays' : ""}`} onClick={() => updateDayValue(i, "THU")}>T</div>
                                                        <div className={`days ${formValue[i]["days"].FRI ? 'actveDays' : ""}`} onClick={() => updateDayValue(i, "FRI")}>F</div>
                                                        <div className={`days ${formValue[i]["days"].SAT ? 'actveDays' : "weekends"}`} onClick={() => updateDayValue(i, "SAT")}>S</div>
                                                        <div className={`days ${formValue[i]["days"].SUN ? 'actveDays' : "weekends"}`} onClick={() => updateDayValue(i, "SUN")}>S</div>
                                                    </div>
                                                    {
                                                        averageOvertimeCalculation && formValue[i].exceedTime && <p className="exceedMaxHours">The average number of hours per week deviates from the set limit</p>
                                                    }
                                                    
                                                </td>
                                                {
                                                    averageOvertimeCalculation && 
                                                    <td>
                                                        {  showMaxWorkingHours(companyShift.find(item => item.value === e.shift),formValue[i]["days"])}
                                                    </td>
                                                }
                                                <td>
                                                    <Select
                                                        options={companyShift}
                                                        placeholder={`${t('Select')}`}
                                                        style={{ width: '100%' }}
                                                        onChange={evt => {
                                                            updateValue(i, "shift", evt.value)
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <Select
                                                        options={project}
                                                        placeholder={`${t('Select')}`}
                                                        style={{ width: '100%' }}
                                                        onChange={evt => {
                                                            updateValue(i, "project", evt.value)
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <Select
                                                        options={task}
                                                        placeholder={`${t('Select')}`}
                                                        style={{ width: '100%' }}
                                                        onChange={evt => {
                                                            updateValue(i, "task", evt.value)
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomMultiSelect
                                                        options={additional}
                                                        value={e.additional || []}
                                                        onChange={evt => {
                                                            updateValue(i, "additional", evt)
                                                        }}
                                                        overrideStrings={{
                                                            selectSomeItems: t('Select'),
                                                            allItemsAreSelected: t('All Selected'),
                                                            search: t('Search'),
                                                            selectAll: t('Select All'),
                                                        }}
                                                    // returnObject
                                                    // disabled={__disabled}
                                                    // overrideStrings={__overrideStrings}
                                                    />
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    </Table>

                </Modal.Body>

                <Modal.Footer>
                    <div class="d-flex justify-content-between align-items-center mt-4">
                        <div>
                            <button type="button" class="btn-fill btn-wd btn btn-primary" style={{ "margin": "0px 1em" }} onClick={() => submitForm('unpublished')}>{t('Save as a draft')}</button>
                            <button type="button" class="btn-fill btn-wd btn btn-primary" style={{ "margin": "0px 1em" }} onClick={() => showConflictShift('published')}>{t('Save and publish')}</button>
                            <button type="submit" class="btn-primary outline btn btn-primary" style={{ "margin": "0px 1em" }} onClick={() => closeForm()}>{t('Close')}</button>
                        </div>
                    </div>
                    {/* <Button variant="secondary">Close</Button>
          <Button variant="primary">Save changes</Button> */}
                </Modal.Footer>
            </Modal.Dialog>
            {
                showMode && conflictList.length > 0 && <ShowMorePeople closePeopleModel={closePeopleModel} conflictList={conflictList} setSaveAndPublish={setSaveAndPublish} />
            }
        </div>


    );


}

export default CreateRoster;