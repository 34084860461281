import React, { Fragment } from 'react';
import { inject } from 'mobx-react';
import Form from 'react-jsonschema-form';
import _ from 'lodash';
import deepEqual from 'deep-equal';
import { Row, Col } from 'react-bootstrap';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '../../hoc/withLocalization';
import LayoutField from './LayoutField';
import LayoutGridField from './LayoutGridField';
import TabsField from './TabsField';
import UserContractList from '../../containers/Admin/Users/UserContractList';

//elements
import { ImageUploadWidget } from './Widgets/ImageUploadWidget';
import { ActiveInactiveWidget } from './Widgets/ActiveInactiveWidget';
import { RadioWidget } from './Widgets/RadioWidget';
import { TrueFalseWidget } from './Widgets/TrueFalseWidget';
import { ProjectStatuses } from './Widgets/ProjectStatuses';
import { TaskStatuses } from './Widgets/TaskStatuses';
import { AllUsersWidget, MembersWidget, ManagersWidget, MembersWidgetMultiple, MembersWidgetMultipleSelect } from './Widgets/UsersWidget';
import { ProjectsWidget } from './Widgets/ProjectsWidget';
import { TasksWidget } from './Widgets/TasksWidget';
import { CheckBoxWidget } from './Widgets/CheckBoxWidget';
import InputTime from './Widgets/InputTime';
import { AttachmentsWidget } from './Widgets/AttachmentsWidget';
import { KidsSelect } from './Widgets/SpecialSelect';
import { VacationStatusesWidget } from './Widgets/VacationStatusesWidget';
import { AbsenceStatusesWidget, AbsenceStatusesWidgetOnEdit } from './Widgets/AbsenceStatusesWidget';
import TimeSelectWidget from './Widgets/TimeSelectWidget';
import { DaysOfWeekWidget } from './Widgets/DaysOfWeekWidget';
import { GpsCoordinatesWidget, GpsCoordinatesWithRoutesWidget } from './Widgets/GpsCoordinatesWidget';
import { MemberInProjectsWidget, MemberInProjectsWidgetMultiSelect } from './Widgets/MemberInProjectsWidget';
import { BusinessTypesSelect } from './Widgets/BusinessTypesSelect';
import DeviationConfigWidget from './Widgets/DeviationConfigWidget';
import ShiftsWidget from './Widgets/ShiftsWidget';
import DocumentTemplateWidget from './Widgets/DocumentTemplateWidget';
import { LeaveTypeWidget, LeaveTypeWidgetOnEdit } from './Widgets/LeaveTypeWidget';
import Button from '../CustomButton/CustomButton';
import { UserFormIntegrationWidget } from './Widgets/UserFormIntegrationWidget';
import { SingleUserGroupsWidget } from './Widgets/UserGroupWidget';
import { SimpleMultiSelectWidget, SimpleSelectWidget } from './Widgets/SimpleSelect';
import { ProjectMemberRulesWidget } from './Widgets/ProjectMemberRulesWidget';
import { CostUnitsAndTypeWidget } from './Widgets/CostUnitsAndTypes';

//utils
import { serverValidation } from '../../library/core';

const fields = {
    layout: LayoutField,
    layout_grid: LayoutGridField,
    tabs: TabsField,
    DeviationConfigWidget,
};

const widgets = {
    ImageUpload: ImageUploadWidget,
    ActiveInactive: ActiveInactiveWidget,
    Radio: RadioWidget,
    TrueFalse: TrueFalseWidget,
    ProjectStatuses,
    TaskStatuses,
    CheckBoxWidget,
    ManagersWidget,
    AllUsersWidget,
    MembersWidget,
    AttachmentsWidget,
    ProjectsWidget,
    TasksWidget,
    InputTime,
    VacationStatuses: VacationStatusesWidget,
    AbsenceStatuses: AbsenceStatusesWidget,
    AbsenceStatusesOnEdit: AbsenceStatusesWidgetOnEdit,
    TimeSelect: TimeSelectWidget,
    DaysOfWeek: DaysOfWeekWidget,
    KidsSelect,
    GpsCoordinatesWidget,
    MemberInProjectsWidget,
    MemberInProjectsWidgetMultiSelect,
    BusinessTypesSelect,
    MembersWidgetMultiple,
    MembersWidgetMultipleSelect,
    DeviationConfigWidget,
    ShiftsWidget,
    LeaveTypeWidget,
    LeaveTypeWidgetOnEdit,
    UserFormIntegrationWidget,
    SingleUserGroupsWidget,
    SimpleMultiSelectWidget,
    SimpleSelectWidget,
    GpsCoordinatesWithRoutesWidget,
    ProjectMemberRulesWidget,
    CostUnitsAndTypeWidget
};

const customFormats = {
    'tax-number': serverValidation('tax'),
    'required-name': /^(?!\s*$).+/,
    'phone-no': serverValidation('phone'),
    'postnumber': serverValidation('zip'),
    'bank_account_number': serverValidation('bank_account_number'),
    'gps': /(^$|^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$)/, // allow empty strings
    'social-no': serverValidation('social'),
    'email': /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    "name(Only a-z, 0-9 and % are allowed)": /^[\p{L}\p{N}%\s-]+$/u
};

const getTypeScope = (typeName) => `types.${typeName}`;
const getPropScope = (scope, propName) => `${scope}.fields.${propName}`;

function intlSchema(schema, scope, i18n, name) {
    const newSchema = {
        ...schema,
        ..._.pickBy({
            title: i18n(schema?.title),
            description: i18n(schema?.description),
            help: i18n(schema?.help),
        }),
    };

    if (schema?.definitions) {
        newSchema.definitions = _.mapValues(schema.definitions, (typeSchema, typeName) =>
            intlSchema(typeSchema, getTypeScope(typeName), i18n, typeName)
        );
    }

    if (schema?.type === 'object') {
        newSchema.properties = _.mapValues(schema.properties, (propSchema, propName) =>
            intlSchema(propSchema, getPropScope(scope, propName), i18n, propName)
        );
    }

    if (schema?.enum && (!schema.enumNames || _.isEqual(schema.enum, schema.enumNames))) {
        newSchema.enumNames = schema.enum.map((option) => _.defaultTo(i18n(`${scope}.options.${option}`), option));
    }

    if (schema?.items && schema?.type === 'array') {
        if (schema.items.enum && !schema.items.enumNames) {
            newSchema.items = {
                ...newSchema.items,
                enumNames: schema.items.enum.map((option) => _.defaultTo(i18n(`${scope}.options.${option}`), option)),
            };
        } else if (schema?.items?.properties && schema?.items?.type === 'object') {
            newSchema.items = {
                ...newSchema.items,
                properties: _.mapValues(schema.items.properties, (propSchema, propName) =>
                    intlSchema(propSchema, getPropScope(scope, propName), i18n, propName)
                ),
            };
        }
    }

    return newSchema;
}

export const isFilled = (fieldName) => ({ formData }) => !!(formData[fieldName] && formData[fieldName].length);
export const isTrue = (fieldName) => ({ formData }) => formData[fieldName];

class GenericForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.entity
        };
        this.submitForm = this.submitForm.bind(this);
    }

    static recomputeFields(props, state) {
        if (props.recomputeFields && props.recomputeFields.length) {
            props.recomputeFields.forEach((fld) => {
                if (fld.indexOf('data.') === 0) {
                    fld = fld.replace('data.', '');
                    if (props.entity.data[fld] !== state.formData.data[fld]) {
                        state.formData.data[fld] = props.entity.data[fld];
                    }
                } else if (props.entity[fld] !== state.formData[fld]) {
                    state.formData[fld] = props.entity[fld];
                }
            });
        }
    }

    componentDidMount() {
        const { formData } = this.state;
        const { formDataCallback } = this.props;
        if (typeof formDataCallback === 'function') {
            formDataCallback(formData);
        }
        setTimeout(() => {
            if (this.form) this.form.validate();
        }, 100);
    }

    componentDidUpdate(prevProps, prevState) {
        const { formDataCallback } = this.props;
        const previousFormData = prevState.formData;
        const newFormData = this.state.formData;
        const formDataSame = deepEqual(previousFormData, newFormData, { strict: true });
        if (!formDataSame && typeof formDataCallback === 'function') {
            formDataCallback(this.state.formData);
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.formData && props.entity && !state.formData.id && props.entity.id) {
            state.formData = props.entity;
        }
        if (state.formData && props.entity && !state.formData._reload && props.entity._reload) {
            state.formData = props.entity;
        }
        GenericForm.recomputeFields(props, state);
        return state;
    }

    onSubmit(e) {
        if (this.props.setRef) {
            return this.props.onSave(e.formData, e, true);
        } else {
            return this.props.onSave(e.formData, e, false);
        }
    }

    submitForm() {
        this.submitButton.click();
    }

    onCancel() {
        this.setState({ formData: null });
        //checking if goback is passed//
        if (this.props.goBack) {
            //if present than calls the function to render same page//
            this.props.goBack();
        }
        else {
            const { listUrl } = this.props;
            if (!listUrl) return;
            this.props.router.navigate(listUrl);
        }
    }

    transformErrors(errors) {
        const { t } = this.props;
        return errors.map((error) => {
            error.message = t(error.message);
            return error;
        });
    }

    //custom form validator//
    validateForm(formData, errors) {
        const { customvalidate } = this.props;
        if (customvalidate !== undefined) return customvalidate(formData, errors);
        return errors;
    }

    async onChange(formData, all) {
        const fd = _.cloneDeep(formData);
        const oldData = this.state.formData;
        Object.keys(fd).forEach((key) => {
            // Fix for the issue when removing completely a string value.            
            if (fd && oldData && typeof oldData[key] === 'string' && oldData[key] && typeof fd[key] === 'undefined') {
                if (
                    !(all.schema.properties[key] && all.schema.properties[key].format === 'date') &&
                    !all.schema.properties[key].isRequired
                ) {
                    fd[key] = '';
                }
            }
            if (
                fd &&
                oldData &&
                (fd[key] === '' || typeof fd[key] === 'undefined') &&
                all.schema.properties[key] &&
                all.schema.properties[key].type.indexOf('null') >= 0 &&
                !all.schema.properties[key].isRequired
            ) {
                fd[key] = null;
            }

        });
        if (this.props.onChange) {
            const newData = await this.props.onChange(fd);
            if (newData) {
                Object.keys(newData).forEach((key) => {
                    fd[key] = newData[key];
                });
            }
        }
        this.setState({ formData: fd }, () => {
            if (this.props.updateSchema) this.props.updateSchema(all, oldData)
        });
    }

    render() {
        const { entity, schema, uiSchema, translationScope, t, disabled,
            listUrl, disallowSave, isBizType, userContract, handleDocument,
            assignDocument, handleAssignDocuments } = this.props;
        const { formData } = this.state;
        return (
            <Fragment>
                <Form
                    ref={(form) => {
                        this.form = form;
                    }}
                    formData={formData}
                    schema={intlSchema(schema, translationScope, t)}
                    onChange={(all) => this.onChange(all.formData, all)}
                    uiSchema={uiSchema}
                    fields={fields}
                    showErrorList={false}
                    widgets={widgets}
                    customFormats={customFormats}
                    onSubmit={(e) => this.onSubmit(e)}
                    transformErrors={(errors) => this.transformErrors(errors)}
                    validate={(formData, errors) => this.validateForm(formData, errors)}
                    liveValidate
                    noHtml5Validate
                >
                    <button
                        ref={(btn) => {
                            this.submitButton = btn;
                            if (this.props.setRef) {
                                this.props.setRef(btn);
                            }   
                        }}
                        className="d-none"
                    />
                </Form>

                {assignDocument &&
                    <DocumentTemplateWidget
                        user_id={entity.id ? entity.id : 0}
                        handleChange={(ids) => handleAssignDocuments(ids)} />
                }

                {!disabled && (
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        ref={fileInput => {
                            this.fileInput = fileInput;
                        }}
                        onChange={this.onUpload}
                        multiple
                    />
                )}

                {isBizType === true && (
                    <div style={{ paddingBottom: '10px' }}>
                        <Button wd fill onClick={() => handleDocument()}>
                            {t('Document Management')}
                        </Button>
                    </div>
                )}
                {entity.user_type !== 'super-admin' && userContract === true && (
                    <UserContractList user_id={entity.id ? entity.id : 0} />
                )}
                <Row>
                    {!disallowSave && (
                        <Col xs={{ span: 6 }} sm={{ span: 3, offset: listUrl ? 6 : 9 }} md={{ span: 2, offset: listUrl ? 8 : 10 }}
                            lg={{ span: 2, offset: listUrl ? 8 : 10 }} xxl={{ span: 2, offset: listUrl ? 8 : 10 }}
                            className="mt-2 text-start text-sm-end">
                            <Button wd fill onClick={() => this.submitForm()}>
                                {t('Save')}
                            </Button>
                        </Col>
                    )}
                    {listUrl && (
                        <Col xs={{ span: 6, offset: disallowSave ? 6 : 0 }} sm={{ span: 3, offset: disallowSave ? 9 : 0 }}
                            md={{ span: 2, offset: disallowSave ? 10 : 0 }} lg={{ span: 2, offset: disallowSave ? 10 : 0 }}
                            xxl={{ span: 2, offset: disallowSave ? 10 : 0 }}
                            className="mt-2 text-end text-sm-end">
                            <Button wd onClick={() => this.onCancel()}>
                                {t('Cancel')}
                            </Button>
                        </Col>
                    )}
                </Row>
            </Fragment>
        );
    }
}

export default applicationRouter(
    withLocalization(inject('commonStore', 'signatureStore', 'clientStore')(GenericForm))
);
