import React, { useState, useMemo } from "react";
import { Row, Col } from 'react-bootstrap'

//elements
import Select from '~/elements/Select.js';
import { Input, NumberInput } from "~/elements/Input.js";
import RequiredStar from '~/elements/RequiredStar.js';
import Button from '~/components/CustomButton/CustomButton';

//utils
import { validateRequestPayload } from '~/utils/commonMethods';

const AddOvertimeSetting = ({ values, updateData, cancel, payroll_wagetypes, t }) => {
    const payrollWageTypeOptions = payroll_wagetypes.map(item => ({ value: item.value, label: item.label }));
    const [name, setName] = useState(values?.name);
    const [overtime, setOvertime] = useState(values?.overtime);
    const [integrated_wagetype, setIntegrated_wagetype] = useState(values?.integrated_wagetype);
    const ratio = useMemo(() => {
        if (!overtime || isNaN(overtime)) return "";
        const ratioValue = (parseFloat(overtime) / 100) + 1;
        return `1:${parseFloat(ratioValue.toFixed(4))}`;
    }, [overtime]);

    const saveData = () => {
        const data = {
            id: values?.id,
            name: name,
            overtime: overtime,
            integrated_wagetype: integrated_wagetype,
            ratio: ratio
        }

        const validFields = ["name", "overtime", "ratio"];
        const validMessage = ["Name", "Overtime", "Ratio"];
        const errors = validateRequestPayload(data, validFields, validMessage, t);
        if (errors) {
            updateData({ hasError: true, errors: errors })
        } else {
            updateData(data)
        }
    }


    return (
        <>
            <Row className="mt-4">
                <Col sm={3} xs={4}>
                    <label className="control-label">{t('Name')} <RequiredStar /></label>
                    <Input value={name} placeholder={t("Enter Name")} onChange={(e) => setName(e.target.value)} />
                </Col>
                <Col sm={3} xs={4}>
                    <label className="control-label">{t('Overtime')} % <RequiredStar /></label>
                    <NumberInput value={overtime} placeholder={t("Overtime % ex: 40.5")}
                        onChange={e => setOvertime(e.target.value)} />
                </Col>
                <Col sm={4} xs={6}>
                    <label className="control-label">{`${t('Payroll')} ${t('Wage Type')}`} </label>
                    <Select
                        options={payrollWageTypeOptions}
                        value={integrated_wagetype && payrollWageTypeOptions.find(item => item.value === integrated_wagetype.value) || null}
                        onChange={selectedItem => {
                            setIntegrated_wagetype(selectedItem)
                        }}
                        placeholder={`${t('Select')} ${t('Payroll')} ${t('Wage Type')}`}
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col sm={4} xs={6} className="mt-3">
                    <label className="control-label">{t('Ratio')} <RequiredStar /></label>
                    <Input value={ratio} disabled placeholder={t("Auto-calcuted from Overtime %")} />
                </Col>
            </Row>
            <div className="position-absolute" style={{ bottom: 10 }}>
                <Button wd me2 fill onClick={() => saveData()}>{t("Save")}</Button>
                <Button wd ms2 onClick={() => cancel()}>{t("Cancel")}</Button>
            </div>
        </>
    )
}

export default AddOvertimeSetting;
