import React, { Component } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment';
import { inject } from 'mobx-react';
import { confirmAlert } from 'react-confirm-alert';

//components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '../../../hoc/applicationRouter.js';
import { ProjectsWidget } from '../../../components/GenericForm/Widgets/ProjectsWidget.js';
import { AttachmentsWidget } from '../../../components/GenericForm/Widgets/AttachmentsWidget.js';

//utils
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc.js';
import { longDateFormat, shortDateFormat } from '../../../utils/dateFormat.js';

//element
import Input, { NumberInput, Textarea } from '../../../elements/Input.js';
import DatePicker from '../../../components/DatePicker/DatePicker.jsx';
import TimePickerInput from '../../../components/TimePicker/index.js';
import Select from '../../../elements/Select.js';
import Button from '../../../components/CustomButton/CustomButton.jsx';
import CustomMultiSelect from '../../../elements/CustomMultiSelect.js';
import RequiredStar from '../../../elements/RequiredStar.js';
import deleteIcon from '../../../assets/img/deleting.svg'
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox.jsx';


class ServiceRequestsForm extends Component {
    constructor(props) {
        super(props);
        const start_time = this.props.authStore?.custConfig?.clientData?.startTimeRules?.start || this.props?.commonStore?.config?.client?.data?.basicRules?.startTimeRules?.start;
        const end_time = this.props.authStore?.custConfig?.clientData?.startTimeRules?.end || this.props.commonStore?.config?.client?.data.basicRules.startTimeRules.end
        this.state = {
            userOptions: [],
            selectedMembers: [],
            projectOptions: this.props.authStore.custConfig?.projects?.map(ele => ({ value: ele.id, label: ele.name })),
            project_id: null,
            extraWorkOptions: this.props.commonStore.config.client.data?.extendedRules?.extrawork_types.map(type => ({
                value: type.code,
                label: type.name,
            })) || [],
            service_type: null,
            no_of_member: 0,
            from_date: moment(new Date()),
            to_date: moment(new Date()).add(1, 'day'),
            from_time: getMinutesFromTimeInput(start_time),
            to_time: getMinutesFromTimeInput(end_time),
            comment: '',
            status: '',
            memberCount: 0,
            attachment_ids: null,
            assignedMembers: [],
            createShiftFromStartDate: false,
        }
    }

    componentDidMount() {
        this.loadData();
    }


    async loadData() {
        const { userStore, serviceRequestStore, authStore, commonStore } = this.props;
        const { currentUser } = userStore;
        let getId = (this.props.router.location.state && this.props.router.location.state.customer) || this.props.update_id || this.props.router.location?.state?.serviceRequests || null;
        if (getId) {
            const getData = await serviceRequestStore.load(getId, currentUser?.user_type);
            const { availableMembers, serviceRequest, assignedMembers } = getData;
            this.setState({
                ...getData?.serviceRequest,
                project_id: authStore?.custConfig?.projects?.map(ele => ele.id === serviceRequest.project_id && { id: ele.id, label: ele.name }) || { value: serviceRequest.project_id },
                userOptions: availableMembers,
                status: serviceRequest?.status,
                selectedMembers: serviceRequest?.status === 'approved' && currentUser?.user_type && assignedMembers.map(ele => ele.value),
                assignedMembers,
                showConflictingMemberModal: false,
                conflictingMembers: [],
                service_type: (() => {
                    const type = commonStore.config.client.data?.extendedRules?.extrawork_types.find(ele => ele.code === serviceRequest.service_type);
                    return type ? { value: type.code, label: type.name } : null;
                })(),
            });
        }
    }

    validateFeilds() {
        const { project_id, to_date, from_date, service_type } = this.state;
        if (!project_id)
            return 'Please Select Project';
        if (!service_type)
            return 'Please Select Service'
        if (moment(to_date).isBefore(from_date))
            return "From date should be before to date";
    }

    handleChange = (name, value) => {
        if (name === 'no_of_member' && value < 0) return
        this.setState({ [name]: value })
    }

    handleSave = async () => {
        const { serviceRequestStore, t, commonStore, router } = this.props;
        try {
            const getError = this.validateFeilds()
            if (getError) return commonStore.addNotification(t(getError), null, 'error');
            let getId = (router.location.state && router.location.state.customer) || this.props.update_id || this.props.router.location?.state?.serviceRequests || null;
            const savedServiceRequest = await serviceRequestStore.save({
                ...this.state,
                project_id: this.state.project_id.value,
                service_type: this.state.service_type.value,
                id: getId
            }, !getId, window.sessionStorage.getItem('cusToken'));
            if (savedServiceRequest.warning)
                commonStore.addNotification(t(savedServiceRequest.warning), null, 'warning');
            else
                commonStore.addNotification(t(savedServiceRequest.message), null, 'success');
            if (window.sessionStorage.getItem('cusToken'))
                router.navigate('/customer');
            else
                router.navigate('/admin/serviceRequests');
        }
        catch (err) {
            commonStore.addNotification(t(err.message), null, 'error');
        }
    }

    handleDeleteRequest = async () => {
        const { t, commonStore, router, serviceRequestStore } = this.props;
        let getId = (router.location.state && router.location.state.customer) || router.location?.state?.serviceRequests;
        let confirmText = 'You have shifts created through this service request. Deleting this service request will also delete the shifts. Are you sure?';
        confirmAlert({
            title: t('Confirm to delete'),
            message: t(confirmText),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        return serviceRequestStore.remove(getId).then((res) => {
                            commonStore.addNotification(t('Deleted'), null, 'error');
                            router.navigate('/admin/serviceRequests');
                        });
                    },
                },
                {
                    label: t('No'),
                    onClick: () => { },
                },
            ],
        });
    };

    handleCancel = async () => {
        const { router } = this.props;
        window.sessionStorage.getItem('cusToken') ? router.navigate('/customer') : router.navigate('/admin/serviceRequests');
    }

    handleAssignMembers = async (finalSave) => {
        const { serviceRequestStore, t, commonStore } = this.props;
        try {
            const { selectedMembers, userOptions, createShiftFromStartDate, conflictingMembers } = this.state;
            if (!selectedMembers?.length) return commonStore.addNotification(t('To approve and assign shifts, please ensure that you select the appropriate team members'), null, 'error');
            const _conflictingMembers = userOptions.filter(ele => selectedMembers.includes(ele.value) && ele?.availablePartially?.length)
            if (!finalSave && _conflictingMembers?.length)
                return this.setState({ conflictingMembers: _conflictingMembers, showConflictingMemberModal: true });

            const savedServiceRequest = await serviceRequestStore.assignMembers({
                selectedMembers: userOptions.filter(ele => selectedMembers.includes(ele.value) && !ele?.availablePartially?.length),
                id: serviceRequestStore.currentEntity?.serviceRequest?.id,
                createShiftFromStartDate,
                conflictingMembers
            });
            commonStore.addNotification(t(savedServiceRequest.message), null, 'success');
            serviceRequestStore.resetLastListLoadTime();
            this.props.handleCloseServiceRequestModal();
        }
        catch (err) {
            commonStore.addNotification(t(err), null, 'error');
        }
    }

    handleShiftAssignMembers = (selectedUser) => {
        const { commonStore, t } = this.props;
        if (this.state.no_of_member && selectedUser.length > this.state.no_of_member)
            return commonStore.addNotification(`${t('You can select upto')} ${this.state.no_of_member} ${t('Members')}`, null, 'error');
        this.setState({ selectedMembers: selectedUser })
    }

    handleNewProject = () => {
        this.props.router.navigate('/admin/projects/add', { state: { prevPath: this.props.router?.location?.pathname } });
    }

    handleRemoveMember = async (memId) => {
        const { t, commonStore, router, serviceRequestStore } = this.props;
        if (serviceRequestStore.currentEntity?.serviceRequest?.data?.members && serviceRequestStore.currentEntity?.serviceRequest?.data?.members.length < 2)
            return commonStore.addNotification(t('Request must have aleast 1 member'), null, 'error');
        let getId = (router.location.state && router.location.state.customer) || this.props.update_id || router.location?.state?.serviceRequests || null;
        const payload = { serviceRequestId: getId, memberId: memId }
        let confirmText = "Removing this member will delete their future registered shifts from today onwards, while previously registered shifts will remain unchanged";
        confirmAlert({
            title: t('Confirm to delete'),
            message: '**' + t(confirmText),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        return serviceRequestStore.removeAssignedMember(payload).then((res) => {
                            commonStore.addNotification(t('Member has been removed, All the future shifts has been deleted'), null, 'error');
                            serviceRequestStore.resetLastListLoadTime();
                            this.loadData()
                        });
                    },
                },
                {
                    label: t('No'),
                    onClick: () => { },
                },
            ],
        });
    }

    render() {
        const { t, commonStore, disableInput, projectStore, serviceRequestStore } = this.props;
        const { project_id, no_of_member, from_date, to_date, projectOptions, from_time, createShiftFromStartDate, to_time, comment, userOptions,
            selectedMembers, status, memberCount, assignedMembers, showConflictingMemberModal, conflictingMembers, extraWorkOptions, service_type } = this.state;
        const { config } = commonStore;
        const dateTimeRules = config.client && config.client.data && config.client.data.dateTimeRules ? config.client.data.dateTimeRules : false;
        const format = dateTimeRules && dateTimeRules.time_format ? dateTimeRules.time_format : 'hh:mm';
        return (
            <>
                {!showConflictingMemberModal && (!disableInput ?
                    <>
                        <div className='d-flex justify-content-between'>
                            <div className='table-container-header'>{t('Service Requests Form')}</div>
                            <div>
                                <Button onClick={() => config?.client ? this.props.router.navigate('/admin/serviceRequests') : this.props.router.navigate('/customer')}>
                                    {t('Go Back')}
                                </Button>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <Row>
                                <Col md={6} xs={12}>
                                    <label className='fw-600'>{t('Project')}<RequiredStar /></label>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {t("Please select the project you would like the team member to work on")}
                                            </Tooltip>
                                        }
                                    >
                                        <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                    </OverlayTrigger>
                                    {config?.client ?
                                        <>
                                            <ProjectsWidget
                                                label={`${t('Project')}`}
                                                allLabel={t('All projects')}
                                                value={project_id?.value}
                                                onChange={(e) => this.handleChange('project_id', { value: e })}
                                            />
                                            {project_id &&
                                                <>
                                                    <label className='fw-600'>{t('Email for which you are creating a request on behalf of')}</label>
                                                    <Input
                                                        value={projectStore?.currentProjectsLookup.managers?.find(ele => Number(ele.id) === Number(project_id?.value))?.email || t('No Email Found for selected project')}
                                                        disabled
                                                    />
                                                </>
                                            }
                                        </>
                                        :
                                        <Select
                                            options={projectOptions}
                                            value={project_id}
                                            onChange={(e) => this.handleChange('project_id', e)}
                                        />
                                    }
                                    <label className='fw-600'>{t('Service')}<RequiredStar /></label>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {t("Select the service you wish the team member to work on")}
                                            </Tooltip>
                                        }
                                    >
                                        <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                    </OverlayTrigger>
                                    <Select
                                        options={extraWorkOptions}
                                        value={service_type}
                                        onChange={(e) => this.handleChange('service_type', e)}
                                    />
                                    <label className={'mt-2 fw-600'}>{t('Total Members Required')}{memberCount ? `(${memberCount} ${t('Available members')})` : ''}</label>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {t("Enter the number of members you require for this service")}
                                            </Tooltip>
                                        }
                                    >
                                        <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                    </OverlayTrigger>
                                    <NumberInput
                                        value={no_of_member}
                                        onChange={evt => this.handleChange('no_of_member', evt.target.value)}
                                    />
                                </Col>
                                {config?.client &&
                                    <Col md={2} className='mt-4'>
                                        <Button fill onClick={() => this.handleNewProject()}>
                                            {t('Add new')}
                                        </Button>
                                    </Col>
                                }
                            </Row>
                        </div>
                        <Row className='mt-3'>
                            <Col md={3} xs={6}>
                                <label className='fw-600'>{t('From Date')}<RequiredStar /></label>
                                <DatePicker
                                    selected={from_date && new Date(from_date)}
                                    onChange={(date) => this.handleChange('from_date', date)}
                                    dateFormat={longDateFormat(dateTimeRules)}
                                />
                            </Col>
                            <Col md={3} xs={6}>
                                <label className='fw-600'>{t('To Date')}<RequiredStar /></label>
                                <DatePicker
                                    selected={to_date && new Date(to_date)}
                                    onChange={(date) => this.handleChange('to_date', date)}
                                    dateFormat={longDateFormat(dateTimeRules)}
                                />
                            </Col>
                        </Row>
                        <Row className='d-flex mt-3'>
                            <Col md={3} xs={6}>
                                <label className='fw-600'>{t('From time')}<RequiredStar /></label>
                                <TimePickerInput
                                    value={minutesToTimeInput(from_time)}
                                    format={format === "hh:mm" ? "HH:mm" : format}
                                    onChange={(value) => this.handleChange('from_time', getMinutesFromTimeInput(value))}
                                />
                            </Col>
                            <Col md={3} xs={6}>
                                <label className='fw-600'>{t('To time')}<RequiredStar /></label>
                                <TimePickerInput
                                    value={minutesToTimeInput(to_time)}
                                    format={format === "hh:mm" ? "HH:mm" : format}
                                    onChange={(value) => this.handleChange('to_time', getMinutesFromTimeInput(value))}
                                />
                            </Col>
                        </Row >
                        <div className='mt-3'>
                            <Textarea
                                label={t('Comment')}
                                value={comment}
                                style={{ width: '100%' }}
                                onChange={(e) => this.handleChange('comment', e.target.value)}
                                placeholder={t('Enter comments here')}
                            />
                        </div>
                        <label className='fw-600'>{t('Attachments')}</label>
                        <div className='attachment-box'>
                            <AttachmentsWidget
                                value={this.state.attachment_ids}
                                onChange={(attachIds) => {
                                    this.setState({
                                        attachment_ids: attachIds,
                                    });
                                }}
                                options={{
                                    imageContext: {
                                        existingAttachment:
                                            serviceRequestStore?.currentEntity?.serviceRequest?.Attachments,
                                        fileType: 'docs',
                                        id: 0,
                                        model: 'ServiceRequest',
                                        data: { project_id: project_id?.value }
                                    },
                                }}
                            />
                        </div>
                        <div className='d-flex mt-3 justify-content-between'>
                            <div>
                                <Button fill onClick={() => this.handleSave()} disabled={status === 'ongoing'}>
                                    {t('Save')}
                                </Button>
                                <Button dynamic_lg onClick={() => this.handleCancel()} className='ml-10'>
                                    {t('Cancel')}
                                </Button>
                            </div>
                            {config?.client && (status === 'active' || status === 'ongoing') &&
                                <div>
                                    <Button dynamic_lg style={{ backgroundColor: 'red', border: 0, color: 'white' }} onClick={() => this.handleDeleteRequest()} className='ml-10'>
                                        {t('Delete')}
                                    </Button>
                                </div>
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className='table-container-header'>{t('Service Requests Form')}</div>
                        <div className='mt-2'>
                            <Row className='mt-1'>
                                <Col md={6} xs={12}>
                                    <label className='fw-600'>{t('From Date')}<RequiredStar /></label>
                                    <DatePicker
                                        selected={from_date && new Date(from_date)}
                                        dateFormat={longDateFormat(dateTimeRules)}
                                        disabled
                                    />
                                </Col>
                                <Col md={6} xs={12}>
                                    <label className='fw-600'>{t('To Date')}<RequiredStar /></label>
                                    <DatePicker
                                        selected={to_date && new Date(to_date)}
                                        dateFormat={longDateFormat(dateTimeRules)}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                {disableInput &&
                                    <Col xs={12}>
                                        <div>
                                            <label className='fw-600'>{t('Select Members')} ({no_of_member} {t('Member')})</label>
                                            <CustomMultiSelect
                                                options={userOptions}
                                                value={selectedMembers}
                                                onChange={(e) => this.handleShiftAssignMembers(e)}
                                                customOptions
                                                overrideStrings={{
                                                    selectSomeItems: t('Select Employees'),
                                                    allItemsAreSelected: t('All Employees are selected'),
                                                    search: t('Search'),
                                                    selectAll: t('Select All Employees'),
                                                }}
                                            />
                                        </div>
                                        {!!selectedMembers?.length &&
                                            <div>
                                                <p>**{t("By assigning shifts, the selected member's contact details will be shared with the customer")}.</p>
                                            </div>
                                        }
                                        {status === 'ongoing' &&
                                            <div>
                                                <p>**{t('Updating Member list will only create shifts from')} {shortDateFormat(moment(new Date()).format('YYYY-MM-DD'), dateTimeRules)} {t('to')} {shortDateFormat(to_date, dateTimeRules)}. {t("If you wish to create shift from 'from date', Select the below checkbox")}.</p>
                                                <div>
                                                    <CustomCheckbox
                                                        isChecked={createShiftFromStartDate}
                                                        onChange={(checked) => this.setState({ createShiftFromStartDate: checked })}
                                                        label={t("Are you sure, you want to create shift from 'from date'") + '?'}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {(status === 'approved' || status === 'ongoing') &&
                                            <div className='mt-3 fw-600'>
                                                {t('shifts')} {t('Assigned To')}
                                                <table className='w-100'>
                                                    <tr className='w-100'>
                                                        <th className='d-flex align-items-center justify-content-center w-75'>{t('Name')}</th>
                                                        <th className='w-25'>{t('Action')}</th>
                                                    </tr>
                                                    {assignedMembers?.map((ele, idx) => (
                                                        <tr className='w-100' style={{ backgroundColor: `${idx % 2 === 0 ? '#dee2e6' : ''}` }}>
                                                            <td className='d-flex align-items-center justify-content-center text-primary w-75'>{ele.label}</td>
                                                            <td className='w-25'>
                                                                <Button icon_sm_delete fill
                                                                    onClick={() => this.handleRemoveMember(ele.value)}>
                                                                    <img src={deleteIcon} alt="delete button" />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </table>
                                            </div>
                                        }
                                    </Col>
                                }
                            </Row>
                        </div>
                        <div className='m-3 d-flex justify-content-end'>
                            {status !== 'approved' &&
                                <Button fill style={{ backgroundColor: 'green', border: 0 }} onClick={() => this.handleAssignMembers()}>
                                    {t('Assign')} {t('Shifts')}
                                </Button>
                            }
                            <Button dynamic_lg onClick={() => this.props.handleCloseServiceRequestModal()} className='ml-10'>
                                {t('Cancel')}
                            </Button>
                        </div>
                    </>)}
                {showConflictingMemberModal && (
                    <>
                        <div style={{ minHeight: '44vh', maxheight: '52vh' }} className='overflow-y-scroll'>
                            <table className='w-100'>
                                <tr className='d-flex justify-content-center'>
                                    <th className='w-25 text-center'>{t('Name')}</th>
                                    <th className='w-75 text-center'>{t('Available')} {t('Date')}</th>
                                </tr>
                                {conflictingMembers?.map((ele, idx) => (
                                    <tr className='d-flex justify-content-center m-1' style={{ color: '#2550AC', backgroundColor: `${idx % 2 === 0 ? '#dee2e6' : ''}` }}>
                                        <td className='w-25 text-center'>{ele?.label}</td>
                                        <td className='w-75 text-center'>
                                            {ele?.availablePartially?.map(ele => (<div>{shortDateFormat(ele, dateTimeRules)}</div>))}
                                        </td>
                                    </tr>
                                ))}
                            </table>
                        </div>
                        **{t("The above members are available for partial shifts only. Would you like to proceed with creating partial shifts for these members? Alternatively, you may deselect members by just clicking on the 'Back' button and update the list as needed")}.
                        <div className='m-3 d-flex justify-content-end'>
                            <Button fill style={{ backgroundColor: 'green', border: 0 }} onClick={() => this.handleAssignMembers(true)}>
                                {t('Create Partial Shifts')}
                            </Button>
                            <Button dynamic_lg onClick={() => this.setState({ showConflictingMemberModal: false })} className='ml-10'>
                                {t('Back')}
                            </Button>
                        </div>
                    </>
                )}
            </>
        )
    }
}

export default inject('userStore', 'authStore', 'serviceRequestStore', 'projectStore')(applicationRouter(withLocalization(ServiceRequestsForm)));