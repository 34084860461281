import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/RosterGenericList';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';

// Elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import LoadingSpinner from '~/elements/LoadingSpinner';

//utils
import statuses from '~/utils/normalStatuses';
import { shortDateFormat } from '~/utils/dateFormat';
import customerFeature from '~/utils/customerFeature';

//assets
import Edit from '../../../assets/img/editing.svg';
import Delete from '../../../assets/img/deleting.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';
import ShowMorePeople from './showMorePeople';
import _Roster from '../../../library/stores/rosterStore';

class RosterList extends Component {
    constructor(props) {
        super(props)
        const page = (this.props.router.location.state && this.props.router.location.state.page) ? this.props.router.location.state.page : 0;
        this.state = {
            accepting: null,
            acceptanceChanged: false,
            selected: null,
            isUserUploadedImg: false,
            userImageAttachmentId: null,
            isTipModal: false,
            tipAmount: '',
            isLoading: true,
            isPageAddedOrEdited: page ? true : false,
            page: page ? page : 0,
            getWeekStartDay: '',
            showPeople:false,
            row:[]
        }
        this.goBack = this.goBack.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    };

    
    //this method is replacing the URL pushing part while add/edit//
    goBack = (page = 0) => {
        this.setState({ page, isPageAddedOrEdited: true });
    }

    //method to maintain react-table page in parent component//
    handlePageChange = (page, isEdit) => {
        this.setState({ page: page, isPageAddedOrEdited: isEdit });
    }

    showPeopleModel = (row) => {
        this.setState({showPeople:true});
        this.setState({row:row});
    }

    closePeopleModel = () => {
        this.setState({showPeople:false});
        this.setState({row:[]});
    }

    handleTableButtonAction = (id, type) => {
        if (type === 'edit') this.props.router.navigate(`/admin/roster/editRoster/${id}`);
        if (type === 'remove') {
            return this.props.handleDelete(id, async(id) => {
                await _Roster.deleteRoster(id)
            });
        }
    };



    alreadyProcessedCols = false;

    cols = [
        {
            Header: 'Week',
            id: 'project_name',
            accessor: ({ id,start_date, end_date }) => (
                <span>
                    <p>{id}</p>
                    (Date {shortDateFormat(start_date,"")} | {shortDateFormat(end_date,"")})
                </span>
            ),
            maxWidth: 500,
            minWidth: 180,
            // isSortable: true,
        },
        {
            Header: 'Members',
            id: '',
            accessor: ({ }) => (
               <></>
            ),
            maxWidth: 250,
            minWidth: 250,
            // isSortable: true,
        },
        {
            Header: 'Task',
            id: 'rosterList.task',
            accessor: ({ }) => (
                <></>
            ),
            maxWidth: 130,
            minWidth: 130,
        },
        {
            Header: 'Shift Time',
            id: 'shift_name',
            accessor: ({  }) => (
               <></>
            ),
            maxWidth: 130,
            minWidth: 130,
        },
        {
            Header: 'Status',
            id: 'published',
            accessor: ({  }) => (
               <></>
            ),
            maxWidth: 130,
            minWidth: 130,
        },
        // {
        //     Header: 'Actions',
        //     id: 'actions',
        //     sortable: false,
        //     accessor: (full) => this.renderTableActionButtons(full.id, full),
        //     maxWidth: 210,
        //     minWidth: 200,
        // },
    ];


    
    
    componentDidMount() {
        this.filterByRedirection();
        this.loadData();
        this.props.rosterStore.resetLastListLoadTime(null);
    }

    filterByRedirection = () => {
        const dashboardRedirected = this.props.router.location?.state?.dashboardRedirected
        if (!dashboardRedirected) return this.setState({ isLoading: false })
        this.props.rosterStore.setFilter("status", `${dashboardRedirected}`)
        this.props.rosterStore.onFilter()
        this.setState({ isLoading: false })
    }

    loadData = async () => {
        const { commonStore } = this.props;
        const promises = [commonStore.setAppLoaded(true)]
        await Promise.all(promises).then(result => {
            this.setState({getWeekStartDay:result[0].client.data.basicRules.standardWeekStartDay})
            this.props.rosterStore.resetLastListLoadTime(new Date());

            if(result[0].client.data.basicRules.weeklyCalculationSettings.allowAverageOvertimeCalculation){
                this.cols.unshift(
                    {
                    Header: 'Weekday',
                    id: 'week day',
                    accessor: ({  }) => (
                        <></>
                    )
                }
                )
            }
        }).catch(err => {
            console.error(err);
        })
    }

    handleAccept(id, full) {
        if (full.status === 'approved' && !full.validateTimetrackOption) return;
        this.setState({
            accepting: {
                ids: [id],
                count: 1,
                entity: 'timelog',
                startedByWorklogButton: true,
            },
        });
    }

    onSelectionChanged(ids) {
        this.setState({ selected: ids });
    }

    onMassAccept() {
        const ids = [];
        if (!this.state.selected) {
            this.props.commonStore.addNotification(this.props.t('Choose items'), null, 'error');
            return;
        }
        Object.keys(this.state.selected).forEach((id) => {
            if (this.state.selected[id]) ids.push(id);
        });
        this.setState({
            acceptanceChanged: false,
            accepting: {
                ids,
                count: ids.length,
                entity: 'timelog',
            },
        });
    }

    afterMassAccept() {
        this.handlePageChange(this.state.page, true);
        this.props.rosterStore.resetLastListLoadTime(new Date());
        this.setState({ accepting: null, acceptanceChanged: true });
    }

    


    renderExpandedContent = (row) => {
        const { t } = this.props;
        const { status, anomaly } = row;
        return (
            <Row className='gx-0'>
                <Col xs={status !== 'draft' ? 3 : 6} md={status !== 'draft' ? 2 : 4} className={status !== 'draft' ? '' : 'd-flex'}>
                    <div className='d-flex'>
                        <div>{t('Clock In')}:&nbsp;</div>
                        <div className={anomaly?.start?.is_early ? "text-warning" : anomaly?.start?.is_late ? "text-danger" : "text-success"}>
                            <i className='fa fa-clock-o' />
                            {anomaly?.start?.is_early ? t('Early') : anomaly?.start?.is_late ? t('Late') : t('On-time')}
                        </div>
                    </div>
                    <div className={status !== 'draft' ? 'd-flex' : 'ms-5 d-flex'}>
                        <div>{t('Location')}:&nbsp;</div>
                        <div className={anomaly?.start?.is_offsite ? "text-danger" : "text-success"}>
                            <i className='fa fa-map-marker' />
                            {anomaly?.start?.is_offsite ? t('Off-site') : t('On-site')}
                        </div>
                    </div>
                </Col>
                <Col xs={status !== 'draft' ? 3 : 6} md={status !== 'draft' ? 4 : 8}>
                    <div className='d-flex'>
                        <div className=''>{t('Comment')}:&nbsp;</div>
                        <div className='text-info'>{t(anomaly?.start?.comment)}</div>
                    </div>
                </Col>
                {status !== 'draft' &&
                    <>
                        <Col xs={3} md={2}>
                            <div className='d-flex'>
                                <div>{t('Clock Out')}:&nbsp;</div>
                                <div className={anomaly?.end?.is_early ? "text-warning" : anomaly?.end?.is_late ? "text-danger" : "text-success"}>
                                    <i className='fa fa-clock-o' />
                                    {anomaly?.end?.is_early ? t('Early') : anomaly?.end?.is_late ? t('Late') : t('On-time')}
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div>{t('Location')}:&nbsp;</div>
                                <div className={anomaly?.end?.is_offsite ? "text-danger" : "text-success"}>
                                    <i className='fa fa-map-marker' />
                                    {anomaly?.end?.is_offsite ? t('Off-site') : t('On-site')}
                                </div>
                            </div>
                        </Col>
                        <Col xs={3} md={4}>
                            <div className='d-flex'>
                                <div >{`${t('Comment')}`}:&nbsp;</div>
                                <div className='text-info'>{t(anomaly?.end?.comment)}</div>
                            </div>
                        </Col>
                    </>
                }
            </Row >
        )
    }

    

    render() {
        const { loading, filters } = this.props.rosterStore;
        const { user_type } = this.props.userStore.currentUser;
        this.localeIsEn = !!(this.props.commonStore && this.props.commonStore.locale === 'en');
        if (this.state.isLoading)
            return <LoadingSpinner />;
        return (
            <Fragment>
                <GenericList
                    header={
                        <GenericFilter
                            filters={filters}
                            setFilter={(name, value) => this.props.rosterStore.setFilter(name, value)}
                            onFilter={() => this.props.rosterStore.onFilter()}
                            loading={loading}
                            hasSearch={user_type !== 'member'}
                            searchFilterPlaceholder={"Search"}
                            hasDateFiltersWithWeekEnable={true}
                            hasProjectFilter={true}
                            hasRosterStatusFilter={true}
                            statusOptions={statuses}
                        />
                    }
                    tableHeader={this.props.t('Roster Shift')}
                    columns={this.cols}
                    requestData={(params) => this.props.rosterStore.loadList(params)}
                    lastListLoadTime={this.props.rosterStore.lastListLoadTime}
                    
                >
                <Button fill wd onClick={() => this.props.onAdd()}>
                    <i className="fa fa-plus-circle fa-xl margin-right-10" /> {this.props.t('Add new')}
                </Button>
                </GenericList>

                {   
                    this.state.showPeople && <ShowMorePeople closePeopleModel={this.closePeopleModel} />

                }
            </Fragment>
        );
    }
}
export default inject(
    'userStore',
    'commonStore',
    'rosterStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(RosterList)))));
