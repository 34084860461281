import RectangularCard from "./RectangularCard";
import applicationRouter from "../../hoc/applicationRouter";
import withLocalization from "../../hoc/withLocalization";
import { Row, Col } from "react-bootstrap";
import CustomCircularProgressbar from "../ProgressBar/CircularProgressBar";
function RectangularSmallCard({ t, title, titleValue, percentage, pathColor, rotationStart, strokeWidth }) {
    return (
        <>
            <RectangularCard backgoundClass={`rectangle-card-bg-white`} className={'custom-style-time-bank'} classbodyStyle={{ padding: '2px' }}>
                <Row className='align-items-center'>
                    <Col xs={7} sm={7}>
                        <span className='d-flex dashboard-progressBar-header-text text-center'>
                            {t(title)}
                        </span>
                        <span className="d-flex dashboard-progressBar-header-text text-center">{t(titleValue)}</span>
                    </Col>
                    <Col xs={5} sm={5}>
                        <span className='d-flex justify-content-center'>
                            <CustomCircularProgressbar
                                percentage={percentage}
                                pathColor={pathColor}
                                rotationStart={rotationStart}
                                strokeWidth={strokeWidth}
                                isStyle={true}
                            />
                        </span>
                    </Col>
                </Row>
            </RectangularCard>
        </>
    )
}
export default (applicationRouter(withLocalization(RectangularSmallCard)));