import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';
import { Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'
import withLocalization from '~/hoc/withLocalization';
import Radio from '../../../../components/CustomRadio/CustomRadio.jsx';
import Select from '../../../../elements/Select.js';
import { NumberInput } from '../../../../elements/Input.js';
import TableWidget from '../TableWidget.js';

const calc_periodOption = [{ value: 'none', label: 'None', title: 'None' },
{ value: 'day', label: 'Day', title: 'Day' }, { value: 'week', label: 'Week', title: 'Week' },
{
    value: 'government',
    label: 'Hours in excess of nine hours in the course of 24 hours and 40 hours in the course of seven days',
    title: 'Hours in excess of nine hours in the course of 24 hours and 40 hours in the course of seven days'
}]

class OverTimeCalculation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            overtimeAutomaticMode: false,
            overtimeCalcRules: {
                calc_percent: 0,
                calc_period: 'none',
                max_hours: 0,
                max_minutes: 0,
                weekend_percent: 0,
                integrated_wageType: null,
            },
            overtime_types: [],
            overtimeThresoldMinutes: 15,
            wageType: props.commonStore.getWageTypesAccordingToIntegrations(props.commonStore?.config?.integration_details, 'su') || []
        }
        this.handleChange = this.handleChange.bind(this);
        // this.handleCheck = this.handleCheck.bind(this);
        this.loadData = this.loadData.bind(this);
        this.translateLables = this.translateLables.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            const basicRules = Object.assign({}, currentEntity.client.data.basicRules);
            if (basicRules.overtimeCalcRules) {
                this.setState({
                    overtimeAutomaticMode: basicRules.overtimeAutomaticMode,
                    overtimeCalcRules: basicRules.overtimeCalcRules,
                    overtime_types: basicRules.overtime_types,
                    overtimeThresoldMinutes: basicRules.overtimeThresoldMinutes
                })
            }
            else {
                this.setState({
                    overtime_types: basicRules.overtime_types,
                    overtimeThresoldMinutes: basicRules.overtimeThresoldMinutes
                })
            }
        }
    }

    translateLables() {
        const { t } = this.props;
        calc_periodOption.forEach(item => {
            item.label = t(item.title)
        });
    }

    validateInputs(name, value) {
        const { overtimeCalcRules } = this.state;
        const { t } = this.props
        let isError = false
        if (name === 'max_minutes' && value > 59)
            isError = t('Minutes can not be greater than 59 instead add hour');
        if (name === 'max_hours') {
            switch (overtimeCalcRules.calc_period) {
                case 'day':
                    if (value > 23) isError = t('Max hours can not be greater than 23')
                    break;
                case 'week':
                    if (value > 167) isError = t('Max hours can not be greater than 167')
                    break;
                case 'month':
                    if (value > 743) isError = t('Max hours can not be greater than 743')
                    break;
                default:
            }
        }
        return isError
    }

    handleChange = (name, value) => {
        const isError = this.validateInputs(name, value)
        if (isError) return this.props.commonStore.addNotification(isError, null, 'error');
        const { overtimeCalcRules, overtimeThresoldMinutes } = this.state;
        overtimeCalcRules[name] = value;
        this.props.handleUserDataChange('overtimeCalcRules', overtimeCalcRules);
        this.setState({ overtimeCalcRules, overtimeThresoldMinutes });
    }

    handleInputRange = (e) => {
        e.persist();
        let _overtimeThresoldMinutes = e.target.value;
        this.props.handleUserDataChange('overtimeThresoldMinutes', _overtimeThresoldMinutes);
        this.setState({ overtimeThresoldMinutes: _overtimeThresoldMinutes });
    }

    render() {
        const { overtimeCalcRules, overtimeAutomaticMode, overtime_types, overtimeThresoldMinutes, wageType } = this.state;
        const { t, commonStore } = this.props;
        const { config } = commonStore;
        this.translateLables();
        return (
            <div>
                <div className="custom-form__row center-block">
                    <div className="custom-form__row" style={{ margin: 10 }}>
                        <Radio
                            label={t('Manual')}
                            checked={!overtimeAutomaticMode}
                            onChange={() => this.setState(prevState => ({ overtimeAutomaticMode: !prevState.overtimeAutomaticMode }),
                                () => {
                                    this.props.handleUserDataChange('overtimeAutomaticMode', this.state.overtimeAutomaticMode);
                                    if (!this.state.overtimeAutomaticMode && this.state.overtimeCalcRules.calc_period !== calc_periodOption[0].value) {
                                        this.handleChange('calc_period', calc_periodOption[0].value);
                                        this.handleChange('max_hours', 0);
                                        this.handleChange('max_minutes', 0);
                                        this.handleChange('calc_percent', 0);
                                        this.handleChange('weekend_percent', 0);
                                    }
                                })} />
                    </div>
                    <div className="custom-form__row" style={{ margin: 10 }}>
                        <Radio
                            label={t('Automatic')}
                            checked={overtimeAutomaticMode}
                            onChange={() => this.setState(prevState => ({ overtimeAutomaticMode: !prevState.overtimeAutomaticMode }),
                                () => this.props.handleUserDataChange('overtimeAutomaticMode', this.state.overtimeAutomaticMode))} />
                    </div>
                    <div className='d-flex mt-12 ml-30'>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {overtimeAutomaticMode ?
                                        t(`This function allows your company to calculate overtime automatically for all users. You can select the period, such as daily or weekly, and enter the maximum number of hours after which overtime should be calculated, for example, 9 hours. This means that when a user has worked for 9 hours and is still at work, they will be paid according to the set overtime percentage, for example, 50%. This means that if a user works for 1 hour, they will be paid for 1 hour and 30 minutes`)
                                        :
                                        t(`If this option is turned on, the user must select a listed percentage of overtime and add it to their working hours. For example, if a user worked for 9 hours and wants to claim 1 hour as overtime, they would add 1 hour as 50% from the overtime list in the mobile app`)
                                    }.
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </div>
                </div>
                {overtimeAutomaticMode ?
                    <div>
                        <Row>
                            <Col sm={4} className="mb-3">
                                <label className="control-label">{t('Period')}</label>
                                <Select
                                    options={calc_periodOption}
                                    value={calc_periodOption.find(item => item.value === overtimeCalcRules.calc_period)}
                                    onChange={period => {
                                        this.handleChange('calc_period', period.value)
                                        if (period.value === "government") {
                                            this.handleChange('max_hours', 9);
                                            this.handleChange('max_minutes', 0);
                                        }
                                        if (period.value === "none") {
                                            this.handleChange('max_hours', 0);
                                            this.handleChange('max_minutes', 0);
                                            this.handleChange('calc_percent', 0);
                                            this.handleChange('weekend_percent', 0);
                                        }
                                    }}
                                    style={{ width: '100%' }}
                                />
                            </Col>
                            <Col sm={4} xs={6} className="mb-3">
                                <NumberInput
                                    label={`${t('Maximum hours')} (hh)`}
                                    name="max_hours"
                                    value={overtimeCalcRules.max_hours}
                                    onChange={evt => {
                                        this.handleChange('max_hours', parseInt(evt.target.value, 10));
                                    }}
                                    disabled={overtimeCalcRules.calc_period === "government" || overtimeCalcRules.calc_period === 'none'}
                                />
                            </Col>
                            <Col sm={4} xs={6} className="mb-3">
                                <NumberInput
                                    label={`${t('Maximum Minutes')} (mm)`}
                                    name="max_minutes"
                                    value={overtimeCalcRules.max_minutes}
                                    onChange={evt => {
                                        this.handleChange('max_minutes', parseInt(evt.target.value, 10));
                                    }}
                                    disabled={overtimeCalcRules.calc_period === "government" || overtimeCalcRules.calc_period === 'none'}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} xs={6}>
                                <NumberInput
                                    label={`${t('Overtime')} (%)`}
                                    name="Overtime"
                                    value={overtimeCalcRules.calc_percent}
                                    onChange={evt => {
                                        this.handleChange('calc_percent', parseInt(evt.target.value, 10));
                                    }}
                                    disabled={overtimeCalcRules.calc_period === 'none'}
                                />
                            </Col>
                            <Col sm={4} xs={6}>
                                <NumberInput
                                    label={`${t('Additional pay for weekend')}`}
                                    name="weekend_percent"
                                    value={overtimeCalcRules.weekend_percent}
                                    onChange={evt => {
                                        this.handleChange('weekend_percent', parseInt(evt.target.value, 10));
                                    }}
                                    disabled={overtimeCalcRules.calc_period === 'none'}
                                />
                            </Col>
                            <Col sm={4} xs={6}>
                                <label className="control-label">{t('Overtime threshold')}</label>
                                <div className="slider-parent">
                                    <input type="range" min="1" max="60"
                                        onChange={event => this.handleInputRange(event)}
                                        value={overtimeThresoldMinutes}
                                    />
                                    <div className="buble">
                                        {overtimeThresoldMinutes} {t('Minutes')}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {config?.integration_details?.product === 'tripletex' && overtimeCalcRules.calc_period === 'day' &&
                            <Row>
                                <Col sm={4} xs={6}>
                                    <label className="control-label">{t('Select Overtime Type')}</label>
                                    <Select
                                        options={wageType}
                                        onChange={(e) => this.handleChange('integrated_wageType', e)}
                                        value={wageType?.find(ele => ele?.value === overtimeCalcRules?.integrated_wageType?.value)}
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>
                        }
                        {(overtimeCalcRules.calc_period === 'government' || overtimeCalcRules.calc_period === 'week') &&
                            <Row className='mt-2'>
                                <p className='font-size-12'><span className='font-size-15 fw-bold'>{t('Note')}:&nbsp;</span>
                                    {overtimeCalcRules.calc_period === 'government' ? t('Weekly overtime calculations for government rules will be based on a week starting from Monday and ending on Sunday')
                                        : t("Weekly overtime calculations will be based on the start day of the week set in the 'Week Start Day' settings under 'Rules for Start & Break Time'")}.
                                </p>
                            </Row>
                        }
                    </div>
                    :
                    <div>
                        <TableWidget
                            headers={['Unique id', 'Amount', 'Label']}
                            metaData={[{ field: 'code', type: 'text', placeholder: '0', required: true },
                            { field: 'multiplier', type: 'number', placeholder: 0, required: true },
                            { field: 'name', type: 'text', placeholder: '%', required: true }]}
                            data={overtime_types}
                            onChange={data => this.props.handleUserDataChange('overtime_types', data)}
                        />
                    </div>
                }
            </div >
        );
    }
}
export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(OverTimeCalculation))));