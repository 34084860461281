import React, { useEffect, useState } from 'react'
import {
    useTable,
    usePagination,
    useSortBy,
    useFlexLayout,
    useRowSelect
} from 'react-table';
import { v4 as uuid } from 'uuid';
import {  OverlayTrigger, Tooltip} from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from "react-router-dom";

import config from '~/library/config';
//components
import TablePagination from './TablePagination';
import withLocalization from '../../hoc/withLocalization';

//elements
import Button from '../CustomButton/CustomButton.jsx';
import LoadingSpinner from '~/elements/LoadingSpinner';
import Accordion from 'react-bootstrap/Accordion';
import deleteIcon from '../../assets/img/deleting.svg';
import editIcon from '../../assets/img/editing.svg';
import { Row, Col } from "react-bootstrap";
import _Roster from '../../library/stores/rosterStore';
import { ro, tr } from 'date-fns/locale';
import { shortDateFormat, timeFormat, totalTimeFormat } from '~/utils/dateFormat';
import Delete from '../../assets/img/deleting.svg';
import ShowMorePeople from '../../containers/Admin/Roster/showMorePeople';
import { useTranslation } from "react-i18next";
import _CommonStore from '../../library/stores/commonStore';


//assets//
import defaultAvatar from '../../assets/img/faces/face-0.jpg';

function GenericList(props) {
    const { t } = useTranslation(); 
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [pageIndex] = useState(0);
    const [pageSize] = useState(props.pageSize ? props.pageSize : 10);
    const [selected, setSelected] = useState({});
    const [sortBy, setSortBy] = useState([]);
    const [selectAll, setSelectAll] = useState('');
    const [filters] = useState(props.filters);
    const [pages, setPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastListLoadTime, setLastListLoadTime] = useState(null);
    const [loading, setLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState({});
    const [isAllRowExpanded, setIsAllRowExpanded] = useState(0);
    const [showPeople, setShowPeople] = useState(false);
    const [peopleList, setPeopleList] = useState([]);
    const [conflictPeopleList, setConflictPeopleList] = useState([]);
    const [saveAndPublish,setSaveAndPublish] = useState(false)
    const [publishId,setPublishId] = useState()
    let [mandatoryOvertimeLimitPerWeek, setMandatoryOvertimeLimitPerWeek] = useState(); //max hours in week
    let [averageOvertimeCalculation, setAverageOvertimeCalculation] = useState(false); 

    console.log("props======",selected)
    useEffect(() => {
        translateColumns();
    }, [props?.i18n?.language, selected])


    //only calls for any change in filter//
    useEffect(() => {
        fetchData({ pageIndex, pageSize, sortBy, filters })
    }, [props.filters]);

    if (props.lastListLoadTime && lastListLoadTime && props.lastListLoadTime !== lastListLoadTime && !loading) {
        fetchData({ pageIndex, pageSize, sortBy, filters });
    }

    function translateColumns() {
        const { columns, t, checkboxField } = props;
        let _columns = [];
        columns.forEach((c) => {
            if (c && !c.hidden) _columns.push(c);
        });
        _columns = _columns.map((column) => {
            const { Header, ...cols } = column;
            return { Header: t(Header), ...cols };
        });

        if (props.allowCheckboxes) _columns.unshift(checkboxCol(checkboxField));
        setColumns(_columns)
        setPageSize(pageSize);
    }


    useEffect(() => {
        getMaxWorkHoure()
    },[])

    async function getMaxWorkHoure(){
        const promises = [_CommonStore.setAppLoaded(true)]
        await Promise.all(promises).then(result => {
            setMandatoryOvertimeLimitPerWeek(result[0].client.data.basicRules.weeklyCalculationSettings.mandatoryOvertimeLimitPerWeek);
            setAverageOvertimeCalculation(result[0].client.data.basicRules.allowWeeklyCalculation);

        }).catch(err => {
            console.error(err);
        })
    }

    const checkboxCol = (checkboxField = "id") => {
        return {
            id: 'checkbox',
            accessor: '',
            Cell: (data) => {
                return (
                    <div className='checkbox checkbox__table_list_container'>
                        <input
                            type="checkbox"
                            checked={selected[data?.row?.original?.[`${checkboxField}`]] === true}
                            onChange={() => toggleRow(data?.row?.original?.[`${checkboxField}`])}
                        />
                        <label className={`checkbox__table_list ${props.cutomCheckBoxClass ? props.cutomCheckBoxClass : ""}`}
                            onClick={() => toggleRow(data?.row?.original?.[`${checkboxField}`])}></label>
                    </div>
                );
            },
            Header: (x) => {
                return (
                    <div className='form-checkbox'>
                        <input
                            type="checkbox"
                            className={"checkbox"}
                            checked={selectAll === 1}
                            ref={(input) => {
                                if (input) {
                                    input.indeterminate = selectAll === 2;
                                }
                            }}
                            onChange={() => toggleSelectAll()}
                        />
                    </div>
                );
            },
            sortable: false,
            width: 30,
        };
    }

    const toggleRow = (id) => {
        const newSelected = Object.assign({}, selected);
        newSelected[id] = !selected[id]
        setSelected(newSelected)
        setSelectAll(2)
        if (props.onSelectionChanged) props.onSelectionChanged(newSelected);
    }

    const toggleSelectAll = () => {
        const newSelected = {};
        if (selectAll === 0) {
            data.forEach((x) => {
                newSelected[x.id] = true;
            });
        }
        setSelected(newSelected)
        setSelectAll(selectAll === 0 ? 1 : 0)
        if (props.onSelectionChanged) props.onSelectionChanged(newSelected);
    }

    function fetchData(state) {
        setLoading(true);
        let _currentPage = state.pageIndex;
        if (props.isPageAddedOrEdited !== undefined && props.isPageAddedOrEdited === false) {
            if (props.handlePageChange)
                props.handlePageChange(state.pageIndex, false);
        }

        if (props.isPageAddedOrEdited !== undefined && props.isPageAddedOrEdited === true) {
            _currentPage = props.page;
            if (props.handlePageChange)
                props.handlePageChange(_currentPage, false);
        }

        let { filters } = props;
        if (state.forceFilters) filters = state.forceFilters;
        // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
        // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
        // this.setState({ loading: true });
        // Request the data however you want.  Here, we'll use our mocked service we created earlier
        props.requestData({
            pagesize: state.pageSize,
            page: _currentPage,
            sort: state.sortBy ? state.sortBy : [],
            // React Table default filter
            filters,
        })
            .then((res) => {
                // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
                setData(res.rows);
                setPages(res.pages);
                setCurrentPage(_currentPage);
                setSortBy(state.sortBy);
                setLastListLoadTime(res.time);
                if (res.selected) {
                    const prevSelected = {};
                    res.selected.split(',').forEach(ele => prevSelected[ele] = true);
                    setSelected(prevSelected)
                }
                else {
                    setSelected({});
                    setSelectAll('');
                }
            }).
            catch(err=>{
                console.error({err})
            }).finally(()=>{
                setLoading(false);
            });
    }

    function handlePagination(genericPage) {
        let filters = props.filters
        if (genericPage) {
            fetchData({
                pageSize: pageSize,
                pageIndex: (genericPage - 1),
                sortBy,
                filters,
            })
        }
    }

    function sortList(sort) {
        fetchData({ pageIndex, pageSize, sortBy: sort, filters })
    }

    function renderSortedButtons(column) {
        if (column.isSorted) {
            return (
                <img src={"/images/blueArrow.svg"} alt="arrow"
                    className={`${column.isSortedDesc ? "transFrom-180" : ""}`}
                    onClick={() => sortList(column.isSortedDesc ? [] : [{ "id": column.id, "desc": true }])}
                />
            )
        } else return (
            <img src={"/images/grayArrow.svg"} alt="arrow"
                onClick={() => sortList([{ "id": column.id, "desc": false }])}
            />
        )
    }

    function handleRowExpansion(id) {
        const _expandedRows = { ...expandedRows };
        _expandedRows[id] = !_expandedRows[id];
        if (Object.values(_expandedRows).find(ele => ele === true))
            setIsAllRowExpanded(1)
        else {
            setIsAllRowExpanded(0)
            props.handleExpandAllSubRows(false);
        }
        setExpandedRows(_expandedRows);
    }

    function exceedMaxWorkingHours(timeRange,days){
        const timeMatch = timeRange.match(/(\d{2}):(\d{2}) - (\d{2}):(\d{2})/);
            console.log("timeMatch-----",timeMatch);
        if (timeMatch) {
            // Parse start and end times
            const startHours = parseInt(timeMatch[1]);
            const startMinutes = parseInt(timeMatch[2]);
            const endHours = parseInt(timeMatch[3]);
            const endMinutes = parseInt(timeMatch[4]);
    
            // Convert start and end times to total minutes
            const startTotal = (startHours * 60) + startMinutes;
            const endTotal = (endHours * 60) + endMinutes;
    
            // Calculate the difference in minutes

            let diff = endTotal - startTotal;
            if (diff < 0) {
                diff += 24 * 60; // Add 24 hours in minutes for overnight
            }
            
            // Convert difference back to hours and minutes
            const diffHours = Math.floor(diff / 60);
            const diffMinutes = diff % 60;
            
            let selecteddayLength = Object.keys(days).filter(day => days[day]).length;
            let getTotalWorkingHoursOfWeek = (parseInt(selecteddayLength)*parseInt(diffHours*60)+diffMinutes)

            if(getTotalWorkingHoursOfWeek > mandatoryOvertimeLimitPerWeek*60){
                return "The average number of hours per week deviates from the set limit"
            }

        }
        // return "Invalid time format";
    }

   

    function deleteAll(id){
        confirmAlert({
            title: ('Confirm to delete'),
            message: "Are you sure to delete an item?",
            buttons: [
                {
                    label: ('Yes'),
                    onClick: async() => {
                        await _Roster.deleteRoster({id:id})
                    },
                },
                {
                    label: ('No'),
                    onClick: () => { },
                },
            ],
        });
    }
    

    async function updatePublish(id){
        let getShiftData = await _Roster.getRosterById(id);
        if(getShiftData.data.override && getShiftData.data.publish == 'unpublished'){
            let data = {
                roster:{
                    id:getShiftData.data.id,
                    "name":getShiftData.data.name,
                    "selected_user_list":getShiftData.data.selected_user_list,
                    "repeat_schedule":getShiftData.data.repeat_schedule,
                    "publish_type":getShiftData.data.publish_type,
                    "published":getShiftData.data.publish,
                    "group":getShiftData.data.group,
                    "employee":getShiftData.data.employee,
                    "start_date":getShiftData.data.start_date,
                    "end_date":getShiftData.data.end_date,
                    "override":getShiftData.data.override
                },
                rosterList:getShiftData.data.RosterShiftLists
            }
            const conflictScheduleList = await _Roster.getConflictScheduleList(data);
            console.log("conflictScheduleList---",conflictScheduleList.conflictList)
            if(conflictScheduleList.conflictList.length == 0){
                setSaveAndPublish(true)
            }else{
                setConflictPeopleList(conflictScheduleList.conflictList);
                setShowPeople(true)
            }
        }else{
            setSaveAndPublish(true)
        }
    }

    useEffect(() => {
        if(saveAndPublish){
            confirmAlert({
                title: ('Confirm to publish'),
                message: "It will not change your current roster. Are you sure to publish?",
                buttons: [
                    {
                        label: ('Yes'),
                        onClick: async() => {
                            await _Roster.updatePublish(publishId)
                        },
                    },
                    {
                        label: ('No'),
                        onClick: () => {setSaveAndPublish(false) },
                    },
                ],
            });
            setSaveAndPublish(false)
        }
       
    },[saveAndPublish])

    const showPeopleModel = (row) => {
        setShowPeople(true);
        setPeopleList(row);
    }

    const closePeopleModel = () => {
        setShowPeople(false);
        setPeopleList([]);
    }

    const handleTableButtonAction = (id, type) => {
        if (type === 'edit') navigate(`/admin/roster/editRoster/${id}`);
    };

    function getWeekNumber(date) {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDays = (date - firstDayOfYear) / (24 * 60 * 60 * 1000);
        return Math.ceil((pastDays + firstDayOfYear.getDay() + 2) / 7);
    }


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize
    } = useTable(
        { columns, data, manualSortBy: true },
        useSortBy,
        usePagination,
        useFlexLayout,
        useRowSelect);
    return (
        <div className='row'>
            {/* <List header starts here> */}
            <div className='mt-0'>
                <div className='row align-items-center'>
                    <div className='col-12 col-sm-12 col-md-2'>
                        <span className='table-container-header'>{props.tableHeader ? props.tableHeader : ""}</span>
                    </div>
                    {!props.splitHeader &&
                        <div className='col-12 col-md-10'>
                            <div className='row genericlist_header_xxl_auto justify-content-lg-end'>
                                <div className={`col-12`}>
                                    <div className='table-list__header'>
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {props.splitHeader && <div className='mt-1'>{props.children}</div>}
            {/* <List header ends> */}

            {/* <filter starts here> */}
            {props.header &&
                <div className='mt-1'>
                    {props.header}
                </div>
            }
            {/* <filter ends> */}


            {/* <table starts here> */}
            
                        {
                             page.length ? page.map(row => {
                                prepareRow(row)
                                return (
                                    <>
                                        <Accordion defaultActiveKey={['0']} alwaysOpen>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className="mt-0 bg-danger">                                                
                                                    <Row className="w-100">
                                                        <Col xs={6}> 
                                                        <div className="d-flex justify-content-start accordionHeader" >
                                                        <p className="period">{row.original.name}   ({'Date'} {shortDateFormat(row.original.start_date)} | {shortDateFormat(row.original.end_date)})</p>
                                                        </div>
                                                        </Col>
                                                        {
                                                            row.original.publish == 'archieve' ? 
                                                            <Col xs={6} className="text-end">
                                                            <div className="d-flex justify-content-end">
                                                                <Button className="publishButton ml-5"  onClick={(e) => {e.stopPropagation()}}>
                                                                {t("Archieve")}
                                                                </Button> 
                                                            </div>
                                                        </Col> :  <Col xs={6} className="text-end">
                                                            <div className="d-flex justify-content-end">
                                                                {
                                                                    row.original.repeat_schedule  && 
                                                                    <>
                                                                    <OverlayTrigger
                                                                            key="roster_repeat"
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="tooltip-repeat">
                                                                                    {t('Repeating')} ({shortDateFormat(row.original.rosterList[row.original.rosterList.length-2].end_week,"")})
                                                                                </Tooltip>
                                                                            }>
                                                                            <img className="repeating" style={{"height": "33px"}} src={"/images/repeat.png"}></img> 
                                                                    </OverlayTrigger>
                                                                    </>
                                                                }
                                                                <div className="actionButton">
                                                                    <OverlayTrigger
                                                                            key="roster_edit"
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="tooltip-top">
                                                                                    {t('Edit Roster')
                                                                                    }
                                                                                </Tooltip>
                                                                            }>
                                                                        <Button icon_sm fill style={{ marginRight: "8px" }}
                                                                        onClick={() => handleTableButtonAction(row.original.id, 'edit')}>
                                                                            <img src={editIcon} alt="edit button" />
                                                                        </Button>
                                                                    </OverlayTrigger>
                                                                    
                                                                    <OverlayTrigger
                                                                            key="roster_delete"
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="tooltip-top">
                                                                                    {t('Archive Roster')
                                                                                    }
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <Button
                                                                                icon_sm_delete
                                                                                fill
                                                                                onClick={(e) => {e.stopPropagation();deleteAll(row.original.id)}}
                                                                            >
                                                                                <img src={deleteIcon} alt="Archive" />
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                </div>
                                                                <Button className="publishButton ml-5"  onClick={(e) => {e.stopPropagation();setPublishId(row.original.id);updatePublish(row.original.id)}}>
                                                                    <i className="fa fa-eye fa-xl margin-right-10 mr-25" />
                                                                {t(row.original.publish == 'unpublished' ? 'Unpublished' : 'Published')}
                                                                </Button> 
                                                            </div>
                                                        </Col>
                                                        }
                                                    </Row> 
                                                </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="table-responsive">
                                                            <table {...getTableProps()} className='table'>
                                                                <thead>
                                                                    {headerGroups.map(headerGroup => (
                                                                        <tr key={uuid()} {...headerGroup.getHeaderGroupProps()}>
                                                                            {headerGroup.headers.map(column => (
                                                                                <th key={uuid()} {...column.getHeaderProps(column.getSortByToggleProps())}
                                                                                    className={`${column.textAlign ? `text-${column.textAlign}` : ""} ${column.padddingLeft5 ? "__titile_padding_left" : ""} __flex_header `}
                                                                                    title={column.Header && ['string','number'].includes(typeof(column.Header)) ? column.Header : ""}
                                                                                >
                                                                                    <span>{column.render('Header')}</span>
                                                                                    {column.isSortable && renderSortedButtons(column)}
                                                                                </th>
                                                                            ))}
                                                                        </tr>
                                                                    ))}
                                                                </thead>
                                                                <tbody role="rowgroup">
                                                                {
                                                                    row.original.rosterList.length && row.original.rosterList.map(rosterList => {
                                                                        return (
                                                                            <>
                                                                            <tr role="row" class="tbody-row rosterTable">
                                                                                {
                                                                                    averageOvertimeCalculation &&  
                                                                                    <td style={{"marginLeft": "57px"}}>
                                                                                        <p>{getWeekNumber(new Date(rosterList.start_week))}</p>
                                                                                    </td>
                                                                                }
                                                                                <td class="class2" role="cell" title="" style={{"marginLeft": averageOvertimeCalculation ? "74px" : ""}}>
                                                                                    <span>
                                                                                        <p>{rosterList.id}</p>
                                                                                        <span>{shortDateFormat(rosterList.start_week,"")} | {shortDateFormat(rosterList.end_week,"")}</span>                                                                                        
                                                                                    </span>
                                                                                    {
                                                                                        averageOvertimeCalculation && <p className='exceedMaxHoursList'>{exceedMaxWorkingHours(`(${timeFormat(rosterList.shift_start_time) +" - "+ timeFormat(rosterList.shift_end_time)})`,rosterList.days)}</p>
                                                                                    }
                                                                                    
                                                                                </td>
                                                                                <td class="class3" role="cell" title="" >
                                                                                    <div class="d-flex justify-content-center">
                                                                                    {
                                                                                        row.original.user && row.original.user.map((user,i) => {
                                                                                            return(
                                                                                                <>
                                                                                                <OverlayTrigger
                                                                                                    key="roster_repeat"
                                                                                                    placement="top"
                                                                                                    overlay={
                                                                                                        <Tooltip id="tooltip-repeat">
                                                                                                            {user.first_name+" "+user.last_name}
                                                                                                        </Tooltip>
                                                                                                    }>
                                                                                                        {i <= 3 ? user.image ? <img src={config.API_ROOT+"/attachments/"+user.image} className='profilePhoto' /> : <img className='userDummyImage' src={defaultAvatar} /> : <></>}
                                                                                                    </OverlayTrigger>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                    {
                                                                                        row.original.user.length > 4 && 
                                                                                        <div class="showMorePeople" onClick={() => showPeopleModel(row.original.user)}>
                                                                                            <p class="showMorePeopleTag">+{row.original.user.length-4}</p>
                                                                                        </div>
                                                                                    }
                                                                                        
                                                                                    </div>
                                                                                </td>
                                                                                <td class="class4" role="cell" title="">
                                                                                    <span class="timelog-date">{rosterList.task}</span>
                                                                                </td>
                                                                                <td class="class4" role="cell" title="" >
                                                                                    <span class="timelog-date">{rosterList.shift_name} - {timeFormat(rosterList.shift_start_time)} - {timeFormat(rosterList.shift_end_time)}</span>                                                                                    
                                                                                </td>
                                                                                <td class="class4" role="cell" title="Draft" >
                                                                                    <span>{rosterList.publish == 'published' ? rosterList.publish  : 'waiting for publish'}</span>
                                                                                </td>
                                                                            </tr>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>      
                                    </>
                                )
                            })
                                :
                                <tr>
                                    <td className='noData d-flex justify-content-start justify-content-md-center mt-10 w-100'>
                                        {props.t("No data found")}
                                    </td>
                                </tr>
                            }

                       
            
            {/* <table ends> */}

            {/* <pagination starts here> */}
            {pages > 1 &&
            
                <div className="mt-4">
                    <TablePagination
                        pages={pages}
                        requestData={props.requestData}
                        filters={props.filters}
                        handlePagination={handlePagination}
                        page={currentPage}
                    />
                </div>
                        
            }
            {/* <pagination ends> */}

            {   
                    showPeople && conflictPeopleList.length > 0 &&<ShowMorePeople closePeopleModel={closePeopleModel} conflictList={conflictPeopleList} setSaveAndPublish={setSaveAndPublish}/>

            }
        </div>
    )
}

export default withLocalization(GenericList)