import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import TableWidget from '../TableWidget.js';
import ExtraPaymentsFormWithModal from './ExtraPaymentsForm.js';
import Button from '~/components/CustomButton/CustomButton.jsx';


class ExtraPayments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            extraPayments: [],
            modalOpen: false,
            currentEntityIndex: -1
        }
        this.loadData = this.loadData.bind(this);
        this.handleUserDataChange = this.handleUserDataChange.bind(this);
        this.handleExtraPaymentCustomForm = this.handleExtraPaymentCustomForm.bind(this);
    }

    static noteText = "To ensure consistency between the reports generated for both time&control as well as your Integration (TripleTex/Visma) report, Please ensure that you fill in the appropriate values for the Additional Percentage or Additional Amount. This will help maintain accuracy and alignment across both sets of data";
    static updateNoteText = function () {
        ExtraPayments.noteText = "Please ensure that you have updated the hourly payment gross rate to fully utilize this function";
    }

    componentDidMount() {
        const { commonStore } = this.props;
        this.loadData();
        if (!commonStore.config?.integration_details?.id) {
            ExtraPayments.updateNoteText();
        }
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.extraPayments) {
            const extraPayments = currentEntity.client.data.extraPayments;
            this.setState({ extraPayments })
        }
    }

    handleUserDataChange = (value) => {
        const { currentEntity } = this.props.clientStore;
        let _extraPayments = currentEntity.client.data.extraPayments;
        if (this.state.currentEntityIndex > -1) {
            _extraPayments[this.state.currentEntityIndex] = value;
        } else {
            _extraPayments.push(value);
        }
        currentEntity.client.data.extraPayments = _extraPayments
        this.setState({ extraPayments: _extraPayments, currentEntityIndex: -1, modalOpen: false });
    }

    handleDataDelete = (index) => {
        const { currentEntity } = this.props.clientStore;
        let _data = currentEntity.client.data.extraPayments;
        _data.splice(index, 1);
        currentEntity.client.data.extraPayments = _data;
    }

    handleExtraPaymentCustomForm = function (entityIndex, isModalOpen) {
        this.setState({ currentEntityIndex: entityIndex, modalOpen: isModalOpen })
    }

    static formatData = function (data, t, _userGroups) {
        return data.map(item => ({
            name: `${t(item.name)} (${item.isActive ? t('Active') : t('Inactive')})`,
            selectecWageTypeInText: item.payrollWageType?.label ? t(item.payrollWageType.label) : '-',
            extraPaymentTypeInText: item.extraPaymentType?.label ? `${t(item.extraPaymentType.label)} (${item.amount})` : '-',
            groups: item.userGroups === 'all' ? t("All Groups") :
                _userGroups.filter(ele => item.userGroups.includes(ele.code))
                    .map((ele, index) => {
                        if ((index + 1) % 3) return ele.name;
                        return `\n${ele.name}`;
                    }).join(", "),
            settings: ['shift_allowance', 'emergency_shifts'].includes(item?.code) ? t("Once activated weekly shift allowance \ncan be assigned to employees from schedule screen") :
                `${item.fullDay ? t("Holiday") : t('Time Range')}`,
            isNotDeletable: !!item.isNotDeletable
        }));
    }

    render() {
        const { t, clientStore } = this.props;
        const { extraPayments } = this.state;
        const _userGroups = clientStore.currentEntity?.client?.data?.extendedRules?.user_groups || []
        const formattedData = ExtraPayments.formatData(extraPayments, t, _userGroups);
        return (
            <div>
                <div className="mt-2 custom-card">
                    <Row>
                        <Col xs={8}>
                            <h6>{t('Additional Payments')}</h6>
                        </Col>
                        <Col xs={4} className='d-flex justify-content-end'>
                            <Button wd fill onClick={() => this.handleExtraPaymentCustomForm(-1, true)}>
                                <i className="fa fa-plus-circle fa-xl margin-right-10" /> {this.props.t('Add new')}
                            </Button>
                        </Col>
                    </Row>
                    <p>{t('Note')}** {t(ExtraPayments.noteText)}</p>
                    <TableWidget
                        headers={['Name(Status)', 'Payroll Supplement', 'Wage Type(Amount or %)', 'Groups', 'Settings']}
                        metaData={[
                            { field: 'name', type: 'text' },
                            { field: 'selectecWageTypeInText', type: 'text' },
                            { field: 'extraPaymentTypeInText', type: 'text' },
                            { field: 'groups', type: 'text', thStyle: { width: "150px", textAlign: 'start' }, tdStyle: { whiteSpace: "pre", textAlign: 'start' } },
                            { field: 'settings', type: 'text', thStyle: { width: "150px", textAlign: 'start' }, tdStyle: { whiteSpace: "pre", textAlign: 'start' } }
                        ]}
                        data={formattedData}
                        onChange={_ => { }}
                        customDeleteHandler={this.handleDataDelete}
                        page={'extraPayments'}
                        className={'mb-100'}
                        customFormHandler={(index) => this.handleExtraPaymentCustomForm(index, true)}
                    />
                    <ExtraPaymentsFormWithModal
                        entity={this.state.currentEntityIndex > -1 ? extraPayments[this.state.currentEntityIndex] : {}}
                        modalOpen={this.state.modalOpen}
                        handleUserDataChange={this.handleUserDataChange}
                        existingRules={extraPayments.filter((_, idx) => idx !== this.state.currentEntityIndex).map(ele => ele.code)}
                        closeModal={() => this.handleExtraPaymentCustomForm(-1, false)}
                    />
                </div>
            </div >
        );
    }
}
export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(ExtraPayments))));