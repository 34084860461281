import { observable, action, makeObservable } from 'mobx';
import agent from '../agent';

class RosterStore {
    constructor() {
        makeObservable(this, {
            currentList: observable,
            setFilter: action,
            onFilter: action,
            filters: observable,
            lastListLoadTime: observable,
            resetLastListLoadTime: action,
            appliedFilters: observable,
        });        
    }

    currentList = [];
    requestParams = null;
    deleteSuccess = false;
    lastListLoadTime = null;
    filters = {
        status: '',
        name: '',
        showAnomalies: false,
    };
    appliedFilters = {
        status: '',
        name: '',
        showAnomalies: false,
    };

    loadList(params) {
        return agent.Roster.list(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    createRoster(body) {
        return agent.Roster.createRoster(body)
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    getConflictScheduleList(body) {
        return agent.Roster.getConflictScheduleList(body)
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    getPublishedConflictScheduleList(body) {
        return agent.Roster.getPublishedConflictScheduleList(body)
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    updateRoster(body,id) {
        return agent.Roster.updateRoster(body,id)
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    getRosterById(id) {
        return agent.Roster.getRosterById(id)
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    deleteRoster(id) {
        return agent.Roster.deleteRoster(id)
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    getuserByGroupId(id) {
        return agent.Roster.getuserByGroupId(id)
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    updatePublish(id) {
        return agent.Roster.updatePublish(id)
            .then(
                action((list) => {
                    return list;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    setFilter(name, value, clearFilter) {
        console.log("ddddd",name,value)
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
        if (clearFilter) {
            this.filters = {}
        }
    }

    onFilter() {
        console.log("this.filtersrrfrfr=====",Object.assign({}, this.filters));
        let filterData = Object.assign({}, this.filters)
        this.appliedFilters = Object.assign({}, this.filters);
        this.loadList(filterData)
        
    }

    resetLastListLoadTime(value) {
        this.lastListLoadTime = value;
    }
}

const _Roster = new RosterStore();
export default _Roster;
