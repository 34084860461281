import React, { useEffect, useState } from 'react';
import { inject } from 'mobx-react';
import { Col, Row } from 'react-bootstrap';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '../../../hoc/withLocalization.js';
import TimePickerInput from '../../../components/TimePicker/index.js';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';

//utils
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc.js';
import { shortDateFormat } from '~/utils/dateFormat';

//assets
import ApprovalIcon from '../../../assets/img/ApprovalIcon.svg';
import RejectIcon from '../../../assets/img/RejectIcon.svg';
import InputTime from '../../../components/GenericForm/Widgets/InputTime.js';

const TimeBankForm = ({ timelogStore, userStore, timeBankStore, t, commonStore, onClose, goBack, timelog_id }) => {
    const [timelog, setTimelog] = useState({});
    const [timeBankHours, setTimeBankHours] = useState(0);
    const [overtimes, setOvertimes] = useState({});
    const [updatedOvertimes, setUpdatedOvertimes] = useState(null);
    const [overtimeArray, setOvertimeArray] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            const _timelog = await timelogStore.load(timelog_id);
            const timelog = JSON.parse(JSON.stringify(_timelog.timelog));
            const timeBankTotalHours = timelog?.data?.timeBank?.length && timelog?.data?.timeBank.reduce((acc, curr) => (curr?.value / curr?.ratio) + acc, 0) || 0
            const {overtimeAutomaticMode, weeklyCalculationSettings = {}} = commonStore.config.client.data.basicRules
            setTimelog(timelog);
            if (overtimeAutomaticMode && !weeklyCalculationSettings.allowAverageOvertimeCalculation) {
                const code = commonStore.config.client.data.basicRules.overtimeCalcRules.calc_percent;
                if (timelog._total_hours_overtime) {
                    const overtimes = ({
                        [code]: {
                            code: `${commonStore.config.client.data.basicRules.overtimeCalcRules.calc_percent}`,
                            value: minutesToTimeInput(timelog._total_hours_overtime, false),
                            name: `Automatic Overtime ${commonStore.config.client.data.basicRules.overtimeCalcRules.calc_percent}%`
                        }
                    })
                    setOvertimes(overtimes)
                    setOvertimeArray([overtimes[code]]);
                }
            }
            else {
                if (timelog?.data?.overtimes && Object.keys(timelog?.data?.overtimes)?.length) {
                    setOvertimes(timelog?.data?.overtimes);
                    setOvertimeArray(Object.keys(timelog?.data?.overtimes).map(ele => timelog?.data?.overtimes[ele]));
                }
            }
            handleTimeBankHours(minutesToTimeInput(timeBankTotalHours));
        };
        loadData()
    }, []);

    const validateTimeBank = () => {

        if (!timeBankHours)
            return (t('Please Enter the Time Bank hours'));
        if (timeBankHours > ((getMinutesFromTimeInput(timelog.to) - getMinutesFromTimeInput(timelog.from)) - timelog.break))
            return (t('Time Bank hours can not exceed the total logged hours'));
    }

    const handleSave = async () => {
        const isValid = validateTimeBank();
        if (isValid)
            return commonStore.addNotification(t(isValid), null, 'error');
        const payload = { timelog_id, requested_minutes: timeBankHours, updatedOvertimes };
        await timeBankStore.saveTimeBank(payload);
        timeBankStore.onFilter();
        goBack();
    };

    const handleTimeBankHours = async (value) => {
        const timeBankMins = getMinutesFromTimeInput(value);
        const originalOvertimes = JSON.parse(JSON.stringify(overtimes));
        setTimeBankHours(timeBankMins);
        if (timeBankMins && Object.keys(originalOvertimes).length) {
            let remainingTimeToDeduct = timeBankMins;
            const _overtime = JSON.parse(JSON.stringify(originalOvertimes));

            for (let key in _overtime) {
                const overtime = _overtime[key];
                const overtimeValueInMins = getMinutesFromTimeInput(overtime.value);
                if (remainingTimeToDeduct > 0) {
                    if (remainingTimeToDeduct >= overtimeValueInMins) {
                        _overtime[key] = { ...overtime, value: minutesToTimeInput(0) };
                        remainingTimeToDeduct -= overtimeValueInMins;
                    } else {
                        _overtime[key] = {
                            ...overtime,
                            value: minutesToTimeInput(overtimeValueInMins - remainingTimeToDeduct),
                        };
                        remainingTimeToDeduct = 0;
                        break;
                    }
                }
            }
            setUpdatedOvertimes(_overtime);
        } else {
            setUpdatedOvertimes(originalOvertimes);
        }
    };

    const dateTimeRules = commonStore.config.client?.data?.dateTimeRules || {};

    return (
        <div className="mb-5">
            <div className="primary-page__title mt-2">{t('User')} {t('Details')}</div>

            {/* User Details */}
            <div className="row timelog-grid-time-card mt-2">
                <Col xs={12} md={6} className="timelog-grid-item-1 justify-content-space-between card-timelog-grid-item">
                    <div className="d-flex">
                        <span className="timelog-grid__text">{t('Name')}</span>
                    </div>
                    <b className="timelog-final-time-card-modal align-self-center">
                        {timelog?.User?.first_name} {timelog?.User?.last_name}
                    </b>
                </Col>
                <Col xs={12} md={6} className="timelog-grid-item-2 justify-content-space-between card-timelog-grid-item">
                    <div className="d-flex align-items-center">
                        <span className="timelog-grid__text">{t('For Date')}</span>
                    </div>
                    <b className="timelog-final-time-card-modal align-self-center">
                        {shortDateFormat(timelog.for_date, dateTimeRules)}
                    </b>
                </Col>
            </div>

            {/* Registered Working Hours */}
            <div className="primary-page__title mt-2">{t('Registered working hours')}</div>
            <div className="row timelog-grid-time-card mt-2">
                <Col xs={12} md={6} className="timelog-grid-item-1 justify-content-space-between card-timelog-grid-item">
                    <div className="d-flex">
                        <span className="timelog-grid__text">{t('Start')}</span>
                    </div>
                    <b className="timelog-final-time-card-modal">{timelog.from}</b>
                </Col>
                <Col xs={12} md={6} className="timelog-grid-item-2 justify-content-space-between card-timelog-grid-item">
                    <div className="d-flex align-items-center">
                        <span className="timelog-grid__text">{t('End')}</span>
                    </div>
                    <b className="timelog-final-time-card-modal">{timelog.to}</b>
                </Col>
            </div>

            {/* Break and Total Time */}
            <div className="row timelog-grid-time-card">
                <Col xs={12} md={6} className="timelog-grid-item-1 justify-content-space-between card-timelog-grid-item">
                    <div className="d-flex align-items-center">
                        <span className="timelog-grid__text">{t('Breaktime')}</span>
                    </div>
                    <b className="timelog-final-time-card-modal">{minutesToTimeInput(timelog.break || 0)}</b>
                </Col>
                <Col xs={12} md={6} className="timelog-grid-item-2 justify-content-space-between card-timelog-grid-item">
                    <div className="d-flex align-items-center">
                        <span className="timelog-grid__text">{t('Total')}</span>
                    </div>
                    <b className="timelog-final-time-card-modal">
                        {timelog.from > timelog.to
                            ? minutesToTimeInput(1440 - getMinutesFromTimeInput(timelog.from) + getMinutesFromTimeInput(timelog.to) - (timelog.break || 0))
                            : minutesToTimeInput(getMinutesFromTimeInput(timelog.to) - getMinutesFromTimeInput(timelog.from) - (timelog.break || 0))}
                    </b>
                </Col>
            </div>

            {/* Add Hours to overtime */}
            {overtimes && Object.keys(overtimes)?.length ?
                <>
                    <div className="row timelog-grid-time-card">
                        {overtimeArray?.length &&
                            overtimeArray.map((overtime, index) => (
                                overtimes[overtime?.code]?.value ?
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className='timelog-grid-extra-payment-item_approval justify-content-space-between card-timelog-grid-item' key={`${index}_${overtime.code}`}>
                                        <div className='d-flex align-items-center'>
                                            <span className="timelog-grid__text timelog-grid__text-capitalize w-100">
                                                {overtime.name}
                                            </span>
                                        </div>
                                        <div>
                                            <InputTime
                                                value={
                                                    overtimes[overtime.code]
                                                        ? overtimes[overtime.code].value
                                                        : '00:00'
                                                }
                                                disabled={true}
                                                className="big-input text-center"
                                            />
                                        </div>
                                    </Col>
                                    : null
                            ))}
                    </div>
                </>
                :
                <div className='text-center'>
                    {t('No Overtime Record Found for the day')}!
                </div>
            }

            {/* Add Hours to Timebank */}
            <div className="row timelog-grid-time-card mt-4">
                <Col xs={12} className="timelog-grid-item-1 justify-content-space-between card-timelog-grid-item height-50">
                    <div className="d-flex align-items-center">
                        <span className="timelog-grid__text" style={{ width: '100%' }}>{t('Add Hours that you wish to add to Timebank')}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <TimePickerInput
                            value={minutesToTimeInput(timeBankHours)}
                            format={'HH:mm'}
                            onChange={(value) => handleTimeBankHours(value)}
                            className="worklog-input border-radius-10 borderworklog"
                        />
                    </div>
                </Col>
            </div>


            <div>{updatedOvertimes && Object.values(updatedOvertimes).length ? t('Updated Overtime') : <></>}</div>
            <div className="row timelog-grid-time-card">
                {updatedOvertimes && overtimeArray.map((overtime, index) => (
                    updatedOvertimes[overtime?.code]?.value ?
                        <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className='timelog-grid-extra-payment-item_approval justify-content-space-between card-timelog-grid-item' key={`${index}_${overtime.code}`}>
                            <div className='d-flex align-items-center'>
                                <span className="timelog-grid__text timelog-grid__text-capitalize w-100">
                                    {overtime.name}
                                </span>
                            </div>
                            <div>
                                <InputTime
                                    value={
                                        updatedOvertimes[overtime.code]
                                            ? updatedOvertimes[overtime.code].value
                                            : '00:00'
                                    }
                                    disabled={true}
                                    className="big-input text-center"
                                />
                            </div>
                        </Col>
                        : null
                ))}
            </div>


            {/* Buttons */}
            <div className="text-center mt-10">
                <Row>
                    <Col xs={12} sm={4}>
                        <Button
                            fill
                            wd
                            icon
                            style={{ backgroundColor: 'green', border: 0 }}
                            onClick={() => handleSave()}
                        >
                            <img src={ApprovalIcon} alt="ApprovalIcon" /> {t('Save')}
                        </Button>
                    </Col>
                    <Col xs={12} sm={4}>
                        <Button
                            fill
                            wd
                            icon
                            style={{ backgroundColor: 'grey', border: 0 }}
                            onClick={() => goBack()}
                        >
                            <img src={RejectIcon} alt="ApprovalIcon" /> {t('Go Back')}
                        </Button>
                    </Col>
                    <Col xs={12} sm={4}>
                        <Button
                            fill
                            wd
                            icon
                            style={{ backgroundColor: 'red', border: 0 }}
                            onClick={() => onClose()}
                        >
                            <img src={RejectIcon} alt="ApprovalIcon" /> {t('Close')}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default inject('timelogStore', 'timeBankStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(TimeBankForm)));
