import { observable, action, makeObservable } from 'mobx';
import moment from 'moment/min/moment-with-locales';
import queryString from 'query-string';
import agent from '../agent';
import userStore from './userStore';
import { calculateBusinessDays } from '../../utils/commonMethods';

class VacationStore {
    constructor() {
        makeObservable(this, {
            currentList: observable,
            currentEntity: observable,
            updatingErrors: observable,
            updating: observable,
            loading: observable,
            requestParams: observable,
            deleteSuccess: observable,
            lastListLoadTime: observable,
            resetLastListLoadTime: action,
            filters: observable,
            appliedFilters: observable,
            setFilter: action,
            onFilter: action,
            loadList: action,
            recalc: action,
            returnDefaultNew: action,
            load: action,
            save: action,
            remove: action,
            cancelVacation: action
        });
    }

    currentList = [];
    currentEntity = {};
    updatingErrors = null;
    updating = false;
    loading = false;
    requestParams = null;
    deleteSuccess = false;
    lastListLoadTime = null;
    filters = {
        status: '',
        name: '',
    };
    appliedFilters = {
        status: '',
        name: '',
    };

    resetLastListLoadTime(value) {
        this.lastListLoadTime = value;
    }

    setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    loadList(params) {
        return agent.Vacations.list(params)
            .then(
                action(list => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.lastListLoadTime = list.time;
                    this.currentList = list;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    async recalc(data, defaultHolidaysForVacation) {
        if (data && data.user_id) {
            this.currentEntity.vacation.user_id = data.user_id;
        }
        let from = data ? data.from : this.currentEntity.vacation.from;
        let to = data ? data.to : this.currentEntity.vacation.to;
        this.currentEntity.vacation.from = from;
        this.currentEntity.vacation.to = to;
        if (data && (moment(from, 'YYYY-MM-DD').valueOf() > moment(to, 'YYYY-MM-DD').valueOf())) {
            this.currentEntity.vacation.to = from;
            to = from;
        }

        let days = calculateBusinessDays(from, to, defaultHolidaysForVacation, true);
        this.currentEntity.vacation.total_days = days;
        return {
            to: this.currentEntity.vacation.to,
            total_days: this.currentEntity.vacation.total_days
        };
    }

    returnDefaultNew(params) {
        this.currentEntity = {
            vacation: {
                user_id: params.user_id,
                from: moment(new Date()).format('YYYY-MM-DD'),
                to: moment(new Date()).format('YYYY-MM-DD'),
                total_days: 1,
                vacation_type: 'normal',
                status: 'active',
                data: {},
            },
        };
        if (params && params.location) {
            const search = queryString.parse(params.location.search);
            if (search.from) {
                this.currentEntity.vacation.from = moment(new Date(search.from)).format('YYYY-MM-DD');
            }
            if (search.to) {
                this.currentEntity.vacation.to = moment(new Date(search.to)).format('YYYY-MM-DD');
            }
        }
        this.recalc();
        this.loading = false;
    }

    load(id) {
        this.loading = true;
        return agent.Vacations.load(id)
            .then(
                action(response => {
                    if (!response.vacation.data) response.vacation.data = {};
                    this.currentEntity = response;
                    this.currentEntity.vacation.from = moment(this.currentEntity.vacation.from).format('YYYY-MM-DD');
                    this.currentEntity.vacation.to = moment(this.currentEntity.vacation.to).format('YYYY-MM-DD');
                    this.loading = false;
                    return response;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    save(values, isAdd) {
        this.updating = true;
        const cUser = userStore.currentUser;
        return agent.Vacations.save(values, isAdd)
            .then(
                action(user => {
                    this.currentEntity = user;
                    //to get the fresh totals//
                    if (user.vacation.user_id === cUser.id) {
                        userStore.pullUser();
                    }
                    this.updating = false;
                    return user;
                })
            )
            .catch(
                action(err => {
                    this.updating = false;
                    this.updatingErrors = err.response && err.response.body && err.response.body.errors;
                    throw err;
                })
            );
    }

    async remove(id) {
        await agent.Vacations.remove(id);
        this.deleteSuccess = true;
        return 1;
    }

    cancelVacation(id) {
        return agent.Vacations.cancelVacation(id)
            .then(
                action(res => {
                    return res;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }
}

const _VacationStore = new VacationStore();
export default _VacationStore;