import React, { useState, useEffect } from 'react';
import { Row, Col, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { inject } from 'mobx-react';
import { confirmAlert } from 'react-confirm-alert';

//components
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import applicationRouter from '~/hoc/applicationRouter'
import UserProfileLogo from '../../../components/UserProfileLogo';
import GenericList from '~/components/GenericList/GenericList';
import TimeBankAddList from './TimeBankAddList.js';
import TimeBankForm from './TimeBankForm.js';

//utils
import { shortDateFormat, totalTimeFormat } from '~/utils/dateFormat';
import { timeBankStatusMap } from '../../../utils/normalStatuses.js';
import { getPercentage } from '../../../utils/commonMethods.js'
import { minutesToTimeInput } from '../../../utils/timeCalc.js';
import { getIntegrationIconWithStatus } from '../../../utils/integrationUtils.js';

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import RectangularSmallCard from '../../../components/Card/RectangularSmallCard';
import LoadingSpinner from '../../../elements/LoadingSpinner.js'

//assets
import Edit from '../../../assets/img/editing.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';
import Delete from '../../../assets/img/deleting.svg';

const TimeBankList = ({ t, timeBankStore, userDetails, userStore, commonStore, handleDelete, router }) => {
    const [totalPayoutHours, setTotalPayoutHours] = useState(0);
    const [addModal, setAddModal] = useState(false);
    const [addId, setAddId] = useState(null);
    const [selectedTimeBankIds, setSelectedTimeBankIds] = useState([]);
    const [reloadList, setReloadList] = useState(new Date());

    const isMember = userStore?.currentUser?.user_type === 'member';
    const clientData = commonStore?.config?.client?.data;
    const timeBank = clientData?.basicRules?.timeBankSettings;
    const dateTimeRules = clientData?.dateTimeRules ? clientData.dateTimeRules : false;
    const timeBankSettings = clientData?.basicRules?.timeBankSettings ? clientData?.basicRules?.timeBankSettings : false;
    const maxLimit = Number(timeBankSettings.maxHourLimitInCycle * 60) + Number(timeBankSettings.maxMinuteLimitInCycle || 0);

    useEffect(() => {
        setTotalPayoutHours(0)
    }, [userDetails?.id, reloadList])

    const onAdd = () => setAddModal(true);

    const handleTableButtonAction = async (id, type, entity) => {
        if (type === 'edit') {
            setAddModal(true)
            setAddId(entity.timelog_id)
        }
        if (type === 'remove') {
            return handleDelete(id, async (id) => {
                await timeBankStore.remove(id);
                timeBankStore.onFilter();
                setReloadList(new Date())
            });
        }
    };

    const onModalClose = () => {
        setAddModal(false)
        setAddId(null)
    }

    const onPayout = async () => {
        const integrationProduct = commonStore.config?.integration_details?.product || '';
        confirmAlert({
            title: t('Select an option'),
            message: '',
            buttons: [
                {
                    label: t('Pay out'),
                    className: integrationProduct ? "" : "cursor-no-drop" ,
                    onClick: () => {
                        if (!integrationProduct) return false;
                        return timeBankStore.saveToIntegration({ action: 'payout', user_id: userDetails?.id, time_bank_ids: selectedTimeBankIds }).then((res) => {
                            commonStore.addNotification(t(res?.message), null, 'success');
                            timeBankStore.onFilter();
                            setReloadList(new Date())
                            return res;
                        });
                    },
                },
                {
                    label: t('Take Vacation'),
                    onClick: () => {
                        return router.navigate(`/admin/vacations`);
                    },
                },
                {
                    label: t('Flexitime'),
                    className: integrationProduct ? "" : "cursor-no-drop" ,
                    onClick: () => {
                        if (!integrationProduct) return false;
                        return timeBankStore.saveToIntegration({ action: 'flexitime', user_id: userDetails?.id, time_bank_ids: selectedTimeBankIds }).then((res) => {
                            commonStore.addNotification(t(res?.message), null, 'success');
                            setReloadList(new Date());
                            timeBankStore.onFilter();
                            return res;
                        });
                    },
                },
            ],
        });
    }

    const handleIntegration = (id, action) => {
        const str = `${t('Please Note that Desyncing the user will not remove the hours from Time&Control or integration product. But it will desync the hours from Time&Control')}`
        confirmAlert({
            title: t(`Confirm to ${action}`),
            message: str,
            buttons: [
                {
                    label: t('Yes'),
                    onClick: () => {
                        return timeBankStore.desyncIntegration({ id }).then((res2) => {
                            commonStore.addNotification(`${t(res2.message)} ${commonStore.config?.integration_details?.product}`, null, 'success');
                            setReloadList(new Date());
                            return res2;
                        })
                    }
                },
                {
                    label: t('No'),
                    onClick: () => { },
                },
            ],
        })
    }


    const renderTableActionButtons = (id, entity) => {
        let statusText = entity?.status;
        if (!statusText) statusText = 'active';
        statusText = timeBankStatusMap[statusText] || '';
        const disabledOnApprovedOrPaid = entity.status !== 'active'
        return (
            <div className='actions-center actions-center__center'>
                <div onClick={() => handleIntegration(id, 'Desync')}>
                    {getIntegrationIconWithStatus(commonStore?.config, entity, t)}
                </div>
                <div className={`status-block status-block-height35 status-${entity.status || 'active'}`}>
                    <div className='text-capitalize'>
                        <div>{t(statusText)}</div>
                    </div>
                </div>
                <OverlayTrigger
                    key="view_project"
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{disabledOnApprovedOrPaid ? t("Can't modify when status is Approved or Paid") : `${t("Edit")} ${t("Request")}`}</Tooltip>}
                >
                    <Button icon_sm fill onClick={() => handleTableButtonAction(id, 'edit', entity)} disabled={disabledOnApprovedOrPaid}>
                        <img src={Edit} alt="edit" />
                    </Button>
                </OverlayTrigger>
                <OverlayTrigger
                    key="Delete_request"
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">{disabledOnApprovedOrPaid ? t("Can't modify when status is Approved or Paid") : `${t("Delete")} ${t("Request")}`}</Tooltip>}
                >
                    <Button icon_sm_delete fill onClick={() => handleTableButtonAction(id, 'remove')} disabled={disabledOnApprovedOrPaid}>
                        <img src={Delete} alt="delete" />
                    </Button>
                </OverlayTrigger>
            </div>
        )
    }

    const onSelectionChanged = (ids) => {
        const { currentMemberTimeBankList } = timeBankStore;
        const totalHours = currentMemberTimeBankList.reduce((acc, curr) => {
            if (ids[curr?.id])
                return acc + curr.adjusted_hours
            else
                return acc
        }, 0);
        setSelectedTimeBankIds(Object.keys(ids).filter(key => ids[key] && Number(key)));
        setTotalPayoutHours(totalHours);
    }

    if (timeBankStore.loading)
        return <LoadingSpinner />

    return (
        <div className={'h-100 mt-2'}>
            <Row>
                {isMember ?
                    <Col md={3} className='d-flex justify-content-end'></Col>
                    :
                    <Col md={3} className='d-flex flex-column'>
                        <UserProfileLogo image_id={userDetails?.image} width='50px' height="50px" className={'m-2'} />
                        <span className='d-flex align-items-center color-2550AC fw-600 text-capitalize'>{userDetails?.name}</span>
                    </Col>
                }
                <Col md={9} className='d-flex justify-content-end'>
                    {isMember &&
                        <div className='me-2'>
                            <RectangularSmallCard title={t('Total Limit')} titleValue={`${timeBank?.maxHourLimitInCycle}:${timeBank?.maxMinuteLimitInCycle}`} percentage='100' pathColor='red' rotationStart='5' strokeWidth='10' />
                        </div>
                    }
                    <div className='me-2'>
                        <RectangularSmallCard title={t('Saved Hours')} titleValue={minutesToTimeInput(userDetails?.total_hours_saved)} percentage={`${userDetails?.total_hours_saved ? getPercentage(maxLimit, userDetails?.total_hours_saved) : 0}`} pathColor='red' rotationStart='5' strokeWidth='10' />
                    </div>
                    <div>
                        <RectangularSmallCard title={t('Claimed Hours')} titleValue='0' percentage='0' pathColor='red' rotationStart='2' strokeWidth='10' />
                    </div>
                </Col>
            </Row>
            <GenericList
                columns={[
                    {
                        Header: 'Timesheet Date',
                        id: 'timelog_date',
                        accessor: ({ timelog_date }) => <span>{shortDateFormat(timelog_date, dateTimeRules)}</span>,
                        maxWidth: 60,
                        minWidth: 60
                    },
                    {
                        Header: 'Hours',
                        id: 'requested_hours',
                        accessor: ({ requested_hours }) => <span>{totalTimeFormat(requested_hours, dateTimeRules)}</span>,
                        maxWidth: 70,
                        minWidth: 70
                    },
                    {
                        Header: 'Ratio',
                        id: 'ratio',
                        accessor: ({ ratio }) => <span>{ratio}</span>,
                        maxWidth: 50,
                        minWidth: 50
                    },
                    {
                        Header: 'Calculated Hours',
                        id: 'adjusted_hours',
                        accessor: ({ adjusted_hours, requested_hours, ratio }) => (
                            <div>
                                <span className='fw-600'>{totalTimeFormat(adjusted_hours, dateTimeRules)}</span>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="tooltip-top">
                                            <div>{t('Total normal hours')} - {totalTimeFormat(requested_hours, dateTimeRules)}</div>
                                            {ratio > 1 ?
                                                <>
                                                    <div>{t('Overtime percent')} - {ratio * 100 - 100}%</div>
                                                    <div>{t('Total calculated hours')} - {totalTimeFormat(adjusted_hours, dateTimeRules)}</div>
                                                    <div>**{t('Total calculated hours is calculated on the basis of normal hours and addition of overtime percentage')}</div>
                                                </>
                                                :
                                                <></>
                                            }
                                        </Tooltip>
                                    }
                                >
                                    <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                </OverlayTrigger>
                            </div>
                        ),
                        maxWidth: 70,
                        minWidth: 70
                    },
                    {
                        Header: 'Type',
                        id: 'type',
                        accessor: ({ type }) => <span>{type}</span>,
                        maxWidth: 70,
                        minWidth: 70
                    },
                    {
                        Header: 'Category type',
                        id: 'moved_type',
                        accessor: ({ hours_moved_type }) => <span>{hours_moved_type ?? '-'}</span>,
                        maxWidth: 70,
                        minWidth: 70
                    },
                    {
                        Header: 'Actions',
                        id: 'actions',
                        accessor: (entity) => renderTableActionButtons(entity.id, entity),
                    },
                ]}
                header={
                    <Row>
                        <Col md={4} className='fw-600'>
                            {t('Time Bank Requests')}
                        </Col>
                    </Row>
                }
                filters={timeBankStore.appliedFilters}
                allowCheckboxes={isMember ? false : true}
                onSelectionChanged={(selection) => onSelectionChanged(selection)}
                requestData={(params) => timeBankStore.loadTimeBankList(params)}
                showCheckBoxOnApproved
                disableSelectAll
                scrollableHeight={isMember ? '' : "33dvh"}
            >
                {userStore?.currentUser?.user_type === 'member' ?
                    <Button fill wd onClick={(id) => onAdd(id)}>
                        {t('Add New')}
                    </Button>
                    :
                    <>
                        <Button fill wd onClick={() => onPayout()} disabled={!totalPayoutHours}>
                            {t('Pay Out')} {totalPayoutHours && totalTimeFormat(totalPayoutHours, dateTimeRules)}{t('hr')}
                        </Button>
                        <Button fill wd onClick={() => onAdd()}>
                            {t('Add New')}
                        </Button>
                    </>
                }
            </GenericList>
            <Modal
                className='modal right fade modal_revised'
                size="lg"
                show={addModal}
                onHide={() => onModalClose()}
            >
                <Modal.Header className="set_ModalHeader">
                    <Modal.Title className='w-100 text-center custom-modal-title'>
                        {t('Available hours that can be saved in the time bank')}
                    </Modal.Title>
                    <img
                        src={CloseModalIcon}
                        className="cursor-pointer"
                        onClick={() => onModalClose()}
                        alt='close-btn'
                    />
                </Modal.Header>
                <Modal.Body>
                    {addId ?
                        <TimeBankForm
                            timelog_id={addId}
                            goBack={() => setAddId(null)}
                            onClose={() => onModalClose()}
                        />
                        :
                        <TimeBankAddList
                            addTimelogId={(id) => setAddId(id)}
                        />
                    }
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default inject('commonStore', 'userStore', 'timeBankStore')(applicationRouter(withLocalization(withRemoveDialog(TimeBankList))));