import React, { Component, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { cloneDeep } from 'lodash';
import { Row, Modal } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

//components
import withLocalization from '~/hoc/withLocalization';
import GenericForm from '~/components/GenericForm/GenericForm';

//utils
import getSchema from '~/library/schemas/extraPayments';
import { extraPaymentWageTypes } from '~/utils/constants';

//assets
import CloseModalIcon from '~/assets/img/CloseApproveModalIcon.svg';

const defaultUiSchema = {
    'ui:field': 'layout',
    'ui:layout:hideframe': true,
    'ui:layout': [
        {
            name: { xs: 6, sm: 6, className: "mt-3" },
            isActive: { xs: { span: 4, offset: 2 }, sm: 3, className: "mt-3" },
        },
        {
            extraPaymentType: { xs: 4, className: "mt-3" },
            amount: { xs: 4, className: "mt-3" },
            payrollWageType: { xs: 4, className: "mt-3" },
        },
        {
            task: { xs: 4, className: "mt-3" },
            userGroups: { xs: 4, className: "mt-3" },
            employmentTypes: { xs: 4, className: "mt-3" },
        },
        {
            fullDay: { xs: 4, className: "mt-4" },
            fromTime: { xs: 4, className: "mt-3" },
            toTime: { xs: 4, className: "mt-3" },
            daysOfWeek: { xs: 12, sm: 9, className: "mt-3" },
        },
        {
            description: { xs: 12, className: "my-2" },
        },
    ],
    name: {
        'ui:placeholder': 'Enter additional payment name',
    },
    isActive: {
        'ui:widget': 'ActiveInactive',
        'ui:options': {
            valueInBool: true
        }
    },
    fullDay: {
        'ui:widget': 'Radio',
    },
    fromTime: {
        'ui:widget': 'InputTime',
        'ui:options': {
            valueInInt: true
        }
    },
    toTime: {
        'ui:widget': 'InputTime',
        'ui:options': {
            valueInInt: true
        }
    },
    daysOfWeek: {
        'ui:widget': 'DaysOfWeek',
        'ui:options': {
            includeCheckAll: true
        }
    },
    userGroups: {
        'ui:widget': 'SimpleMultiSelectWidget',
        'ui:options': {}
    },
    description: {
        'ui:widget': 'textarea',
        'ui:placeholder': 'Add a brief description for additional payment rule'
    },
    task: {
        'ui:widget': 'SimpleSelectWidget',
    }
};

class ExtraPaymentsForm extends Component {
    constructor(props) {
        super(props);
        this.wagetypes = props.commonStore.getWageTypesAccordingToIntegrations(props.commonStore?.config?.integration_details, 'su') || [];
        this._userGroups = props.commonStore.config?.client?.data?.extendedRules?.user_groups?.length ?
            props.commonStore.config?.client?.data?.extendedRules?.user_groups
            : [];
        const _availableTasksForExtraPayments = JSON.parse(JSON.stringify(props.clientStore.availableActiveTaskInClient));
        _availableTasksForExtraPayments.unshift({ name: 'None', id: 0 });
        this.availableTasksForExtraPayments = _availableTasksForExtraPayments;
        this.state = {
            schema: getSchema({
                payrollWagetypes: this.wagetypes, code: props.entity?.code,
                isNotDeletable: props.entity.isNotDeletable,
                translate: props.t
            }),
            uiSchema: this.prepareSchema(defaultUiSchema, this._userGroups, this.props.entity.code, this.props.t, this.availableTasksForExtraPayments)
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { schema } = this.state;
        const { entity } = this.props;
        const _schema = cloneDeep(schema)
        if (entity.task) {
            _schema.properties.userGroups['readOnly'] = true
            _schema.properties.employmentTypes['readOnly'] = true
        }
        this.setState({ schema: _schema })
    }

    validateExtraPayment(formData, errors) {
        if (formData) {
            const { t, existingRules } = this.props;
            if (formData?.name && existingRules.includes(formData.name.replace(/\s/g, '').toLowerCase())) {
                errors.name.addError(t('Name already in use, please add unique name'))
            }
            if (!formData?.daysOfWeek) {
                errors.daysOfWeek.addError(t('Please select atleast one day!'))
            }
            if (!formData?.fullDay && !formData?.fromTime && !formData?.toTime) {
                errors.fromTime.addError(t('Please select from time or to time'))
                errors.toTime.addError(t('Please select from time or to time'))
            }
        }
        return errors;
    }

    onChangeHandler(formData) {
        if (formData.task) {
            formData.employmentTypes = 'all';
            formData.userGroups = 'none';
        }
        return formData;
    }

    confirmToSave = (data, entity, values) => {
        const { t, userStore } = this.props;
        const message = entity.length < values.length
            ? 'You have added one or more groups to this Additional Payment. Would you like the system to automatically assign this Additional Payment to all members within the selected group(s)'
            : entity.length && values[0] === 'none'
                ? 'You have removed all groups from this additional payment, would like the system to also remove the additional from member profile'
                : entity.length > values.length
                    ? 'You have removed groups from this additional payment, would like the system to also remove the additional from member profile'
                    : 'You have added one or more groups to this Additional Payment. Would you like the system to automatically assign this Additional Payment to all members within the selected group(s)';
        confirmAlert({
            title: t('Confirm Auto-assign') + '?',
            message: t(message),
            buttons: [
                {
                    label: t('Yes') + ', ' + t('auto-assign'),
                    onClick: async () => {
                        await userStore.updateUserProfileOnBasisOfGroups({ extraPayments: data });
                        this.props.handleUserDataChange(data);
                    },
                },
                {
                    label: t('No') + ' ' + t('Will update Manually'),
                    onClick: () => {
                        this.props.handleUserDataChange(data);
                    },
                }
            ],
        });
    }

    onSave = async (values) => {
        const { entity } = this.props;
        const data = {
            code: values.code ? values.code : values.name.replace(/\s/g, '').toLowerCase(),
            name: values.name,
            extraPaymentType: extraPaymentWageTypes.find(ele => ele.value === values.extraPaymentType),
            payrollWageType: this.wagetypes.find(ele => ele.value === values.payrollWageType),
            amount: values.amount,
            fullDay: values.fullDay,
            fromTime: values.fromTime,
            toTime: values.toTime,
            daysOfWeek: values.daysOfWeek,
            task: values.task,
            userGroups: values.userGroups,
            employmentTypes: values.employmentTypes,
            description: values.description?.trim() ? values.description : values.name,
            isNotDeletable: !!values.isNotDeletable,
            isActive: !!values.isActive
        }
        if (values.userGroups) {
            const entityGroups = entity?.userGroups ? entity?.userGroups.split(',') : [];
            const valuesGroups = values.userGroups.split(',');
            const checkDiffGroups = entityGroups.length !== valuesGroups.length ||
                !entityGroups.every(element => valuesGroups.includes(element)) ||
                valuesGroups.filter(element => !entityGroups.includes(element)).length > 0;
            if (checkDiffGroups && !values?.task)
                this.confirmToSave(data, entityGroups, valuesGroups)
            else
                this.props.handleUserDataChange(data);
        }
        else
            this.props.handleUserDataChange(data);
    }

    getBack = () => {
        if (this.props.getBack)
            this.props.getBack()
    }

    static formatEntity = function (entity) {
        const formattedEntity = {
            ...entity,
            payrollWageType: entity?.payrollWageType?.value || 'null',
            extraPaymentType: entity?.extraPaymentType?.value || 'amount',
            userGroups: entity.userGroups ? entity.userGroups : 'none'
        }
        return formattedEntity;
    }

    onChangeForUserGroup = (selected) => {
        if (selected.find(ele => ele.value === 'none') && selected[0].value === 'none') {
            return selected.filter(ele => ele.value !== 'none');
        }
        if ((selected.find(ele => ele.value === 'none') && selected[0].value !== 'none') || !selected.length) {
            return [{ value: 'none', name: 'No Group' }];
        }
    }

    prepareSchema = function (_schema, _userGroups, code, t, availableTasksForExtraPayments) {
        _userGroups.sort((a, b) => a.name.localeCompare(b.name));
        const formattedUserGroup = _userGroups.map(ele => ({ label: ele.name, value: ele.code }))
        formattedUserGroup.unshift({ label: 'No Group', value: 'none' })
        const __schema = cloneDeep(_schema)
        __schema.userGroups = {
            'ui:widget': 'SimpleMultiSelectWidget',
            'ui:help': t('Please note that all employees in this group will receive these extra payments'),
            'ui:options': {
                options: formattedUserGroup,
                selectAllOption: false,
                customSelectOnChange: true,
                onChangeForUserGroup: (selected) => this.onChangeForUserGroup(selected),
                overrideStrings: {
                    allItemsAreSelected: t('No Group'),
                    selectSomeItems: t('Select Groups'),
                    search: t('Search'),
                    noOptions: t("No groups are available, you can add them in the control panel under 'Extended Rules' and 'User Groups'")
                }
            }
        }
        __schema.task = {
            'ui:widget': 'SimpleSelectWidget',
            'ui:help': t('Only members assigned to this task can access these extras, and only when they choose this task'),
            'ui:options': {
                options: availableTasksForExtraPayments.map(ele => ({ label: ele.name, value: ele.id }))
            }
        }
        __schema.description = {
            'ui:widget': 'textarea',
            'ui:placeholder': t('Add a brief description for additional payment rule')
        }

        __schema.name = {
            'ui:placeholder': t('Enter additional payment name')
        }

        switch (code) {
            case 'weekends': {
                __schema.daysOfWeek = {
                    'ui:widget': 'DaysOfWeek',
                    'ui:options': {
                        includeCheckAll: true
                    },
                    'ui:help': t("Please make sure to keep weekend days same as default holidays under basic rules")
                }
                __schema.name = {
                    'ui:placeholder': t('Enter additional payment name'),
                    'ui:help': t("This is a defualt addtional payment, so few settings will be disabled as they can't be overwritten")
                }
                break;
            }
            case 'emergency_shifts': {
                __schema.name = {
                    'ui:placeholder': t('Enter additional payment name'),
                    'ui:help': t("This is a defualt addtional payment, so few settings will be disabled as they can't be overwritten")
                }
                __schema.userGroups['ui:help'] = t("Assign emergency shift(On-call) ability to employee from schedule");
                __schema.employmentTypes = {
                    'ui:help': t("Assign emergency shift(On-call) ability to employee from schedule")
                }
                __schema.fromTime = {
                    'ui:widget': 'InputTime',
                    'ui:options': {
                        valueInInt: true
                    },
                    'ui:help': t("Please choose time after company hours")
                }
                __schema.toTime = {
                    'ui:widget': 'InputTime',
                    'ui:options': {
                        valueInInt: true
                    },
                    'ui:help': t("Please choose time after company hours")
                }
                __schema.isActive = {
                    'ui:widget': 'ActiveInactive',
                    'ui:options': {
                        valueInBool: true
                    },
                    'ui:help': t('Activate emergency shift(On-call) and assigne to employees with shift from schedule screen')
                }
                break;
            }
            case 'shift_allowance': {
                __schema.name = {
                    'ui:placeholder': t('Enter additional payment name'),
                    'ui:help': t("This is a defualt addtional payment, so few settings will be disabled as they can't be overwritten")
                }
                __schema.userGroups['ui:help'] = t("Assign shift allowance to employee from schedule");
                __schema.employmentTypes = {
                    'ui:help': t("Assign shift allowance to employee from schedule")
                }
                __schema.isActive = {
                    'ui:widget': 'ActiveInactive',
                    'ui:options': {
                        valueInBool: true
                    },
                    'ui:help': t('Activate Shift Allowance and use it from schedule screen')
                }
                break;
            }
            default:
                break;
        }
        return __schema;
    }

    updateSchema = (allFormData, oldData) => {
        const { schema } = allFormData;
        let _schema = cloneDeep(schema);
        if (allFormData?.formData?.task) {
            _schema.properties.employmentTypes['readOnly'] = true;
            _schema.properties.userGroups['readOnly'] = true;
        }
        if (!allFormData?.formData?.task) {
            _schema.properties.employmentTypes['readOnly'] = false;
            _schema.properties.userGroups['readOnly'] = false;
        }
        this.setState({ schema: _schema })
    }

    render() {
        const { schema, uiSchema } = this.state;
        return (
            <GenericForm
                entity={ExtraPaymentsForm.formatEntity(this.props.entity)}
                uiSchema={uiSchema}
                schema={schema}
                updateSchema={(allFormData, formData) => this.updateSchema(allFormData, formData)}
                translationScope="forms.extraPayments"
                onSave={(values, redirect) => this.onSave(values, redirect)}
                onChange={(fd) => this.onChangeHandler(fd)}
                customvalidate={(formData, errors) => this.validateExtraPayment(formData, errors)}
                listUrl={"/"}
                goBack={() => this.getBack()}
            />
        );
    }
}

const ExtraPaymentsFormWithModal = (props) => {
    const [modalOpen, setModalOpen] = useState(props.modalOpen);
    useEffect(() => {
        setModalOpen(props.modalOpen);
    }, [props.modalOpen])
    const closeModal = function () {
        props.closeModal()
    }
    return (
        <Modal
            className={"modal right fade modal_revised"}
            backdrop="static"
            show={modalOpen}
            onHide={closeModal}
        >
            <Modal.Header>
                <Modal.Title className="w-100 text-center m-0">{props.t('Additional Payments')}</Modal.Title>
                <img
                    src={CloseModalIcon}
                    className="cursor-pointer"
                    alt='close_button'
                    onClick={closeModal}
                />
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <ExtraPaymentsForm
                        entity={props.entity || {}}
                        handleUserDataChange={props.handleUserDataChange}
                        getBack={closeModal}
                        {...props}
                    />
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default inject('commonStore', 'clientStore', 'userStore')(withLocalization(observer(ExtraPaymentsFormWithModal)));
