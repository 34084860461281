import React, { Component, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { find } from 'lodash';

//components
import withLocalization from '~/hoc/withLocalization';

//elements
import CustomMultiSelect from '../../../elements/CustomMultiSelect';
import Select from '../../../elements/Select';

const prepareUserGroupOptions = (userGroups, includeUsersInGroupIds = false) => {
    return userGroups.map(e => {
        return {
            label: e.name,
            value: e.code,
            userIds: includeUsersInGroupIds ? e.userIds : [],
            disabled: e.disabled !== undefined ? e.disabled : false 
        }
    })
}

const UserGroupsWidget = inject('commonStore', 'userGroupStore', 'userStore')(
    withLocalization(observer(class UserGroupsWidget extends Component {
        constructor(props) {
            super(props);
            const userGroups = props?.commonStore?.config?.client?.data?.extendedRules?.user_groups || [];
            this.state = {
                userGroupOptions: prepareUserGroupOptions(userGroups, false)
            }
        }

        componentDidMount() {
            this.loadUserGroupOptions();
        }

        loadUserGroupOptions = async () => {
            try {
                const includeUserDetails = this.props.mode === 'getUsersInGroup';
                const { currentEntity } = this.props.userStore;
                const params = {
                    include_user_details: includeUserDetails,
                }
                if (!includeUserDetails && !isNaN(currentEntity?.user?.id)) params.user_id = currentEntity?.user?.id;
                const userGroups = await this.props.userGroupStore.getGroupDetails(params);
                const userGroupOptions = prepareUserGroupOptions(userGroups, includeUserDetails);
                if (includeUserDetails && this.props.updateGroupBasedOnSelectedUsers)
                    this.props.updateGroupBasedOnSelectedUsers(userGroupOptions);
                this.setState({ userGroupOptions });
            } catch (err) {
                console.error(err);
                this.props.commonStore.addNotification(err.message, null, 'error');
            }
        }

        render() {
            const { userGroupOptions } = this.state;
            const { t, onChange, value } = this.props;
            const selectedOptions = value ? value.split(',') : [];
            const isGetUsersInGroupMode = this.props.mode === 'getUsersInGroup';
            return (
                <CustomMultiSelect
                    options={userGroupOptions}
                    value={selectedOptions}
                    onChange={(selected) => {
                        if (isGetUsersInGroupMode)
                            onChange(userGroupOptions.filter(e => selected.includes(e.value)));
                        else
                            onChange(selected.join(','))
                    }}
                    overrideStrings={{
                        selectSomeItems: t('Select Groups'),
                        allItemsAreSelected: t('All groups are selected'),
                        search: t('Search'),
                        selectAll: t('Select All'),
                        noOptions: t("No groups are available, you can add them in the control panel under 'Extended Rules' and 'User Groups'")
                    }}
                />
            );
        }
    }))
);


const CurrentUserGroups = (props) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        loadUserGroupOptions(props.user_id);
    }, [props.user_id])

    const loadUserGroupOptions = async (user_id) => {
        try {
            let allGroups = props?.commonStore?.config?.client?.data?.extendedRules?.user_groups || [], options = [];
            if (user_id > 0 && !props.isMember) {
                let currentUserGroups = await props.userGroupStore.getUserGroups(user_id);
                options = prepareUserGroupOptions(allGroups.filter(e => currentUserGroups.find(f => f.user_group_code === e.code), false))
            }
            else {
                options = prepareUserGroupOptions(allGroups, false);
            }
            setOptions(options);
        }
        catch (err) {
            console.error({ err });
            props.commonStore.addNotification(props.t(err.message ? err.message : 'Something went wrong'), null, 'error');
        }
    }

    if (props.isMember) {
        return <div className='pt-2 fw-bold'>
            {props.value > 0 ? options.find(ele => ele.value = props.value).name : props.t("No Group")}
        </div>
    }

    return (
        <Select
            options={options}
            value={props.value ? find(options, { value: props.value }) : null}
            isDisabled={props.disabled}
            placeholder={props.t("Select Group")}
            onChange={selected => props.onChange(selected && selected.value ? selected.value : -1)}
            className={"custom-select-max-height-200"}
            isClearable={true}
            noOptionsCustomMessage={props.t("This employee is not part of any group, you can add employees to groups from employee profile or from manage groups section on employee table actions")}
        />
    )
}

export const CurrentUserGroupsWidget = inject('commonStore', 'userGroupStore')(withLocalization(CurrentUserGroups));

export const SingleUserGroupsWidget = observer(class SingleUserGroupsWidget extends Component {
    render() {
        return <UserGroupsWidget mode="assign" {...this.props} />;
    }
});

export const UsersFromUserGroupsWidget = observer(class UsersFromUserGroupsWidget extends Component {
    render() {
        return <UserGroupsWidget mode="getUsersInGroup" {...this.props} />;
    }
});
