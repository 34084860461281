import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '../../../hoc/withLocalization';
import withLocalization from '../../../hoc/withLocalization';

//elements
import Select from '../../../elements/Select';
import CustomMultiSelect from '../../../elements/CustomMultiSelect';

const SimpleSelectWithMobx = inject('commonStore')(applicationRouter(withLocalization(observer(class SimpleSelect extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(selected, _returnObject) {
        const { onChange, multiSelect, options } = this.props;
        if (multiSelect) {
            if (_returnObject) return onChange(selected);
            if (options.customSelectOnChange) {
                const filteredOptions = options.onChangeForUserGroup(selected)
                if (filteredOptions?.length) {
                    const commaSeperatedIds = filteredOptions.map(ele => ele.value).join(',')
                    return onChange(commaSeperatedIds);
                }
            }
            const commaSeperatedIds = selected.map(ele => ele.value).join(',')
            return onChange(commaSeperatedIds)
        }

        if (_returnObject) return onChange(selected)

        return onChange(selected.value);
    }

    render() {
        const { value, options, disabled, multiSelect, returnObject = false, schema, overrideStrings = {}, styleClasses, wrapperClass = false, placeholder } = this.props;
        const __options = options?.options ? options.options : options;
        const __returnObject = options?.returnObject !== undefined ? !!options.returnObject : !!returnObject;
        const __disabled = schema?.readOnly !== undefined ? !!schema.readOnly : !!disabled;
        const __overrideStrings = options?.overrideStrings ? options.overrideStrings : overrideStrings;
        const __wrapperClass = options?.wrapperClass || wrapperClass || (multiSelect ? '' : 'managers-widget');

        return (
            <div className={__wrapperClass}>
                {multiSelect && options ?
                    <CustomMultiSelect
                        options={__options}
                        value={value || []}
                        onChange={(selected) => this.onChange(selected, __returnObject)}
                        returnObject
                        disabled={__disabled}
                        overrideStrings={__overrideStrings}
                        hasSelectAll={options?.selectAllOption}
                    />
                    : <Select
                        options={__options}
                        value={__returnObject ? value : __options.find(e => e.value === value)}
                        isDisabled={__disabled}
                        onChange={selected => this.onChange(selected, __returnObject)}
                        style={{ width: '100%' }}
                        className={styleClasses}
                        placeholder={placeholder || 'Select'}
                    />}
            </div>
        );
    }
}))));


export const SimpleSelectWidget = observer(class SimpleSelectWidget extends Component {
    render() {
        return <SimpleSelectWithMobx {...this.props} />
    }
})

export const SimpleMultiSelectWidget = observer(class SimpleMultiSelectWidget extends Component {
    render() {
        return <SimpleSelectWithMobx {...this.props} multiSelect />
    }
})



