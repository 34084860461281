import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Badge, Modal, OverlayTrigger, Tab, Tabs, Tooltip, Row, Col } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';
import { Link } from 'react-router-dom';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import GenericFilter from '../../../components/GenericList/GenericFilter.js';
import withLocalization from '~/hoc/withLocalization';
import GenericList from '~/components/GenericList/GenericList';
import ServiceRequestsForm from './ServiceRequestsForm.js';
import ServiceRequestExtendForm from './ServiceRequestExtendForm.js';
import ShowModalOnReadMore from '~/components/ReadMore'

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';

//utils
import { shortDateFormat } from '~/utils/dateFormat';
import { serviceRequestStatusMap, service_request_statuses } from '~/utils/normalStatuses';

//assets
import Edit from '../../../assets/img/editing.svg';
import Extend from '../../../assets/img/Extend.svg';
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg'
import ProjectChat from '../../../assets/img/ProjectChat.svg'

const getColumns = (isCustomerMode, dateTimeRules, renderTableActionButtons, t, handleTableButtonAction, handleReadMoreModal, user_type, extrawork_types) => {
    const col = [
        {
            Header: 'Project',
            id: 'project_name',
            accessor: ({ project_name, service_type, task_name, id }) => (
                <div>
                    <div className='font-size-15 text-primary-color cursor-pointer' onClick={() => handleTableButtonAction(id, 'edit')}>{t('Project')}: {project_name}</div>
                    <div className='mt-1'>{t('Service')}: {service_type ? extrawork_types?.find(ele => ele.code === service_type)?.name : task_name}</div>
                </div>
            ),
            textAlign: 'start',
        },
        {
            Header: 'Total Member',
            id: 'no_of_member',
            accessor: ({ no_of_member, data }) => <span>{no_of_member ? no_of_member : data?.members ? data?.members?.length : no_of_member}</span>,
            maxWidth: 55,
        },
        {
            Header: 'Members',
            id: 'members',
            accessor: ({ data, id }) => (
                <div>
                    {data?.members ?
                        <ShowModalOnReadMore
                            data={data?.members}
                            charLength={60}
                            handleModel={handleReadMoreModal}
                            id={id}
                            t={t}
                        />
                        : <span className='text-danger fw-600'>{t('Members not assigned Yet')}!</span>}
                </div>
            ),
            textAlign: 'start',
        },
        {
            Header: 'From Date | To Date',
            id: 'id',
            accessor: ({ from_date, to_date }) => `${shortDateFormat(from_date, dateTimeRules)} | ${shortDateFormat(moment(to_date), dateTimeRules)}`,
            isSortable: true,
        },
        {
            Header: 'Actions',
            id: 'actions',
            accessor: (full) => renderTableActionButtons(full.id, full, dateTimeRules),
        },
    ];

    if (isCustomerMode || user_type === 'member')
        col.splice(2, 1)
    return col
}


class ServiceRequestsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showStatusUpdateModal: false,
            showExtendRequestModal: false,
            showReadMoreModal: false,
            updateRequestStatusModal: false,
            showAvailableMembers: false,
            memberCountForHeader: {},
            updateId: null,
            readMoreId: null
        };
    }

    componentDidMount() {
        this.loadData();
    }
    
    async loadData() {
        const { serviceRequestStore, commonStore, userStore } = this.props;
        if (commonStore?.config?.client && userStore?.currentUser?.user_type !== 'member') {
            const { memberDetails } = await serviceRequestStore.loadMemberCountForShiftAndLeaves();
            this.setState({ memberCountForHeader: memberDetails });
        }
    }

    handleRequestTab = (tabKey) => {
        const { serviceRequestStore } = this.props;
        if (tabKey === 'approved') {
            serviceRequestStore.setFilter('listStatusFilter', "approved");
            serviceRequestStore.setFilter('status', "approved");
            serviceRequestStore.onFilter();
        }
        else {
            serviceRequestStore.setFilter('listStatusFilter', "active");
            serviceRequestStore.setFilter('status', "");
            serviceRequestStore.onFilter();
        }
    }

    handleTableButtonAction = (id, type, full) => {
        if (type === 'edit') {
            const { config } = this.props?.commonStore;
            if (config?.client)
                return this.props.router.navigate(`/admin/serviceRequests/${id}`, { state: { serviceRequests: id } });
            this.props.router.navigate(`/customer/${id}`, { state: { customer: id } });
        }
        if (type === 'extend') {
            this.setState({ showExtendRequestModal: true, updateId: id });
        }
    };

    handleServiceRequestModal = (id) => {
        this.setState({ showStatusUpdateModal: true, updateId: id });
    };

    handleReadMoreModal = (id) => {
        this.setState({ showReadMoreModal: true, readMoreId: id });
    };

    handleRequestStatusModal = (id, entity) => {
        if (entity.status === 'ongoing')
            this.setState({ updateRequestStatusModal: true, updateId: id });
    };

    handleStatus = async (action) => {
        const { serviceRequestStore, t, commonStore } = this.props;
        try {
            const savedServiceRequest = await serviceRequestStore.saveStatus({
                id: this.state.updateId,
                status: action
            });
            commonStore.addNotification(t(savedServiceRequest.message), null, 'success');
            this.setState({ updateId: null, updateRequestStatusModal: false })
            serviceRequestStore.resetLastListLoadTime();
        }
        catch (err) {
            commonStore.addNotification(t(err), null, 'error');
        }
    }

    renderTableActionButtons = (id, full) => {
        const user_type = this.props.userStore?.currentUser?.user_type ?? false;
        const { t } = this.props;
        let statusText = full.status;
        if (!statusText) statusText = 'active';
        statusText = serviceRequestStatusMap[statusText] || '';
        return (
            <div className="actions-center actions-center__center">
                {user_type !== 'member' &&
                    <div>
                        <Badge bg className="projectMemberBadge">{full?.data?.members?.length}</Badge>
                        <span
                            className="m-1 cursor-pointer projectassignbtn"
                            onClick={() => (user_type === 'pm' || user_type === 'admin') && this.handleServiceRequestModal(id)}
                        >
                            {t('Assign')}
                        </span>
                    </div>
                }
                <div
                    className={`status-block status-block-height35 status-${full.status || 'active'}`}
                    onClick={() => this.handleRequestStatusModal(id, full)}
                >
                    <div className='text-capitalize'>
                        <div>{t(statusText)}</div>
                        <div style={{ fontSize: 8 }}>{full?.data?.registered_by && t('Customer')}</div>
                    </div>
                </div>
                {user_type && user_type !== 'member' && full.status === 'ongoing' &&
                    <OverlayTrigger
                        key="view_project"
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">{full.status === 'ongoing' ? `${t("Extend")} ${t("Request")}` : t('For Extending the service request, Please approve the service request and try again')}</Tooltip>}
                    >
                        <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'extend')} disabled={full.status !== 'ongoing'}>
                            <img src={Extend} alt="extend" />
                        </Button>
                    </OverlayTrigger>
                }
                {!user_type &&
                    <OverlayTrigger
                        key="view_project"
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">{t("Edit")} {t("Request")}</Tooltip>}
                    >
                        <Button icon_sm fill onClick={() => this.handleTableButtonAction(id, 'edit')}>
                            <img src={Edit} alt="edit" />
                        </Button>
                    </OverlayTrigger>
                }
                {user_type &&
                    <Button icon_sm fill>
                        <Link to={`/admin/chat/${full.project_id}`} className='text-decoration-none text-light'>
                            <img src={ProjectChat} alt="ProjectChat" />
                        </Link>
                    </Button>
                }

            </div>
        );
    };

    handleScheduleRouting = (moduleName) => {
        if (moduleName === 'schedule')
            return this.props.router.navigate(`/admin/schedule`, { state: { applyScheduleFilter: true } });
        return this.props.router.navigate(`/admin/${moduleName}`);
    }

    render() {
        const { t, commonStore, userStore, serviceRequestStore, authStore } = this.props;
        const { loading, filters, appliedFilters, lastListLoadTime } = serviceRequestStore;
        const { showExtendRequestModal, showStatusUpdateModal, showReadMoreModal, updateRequestStatusModal, showAvailableMembers, memberCountForHeader } = this.state;
        const user_type = userStore?.currentUser?.user_type;
        const { config } = commonStore;
        const dateTimeRules =
            config.client && config.client.data && config.client.data.dateTimeRules
                ? config.client.data.dateTimeRules
                : false;

        const cols = getColumns(!!window.sessionStorage.getItem('cusToken'), dateTimeRules, this.renderTableActionButtons, t, this.handleTableButtonAction, this.handleReadMoreModal, user_type, commonStore.config.client.data?.extendedRules?.extrawork_types)
        const isListStatusApproved = serviceRequestStore?.filters?.listStatusFilter === 'approved';

        return (
            <Fragment>
                <div className='d-flex'>
                    {window.sessionStorage.getItem('cusToken') ?
                        <div className='d-md-flex align-items-center custom-customer-header mb-md-0 mb-3'>
                            {t('Welcome')} <span className='fw-600 text-capitalize ps-1 pe-1'>{authStore?.custConfig?.customerDetails?.email},</span> {t('to The customer portal of')} <span className='text-capitalize fw-600 pe-1 ps-1'>{authStore?.custConfig?.clientDetails?.name}!</span>
                        </div>
                        :
                        <Row className='w-100'>
                            <Col md={3} xs={12}>
                                <div className='d-md-flex align-items-center custom-customer-header mb-md-0 mb-3'>
                                    <span className='fw-600 text-capitalize ps-1 pe-1'>{t('Service Requests')}</span>
                                </div>
                            </Col>
                            <Col md={3} xs={12}>
                                <Tabs className="scheduleManagementTabs" defaultActiveKey={serviceRequestStore?.appliedFilters?.listStatusFilter || 'active'} onSelect={(e) => this.handleRequestTab(e)}>
                                    <Tab eventKey="active" title={t('Ongoing')}>
                                    </Tab>
                                    <Tab eventKey="approved" title={t('Completed')}>
                                    </Tab>
                                </Tabs>
                            </Col>
                            {user_type !== 'member' &&
                                <Col md={6} xs={12} className='d-flex justify-content-between' style={{ maxHeight: 40 }}>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {t("Absence")} {t("overview")} {t("from")} {shortDateFormat(moment(new Date()).startOf("month").format('YYYY-MM-DD'), dateTimeRules)} {t("to")} {shortDateFormat(moment(new Date()).endOf("month").format('YYYY-MM-DD'), dateTimeRules)}
                                            </Tooltip>
                                        }>
                                        <div className="p-2 pe-3 ps-3 custom-buttonlike-style" style={{ fontSize: 14 }} onClick={() => this.handleScheduleRouting('absences')}>
                                            {t('Absence')}: {memberCountForHeader?.absence_count}
                                        </div>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {t("Vacation")} {t("overview")} {t("from")} {shortDateFormat(moment(new Date()).startOf("month").format('YYYY-MM-DD'), dateTimeRules)} {t("to")} {shortDateFormat(moment(new Date()).endOf("month").format('YYYY-MM-DD'), dateTimeRules)}
                                            </Tooltip>
                                        }>
                                        <div className="p-2 pe-3 ps-3 custom-buttonlike-style" style={{ fontSize: 14 }} onClick={() => this.handleScheduleRouting('vacations')}>
                                            {t('Vacation')}: {memberCountForHeader?.vacation_count}
                                        </div>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {t("Considering")} {t("Schedule")} {t("from")} {shortDateFormat(moment(new Date()).startOf('week').day(1).format('YYYY-MM-DD'), dateTimeRules)} {t("to")} {shortDateFormat(moment(new Date()).startOf('week').add(1, 'week').format('YYYY-MM-DD'), dateTimeRules)}
                                            </Tooltip>
                                        }>
                                        <div className="p-2 pe-3 ps-3 custom-buttonlike-style" style={{ fontSize: 14 }} onClick={() => this.handleScheduleRouting('schedule')}>
                                            {t('Scheduled')}({t('Week')}): {memberCountForHeader?.scheduled_count}
                                        </div>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {t("Overview of available employees/member")} {t("from")} {shortDateFormat(moment(new Date()).startOf('week').day(1).format('YYYY-MM-DD'), dateTimeRules)} {t("to")} {shortDateFormat(moment(new Date()).startOf('week').add(1, 'week').format('YYYY-MM-DD'), dateTimeRules)}
                                            </Tooltip>
                                        }>
                                        <div className="p-2 pe-3 ps-3 custom-buttonlike-style" style={{ fontSize: 14 }} onClick={() => this.setState({ showAvailableMembers: true })}>
                                            {t('Available')}: {memberCountForHeader?.available_members?.length}
                                        </div>
                                    </OverlayTrigger>

                                </Col>
                            }
                        </Row>
                    }
                </div>
                <GenericList
                    columns={cols}
                    filters={appliedFilters}
                    lastListLoadTime={lastListLoadTime}
                    header={
                        <GenericFilter
                            filters={filters}
                            setFilter={(name, value) => serviceRequestStore.setFilter(name, value)}
                            onFilter={() => serviceRequestStore.onFilter()}
                            loading={loading}
                            hasProjectFilter={user_type && true}
                            hasStatusFilter={user_type !== 'member' && true}
                            statusOptions={isListStatusApproved ? service_request_statuses.filter(ele => ele.code === 'approved') : service_request_statuses.filter(ele => ele.code !== 'approved')}
                            statusDisabled={isListStatusApproved}
                        />
                    }
                    requestData={(params) => serviceRequestStore.loadList({ ...params, user_type })}
                >
                    {user_type !== 'member' &&
                        <>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        {t("By clicking here, you can create a new service request")}
                                    </Tooltip>
                                }
                            >
                                <i className="fa fa-info-circle text-muted d-flex align-items-center" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                            </OverlayTrigger>

                            <Button fill wd onClick={() => this.props.onAdd()}>
                                <i className="fa fa-plus-circle fa-xl margin-right-10" />
                                {t('Add new')}
                            </Button>
                        </>}
                </GenericList>

                <Modal
                    size="xl"
                    show={showStatusUpdateModal}
                    onHide={() => this.setState({ showStatusUpdateModal: false })}
                >
                    <Modal.Header className="set_ModalHeader">
                        <Modal.Title className='w-100 text-center custom-modal-title'>
                            {t('Request')} {t('Details')}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={() => this.setState({ showStatusUpdateModal: false })}
                            alt='close-btn'
                        />
                    </Modal.Header>
                    <Modal.Body style={{ minHeight: '500px' }} className='mb-4'>
                        <ServiceRequestsForm
                            update_id={this.state.updateId}
                            handleCloseServiceRequestModal={() => this.setState({ showStatusUpdateModal: false })}
                            disableInput
                        />
                    </Modal.Body>
                </Modal>

                <Modal
                    size="xl"
                    show={showExtendRequestModal}
                    onHide={() => this.setState({ showExtendRequestModal: false })}
                >
                    <Modal.Header className="set_ModalHeader">
                        <Modal.Title className='w-100 text-center custom-modal-title'>
                            {t('Request')} {t('Details')}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={() => this.setState({ showExtendRequestModal: false })}
                            alt='close-btn'
                        />
                    </Modal.Header>
                    <Modal.Body style={{ minHeight: '52vh' }} className='mb-4'>
                        <ServiceRequestExtendForm
                            handleCloseExtendRequestModal={() => this.setState({ showExtendRequestModal: false })}
                            update_id={this.state.updateId}
                        />
                    </Modal.Body>
                </Modal>

                <Modal
                    size='xl'
                    show={showReadMoreModal}
                    onHide={() => this.setState({ showReadMoreModal: false })}
                >
                    <Modal.Header className="set_ModalHeader">
                        <Modal.Title className='w-100 text-center custom-modal-title'>
                            {t('Assigned Members')} {t('Details')}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={() => this.setState({ showReadMoreModal: false })}
                            alt='close-btn'
                        />
                    </Modal.Header>
                    <Modal.Body className='mb-4 d-flex justify-content-center'>
                        <table className='w-75'>
                            <tr className='d-flex justify-content-center'><th>{t('Name')}</th></tr>
                            {serviceRequestStore?.currentList?.find(ele => ele.id === this.state?.readMoreId)?.data?.members?.map((ele, idx) => (
                                <tr className='d-flex justify-content-center m-1' style={{ color: '#2550AC', backgroundColor: `${idx % 2 === 0 ? '#dee2e6' : ''}` }}><td>{ele?.name}</td></tr>
                            ))}
                        </table>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={showAvailableMembers}
                    onHide={() => this.setState({ showAvailableMembers: false })}
                >
                    <Modal.Header className="set_ModalHeader">
                        <Modal.Title className='w-100 text-center custom-modal-title'>
                            {t('Available members')}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={() => this.setState({ showAvailableMembers: false })}
                            alt='close-btn'
                        />
                    </Modal.Header>
                    <Modal.Body className='mb-4 d-flex justify-content-center'>
                        <table className='w-75'>
                            <tr className='d-flex justify-content-center'><th>{t('Name')}</th></tr>
                            {memberCountForHeader?.available_members?.map((ele, idx) => (
                                <tr className='d-flex justify-content-start m-1' style={{ color: '#2550AC', backgroundColor: `${idx % 2 === 0 ? '#dee2e6' : ''}` }}>
                                    <td className='ml-10'>{ele?.name}</td>
                                </tr>
                            ))}
                        </table>
                    </Modal.Body>
                </Modal>

                <Modal
                    size='xl'
                    show={updateRequestStatusModal}
                    onHide={() => this.setState({ updateRequestStatusModal: false })}
                >
                    <Modal.Header className="set_ModalHeader">
                        <Modal.Title className='w-100 text-center custom-modal-title'>
                            {t('Update Status')}
                        </Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={() => this.setState({ updateRequestStatusModal: false })}
                            alt='close-btn'
                        />
                    </Modal.Header>
                    <Modal.Body className='mb-4 mt-2'>
                        <div className='mb-3'>
                            **{t('By Clicking Complete, The request will be Marked as Done and an email be sent to the Customer')}.
                        </div>
                        <Button fill onClick={() => this.handleStatus('approved')} style={{ backgroundColor: 'green' }}>
                            {t('Complete')}
                        </Button>
                        <Button dynamic_lg onClick={() => this.setState({ updateRequestStatusModal: false })} className='ml-10'>
                            {t('Cancel')}
                        </Button>
                    </Modal.Body>
                </Modal>
            </Fragment>
        );
    }
}
export default inject(
    'userStore',
    'subscriptionStore',
    'serviceRequestStore',
    'commonStore',
    'authStore'
)(applicationRouter(withLocalization(withRemoveDialog(observer(ServiceRequestsList)))));