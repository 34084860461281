import React from 'react';
import { inject } from 'mobx-react';
import RectangularSmallCard from '../../../components/Card/RectangularSmallCard';

//components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter'
import TimeBankMemberList from './TimeBankMemberList';
import { Row, Col } from 'react-bootstrap';

const TimeBank = ({ t, commonStore, userStore }) => {
    const timeBank = commonStore?.config?.client?.data?.basicRules?.timeBankSettings;
    const isMember = userStore?.currentUser?.user_type === 'member';
    return (
        <>
            <Row>
                <Col xs={9} md={9} className='d-flex'>
                    <span className='fw-600 custom-customer-header text-capitalize ps-1 pe-1'>{t('Time Bank')}</span>
                </Col>
                {!isMember &&
                    <Col md={3} xs={9} className='d-flex justify-content-end'>
                        <RectangularSmallCard title='Total Limit' titleValue={`${timeBank?.maxHourLimitInCycle}:${timeBank?.maxMinuteLimitInCycle}`} percentage='100' pathColor='red' rotationStart='5' strokeWidth='10' />
                    </Col>
                }
            </Row>
            <TimeBankMemberList />
        </>
    )
}

export default inject('commonStore', 'userStore')(applicationRouter(withLocalization(TimeBank)));