import React, { Component } from 'react';
import applicationRouter from '~/hoc/applicationRouter'
import { inject, observer } from 'mobx-react';

import RosterList from './RosterList';

import withRemoveDialog from '~/hoc/withRemoveDialog';

class RosterPage extends Component {
    onAdd() {
        this.props.router.navigate('/admin/roster/createRoster');
    }
    componentWillMount() {
        // const params = new URLSearchParams(this.props.router.location.search);
        // const filterByStatus = params.get('status');
        // this.props.timelogStore.setFilter('status', filterByStatus || null);
        // this.props.timelogStore.onFilter();
    }
    render() {
        return (
            <RosterList
                onAdd={() => this.onAdd()}
            />
        );
    }
}
export default inject('authStore', 'timelogStore','rosterStore')(applicationRouter(withRemoveDialog(observer(RosterPage))));
