import React, { useState, useEffect } from 'react';
import { inject } from 'mobx-react';

//components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter'
import UserProfileLogo from '../../../components/UserProfileLogo';
import { Col, Row } from 'react-bootstrap';
import TimeBankList from './TimeBankList';

const TimeBankMemberList = ({ timeBankStore, t, userStore }) => {
    const [timeBankMember, setTimeBankMember] = useState([]);
    const [currentMemberId, setCurrentMemberId] = useState(null);
    const [filterName, setFilterName] = useState('');
    const isMember = userStore?.currentUser?.user_type === 'member';

    useEffect(() => {
        const loadData = async () => {
            const members = await timeBankStore.loadTimeBankMember();
            const currMem = timeBankStore?.filters?.currentUserTimeBankList ? members?.find(ele => ele?.id === timeBankStore?.filters?.currentUserTimeBankList) : members[0]
            setCurrentMemberId(currMem)
            setTimeBankMember(members);
            timeBankStore.setFilter('currentUserTimeBankList', currMem?.id)
        };
        loadData();
    }, []);

    useEffect(() => {
        if (!filterName)
            setTimeBankMember(timeBankStore?.currentMemberList)
    }, [filterName])

    const handleSearch = () => {
        if (!filterName)
            setTimeBankMember(timeBankStore?.currentMemberList);
        const filterTimeBank = timeBankStore?.currentMemberList?.filter(ele => ele?.name?.toLowerCase().includes(filterName.toLowerCase()));
        setTimeBankMember(filterTimeBank);
    }

    const handleMemberList = (entity) => {
        setCurrentMemberId(entity)
        timeBankStore.setFilter('currentUserTimeBankList', entity.id)
        timeBankStore.onFilter();
    }

    return (
        <Row style={{ height: '68vh' }} className='border border-radius-10'>
            {!isMember &&
                <Col md={3} className='h-100 ps-0'>
                    <div className={'border border-radius-10 h-100'}>
                        <h5 className='fw-600 m-2 mb-4'>{t('Members')}</h5>
                        <div className="conversation-search m-2 mb-4">
                            <input
                                type="text"
                                className="conversation-search-input"
                                placeholder={t('Search')}
                                onChange={(e) => setFilterName(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.code === 'Enter')
                                        handleSearch();
                                }}
                                value={filterName}
                            />
                            <i className="fa fa-search conversation-search-icon" onClick={() => handleSearch()} />
                        </div>
                        {timeBankMember.length > 0 ?
                            <div className={'custom-table-child'}>
                                {timeBankMember.map((ele, index) => (
                                    <div key={index} className={`d-flex align-items-center border-top-1 ${currentMemberId?.id === ele?.id ? 'custom-active-bg' : ''}`} onClick={() => handleMemberList(ele)}>
                                        <UserProfileLogo image_id={ele?.image} width='35px' height="35px" className={'m-2'} />
                                        <span className='ml-10 d-flex align-items-center'>{ele.name}</span>
                                    </div>
                                ))}
                            </div>
                            :
                            <div className='d-flex justify-content-center fw-600'>{t('No data found')}</div>
                        }
                    </div>
                </Col>
            }
            <Col md={!isMember ? 9 : 12}>
                {(currentMemberId?.id || isMember) ?
                    <TimeBankList
                        userDetails={currentMemberId}
                    />
                    :
                    <div className='d-flex justify-content-center fw-600 align-items-center h-100'>{t('No data found')}</div>
                }
            </Col>
        </Row>
    );
};

export default inject('userStore', 'timeBankStore')(applicationRouter(withLocalization(TimeBankMemberList)));