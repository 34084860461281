import React from 'react';
import { MultiSelect } from "react-multi-select-component";

//stles
import './customSelectStyles.css';

const getSelectedObjects = (value, options) => {
    let selected = [];
    if (!value) return selected;
    const _value = typeof value === "string" ?
        value.trim().length ?
            value.split(',').map(ele => !isNaN(ele) ? Number(ele) : ele) : []
        : value.map(ele => !isNaN(ele) ? Number(ele) : ele);

    if (options.length && _value.length) {
        selected = options.filter(ele => !isNaN(ele.value) ? _value.includes(Number(ele.value)) : _value.includes(ele.value))
    }
    return selected;
}

const getSelectedIds = (selected) => {
    let selectedIds = []
    if (selected.length) {
        selectedIds = selected.map(ele => { return isNaN(ele.value) ? Number(ele.value) : ele.value })
    }
    return selectedIds;
}

const ItemRenderer = ({
    checked,
    option,
    onClick,
    disabled,
  }) => (
    <div className={`item-renderer d-flex align-items-center`} style={{ height: 15 }}>
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      <span className='d-flex'>{option.label}{option?._label && <span className='text-danger ml-10 fs10 d-flex align-items-center'>({option._label})</span>}</span>
    </div>
  );

const CustomMultiSelect = ({ options, value, onChange, className, returnObject = false, disabled, customOptions, hasSelectAll, ...rest }) => {
    const _options = options.length ? options : [];
    const _value = getSelectedObjects(value, options);
    const customClasses = className ? className : "";
    const _hasSelectAll = hasSelectAll === undefined ? true : hasSelectAll ? true : false;
    return <MultiSelect
        ItemRenderer={customOptions && ItemRenderer}
        options={_options}
        value={_value}
        onChange={(selected) => onChange(returnObject ? selected : getSelectedIds(selected))}
        className={`custom-multi-select ${customClasses} ${disabled ? "__disabled" : ""}`}
        disabled={disabled}
        hasSelectAll={_hasSelectAll}
        {...rest}
    />;
};

export default CustomMultiSelect;
