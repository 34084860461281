import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { confirmAlert } from 'react-confirm-alert';
import { Modal } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales'; // 'moment/min/moment-with-locales';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import withDnDContext from '~/hoc/withDnDContext';
import Scheduler, { SchedulerData, ViewTypes } from '~/components/Scheduler';
import Button from '~/components/CustomButton/CustomButton.jsx';
import MoveOrCopyModal from './MoveOrCopyModal';
import ShiftForm from './ShiftForm';
import BulkShiftForm from './BulkShiftForm';
import EventItemTemplateResolver from './EventItemTemplateResolver';
import EventItemPopoverTemplateResolver from './EventItemPopoverTemplateResolver';
import MonthCalendar from './MonthCalendar';
import ScheduleAvailabilityList from './ScheduleAvailabilityList';
import CheckAvailabilityForm from './CheckAvailabilityForm';
import ScheduleAddAvailability from './ScheduleAddAvailability';
import ScheduleSettings from './ScheduleSettings';
import Management from './Management';
import MemberAvailabilityList from './MemberAvailabilityList';
import ScheduleWeekHeader from './ScheduleWeekHeader';
import Checkbox from '~/components/CustomCheckbox/CustomCheckbox';
import Select from '~/elements/Select'
//elements
import LoadingSpinner from '~/elements/LoadingSpinner';
import { Input } from '~/elements/Input';

//utils
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';
import { shortDateFormat, timeFormat } from '../../../utils/dateFormat';
import { NotificationSGV, SyncSGV } from '../../../utils/constants';

//styles
import '~/components/Scheduler/css/style.css';

//assets
import CloseIcon from "~/assets/img/modal-close-icon.svg";
import CloseModalIcon from '../../../assets/img/CloseApproveModalIcon.svg';
import { compareObjects, getCompanyShiftDetails } from '../../../utils/commonMethods';

class ScheduleMain extends Component {
    constructor(props) {
        super(props);
        this.emergencyShiftRule = props?.commonStore?.config?.client?.data?.extraPayments?.find(ele => ele.code === 'emergency_shifts') || false;
        this.state = {
            width: 0,
            viewModel: null,
            currentView: props.scheduleStore.filters.currentView,
            moving: false,
            movingData: null,
            editing: false,
            bulkEditing: false,
            stub: null,
            toggle_todaybtn: false,
            showCalendar: false,
            loading: '',
            openSettings: false,
            openManagement: false,
            add: false,
            isCheckAvailability: false,
            isAddAvailability: false,
            showAvailabilityList: false,
            responseData: [],
            showBudgetModal: false,
            budget: '',
            budgetForWhatDay: '',
            hideShowModal: props.scheduleStore?.currentWeekHeader.some(ele => ele.show_shifts_to_member === false),
            ids: [],
            deleteEmergencyShift: true,
            deleteShiftAllowance: true,
            showMultiDeleteConfimation: false,
            emergencyDetails: null,
            showTimesheetApprovalModal: false,
            sendTimesheetApprovalNotification: true,
            syncWithIntegration: false,
            showShiftAssignModal: false,
            isModalLoading: false
        };
        this.wrapper = React.createRef();
        this.filterTodayShifts = this.filterTodayShifts.bind(this)
        this.viewAvailabilityAssign = this.viewAvailabilityAssign.bind(this)
        this.startCopyToNextWeek = this.startCopyToNextWeek.bind(this)
        this.newBulkEvent = this.newBulkEvent.bind(this)
        this.checkFiltersAndSetResources = this.checkFiltersAndSetResources.bind(this)
        this.reloadData = this.reloadData.bind(this)
        this.handleShiftAssignModal = this.handleShiftAssignModal.bind(this)
    }


    componentDidMount() {
        this.setState({ width: this.wrapper.current.parentElement.offsetWidth - 10, loading: true });
        this.loadData();
    }

    checkIfWeekHeaderCallNeeded = () => {
        const { commonStore } = this.props;
        const isScheduleShiftsCalculationsEnabled = commonStore?.config?.client?.data?.basicRules.enableScheduleShiftsCalculations;
        const isSchedulePublishUnpublishFeatueEnabled = commonStore?.config?.client?.data?.basicRules.enableSchedulePublishUnpublishFeatue;
        return isScheduleShiftsCalculationsEnabled || isSchedulePublishUnpublishFeatueEnabled;
    }

    async loadData() {
        const { userStore, scheduleStore, shiftAllowanceStore } = this.props;
        const userType = userStore.currentUser.user_type;
        if (this.props?.router?.location?.state?.applyScheduleFilter) {
            const filterValue = { value: true, label: 'Scheduled', title: 'Scheduled' }
            scheduleStore.setFilter('resourceFilterValue', filterValue);
        }
        await scheduleStore.loadEverything({
            userType,
            userId: userStore.currentUser.id,
            project: this.props.scheduleStore.filters.project ? this.props.scheduleStore.filters.project : "",
            currentView: scheduleStore.filters.currentView,
            hours: scheduleStore.filters.dayDurationFilterValue
        }, this.getDateTimeRules(), this.checkIfWeekHeaderCallNeeded());
        await shiftAllowanceStore.getShiftAllowanceCount({ start: '', end: '' })
        this.initScheduler()
    }

    async reloadData(schedulerData) {
        const autoReload = !schedulerData;
        if (!schedulerData) schedulerData = this.state.viewModel;
        const { scheduleStore, userStore, shiftAllowanceStore } = this.props;
        const params = {
            start: schedulerData.startDate,
            end: schedulerData.endDate,
            userType: userStore.currentUser?.user_type,
            userId: userStore.currentUser?.id,
            currentView: scheduleStore.filters.currentView,
            project: scheduleStore.filters.project ? scheduleStore.filters.project : "",
            hours: scheduleStore.filters.dayDurationFilterValue,
        };
        this.setState({ loading: true })
        const data = await scheduleStore.loadList(params, this.getDateTimeRules());
        schedulerData.setEvents(data.list);
        schedulerData.setAvailability(scheduleStore.currentAvailabilityList?.list);
        await shiftAllowanceStore.getShiftAllowanceCount({ start: schedulerData.startDate, end: schedulerData.endDate })
        if (this.checkIfWeekHeaderCallNeeded()) await scheduleStore.getWeekHeader(params);
        await scheduleStore.loadResources(params, true)
        this.checkFiltersAndSetResources(schedulerData);
        this.setState({ loading: false })
        if (autoReload) {
            this.setState({ viewModel: schedulerData }, () => {
                this.props.scheduleStore.initializeShiftEventCheckboxData(this.state.viewModel);
            });
        }
        return schedulerData;
    }

    onListViewChange = (evt) => {
        if (evt.value !== this.state.currentView) {
            this.props.scheduleStore.setFilter("currentView", evt.value);
            this.props.scheduleStore.setFilter("sortFilterValue", null);
            this.props.scheduleStore.setFilter("resourceFilterValue", null);
            this.setState({ currentView: evt.value, loading: true },
                () => { this.loadData() })
        }
    }

    getDateTimeRules = () => {
        const { commonStore } = this.props;
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data
            && commonStore.config.client.data.dateTimeRules ?
            commonStore.config.client.data.dateTimeRules : false;
        return dateTimeRules;
    }

    isAvailibiltyFeatureEnabled = () => {
        const { commonStore } = this.props;
        const allowAvailability = commonStore.config.client && commonStore.config.client.data
            && commonStore.config.client.data.basicRules && commonStore.config.client.data.basicRules.allowAvailability ?
            commonStore.config.client.data.basicRules.allowAvailability : false;
        return allowAvailability;
    }

    handleProjectChange = async (value) => {
        const { scheduleStore, userStore } = this.props;
        const params = {
            start: this.state.viewModel.startDate,
            end: this.state.viewModel.endDate,
            userType: await userStore.currentUser?.user_type,
            userId: await userStore.currentUser?.id,
            currentView: scheduleStore.filters.currentView,
            project: value,
        };
        const schedulerData = this.state.viewModel;
        const filteredShiftEvents = await scheduleStore.loadList(params, this.getDateTimeRules());
        schedulerData.setEvents(filteredShiftEvents.list);
        this.checkFiltersAndSetResources(schedulerData);
        this.setState({ viewModel: schedulerData }, () => {
            this.props.scheduleStore.initializeShiftEventCheckboxData(this.state.viewModel);
        });
    }

    handleDayDurationChange = async () => {
        const { scheduleStore, userStore } = this.props;
        const params = {
            start: this.state.viewModel.startDate,
            end: this.state.viewModel.endDate,
            userType: await userStore.currentUser?.user_type,
            userId: await userStore.currentUser?.id,
            currentView: scheduleStore.filters.currentView,
            project: scheduleStore.filters.project,
            hours: scheduleStore.filters.dayDurationFilterValue,
        };
        const schedulerData = this.state.viewModel;
        const filteredShiftEvents = await scheduleStore.loadList(params, this.getDateTimeRules());
        schedulerData.setEvents(filteredShiftEvents.list);
        this.checkFiltersAndSetResources(schedulerData);
        this.setState({ viewModel: schedulerData }, () => {
            this.props.scheduleStore.initializeShiftEventCheckboxData(this.state.viewModel);
        });
    }

    handlePmManagedShiftsOptionChange = (schedulerData, pmManagedShiftsFilterValue) => {
        const { scheduleStore } = this.props;
        const { currentList } = scheduleStore;
        if (pmManagedShiftsFilterValue.value !== null)
            schedulerData.setEvents(currentList.list && currentList.list.length ? currentList.list.filter(e => e.isEditable === pmManagedShiftsFilterValue.value) : []);
        else
            schedulerData.setEvents(currentList.list);
    }

    checkFiltersAndSetResources = (currentScheduleData, updateViewModal = false) => {
        const { scheduleStore, userStore } = this.props;
        const { currentUser } = userStore;
        const { filters, resources, filteredResources } = scheduleStore;
        let _filteredResources = [];
        //filtering resources using project members//
        if (filters.project) {
            _filteredResources = filteredResources;
        } else {
            _filteredResources = resources;
        }
        //sorting resources using sort filter values//
        if (filters.sortFilterValue && !filters.currentView) {
            _filteredResources.sort((a, b) => compareObjects(a, b, filters.sortFilterValue.value));
        }
        //filtering resources using scheduler filter values//
        if (!!filters.resourceFilterValue && !Object.is(filters.resourceFilterValue.value, null) && !filters.currentView) {
            _filteredResources = _filteredResources.filter((r) => r.is_shift_available === filters.resourceFilterValue.value || r.id === 0);
        }
        //filtering events/shifts for PM users based on access scheduler filter values//
        if (currentUser.user_type === 'pm' && !!filters.pmManagedShiftsFilterValue) {
            this.handlePmManagedShiftsOptionChange(currentScheduleData, filters.pmManagedShiftsFilterValue);
        }
        //updating the current scheduler with filtered resources//
        currentScheduleData.setResources(_filteredResources);
        //if need to update the viewModel manually then pass it as an argument//
        if (updateViewModal) {
            this.setState({ viewModel: currentScheduleData }, () => {
                this.props.scheduleStore.initializeShiftEventCheckboxData(this.state.viewModel);
            });
        }
    }

    prevClick = async (schedulerData) => {
        schedulerData.prev();
        const newScheduler = await this.reloadData(schedulerData);
        this.setState({ viewModel: newScheduler }, () => {
            this.props.scheduleStore.initializeShiftEventCheckboxData(this.state.viewModel);
        });
    };

    nextClick = async (schedulerData) => {
        schedulerData.next();
        const newScheduler = await this.reloadData(schedulerData);
        this.setState({ viewModel: newScheduler }, () => {
            this.props.scheduleStore.initializeShiftEventCheckboxData(this.state.viewModel);
        });
    };

    onViewChange = async (schedulerData, view) => {
        if (view.viewType === '1') {
            schedulerData.setViewType(view.viewType, view.isEventPerspective);
            const newScheduler = await this.reloadData(schedulerData);
            this.setState({ viewModel: newScheduler, showCalendar: false }, () => {
                this.props.scheduleStore.initializeShiftEventCheckboxData(this.state.viewModel);
            });
        } else if (view.viewType === '2') {
            const newScheduler = await this.reloadData(schedulerData);
            this.setState({ viewModel: newScheduler, showCalendar: true }, () => {
                this.props.scheduleStore.initializeShiftEventCheckboxData(this.state.viewModel);
            });
        }
    };

    eventClicked = (event) => {
        const { userStore } = this.props;
        const { currentUser } = userStore;
        const isMember = currentUser.user_type === 'member';
        if (event.source === 'vacation' || event.source === 'absence'
            || event.availability_type === 'available' || event.availability_type === 'not available') return;
        if (isMember && event.resourceId > 0 && event.resourceId !== currentUser.id) return;
        this.setState({ editing: event.id, stub: event });
    };

    newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        const companyShiftDetails = getCompanyShiftDetails(this.props.commonStore.config, item.companyShiftId, minutesToTimeInput)
        const isPendingShift = slotId === 'Pending' || slotId === 0;
        let _slotId = 0;
        let valuesToFilter = [];
        if (!!this.state.currentView) {
            if (item && item.slotIds && item.slotIds.length && item.slotIds[0] !== 0) {
                valuesToFilter = item.slotIds;
                if (item.slotIds.length === 1) {
                    _slotId = item.slotIds[0]
                }
            }
        } else {
            _slotId = isNaN(Number(slotId)) ? 0 : Number(slotId);
        }

        this.setState({
            editing: 0,
            stub: {
                for_date: new Date(start.replace(' ', 'T')),
                assignee_id: _slotId,
                start_time: getMinutesFromTimeInput(companyShiftDetails.start),
                end_time: getMinutesFromTimeInput(companyShiftDetails.end),
                company_shift_id: companyShiftDetails.companyShiftId,
                do_notify: true,
                valuesToFilter: valuesToFilter,
                isPendingShift,
                user_group: this.state.currentView && this.state.currentView === 2 && item.slotId > 0 ? item.slotId : -1
            },
        });
    };

    newBulkEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        const companyShiftDetails = getCompanyShiftDetails(this.props.commonStore.config, 0, minutesToTimeInput)
        this.setState({
            bulkEditing: 0,
            stub: {
                start_time: getMinutesFromTimeInput(companyShiftDetails.start),
                end_time: getMinutesFromTimeInput(companyShiftDetails.end),
                company_shift_id: companyShiftDetails.companyShiftId,
            },
        });
    };

    closeEditingWindow = (doReload) => {
        this.setState({
            editing: false,
            stub: null,
            responseData: []
        }, () => {
            if (doReload) this.reloadData();
        });
    };

    afterEditing = async (status, users) => {
        const { t, scheduleStore, commonStore, userStore } = this.props;
        const { currentUser } = userStore;
        if (status === 'cancel') {
            this.closeEditingWindow(true);
        }
        if (status === 'delete') {
            this.closeEditingWindow(false);
            if (scheduleStore?.resources?.filter(ele => ele?.emergency_id)?.length || this.props.shiftAllowanceStore?.allShiftAllowance?.length) {
                this.setState({ ids: [], showMultiDeleteConfimation: true })
            }
            else {
                confirmAlert({
                    title: t('Confirm delete'),
                    message: t('This will remove the schedule'),
                    buttons: [
                        {
                            label: t('Yes'),
                            onClick: async () => {
                                await scheduleStore.remove(scheduleStore.currentEntity.id);
                                this.reloadData();
                            },
                        },
                        {
                            label: t('No')
                        },
                    ],
                });
            }
        }
        if (status === 'save') {
            try {
                const shiftDetails = await scheduleStore.save(scheduleStore.currentEntity, !scheduleStore.currentEntity.id);
                if (shiftDetails && shiftDetails.assignedMemberName) {
                    let responseData = this.state.responseData;
                    responseData.push(shiftDetails)
                    this.setState({ responseData: responseData })
                }
            } catch (e) {
            }
        }
        if (status === 'save_pending') {
            try {
                if (this.props.scheduleStore.currentEntity.for_date && this.props.scheduleStore.currentEntity.to_date
                    && moment(this.props.scheduleStore.currentEntity.to_date).isAfter(this.props.scheduleStore.currentEntity.for_date)
                    && this.props.scheduleStore.currentEntity.selectedDays && !this.props.scheduleStore.currentEntity.selectedDays.length) {
                    return commonStore.addNotification(t("Please select days to create shifts"), null, 'warning');
                }
                await scheduleStore.save(scheduleStore.currentEntity, !scheduleStore.currentEntity.id);
                this.closeEditingWindow(true);
            } catch (e) {
                console.error(e)
            }
        }
        if (status === 'send-shift-request-for-sick-leave') {
            let Ids = scheduleStore.currentAvailableAssignees.map(el => el.id);
            try {
                scheduleStore.sendSwapShiftRequest(scheduleStore.currentEntity, Ids, currentUser.id, 'sick-leave').then(res => {
                    this.closeEditingWindow(true);
                });
            } catch (e) {
                commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
        }
        if (status === 'send-shift-request-for-swap-shift') {
            let Ids = users
            try {
                scheduleStore.sendSwapShiftRequest(scheduleStore.currentEntity, Ids, currentUser.id, 'swap-shift').then(res => {
                    this.closeEditingWindow(true);
                });
            } catch (e) {
                commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
        }
        if (status === 'member_accept' || status === 'member_reject') {
            try {
                if (status === 'member_reject' && !scheduleStore.currentEntity.comment) {
                    throw new Error('Comment is required');
                }
                await scheduleStore.memberAction(status, currentUser);
                this.closeEditingWindow(true);
            } catch (e) {
                commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
        }
    };

    afterBulkEditing = async (status, values) => {
        const { t, scheduleStore, commonStore } = this.props;
        if (status === 'cancel') {
            this.setState({
                bulkEditing: false,
                stub: null,
            });
        }
        if (status === 'save') {
            try {
                await scheduleStore.addBulkShift(values);
                this.setState({
                    bulkEditing: false,
                    stub: null,
                }, () => {
                    this.reloadData();
                });
            } catch (e) {
                commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            }
        }
    };

    moveEvent = (event, slotId, start) => {
        const { resources } = this.props.scheduleStore;
        const { config } = this.props.commonStore;
        const { viewModel } = this.state;
        let addUserToGroup = false, groupOrTitleName = null, availableAssigneess = [],
            user_group_code = event.userGroup ? event.userGroup : -1,
            _slotId = slotId, isShiftToPending = slotId === "Pending" || slotId === 0;


        if (this.state.currentView) {
            availableAssigneess = viewModel.renderData.find(ele => ele.slotId === slotId)?.slotIds;
            if (this.state.currentView === 2 && !event.resourceId && _slotId > 0 && !availableAssigneess.length) {
                return this.props.commonStore.addNotification(this.props.t("No available employees"), null, 'warning');
            }
            if (!availableAssigneess.length && event.resourceId) {
                availableAssigneess = [event.resourceId]
            }
            if (this.state.currentView === 2) {
                const groups = config.client.data.extendedRules.user_groups || []
                groupOrTitleName = groups.find(ele => ele.code === slotId)?.name || this.props.t("No Group");
            } else {
                groupOrTitleName = slotId;
            }
        }

        if (event.start === start && event.resourceId === slotId) return;

        if (this.state.currentView === 1) {
            const { t, commonStore } = this.props;
            if (event.employeeType !== slotId && slotId !== "Pending" && event.employeeType !== "Pending")
                return commonStore.addNotification(t("Cannot move shift to different role"), null, 'warning');

            if (slotId !== "Pending" && event.employeeType !== _slotId && event.employeeType !== "Pending") return;
            _slotId = slotId === "Pending" ? 0 : event.resourceId;
            if (event.start === start && event.employeeType === slotId) return;
        }

        if (this.state.currentView === 2) {
            user_group_code = slotId;
            _slotId = slotId === 0 ? 0 : event.resourceId;
            if (event.userGroup > 0 && event.userGroup !== slotId) {
                let _toUser = resources.find(el => el.id === _slotId);
                if (_toUser && _toUser.user_groups && !_toUser.user_groups.includes(slotId)) {
                    addUserToGroup = true;
                }
            }
        }
        else if (_slotId !== event.resourceId && event.userGroup > 0 && resources.length) {
            let _toUser = resources.find(el => el.id === _slotId);
            if (_toUser && _toUser.user_groups && !_toUser.user_groups.includes(event.userGroup)) {
                addUserToGroup = true;
            }
        }

        this.setState({
            moving: true,
            movingData: {
                id: event.id,
                fromDay: event.start,
                toDay: start,
                fromUser: event.resourceId,
                toUser: _slotId,
                addUserToGroup: addUserToGroup,
                user_group_code,
                availableAssigneess,
                currentView: this.state.currentView,
                isShiftToPending,
                groupOrTitleName: groupOrTitleName,
                shiftAllowance: event.shiftAllowance
            },
        });
    };

    moveToPending = (event, slotId, slotName, start, end) => {
        this.setState({
            movingData: {
                id: event.id,
                fromDay: event.start,
                toDay: start,
                fromUser: event.resourceId,
                toUser: 0,
                addUserToGroup: false,
                availableAssigneess: [],
                currentView: this.state.currentView,
                isShiftToPending: true,
                do_notify: 'false',
                groupOrTitleName: null
            }
        }, () => {
            this.shiftToPending();
        })
    }

    handleShiftAssignModal = async (event) => {
        const { scheduleStore, commonStore, t } = this.props;
        const data = event ? {
            id: event.id,
            fromDay: event.forDate,
            toDay: event.forDate,
            fromUser: event.resourceId,
            toUser: null,
            currentSelected: null,
            addUserToGroup: false,
            user_group_code: null,
            do_notify: true,
            shiftAllowance: false,
            emergencyCall: false
        } : null;
        if (data?.id) {
            try {
                await scheduleStore.getAvailableAssignees(data.id);
            }
            catch (err) {
                console.error({ err })
                commonStore.addNotification(t(err.message || 'Something went wrong'), null, "error");
            }
        }
        this.setState({ stub: event, movingData: data, showShiftAssignModal: !this.state.showShiftAssignModal })
    }

    handleShiftAssign = (name, value) => {
        if (name === 'do_notify') {
            this.setState({ movingData: { ...this.state.movingData, do_notify: value } })
        }

        if (name === 'toUser') {
            const _movingData = { ...this.state.movingData }
            _movingData.toUser = value.value;
            _movingData.currentSelected = value;
            if (this.state.stub.userGroup > 0 && !value.groups.includes(this.state.stub.userGroup)) {
                _movingData.addUserToGroup = true;
                _movingData.user_group_code = this.state.stub.userGroup;
            } else {
                _movingData.addUserToGroup = false;
                _movingData.user_group_code = null;
            }
            this.setState({ movingData: _movingData })
        }

        if (name === 'submit') {
            if (!(this.state?.movingData?.toUser))
                return this.props.commonStore.addNotification(this.props.t('Please select employee'), null, 'warning');
            this.setState({ isModalLoading: true }, this.assignShfitToEmployee);
        }
    }

    assignShfitToEmployee = async () => {
        const { t, scheduleStore, commonStore } = this.props;
        const data = this.state.movingData;
        try {
            await scheduleStore.move(data);
            this.setState({ movingData: null, stub: null, showShiftAssignModal: false, isModalLoading: false });
            commonStore.addNotification(t('Shift assigned successfully'), null, 'success');
            this.reloadData();
        } catch (e) {
            commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            this.setState({ isModalLoading: false })
            console.error(e)
        }
    }

    updateMovingData = (data) => {
        this.setState({
            movingData: {
                ...this.state.movingData,
                ...data
            }
        })
    }

    eventItemTemplateResolver = (
        schedulerData,
        event,
        bgColor,
        isStart,
        isEnd,
        mustBeHeight,
        agendaMaxEventWidth
    ) => {
        const { toggle_todaybtn } = this.state;
        let getEndDate = new Date(event.end);
        let currentDate = new Date();
        const { t } = this.props;
        // these if-else basically use here to get functionality of today button ,,,, only today shifts will be shown.
        if (toggle_todaybtn) {
            if (getEndDate.getDate() === currentDate.getDate()) {
                return (
                    <EventItemTemplateResolver
                        params={{
                            schedulerData,
                            event,
                            bgColor,
                            isStart,
                            isEnd,
                            mustBeHeight,
                            agendaMaxEventWidth,
                            t,
                            toggle_todaybtn
                        }}
                    />
                );
            }
        }
        else {
            return (
                <EventItemTemplateResolver
                    params={{
                        schedulerData,
                        event,
                        bgColor,
                        isStart,
                        isEnd,
                        mustBeHeight,
                        agendaMaxEventWidth,
                        t,
                        toggle_todaybtn
                    }}
                />
            );
        }
    };

    eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor) => {
        const { t } = this.props;
        const dateTimeRules = this.getDateTimeRules();
        return (
            <EventItemPopoverTemplateResolver
                params={{
                    schedulerData,
                    eventItem,
                    title,
                    start,
                    end,
                    statusColor,
                    t,
                    dateTimeRules,
                }}
            />
        );
    };

    getSummary = (schedulerData, headerEvents, slotId, slotName, headerStart, headerEnd) => { };

    startCopyToNextWeek() {
        const { scheduleStore, t, commonStore } = this.props;
        const { viewModel } = this.state;
        const params = { start: viewModel.startDate, end: viewModel.endDate };
        let count = 0;
        viewModel.events.forEach(item => {
            const validation = item.source === 'shift' && item.resourceId > 0 && item.isEditable;
            if (moment(item.start).format('YYYY-MM-DD') >= viewModel.startDate &&
                moment(item.end).format('YYYY-MM-DD') <= viewModel.endDate && validation) {
                count++;
            }
        })

        confirmAlert({
            title: t('Confirm copy'),
            message: count + ' ' + t('shifts are ready for copy to next week.') + ' '
                + t('This will override the schedule for next week') + '. '
                + t('Shift Allowance and Emergency Shifts(On Call) will not be include in this, needs to be maintained manually'),
            buttons: [
                {
                    label: t('Yes'),
                    onClick: async () => {
                        try {
                            const result = await scheduleStore.copyToNextWeek(params);
                            commonStore.addNotification(result.message || 'Copied', null, 'success');
                            this.reloadData();
                        }
                        catch (err) {
                            console.error(err);
                            commonStore.addNotification('Something went wrong', null, 'error');
                        }
                    },
                },
                {
                    label: t('No')
                },
            ],
        });
    }

    filterTodayShifts() {
        this.setState({ toggle_todaybtn: !this.state.toggle_todaybtn });
    }

    initScheduler() {
        const { scheduleStore, userStore, t, commonStore } = this.props;
        const { currentList, currentAvailabilityList } = scheduleStore;
        const { currentUser } = userStore;
        const isMember = currentUser.user_type === 'member';
        let _userGroups = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.extendedRules
            && commonStore.config.client.data.extendedRules.user_groups && commonStore.config.client.data.extendedRules.user_groups.length ?
            commonStore.config.client.data.extendedRules.user_groups : [];
        let _roles = commonStore.config.client && commonStore.config.client.data && commonStore.config.client.data.roles ? commonStore.config.client.data.roles : [];
        const userGroups = [{ code: 0, name: 'Pending' }, ..._userGroups, { code: -1, name: t("No Group") }];
        const roles = [{ code: 0, name: 'Pending' }, ..._roles, { code: 'z_none', name: 'Others' }];
        if (moment.locale() === 'en') {
            moment.locale('en-GB');
        }
        const schedulerData = new SchedulerData(
            moment(new Date()).format('YYYY-MM-DD'),
            ViewTypes.Week,
            false,
            {
                displayWeekend: true,
                weekCellWidth: this.state.width > 800 ? '12%' : 80,
                weekResourceTableWidth: this.state.width > 800 ? '16%' : 120,
                schedulerMaxHeight: 1200,
                schedulerWidth: this.state.width,
                eventItemLineHeight: this.state.currentView ? 70 : 60,
                eventItemHeight: this.state.currentView ? 60 : 50,
                startResizable: false,
                endResizable: false,
                movable: !isMember,
                currentUserId: currentUser.id,
                nonWorkingTimeHeadColor: '#FF0202',
                nonWorkingTimeHeadBgColor: 'transparent',
                nonWorkingTimeBodyBgColor: '#F2F5FB',
                views: [
                    {
                        viewName: t('Week'),
                        viewType: ViewTypes.Week,
                        showAgenda: false,
                        isEventPerspective: false,
                    },
                    {
                        viewName: t('Month'),
                        viewType: ViewTypes.Month,
                        showAgenda: false,
                        isEventPerspective: false,
                    },
                ],
                getSummaryFunc: this.getSummary,
            },
            { isNonWorkingTimeFunc: this.isNonWorkingTimeFunc },
            undefined,
            this.state.currentView,
            userGroups,
            roles
        );
        this.checkFiltersAndSetResources(schedulerData);
        schedulerData._getTotalsRoleWise(this.props.scheduleStore.detailsInEmployeeType);
        schedulerData.setEvents(currentList.list);
        schedulerData.setAvailability(currentAvailabilityList.list);
        const hasUnpublishedShifts = commonStore?.config?.client?.data?.basicRules.enableSchedulePublishUnpublishFeatue && this.props.scheduleStore?.currentWeekHeader.some(ele => ele.show_shifts_to_member === false);
        this.setState({ viewModel: schedulerData, hideShowModal: hasUnpublishedShifts }, () => {
            this.setState({ loading: false });
            this.props.scheduleStore.initializeShiftEventCheckboxData(this.state.viewModel);
        });
    }

    async shiftToPending() {
        const { scheduleStore } = this.props;
        try {
            await scheduleStore.move(this.state.movingData);
            this.reloadData();
        }
        catch (e) {
            console.error({ e });
        }
    }

    async afterMoveDecision(state, childState) {
        const { t, scheduleStore, commonStore } = this.props;
        const data = this.state.movingData;
        data.do_notify = childState.notify;
        data.shiftAllowance = childState.shiftAllowance;
        data.emergencyCall = childState.emergencyCall;
        try {
            if (state === 'copy') {
                await scheduleStore.copy(data);
            }
            if (state === 'move') {
                await scheduleStore.move(data);
            }
            this.setState({ moving: false, movingData: null });
            this.reloadData();
        } catch (e) {
            commonStore.addNotification(t(e.message || 'Error'), null, 'error');
            console.error(e)
        }
    }

    isNonWorkingTimeFunc(schedulerData, time) {
        const { localeMoment } = schedulerData;
        const dayOfWeek = localeMoment(time).weekday();
        if (dayOfWeek === 6 || dayOfWeek === 5) return true;
        return false;
    }

    afterAvailModal(status) {
        const { userStore } = this.props;
        const { currentUser } = userStore;
        const isMember = currentUser.user_type === 'member';
        if (status === 'cancel') {
            if (isMember) {
                this.setState({
                    isCheckAvailability: false,
                    showAvailabilityList: !this.state.showAvailabilityList
                })
            } else {
                this.setState({
                    isCheckAvailability: false,
                    openManagement: !this.state.openManagement
                })
            }
        }

    }

    renderAvailabilityList = () => {
        return (
            <ScheduleAvailabilityList
                editRowData={(data) => this.editAvailabilityDetails(data)}
                updateAvailabilityStatus={this.reloadData}
                reloadData={this.reloadData}
                viewModel={this.state.viewModel}
            />
        )
    }

    renderAddAvailabilityAssign = () => {
        const { t } = this.props;
        const { isAddAvailability } = this.state;
        return (
            <Modal
                className="modal right fade"
                show={isAddAvailability}
                onHide={() => this.handleClosesAvailabilityListModal()}
            >
                <Modal.Body className="cmnFormModalBoday">
                    <div className="cmnFormHead">
                        <div className="cmnFormHeadLeft">
                            <button className="cmnFormCloseBtn" onClick={() => this.handleClosesAvailabilityListModal()}><img src={CloseIcon} alt="close button"></img></button>
                            <div className="cmnFormTxt">{t('Add Availability')}</div>
                        </div>
                        <div className='cmnFormActBtn'>
                            <Button btn_reject nb onClick={() => this.handleClosesAvailabilityListModal()}>
                                {t('Close')}
                            </Button>
                            <Button fill nb
                                onClick={() => !this.state.selectedRowData ? this.submitAvailability() : this.editData()}
                            >
                                {t('Save')}
                            </Button>
                        </div>
                    </div>
                    <ScheduleAddAvailability
                        changeStatusOfRequest={(id, data) => this.changeStatusOfRequest(id, data)}
                        editData={(data) => this.setState({ selectedRowData: data })}
                        selectedRowData={this.state.selectedRowData}
                        setData={(data) => this.setState({ availabilityData: data })}
                    />
                </Modal.Body>
            </Modal>
        )

    }

    handleHideShow = (date, is_visible, confirmationNeeded) => {
        const _message = this.props.t(`that you want to ${is_visible ? "Publish" : "Unpublish"} the shift-schedule`) + " ?";
        if (confirmationNeeded) {
            confirmAlert({
                title: this.props.t('Are You Sure'),
                message: this.props.t(_message),
                buttons: [
                    {
                        label: this.props.t('Yes'),
                        onClick: async () => {
                            await this.props.scheduleStore.hideShowShifts({ for_date: date ? date : this.state.budgetForWhatDay, is_visible, budget_for_day: this.state.budget })
                            this.reloadData();
                            this.setState({ showBudgetModal: false, budgetForWhatDay: 0, budget: 0 })
                        },
                    },
                    {
                        label: this.props.t('No')
                    },
                ],
            });
        }
        else {
            this.props.scheduleStore.hideShowShifts({ for_date: date ? date : this.state.budgetForWhatDay, is_visible, budget_for_day: this.state.budget })
            this.reloadData();
            this.setState({ showBudgetModal: false, budgetForWhatDay: 0, budget: 0 })
        }
    }

    showBudgetModal = (date) => {
        this.setState({ showBudgetModal: true, budgetForWhatDay: date })
    }

    renderInner() {
        const { userStore, t } = this.props;
        const { currentUser } = userStore;
        const { viewModel, moving, currentView, editing, openManagement, showAvailabilityList, bulkEditing,
            stub, movingData, toggle_todaybtn, isCheckAvailability, isAddAvailability } = this.state;
        const userType = currentUser.user_type;
        return (
            <div id="RBS-Scheduler-root" className="scheduler" style={{ width: `${viewModel.getSchedulerWidth() || 100}px` }}>
                <ScheduleWeekHeader
                    schedulerData={viewModel}
                    prevClick={this.prevClick}
                    nextClick={this.nextClick}
                    onViewChange={this.onViewChange}
                    handleProjectChange={this.handleProjectChange}
                    onListViewChange={this.onListViewChange}
                    viewAvailabilityAssign={this.viewAvailabilityAssign}
                    filterTodayShifts={this.filterTodayShifts}
                    startCopyToNextWeek={this.startCopyToNextWeek}
                    newBulkEvent={this.newBulkEvent}
                    openManagementModal={() => this.setState({ openManagement: true })}
                    checkFiltersAndSetResources={this.checkFiltersAndSetResources}
                    handleDayDurationChange={this.handleDayDurationChange}
                    handleMultiDelete={this.handleMultiDelete}
                    getDateTimeRules={this.getDateTimeRules}
                    isAvailibiltyFeatureEnabled={this.isAvailibiltyFeatureEnabled()}
                    currentView={currentView}
                    handleTimesheetApprovalModal={this.handleTimesheetApprovalModal}
                />
                <Scheduler
                    schedulerData={this.state.viewModel}
                    onViewChange={this.onViewChange}
                    eventItemClick={this.eventClicked}
                    moveEvent={this.moveEvent}
                    eventItemTemplateResolver={this.eventItemTemplateResolver}
                    eventItemPopoverTemplateResolver={this.eventItemPopoverTemplateResolver}
                    getSummaryFunc={this.getSummary}
                    newEvent={this.newEvent}
                    allowResourceSelection={userType === 'pm' || userType === 'admin'}
                    finish={(status) => this.afterEditing(status)}
                    moveToPending={(event, slotId, slotName, start, end) => this.moveToPending(event, slotId, slotName, start, end)}
                    handleShiftAssignModal={this.handleShiftAssignModal}
                    toggle_todaybtn={toggle_todaybtn}
                    t={t}
                    currentView={currentView}
                    handleHideShow={this.handleHideShow}
                    showBudgetModal={this.showBudgetModal}
                    handleOnCallClick={this.handleOnCallClick}
                />
                {moving && (
                    <MoveOrCopyModal
                        movingData={movingData}
                        decide={(state, doNotify) => this.afterMoveDecision(state, doNotify)}
                        updateMovingData={this.updateMovingData}
                    />
                )}
                {editing !== false && (
                    <ShiftForm
                        id={editing}
                        add={editing === 0}
                        stub={stub}
                        finish={(status, users) => this.afterEditing(status, users)}
                        responseData={this.state.responseData}
                        isAvailibiltyFeatureEnabled={this.isAvailibiltyFeatureEnabled()}
                        handleHideShow={this.handleHideShow}
                        currentView={currentView}
                    />
                )}
                {bulkEditing !== false && (
                    <BulkShiftForm
                        viewModel={viewModel}
                        finish={(status, obj) => this.afterBulkEditing(status, obj)}
                    />

                )}
                {isCheckAvailability &&
                    <CheckAvailabilityForm
                        viewModel={viewModel}
                        finish={(status) => this.afterAvailModal(status)}
                    />
                }
                {openManagement &&
                    <Management
                        renderAvailabilityList={this.renderAvailabilityList}
                        setIsAddAvailability={() => this.setState({ isAddAvailability: true })}
                        closeManagement={() => this.setState({ openManagement: false })}
                        openManagement
                        checkAvailibilty={() => this.setState({ isCheckAvailability: !isCheckAvailability, openManagement: !this.state.openManagement })}
                        userType={userType}
                        t={t}
                        isAvailibiltyFeatureEnabled={this.isAvailibiltyFeatureEnabled()}
                    />
                }
                {this.state.showAvailabilityList &&
                    <MemberAvailabilityList
                        renderAvailabilityList={this.renderAvailabilityList}
                        setIsAddAvailability={() => this.setState({ isAddAvailability: !isAddAvailability })}
                        checkAvailibilty={() => this.setState({ isCheckAvailability: !isCheckAvailability, showAvailabilityList: !showAvailabilityList })}
                        closeModal={() => this.setState({ showAvailabilityList: false })}
                        userType={userType}
                        t={t}
                    />
                }
                {this.renderAddAvailabilityAssign()}
            </div>
        );
    }

    renderMonthCalender() {
        return (
            <Fragment>
                <MonthCalendar
                    schedulerData={this.state.viewModel}
                    toggle_todaybtn={this.state.toggle_todaybtn}
                    prevClick={this.prevClick}
                    nextClick={this.nextClick}
                    bulkEvent={this.newBulkEvent}
                    onViewChange={this.onViewChange}
                    isAvailibiltyFeatureEnabled={this.isAvailibiltyFeatureEnabled()}
                />
            </Fragment>
        )
    }

    viewAvailabilityAssign = () => {
        this.setState({ add: true }, () => this.setState({ showAvailabilityList: true }));
    };

    handleClosesAvailabilityListModal = () => {
        this.setState({ add: false, isAddAvailability: false, selectedRowData: null, availabilityData: null });
        this.props.scheduleStore.returnDefaultNew();
    };

    submitAvailability = () => {
        let { availabilityData } = this.state;
        const { userStore, commonStore, t } = this.props;

        try {
            if (!availabilityData?.availability?.length)
                return commonStore.addNotification(t('Please fill all the fields'), null, 'warning');

            availabilityData.availability.forEach(element => {
                element.for_date = moment(element.for_date).format("YYYY-MM-DD");
            });
            availabilityData.user_id = userStore.currentUser.id;
            availabilityData.client_id = userStore.currentUser.client_id;
            availabilityData.request_type = availabilityData?.request_type || 'weekly';
            this.setState({ availabilityData: availabilityData })

            this.props.scheduleStore.saveAvailability(availabilityData).then(res => {
                this.setState({ isAddAvailability: false, availabilityData: null }, this.reloadData);
                this.props.scheduleStore.resetLastListLoadTime();
            }).catch(e => {
                console.error(e);
                commonStore.addNotification(t(e), null, 'error');
            });
        }
        catch (err) {
            console.error(err);
            commonStore.addNotification(t(err), null, 'error');
        }
    }

    editAvailabilityDetails = (data) => {
        this.setState({ selectedRowData: data, isAddAvailability: true });
    }

    changeStatusOfRequest = (id, data) => {
        this.props.scheduleStore.updateStatus(id, data).then(res => {
            this.setState({ selectedRowData: null, isAddAvailability: false, availabilityData: null });
        });
    }

    editData = () => {
        this.props.scheduleStore.updateStatus(this.state.selectedRowData.id, this.state.selectedRowData).then(res => {
            this.setState({ selectedRowData: null, isAddAvailability: false, availabilityData: null });
        });
        this.reloadData();
    }

    handleMultiDelete = () => {
        const { scheduleStore, shiftAllowanceStore, t, commonStore } = this.props;
        let ids = [];
        Object.keys(scheduleStore.shiftEventSelectionData).forEach(key => {
            if (scheduleStore.shiftEventSelectionData[key].checked) {
                ids.push(key);
            }
        })
        if (ids.length) {
            const emergencyShiftCount = scheduleStore.resources.filter(ele => ele?.emergency_id)?.length
            if (shiftAllowanceStore.allShiftAllowance.length || emergencyShiftCount)
                this.setState({ ids: ids, showMultiDeleteConfimation: true })
            else {
                this.confirmTextForMultiDeletion(ids)
            }
        }
        else
            commonStore.addNotification(t('Please select atleast one shift'), null, 'warning');
    }

    confirmTextForMultiDeletionWithAllowanceAndEmergency = async (ids, deleteShiftAllowance, deleteEmergencyShift) => {
        const { scheduleStore, commonStore, t } = this.props;
        if (!ids.length) {
            await scheduleStore.remove(scheduleStore.currentEntity.id, { deleteShiftAllowance, deleteEmergencyShift });
            this.reloadData();
        }
        else {
            await scheduleStore.removeMultiple(ids, { deleteShiftAllowance, deleteEmergencyShift }).then((res2) => {
                scheduleStore.resetLastListLoadTime();
                commonStore.addNotification(t(res2.message), null, 'success');
                this.reloadData();
            });
        }
        this.setState({ showMultiDeleteConfimation: false, deleteShiftAllowance: true, deleteEmergencyShift: true, ids: [] });
    }

    confirmTextForMultiDeletion = async (ids) => {
        const { scheduleStore, t, commonStore } = this.props
        const str = `${t("You have selected")} ${ids.length} ${t("shift(s) to delete")}.`;
        confirmAlert({
            title: this.props.t('Are you sure that you want to delete') + " ?",
            message: str,
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        return scheduleStore.removeMultiple(ids).then((res2) => {
                            scheduleStore.resetLastListLoadTime();
                            commonStore.addNotification(t(res2.message), null, 'success');
                            this.reloadData();
                            return res2;
                        });
                    },
                },
                {
                    label: this.props.t('No'),
                    onClick: () => this.props.scheduleStore.initializeShiftEventCheckboxData(this.state.viewModel),
                },
            ],
        })
    }

    handleHideShowModal = (isTrue) => {
        this.props.scheduleStore.hideShowShifts({ is_visible: isTrue, isMultiDay: isTrue })
        this.reloadData();
        this.setState({ hideShowModal: false })
    }

    handleOnCallClick = async (event) => {
        this.setState({ emergencyDetails: event });
    }

    deleteEmergencyShift = (emergencyDetails) => {
        this.props.shiftAllowanceStore.removeEmergencyShift(emergencyDetails?.slotEmergencyId).then((res) => {
            this.props.commonStore.addNotification(this.props.t('Deleted'), null, 'error');
            this.setState({ emergencyDetails: null });
            this.reloadData();
        });
    }

    handleTimesheetApprovalModal = () => {
        this.setState({ showTimesheetApprovalModal: !this.state.showTimesheetApprovalModal });
    }

    handleApproveTimesheets = () => {
        const { scheduleStore } = this.props;
        let selectedShifts = scheduleStore.shiftEventSelectionData ?
            Object.values(scheduleStore.shiftEventSelectionData).filter(e => e.checked && e.details.timelogsToBeApprovedCount) : [];


        let timelogIds = [], userIdSet = new Set();
        for (const shiftData of selectedShifts) {
            timelogIds.push(...shiftData.details.timelogsToBeApproved.map(t => t.timelogId));
            userIdSet.add(shiftData.details.userId);
        }
        const payload = {
            timelogIds,
            userIds: [...userIdSet],
            sendNotification: this.state.sendTimesheetApprovalNotification,
            syncWithIntegration: this.state.syncWithIntegration
        }
        this.setState({ loading: true }, () => this.approveTimesheets(payload));
    }

    approveTimesheets = async (payload) => {
        const { commonStore, scheduleStore, t } = this.props;
        try {
            const res = await scheduleStore.approveTimesheets(payload);
            commonStore.addNotification(t(res.message ? res.message : 'Timesheets Approved'), null, 'success');
            this.reloadData();
        } catch (err) {
            console.error({ err });
            commonStore.addNotification(err.message, null, 'error');
        } finally {
            this.setState({ loading: false, showTimesheetApprovalModal: false });
        }
    }

    render() {
        const { scheduleStore, t, userStore, commonStore } = this.props;
        const { resources, currentAvailableAssignees } = scheduleStore;
        const { viewModel, showCalendar, loading, ids, deleteShiftAllowance, deleteEmergencyShift,
            showMultiDeleteConfimation, emergencyDetails, showTimesheetApprovalModal,
            sendTimesheetApprovalNotification, syncWithIntegration, showShiftAssignModal, isModalLoading } = this.state;
        const loaded = !!resources && this.state.width > 0 && viewModel;
        const isMember = userStore.currentUser.user_type === 'member';
        const hasIntegration = commonStore?.config?.integration_details?.product ? commonStore.config.integration_details.product : null;
        if (loading) {
            return <LoadingSpinner />;
        }

        return (
            <div>
                <div className='w-100 h-100' ref={this.wrapper}>
                    {!showCalendar && loaded && this.renderInner()}
                    {showCalendar && this.renderMonthCalender()}
                </div>
                <Modal
                    className="modal right fade"
                    show={this.state.openSettings}
                    onHide={() => this.setState({ openSettings: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Availability')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ScheduleSettings />
                    </Modal.Body>
                </Modal>
                <Modal
                    className="modal fade"
                    show={this.state.showBudgetModal}
                    onHide={() => this.setState({ showBudgetModal: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Revenues')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Input
                            className="custom-form__col"
                            placeholder={t(`Enter Revenues`)}
                            type="text"
                            value={this.state.budget}
                            onChange={(e) => this.setState({ budget: e.target.value })}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button fill wd onClick={() => this.handleHideShow()}>
                            {t('Done')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    className="modal fade"
                    show={this.state.hideShowModal && userStore.currentUser.user_type !== 'member'}
                    onHide={() => this.setState({ hideShowModal: false })}
                >
                    <Modal.Header>
                        <Modal.Title>{t('Schedule Shifts')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>{t(`Time & Control has noticed you have shifts to publish`)}</div>
                        <div className='d-flex justify-content-around mt-2'>
                            <Button wd fill onClick={() => this.handleHideShowModal(true)}>
                                {t('Publish Week')}
                            </Button>
                            <Button wd onClick={() => this.setState((state) => ({ hideShowModal: !state.hideShowModal }))}>
                                {t('Do it later')}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    className="modal fade h-75 align-content-center"
                    show={showMultiDeleteConfimation}
                    onHide={() => this.setState({ showMultiDeleteConfimation: false })}
                >
                    <Modal.Header>
                        <Modal.Title>{t('Confirm to delete')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={() => this.setState({ showMultiDeleteConfimation: false })}
                            alt='close-btn'
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <p className='fw-semibold font-size-15 color-2550AC'>**{ids.length ? `${t("You have selected")} ${ids.length} ${t("shift(s) to delete")}. ` : ''}
                            {t("If you wish to remove the shift allowance and emergency shift(On-Call), Please Select accordingly")}</p>
                        <div className='d-flex justify-content-around mt-4 mb-4'>
                            <Checkbox
                                isChecked={deleteShiftAllowance}
                                label={`${t('Delete')} ${t('Shift Allowance')}`}
                                onChange={() => this.setState(state => ({ deleteShiftAllowance: !state.deleteShiftAllowance }))}
                                wrapperClassName={'shift-chekbox-style font-size-12'}
                                disabled={!this.props.shiftAllowanceStore?.allShiftAllowance?.length}
                                title={!this.props.shiftAllowanceStore?.allShiftAllowance?.length ? 'No Shift Allowance Found' : null}
                            />
                            <Checkbox
                                isChecked={deleteEmergencyShift}
                                label={`${t('Delete')} ${t('Emergency Shift(On Call)')}`}
                                onChange={() => this.setState(state => ({ deleteEmergencyShift: !state.deleteEmergencyShift }))}
                                wrapperClassName={'shift-chekbox-style font-size-12'}
                                disabled={!scheduleStore?.resources?.filter(ele => ele?.emergency_id)?.length}
                                title={!scheduleStore?.resources?.filter(ele => ele?.emergency_id)?.length ? 'No Emergency Shift(On Call) Found' : null}
                            />
                        </div>
                        <p className='font-size-12 m-0'><strong>{t('Note')}</strong>:</p>
                        <p className='font-size-12 fw-semibold'><i className='fa fa-arrow-right' />{t('Shift Allowance if Synced with integration will also be removed')}
                            <br /><i className='fa fa-arrow-right' />{t('Emergency Shift(On-Call) if logged in timesheet and is Synced with integration will not be removed automatically, To remove please delete and re-register the concerned timesheets')}.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button fill wd
                            onClick={() => this.confirmTextForMultiDeletionWithAllowanceAndEmergency(ids, deleteShiftAllowance, deleteEmergencyShift)}>
                            {t('Yes')}
                        </Button>
                        <Button wd onClick={() => this.setState({ showMultiDeleteConfimation: false, deleteShiftAllowance: true, deleteEmergencyShift: true })}>
                            {t('No')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    className="modal fade h-75 align-content-center"
                    show={emergencyDetails ? true : false}
                    onHide={() => this.setState({ emergencyDetails: null })}
                >
                    <Modal.Header>
                        <Modal.Title className='w-100 text-center custom-modal-title'>{t('Emergency Shift(On Call)')} {t('Details')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={() => this.setState({ emergencyDetails: null })}
                            alt='close-btn'
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex justify-content-around mb-3'>
                            <table className='w-100 text-center'>
                                <tr className='bg-body-secondary fw-600'>
                                    {!isMember && <th>{t('Name')}</th>}
                                    <th>{t('Start Date')}</th>
                                    <th>{t('End Date')}</th>
                                    <th>{t('Time')}</th>
                                </tr>
                                <tr>
                                    {!isMember && <td>{emergencyDetails?.slotName}</td>}
                                    <td>{shortDateFormat(emergencyDetails?.slotEmergencyFromDate, this.getDateTimeRules())}</td>
                                    <td>{shortDateFormat(emergencyDetails?.slotEmergencyToDate, this.getDateTimeRules())}</td>
                                    <td>{timeFormat(this.emergencyShiftRule?.fromTime, this.getDateTimeRules())}-{timeFormat(this.emergencyShiftRule?.toTime, this.getDateTimeRules())}</td>
                                </tr>
                            </table>
                        </div>
                        <p className='font-size-15'><strong>{t('Note')}:</strong>&nbsp;
                            {!isMember ? t('Emergency Shift(On-Call) if logged in timesheet and is Synced with integration will not be removed automatically, To remove please delete and re-register the concerned timesheets') :
                                t('You are assigned to handle the emergency shifts(on-call) for the above dates')}.</p>
                    </Modal.Body>
                    {!isMember &&
                        <Modal.Footer>
                            <Button fill wd onClick={() => this.deleteEmergencyShift(emergencyDetails)} disabled={isMember}>
                                {t('Delete')}
                            </Button>
                            <Button wd onClick={() => this.setState({ emergencyDetails: null })}
                            >
                                {t('Cancel')}
                            </Button>
                        </Modal.Footer>
                    }
                </Modal>
                <Modal
                    className="modal fade h-75 align-content-center"
                    show={showTimesheetApprovalModal}
                    onHide={this.handleTimesheetApprovalModal}
                >
                    <Modal.Header>
                        <Modal.Title className='w-100 text-center custom-modal-title'>{t('Approve Multiple Timesheets')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={this.handleTimesheetApprovalModal}
                            alt='close-btn'
                        />
                    </Modal.Header>
                    <Modal.Body>
                        <p className='m-2 fw-bold'>{t("You have selected timesheets to approve, please confirm below settings for additional operations")}.</p>
                        <div className={`d-flex mb-2 ${hasIntegration ? 'justify-content-space-between' : 'justify-content-end'} font-size-12`}>
                            {hasIntegration &&
                                <div className='d-flex'>
                                    <Checkbox
                                        wrapperClassName="days-of-week__item"
                                        key={"timesheet_notify_all"}
                                        isChecked={syncWithIntegration}
                                        onChange={() => this.setState({ syncWithIntegration: !syncWithIntegration })}
                                        label={t("Sync hours to") + ` ${hasIntegration}`}
                                    />
                                    {SyncSGV({ width: 20, fillColor: "#2550AC" })}
                                </div>
                            }
                            <div className='d-flex'>
                                <Checkbox
                                    wrapperClassName="days-of-week__item"
                                    key={"timesheet_notify_all"}
                                    isChecked={sendTimesheetApprovalNotification}
                                    onChange={() => this.setState({ sendTimesheetApprovalNotification: !sendTimesheetApprovalNotification })}
                                    label={t("Send approval notification")}
                                />
                                {NotificationSGV({ width: 20, fillColor: "#2550AC" })}
                            </div>
                        </div>
                        <p className='mx-2 mt-4'><strong>{t('Note')}:&nbsp;</strong>{t("Timesheets can only be approved up to the current date. Future and ongoing timesheets cannot be approved")}.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button fill wd onClick={this.handleApproveTimesheets}>
                            {t('Approve Timesheets')}
                        </Button>
                        <Button wd onClick={this.handleTimesheetApprovalModal}
                        >
                            {t('Cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    className="modal fade h-75 align-content-center"
                    show={showShiftAssignModal}
                    onHide={_ => this.handleShiftAssignModal(null)}
                >
                    <Modal.Header>
                        <Modal.Title className='w-100 text-center custom-modal-title'>{t('Assign shift')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            onClick={_ => this.handleShiftAssignModal(null)}
                            alt='close-btn'
                        />
                    </Modal.Header>
                    {isModalLoading ?
                        <LoadingSpinner /> :
                        <>
                            <Modal.Body>
                                <p className='m-2 fw-bold'>{t("Please select employee to assign shift")}.</p>
                                <div className='row my-2'>
                                    <div className='col-6'>
                                        <Select
                                            options={currentAvailableAssignees.map(e => { return { value: e.id, label: e.username, groups: e.groups } })}
                                            value={this.state.movingData?.currentSelected || null}
                                            isDisabled={!currentAvailableAssignees || !currentAvailableAssignees.length}
                                            onChange={selected => this.handleShiftAssign('toUser', selected)}
                                            style={{ width: '100%' }}
                                            className={'custom-select-max-height-200'}
                                            disabled={isModalLoading}
                                        />
                                    </div>
                                    <div className='col-6 d-flex'>
                                        <Checkbox
                                            wrapperClassName="days-of-week__item"
                                            key={"timesheet_notify_all"}
                                            disabled={isModalLoading}
                                            isChecked={!!(this.state.movingData?.do_notify)}
                                            onChange={() => this.handleShiftAssign('do_notify', !(this.state.movingData?.do_notify))}
                                            label={t("Notify user")}
                                        />
                                        {NotificationSGV({ width: 20, fillColor: "#2550AC" })}
                                    </div>
                                </div>
                                {!currentAvailableAssignees.length &&
                                    <p className='mx-2 text-danger font-size-12 fw-bold'>{t("No employee found for the current shift")}</p>}
                                <p className='fw-bold font-size-15 m-2'>{t('Shift details')}</p>
                                <div className='row m-2'
                                    style={{
                                        backgroundColor: "lightgray",
                                        borderRadius: "10px", border: "2px solid #958e8e"
                                    }}
                                >
                                    <div className='col-6'>
                                        <span className='fw-bold font-size-12 me-4'>{t("Time")}</span>
                                        <span className='font-size-15'>{this.state?.stub?.title}</span>
                                    </div>
                                    <div className='col-6'>
                                        <span className='fw-bold font-size-12 me-4'>{t("Date")}</span>
                                        <span className='font-size-15'>{this.state?.stub?.forDate}</span>
                                    </div>
                                    <div className='col-6'>
                                        <span className='fw-bold font-size-12 me-4'>{t("Project")}</span>
                                        <span className='font-size-15'>{this.state?.stub?.taskName}</span>
                                    </div>
                                    <div className='col-6 d-flex'>
                                        <span className='fw-bold font-size-12 me-4'>{t("Task")}</span>
                                        <span className='font-size-15'>{this.state?.stub?.projectName}</span>
                                    </div>
                                </div>
                                <p className='mx-2 mt-2 font-size-12 fw-bold'>
                                    <span className='font-size-15 fw-bold'>{t('Note')}:&nbsp;</span>
                                    {t("The employee list in the drop-down is filtered by project, excluding those already assigned to another shift, on absence, or on vacation")}.
                                </p>
                                {this.state.movingData?.addUserToGroup &&
                                    <p className='mx-2 font-size-12 fw-bold'>
                                        {t("We observe that the employee is not in the same group as the shift. No worries, the system will automatically add the employee to the required group.")}
                                    </p>
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <Button fill wd onClick={() => this.handleShiftAssign('submit')}>
                                    {t('Assign')}
                                </Button>
                                <Button wd onClick={_ => this.handleShiftAssignModal(null)}>
                                    {t('Cancel')}
                                </Button>
                            </Modal.Footer>
                        </>
                    }
                </Modal>
            </div >
        );
    }
}

export default inject('dashboardStore', 'userStore', 'commonStore', 'scheduleStore', 'shiftAllowanceStore')(withLocalization(applicationRouter(withDnDContext(observer(ScheduleMain)))));