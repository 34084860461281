import React, { useState } from "react";
import { Row, Col, Modal } from 'react-bootstrap';

//components
import TimePickerInput from '~/components/TimePicker';

//elements
import Select from '~/elements/Select.js';
import RequiredStar from '~/elements/RequiredStar.js';
import Button from '~/components/CustomButton/CustomButton';

//utils
import { getMinutesFromTimeInput, minutesToTimeInput } from '~/utils/timeCalc';
import { validateRequestPayload } from '~/utils/commonMethods';

//assets
import CloseModalIcon from '../../../../../assets/img/CloseApproveModalIcon.svg';

const CompanyShiftOvertime = ({ values, submit, cancel, t, overtimeSettingList, dateTimeRules }) => {
    const [overtimeSettingId, setOvertimeSettingId] = useState(values?.overtime_setting_id || 0);
    const [fromTime, setFromTime] = useState(minutesToTimeInput(values?.from || 0));
    const [toTime, setToTime] = useState(minutesToTimeInput(values?.to || 0));

    const saveData = () => {
        const data = {
            id: values?.id,
            overtime_setting_id: overtimeSettingId,
            from: getMinutesFromTimeInput(fromTime),
            to: getMinutesFromTimeInput(toTime)
        }

        const validFields = ["overtimeSettingId", "fromTime", "toTime"];
        const validMessage = ["Overtime", "From time", "To time"];
        const errors = validateRequestPayload({ overtimeSettingId, fromTime, toTime }, validFields, validMessage, t);
        if (errors) {
            submit({ hasError: true, errors: errors })
        } else {
            submit(data)
        }
    }



    return (
        <Modal
            size="md"
            centered
            show={true}
            onHide={cancel}
        >
            <Modal.Header>
                <Modal.Title className="w-100 text-center m-0">{t('Overtime Configuration')}</Modal.Title>
                <img
                    src={CloseModalIcon}
                    alt='closeIcon'
                    className="cursor-pointer"
                    onClick={cancel}
                />
            </Modal.Header>
            <Modal.Body>
                <Row className="mt-2">
                    <Col xs={12}>
                        <label className="control-label">{t('Overtime')} <RequiredStar /></label>
                        <Select
                            options={overtimeSettingList}
                            value={overtimeSettingList.find(item => item.value === overtimeSettingId)}
                            onChange={evt => {
                                setOvertimeSettingId(evt.value)
                            }}
                            placeholder={`${t('Select')} ${t('Overtime')}`}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col xs={6}>
                        <label className="control-label">{t('From time')} <RequiredStar />
                            <span className="text-muted "><em> (hh:mm)</em></span></label>
                        <TimePickerInput
                            style={{ width: 150 }}
                            value={fromTime}
                            format={dateTimeRules.time_format === "hh:mm" ? "HH:mm" : dateTimeRules.time_format}
                            onChange={(e) => { setFromTime(e) }}
                        />
                    </Col>
                    <Col xs={6}>
                        <label className="control-label">{t('To time')} <RequiredStar />
                            <span className="text-muted "><em> (hh:mm)</em></span></label>
                        <TimePickerInput
                            style={{ width: 150 }}
                            value={toTime}
                            format={dateTimeRules.time_format === "hh:mm" ? "HH:mm" : dateTimeRules.time_format}
                            onChange={(e) => { setToTime(e) }}
                        />
                    </Col>
                    <Col xs={12}>
                        <p className="font-size-12 pt-2 mb-1">
                            <strong>{t('Note')}</strong>
                        </p>
                        <span className="d-flex font-size-12 text-danger">
                            <i>{t("Be informed that any changes to overtimes will not effect the past timesheet entries, if needed please reset the timesheet and save again so the changes may reflect")}.</i>
                        </span>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={saveData} className="btn-wd btn-fill">
                    {t('Save')}
                </Button>
                <Button variant="secondary" onClick={cancel} className="btn-wd">
                    {t('Cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CompanyShiftOvertime;
