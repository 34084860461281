import { action, makeObservable } from 'mobx';

import agent from '../agent';
import commonStore from './commonStore';

class UserGroupStore {
    constructor() {
        makeObservable(this, {
            getUserGroups: action,
            getGroupByUserAndUsageDetails: action,
            addOrUpdateUserGroupAndAssignees: action,
            removeUserGroup: action,
            getGroupDetails: action,
        });
    }

    getGroupDetails(params = {}) {
        try {
            return agent.UserGroups.getGroupDetails(params).then(
                action((response) => {
                    return response.userGroupList ? response.userGroupList : [];
                })
            ).catch(
                action(err => {
                    throw err
                })
            )
        } catch (err) {
            commonStore.addNotification(err.message, null, 'error');
        }
    }

    getUserGroups(user_id) {
        try {
            return agent.UserGroups.getUserGroups(user_id).then(
                action((response) => {
                    return response.userGroups ? response.userGroups : [];
                })
            ).catch(
                action(err => {
                    throw err
                })
            )
        } catch (err) {
            commonStore.addNotification(err.message, null, 'error');
        }
    }
    getUserList() {
        try {
            return agent.UserGroups.getUserList().then(
                action((response) => {
                    return response.userList ? response.userList : [];
                })
            ).catch(
                action(err => {
                    throw err
                })
            )
        } catch (err) {
            commonStore.addNotification(err.message, null, 'error');
        }
    }

    getGroupByUserAndUsageDetails() {
        try {
            return agent.UserGroups.getGroupByUserAndUsageDetails().then(
                action((response) => {
                    return response.user_groups_hash_map ? response.user_groups_hash_map : [];
                })
            ).catch(
                action(err => {
                    throw err
                })
            )
        } catch (err) {
            commonStore.addNotification(err.message, null, 'error');
        }
    }

    addOrUpdateUserGroupAndAssignees(data, isAdd) {
        try {
            if (isAdd) {
                return agent.UserGroups.addUserGroupAndAssignMembers(data).then(
                    action((response) => {
                        return response;
                    })
                ).catch(
                    action(err => {
                        throw err
                    })
                )
            } else {
                return agent.UserGroups.updateUserGroupAndAssignees(data).then(
                    action((response) => {
                        return response;
                    })
                ).catch(
                    action(err => {
                        throw err
                    })
                )
            }
        } catch (err) {
            commonStore.addNotification(err.message, null, 'error');
        }
    }

    removeUserGroup(code) {
        try {
            return agent.UserGroups.removeGroup(code).then(
                action((response) => {
                    return response;
                })
            ).catch(
                action(err => {
                    console.error("eee", err)
                    throw err
                })
            )
        } catch (err) {
            commonStore.addNotification(err.message, null, 'error');
        }
    }

}

const _UserGroupStore = new UserGroupStore()
export default _UserGroupStore;
