import { observable, action, makeObservable } from 'mobx';
import agent from '../agent';

class signatureStore {
    constructor() {
        makeObservable(this, {
            isLoading: observable,
            isDocUploading: observable,
            isDocFetching: observable,
            isFullScreen: observable,
            caseId: observable,
            documentList: observable,
            isDocFinished: observable,
            caseName: observable,
            documentPages: observable,
            accessCode: observable,
            dateFormat: observable,
            signatureLoading: observable,
            filters: observable,
            appliedFilters: observable,
            setFilter: action,
            onFilter: action,
            config: observable,
            signDocHistoryList: observable,
            lastListLoadTime: observable,
            signDocumentList: observable,
            recipientList: observable,
            VerifyCode: action,
            getConfig: action,
            getSignDocHistory: action,
            resetLastListLoadTime: action,
            getSignDocument: action,
            uploadDoc: action,
            openFullScreenMode: action,
            closeFullScreenMode: action,
            removeDoc: action,
            deleteDocPage: action,
            fetchAll: action,
            setCaseId: action,
            createCase: action,
            updateDocName: action,
            resetDocList: action,
            resetDocPages: action,
            sendDocNow: action,
            getRecipientDoc: action,
            getByDocCaseId: action,
            verifyAccessCode: action,
            storeSignature: action,
            generateSignatureLink: action,
            downloadCompletedDocument: action,
            downloadPdf: action,
            removeCase: action
        });
    }
    isLoading = false;
    isDocUploading = false;
    isDocFetching = false;
    isFullScreen = false;
    caseId = sessionStorage.getItem('CaseId') || 0;
    documentList = null;
    isDocFinished = null;
    caseName = null;
    documentPages = null;
    accessCode = null;
    dateFormat = null;
    signatureLoading = false;
    config = {};
    signDocHistoryList = [];
    lastListLoadTime = null;
    signDocumentList = [];
    recipientList = [];
    filters = {
        status: '',
    };
    appliedFilters = {
        name: '',
        status: '',
    };

    setFilter(name, value) {
        const filter = Object.assign({}, this.filters);
        filter[name] = value;
        this.filters = filter;
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
        this.requestParams.filters = this.appliedFilters;
    }

    VerifyCode(access_code) {
        this.accessCode = access_code;
    }
    resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    getConfig() {
        return this.config;
    }

    getSignDocHistory(params) {
        //calls get method from signDocHistory API//
        //takes 1 arguments, type - object, params//
        //returns signdochistory list//
        return agent.Signature.signDocHistory(params)
            .then(
                action((list) => {
                    list.time = new Date();
                    this.signDocHistoryList = list;
                    this.lastListLoadTime = list.time;
                    return this.signDocHistoryList;
                })
            )
            .catch(
                action((error) => {
                    throw error;
                })
            );
    }

    getSignDocument(params, contract_user, user_id) {
        params.contract_user = contract_user ? contract_user : false;
        params.user_id = user_id ? user_id : null;
        return agent.Signature.createDocument(params)
            .then(
                action((list) => {
                    this.requestParams = params;
                    list.time = new Date();
                    this.signDocumentList = list;
                    this.lastListLoadTime = list.time;
                    return this.signDocumentList;
                })
            )
            .catch(
                action((err) => {
                    // this.isDocUploading = false;
                    throw err;
                })
            );
    }

    uploadDoc(data) {
        this.isDocUploading = true;
        return agent.Signature.uploadDocs(data)
            .then(
                action((doc) => {
                    this.isDocUploading = false;
                    this.documentList = doc.sign_document.data;
                    this.caseName = doc.sign_document.name;
                    return doc;
                })
            )
            .catch(
                action((err) => {
                    this.isDocUploading = false;
                    throw err;
                })
            );
    }

    openFullScreenMode() {
        this.isFullScreen = true;
    }
    closeFullScreenMode() {
        this.isFullScreen = false;
    }
    removeDoc(data) {
        return agent.Signature.deleteDocs(data)
            .then(
                action((res) => {
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    deleteDocPage(id) {
        return agent.Signature.deleteDocPage(id)
            .then(
                action((res) => {
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }
    fetchAll(data, page = 'Upload') {
        if (page === 'Prepare Document') {
            this.isDocFetching = true;
        }
        return agent.Signature.fetchAllDocs(data)
            .then(
                action((res) => {
                    if (res.document_pages && res.document_pages !== undefined) {
                        this.documentPages = res.document_pages;
                    }
                    this.documentList = res.sign_document.data;
                    this.caseName = res.sign_document.name;
                    this.isDocFetching = false;
                    return res;
                })
            )
            .catch(
                action((err) => {
                    this.isDocFetching = false;
                    throw err;
                })
            );
    }

    setCaseId(id) {
        this.caseId = id;
    }
    createCase(data) {
        return agent.Signature.createCaseID(data)
            .then(
                action((doc) => {
                    this.caseId = doc.sign_document.id;
                    sessionStorage.setItem('CaseId', this.caseId);
                    return doc;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    updateDocName(docInfo) {
        return agent.Signature.updateDocName(docInfo)
            .then(
                action((res) => {
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }
    resetDocList() {
        this.documentList = null;
        this.documentPages = null;
        this.caseId = null;
    }
    resetDocPages() {
        this.documentPages = null;
    }
    sendDocNow(data) {
        return agent.Signature.sendDocNow(data)
            .then(
                action((res) => {
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    getRecipientDoc(id) {
        return agent.Signature.getRecipientDoc(id)
            .then(
                action((res) => {
                    if (res && res.client)
                        this.dateFormat = res.client.data.dateTimeRules;
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    getByDocCaseId(id) {
        this.isDocFinished = true;
        return agent.Signature.getByDocCaseId(id).then(
            action((res) => {
                this.isDocFinished = false;
                return res;
            })
        )
            .catch(
                action((err) => {
                    this.isDocFinished = false;
                    throw err;
                })
            )
    }
    verifyAccessCode(data) {
        return agent.Signature.verifyAccessCode(data)
            .then(
                action((res) => {
                    this.dateFormat = res.client.data.dateTimeRules
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    storeSignature(data) {
        this.signatureLoading = true;
        return agent.Signature.storeSignature(data)
            .then(
                action((res) => {
                    this.signatureLoading = false
                    return res;
                })
            )
            .catch(
                action((err) => {
                    this.signatureLoading = false
                    throw err;
                })
            );
    }
    generateSignatureLink(id) {
        return agent.Signature.generateSignatureLink(id).then(
            action((res) => {
                return res;
            })
        )
            .catch(
                action((err) => {

                    throw err;
                })
            )
    }

    downloadCompletedDocument(id) {
        return agent.Signature.downloadCompletedDocument(id).then(
            action((res) => {
                return res;
            })
        )
            .catch(
                action((err) => {

                    throw err;
                })
            )
    }


    downloadPdf(id) {
        return agent.Signature.downloadPdf(id)
            .then(
                action((res) => {
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }

    async removeCase(case_id) {
        await agent.Signature.deleteSignatureDoc(case_id)
            .then(
                action((res) => {
                    return res;
                })
            )
            .catch(
                action((err) => {
                    throw err;
                })
            );
    }
}

const _signatureStore = new signatureStore();
export default _signatureStore;
