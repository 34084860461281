import React from 'react';
import { inject } from 'mobx-react';

//components
import withLocalization from '~/hoc/withLocalization';
import applicationRouter from '~/hoc/applicationRouter'
import GenericList from '~/components/GenericList/GenericList';

//utils

//elements
import Button from '../../../components/CustomButton/CustomButton.jsx';
import { timeBankStatusMap } from '../../../utils/normalStatuses.js';

//assets
import { timeFormat, totalTimeFormat } from '../../../utils/dateFormat.js';

const TimeBankAddList = ({ t, timeBankStore, userDetails, userStore, commonStore, addTimelogId }) => {
    const isMember = userStore?.currentUser?.user_type === 'member';
    const dateTimeRules = commonStore?.config?.client?.data?.dateTimeRules
        ? commonStore.config.client.data.dateTimeRules : false;

    const handleTableButtonAction = (id, action) => {
        if (action === 'add') {
            addTimelogId(id);
        }
    }


    const renderTableActionButtons = (id, entity) => {
        let statusText = entity?.status;
        if (!statusText) statusText = 'active';
        statusText = timeBankStatusMap[statusText] || '';
        return (
            <div className='actions-center actions-center__center'>
                <div className={`status-block status-block-height35 status-${entity.status || 'active'}`}>
                    <div className='text-capitalize'>
                        <div>{t(statusText)}</div>
                    </div>
                </div>
                <Button fill wd onClick={() => handleTableButtonAction(id, 'add')}>
                    {entity.time_bank_request_id ? t('Update Hours') : t('Save Hours')}
                </Button>
            </div>
        )
    }

    return (
        <div className={'h-100 mt-2'}>
            <GenericList
                columns={[
                    !isMember && {
                        Header: 'Username',
                        id: 'full_name',
                        accessor: ({ full_name }) => <span>{full_name}</span>,
                    },
                    {
                        Header: 'Project',
                        id: 'project_name',
                        accessor: ({ project_name }) => <span>{project_name}</span>,
                    },
                    {
                        Header: 'Task',
                        id: 'task_name',
                        accessor: ({ task_name }) => <span>{task_name}</span>,
                    },
                    {
                        Header: 'Date',
                        id: 'timelog_date',
                        accessor: ({ timelog_date }) => <span>{timelog_date}</span>,
                    },
                    {
                        Header: 'Time',
                        id: 'time',
                        accessor: ({ from, to }) => <span>{timeFormat(from, dateTimeRules)} - {timeFormat(to, dateTimeRules)}</span>,
                    },
                    {
                        Header: 'Total Hours',
                        id: 'time_hours',
                        accessor: ({ total_hours_normal }) => <span>{totalTimeFormat(total_hours_normal, dateTimeRules)}</span>,
                    },
                    {
                        Header: 'Actions',
                        id: 'actions',
                        minWidth: 230,
                        accessor: (entity) => renderTableActionButtons(entity.id, entity),
                    },
                ]}
                filters={timeBankStore.appliedFilters}
                requestData={(params) => timeBankStore.getPendingTimelogs(params)}
            >
            </GenericList>
        </div>
    );
};

export default inject('commonStore', 'userStore', 'timeBankStore')(applicationRouter(withLocalization(TimeBankAddList)));