import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import moment from 'moment/min/moment-with-locales';
import { getDay } from 'date-fns';

//agent
import agent from '~/library/agent';

//components
import withLocalization from '~/hoc/withLocalization';
import Button from '~/components/CustomButton/CustomButton.jsx';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { MembersWidgetMultipleSelect } from '../../../components/GenericForm/Widgets/UsersWidget';

//elements
import { Textarea } from '../../../elements/Input';

//utils
import { longDateFormat } from '../../../utils/dateFormat';

//assets
import CloseIcon from "~/assets/img/modal-close-icon.svg";

class CheckAvailabilityForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_date: moment(new Date(props.viewModel.startDate)).format('YYYY-MM-DD'),
            end_date: moment(new Date(props.viewModel.endDate)).format('YYYY-MM-DD'),
            assignees: [],
            comment: ''
        }
    }


    async handleCancel(action) {
        this.props.finish(action);
    }


    handleChange = (name, data) => {
        this.setState({ [name]: data });
        if (name === 'end_date') {
            this.setState({
                end_date: moment(this.state.start_date).add(6, 'days').format('YYYY-MM-DD')
            })
        }
    }

    async handleSave() {
        const { t, commonStore } = this.props;
        let payload = {
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            assignees: this.state.assignees.includes(',') ? this.state.assignees.split(',') : [`${this.state.assignees}`],
            comment: this.state.comment,
        }
        await agent.Schedule.sendNotify(payload).then(() => {
            commonStore.addNotification(t('Saved'), null, 'success')
            this.handleCancel('cancel')
        }).catch((err) => {
            commonStore.addNotification(err.message || t('Error'), null, 'error');
        });
    }

    render() {
        const { t, commonStore } = this.props
        const dateTimeRules = commonStore.config.client && commonStore.config.client.data
            && commonStore.config.client.data.dateTimeRules ? commonStore.config.client.data.dateTimeRules : false;

        return (
            <Modal dialogClassName="bulk-shift-modal" size='xl' show>
                <Modal.Body>
                    <div className="cmnFormHead">
                        <div className="cmnFormHeadLeft">
                            <button className="cmnFormCloseBtn" onClick={() => this.handleCancel('cancel')}>
                                <img src={CloseIcon} alt="close button"></img>
                            </button>
                            <div className="cmnFormTxt">{this.props.t('Create / edit availability')}</div>
                        </div>
                    </div>
                    <div className='pe-4 ps-4'>
                        <div className="d-flex justify-content-between mt-5 mb-3">
                            <div className="flex-grow-1 me-4">
                                <MembersWidgetMultipleSelect
                                    className="form-control"
                                    placeholder={t('Select User To check Availability')}
                                    onChange={(value) => { this.setState({ assignees: value }) }}
                                    value={'all'}
                                    defaultIfOne
                                />
                            </div>
                            <div className="me-4">
                                <DatePicker
                                    selected={new Date(this.state.start_date)}
                                    placeholder={t('From date')}
                                    onChange={(date) => this.handleChange('start_date', moment(date).format('YYYY-MM-DD'))}
                                    dateFormat={longDateFormat(dateTimeRules)}
                                    filterDate={date => getDay(date) === 1}
                                />

                            </div>
                            <div className="">
                                <DatePicker
                                    selected={new Date(this.state.end_date)}
                                    placeholder={t('To date')}
                                    onChange={(date) => this.handleChange('end_date', moment(date).format('YYYY-MM-DD'))}
                                    dateFormat={longDateFormat(dateTimeRules)}
                                    filterDate={date => getDay(date) === 0}
                                />

                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <label className="control-label fs15">{t('Comment')}</label>
                                <Textarea
                                    value={this.state.comment}
                                    onChange={(data) => this.handleChange('comment', data.target.value)}
                                    placeholder={t('Enter comments here')}
                                />
                            </div>
                        </div>
                        <div className='d-flex justify-content-end mt-5 mb-3'>
                            <div className='cmnFormActBtn'>
                                <Button btn_reject nb onClick={() => this.handleCancel('cancel')}>
                                    {t('Close')}
                                </Button>
                                <Button fill nb onClick={values => this.handleSave('save', values)}>
                                    {t('Send Availability Request')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}
export default inject('commonStore')(withLocalization(observer(CheckAvailabilityForm)));