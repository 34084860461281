import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import SwitchWidget from '../SwitchWidget';
import { DaysOfWeekWidget } from '../../../../components/GenericForm/Widgets/DaysOfWeekWidget';

//elements
import { NumberInput } from '../../../../elements/Input';


class VacationRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            standared_vacation_days: 10,
            default_holidays_for_vacation: '5.6',
            enable_default_holidays_for_absence: false,
        }
        this.loadData = this.loadData.bind(this);
        this.handleUserDataChange = this.handleUserDataChange.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.Vacation_setup) {
            const Vacation_setup = Object.assign({}, currentEntity.client.data.Vacation_setup);
            this.setState({
                standared_vacation_days: Vacation_setup.standared_vacation_days,
                default_holidays_for_vacation: Vacation_setup.default_holidays_for_vacation,
                enable_default_holidays_for_absence: Vacation_setup.enable_default_holidays_for_absence,
            })
        }
    }

    handleUserDataChange = (name, value) => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data.Vacation_setup) {
            currentEntity.client.data.Vacation_setup[name] = value;
        } else {
            let Vacation_setup = {};
            Vacation_setup[name] = value;
            currentEntity.client.data.Vacation_setup = Vacation_setup;
        }
    }

    handleDayChanges = (days) => {
        const { commonStore, t } = this.props;
        if (!days) {
            commonStore.addNotification(t('All weekdays will be considered while calculating vacations'), null, 'warning');
            this.setState({ default_holidays_for_vacation: days, enable_default_holidays_for_absence: false },
                () => {
                    this.handleUserDataChange('default_holidays_for_vacation', days);
                    this.handleUserDataChange('enable_default_holidays_for_absence', false);
                });
        }
        else
            this.setState({ default_holidays_for_vacation: days },
                () => this.handleUserDataChange('default_holidays_for_vacation', days))
    }

    render() {
        const { standared_vacation_days, default_holidays_for_vacation, enable_default_holidays_for_absence } = this.state;
        const { t } = this.props;
        return (
            <>
                <Row className='mt-2'>
                    <Col sm={4}>
                        <NumberInput
                            label={`${t('Standard vacation days')}`}
                            name="standared_vacation_days"
                            value={standared_vacation_days}
                            onChange={evt => {
                                const days = parseInt(evt.target.value, 10)
                                this.setState({ standared_vacation_days: days },
                                    () => this.handleUserDataChange('standared_vacation_days', days))
                            }}
                        />
                    </Col>
                </Row>
                <Row className='my-4'>
                    <label className="control-label">{t('Default holidays')}</label>
                    <DaysOfWeekWidget
                        value={default_holidays_for_vacation}
                        onChange={this.handleDayChanges}
                    />
                </Row>
                <Row className="mb-2">
                    <Col className="mt-1" sm={1}>
                        <SwitchWidget
                            value={enable_default_holidays_for_absence}
                            disabled={!default_holidays_for_vacation}
                            onChange={checked => {
                                this.setState({ enable_default_holidays_for_absence: checked },
                                    () => this.handleUserDataChange('enable_default_holidays_for_absence', checked))
                            }}
                        />
                    </Col>
                    <Col sm={10}>
                        <label className="control-label">{t('Allow default holidays on Absence')}</label>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("If enabled the default holidays will not be considered while calculating the total absence days")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted ms-2" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
            </>
        );
    }
}
export default inject('clientStore', 'commonStore')(applicationRouter(withLocalization(observer(VacationRules))));
