import moment from 'moment/min/moment-with-locales';
import { RRuleSet, rrulestr } from 'rrule';

import config from './config';
import behaviors from './behaviors';

import { ViewTypes, CellUnits, DATE_FORMAT, DATETIME_FORMAT } from './index';
const ROWHEIGHT = 0;

export default class SchedulerData {
    constructor(
        date = moment().format(DATE_FORMAT),
        viewType = ViewTypes.Week,
        isEventPerspective = false,
        newConfig = undefined,
        newBehaviors = undefined,
        localeMoment = undefined,
        currentView = 0,
        userGroups,
        roles,
    ) {
        this.resources = [];
        this.events = [];
        this.eventGroups = [];
        this.eventGroupsAutoGenerated = true;
        this.viewType = viewType;
        this.cellUnit = viewType === ViewTypes.Day ? CellUnits.Hour : CellUnits.Day;
        this.isEventPerspective = isEventPerspective;
        this.resizing = false;
        this.scrollToSpecialMoment = false;
        this.documentWidth = 0;
        this.availability = []
        this.localeMoment = moment;
        if (localeMoment) this.localeMoment = localeMoment;
        this.config = newConfig == undefined ? config : { ...config, ...newConfig };
        this._validateMinuteStep(this.config.minuteStep);
        this.behaviors = newBehaviors == undefined ? behaviors : { ...behaviors, ...newBehaviors };
        this._resolveDate(0, date);
        this._createHeaders();
        this._createRenderData();
        this.currentView = currentView;
        this.totalsRoleWise = {};
        this.userGroups = userGroups;
        this.roles = roles
    }

    setLocaleMoment(localeMoment) {
        if (localeMoment) {
            this.localeMoment = localeMoment;
            this._createHeaders();
            this._createRenderData();
        }
    }

    setResources(resources) {
        this._validateResource(resources);
        this.resources = Array.from(new Set(resources));
        this._createRenderData();
        this.setScrollToSpecialMoment(true);
    }

    setEventGroupsAutoGenerated(autoGenerated) {
        this.eventGroupsAutoGenerated = autoGenerated;
    }

    setRoleView(currentView) {
        this.currentView = currentView;
    }

    // optional
    setEventGroups(eventGroups) {
        this._validateEventGroups(eventGroups);
        this.eventGroups = Array.from(new Set(eventGroups));
        this.eventGroupsAutoGenerated = false;
        this._createRenderData();
        this.setScrollToSpecialMoment(true);
    }

    setMinuteStep(minuteStep) {
        if (this.config.minuteStep !== minuteStep) {
            this._validateMinuteStep(minuteStep);
            this.config.minuteStep = minuteStep;
            this._createHeaders();
            this._createRenderData();
        }
    }

    setBesidesWidth(besidesWidth) {
        if (besidesWidth >= 0) {
            this.config.besidesWidth = besidesWidth;
        }
    }

    getMinuteStepsInHour() {
        return 60 / this.config.minuteStep;
    }

    addResource(resource) {
        const existedResources = this.resources.filter((x) => x.id === resource.id);
        if (existedResources.length === 0) {
            this.resources.push(resource);
            this._createRenderData();
        }
    }

    addEventGroup(eventGroup) {
        const existedEventGroups = this.eventGroups.filter((x) => x.id === eventGroup.id);
        if (existedEventGroups.length === 0) {
            this.eventGroups.push(eventGroup);
            this._createRenderData();
        }
    }

    removeEventGroupById(eventGroupId) {
        let index = -1;
        this.eventGroups.forEach((item, idx) => {
            if (item.id === eventGroupId) index = idx;
        });
        if (index !== -1) this.eventGroups.splice(index, 1);
    }

    containsEventGroupId(eventGroupId) {
        let index = -1;
        this.eventGroups.forEach((item, idx) => {
            if (item.id === eventGroupId) index = idx;
        });
        return index !== -1;
    }

    setEvents(events) {
        this._validateEvents(events);
        this.events = Array.from(events);
        this._createRenderData();
    }

    setAvailability(availability) {
        this.availability = Array.from(availability);
        if (this.eventGroupsAutoGenerated) this._generateEventGroups();
        if (this.config.recurringEventsEnabled) this._handleRecurringEvents();
        this._createRenderData();
    }

    setScrollToSpecialMoment(scrollToSpecialMoment) {
        if (this.config.scrollToSpecialMomentEnabled) this.scrollToSpecialMoment = scrollToSpecialMoment;
    }

    prev() {
        this._resolveDate(-1);
        this.events = [];
        this.availability = [];
        this._createHeaders();
        this._createRenderData();
    }

    next() {
        this._resolveDate(1);
        this.events = [];
        this.availability = [];
        this._createHeaders();
        this._createRenderData();
    }

    setDate(date = moment().format(DATE_FORMAT)) {
        this._resolveDate(0, date);
        this.events = [];
        this.availability = [];
        this._createHeaders();
        this._createRenderData();
    }

    setViewType(viewType = ViewTypes.Week, isEventPerspective = false) {
        this.isEventPerspective = isEventPerspective;
        this.cellUnit = CellUnits.Day;

        if (this.viewType !== viewType) {
            let date = this.startDate;
            if (viewType === ViewTypes.Custom || viewType === ViewTypes.Custom1 || viewType === ViewTypes.Custom2) {
                this.viewType = viewType;
                this._resolveDate(0, date);
            } else {
                if (this.viewType < viewType) {
                    if (viewType === ViewTypes.Week) {
                        this.startDate = this.localeMoment(date).startOf('week').format(DATE_FORMAT);
                        this.endDate = this.localeMoment(this.startDate).endOf('week').format(DATE_FORMAT);
                    } else if (viewType === ViewTypes.Month) {
                        this.startDate = this.localeMoment(date).startOf('month').format(DATE_FORMAT);
                        this.endDate = this.localeMoment(this.startDate).endOf('month').format(DATE_FORMAT);
                    } else if (viewType === ViewTypes.Quarter) {
                        this.startDate = this.localeMoment(date).startOf('quarter').format(DATE_FORMAT);
                        this.endDate = this.localeMoment(this.startDate).endOf('quarter').format(DATE_FORMAT);
                    } else if (viewType === ViewTypes.Year) {
                        this.startDate = this.localeMoment(date).startOf('year').format(DATE_FORMAT);
                        this.endDate = this.localeMoment(this.startDate).endOf('year').format(DATE_FORMAT);
                    }
                } else {
                    const start = this.localeMoment(this.startDate);
                    const end = this.localeMoment(this.endDate).add(1, 'days');

                    if (this.selectDate !== undefined) {
                        const selectDate = this.localeMoment(this.selectDate);
                        if (selectDate >= start && selectDate < end) {
                            date = this.selectDate;
                        }
                    }

                    const now = this.localeMoment();
                    if (now >= start && now < end) {
                        date = now.format(DATE_FORMAT);
                    }

                    if (viewType === ViewTypes.Day) {
                        this.startDate = date;
                        this.endDate = this.startDate;
                        this.cellUnit = CellUnits.Hour;
                    } else if (viewType === ViewTypes.Week) {
                        this.startDate = this.localeMoment(date).startOf('week').format(DATE_FORMAT);
                        this.endDate = this.localeMoment(this.startDate).endOf('week').format(DATE_FORMAT);
                    } else if (viewType === ViewTypes.Month) {
                        this.startDate = this.localeMoment(date).startOf('month').format(DATE_FORMAT);
                        this.endDate = this.localeMoment(this.startDate).endOf('month').format(DATE_FORMAT);
                    } else if (viewType === ViewTypes.Quarter) {
                        this.startDate = this.localeMoment(date).startOf('quarter').format(DATE_FORMAT);
                        this.endDate = this.localeMoment(this.startDate).endOf('quarter').format(DATE_FORMAT);
                    }
                }

                this.viewType = viewType;
            }

            this.events = [];
            this.availability = [];
            this._createHeaders();
            this._createRenderData();
            this.setScrollToSpecialMoment(true);
        }
    }

    setSchedulerMaxHeight(newSchedulerMaxHeight) {
        this.config.schedulerMaxHeight = newSchedulerMaxHeight;
    }

    isSchedulerResponsive() {
        return !!this.config.schedulerWidth.endsWith && this.config.schedulerWidth.endsWith('%');
    }

    isResourceViewResponsive() {
        const resourceTableWidth = this.getResourceTableConfigWidth();
        return !!resourceTableWidth.endsWith && resourceTableWidth.endsWith('%');
    }

    isContentViewResponsive() {
        const contentCellWidth = this.getContentCellConfigWidth();
        return !!contentCellWidth.endsWith && contentCellWidth.endsWith('%');
    }

    getSchedulerWidth() {
        const baseWidth = this.documentWidth - this.config.besidesWidth > 0 ? this.documentWidth - this.config.besidesWidth : 0;
        return this.isSchedulerResponsive()
            ? parseInt((baseWidth * Number(this.config.schedulerWidth.slice(0, -1))) / 100)
            : this.config.schedulerWidth;
    }

    getResourceTableWidth() {
        const resourceTableConfigWidth = this.getResourceTableConfigWidth();
        const schedulerWidth = this.getSchedulerWidth();
        let resourceTableWidth = this.isResourceViewResponsive()
            ? parseInt((schedulerWidth * Number(resourceTableConfigWidth.slice(0, -1))) / 100)
            : resourceTableConfigWidth;
        if (this.isSchedulerResponsive() && this.getContentTableWidth() + resourceTableWidth < schedulerWidth)
            resourceTableWidth = schedulerWidth - this.getContentTableWidth();
        return resourceTableWidth;
    }

    getContentCellWidth() {
        const contentCellConfigWidth = this.getContentCellConfigWidth();
        const schedulerWidth = this.getSchedulerWidth();
        return this.isContentViewResponsive()
            ? parseInt((schedulerWidth * Number(contentCellConfigWidth.slice(0, -1))) / 100)
            : contentCellConfigWidth;
    }

    getContentTableWidth() {
        return this.headers.length * this.getContentCellWidth();
    }

    getScrollToSpecialMoment() {
        if (this.config.scrollToSpecialMomentEnabled) return this.scrollToSpecialMoment;
        return false;
    }

    getSlots() {
        return this.isEventPerspective ? this.eventGroups : this.resources;
    }

    getSlotById(slotId) {
        const slots = this.getSlots();
        let slot;
        slots.forEach((item) => {
            if (item.id === slotId) slot = item;
        });
        return slot;
    }

    getResourceById(resourceId) {
        let resource;
        this.resources.forEach((item) => {
            if (item.id === resourceId) resource = item;
        });
        return resource;
    }

    getTableHeaderHeight(currentView) {
        return !!currentView ? this.config.tableHeaderHeightRoleView : this.config.tableHeaderHeight;
    }

    getSchedulerContentDesiredHeight(roleHeight = 0) {
        let height = 0;
        this.renderData.forEach((item) => {
            if (item.render) height += (item.rowHeight + roleHeight);
        });
        return height;
    }

    getCellMaxEvents() {
        return this.viewType === ViewTypes.Week
            ? this.config.weekMaxEvents
            : this.viewType === ViewTypes.Day
                ? this.config.dayMaxEvents
                : this.viewType === ViewTypes.Month
                    ? this.config.monthMaxEvents
                    : this.viewType === ViewTypes.Year
                        ? this.config.yearMaxEvents
                        : this.viewType === ViewTypes.Quarter
                            ? this.config.quarterMaxEvents
                            : this.config.customMaxEvents;
    }

    getDateLabel(customViewType) {
        const start = moment(new Date(this.startDate));
        const end = moment(new Date(this.endDate));
        let dateLabel = start.format('LL');
        this.viewType = customViewType;

        if (customViewType === 2) {
            this.startDate = this.localeMoment(this.startDate).startOf('month').format(DATE_FORMAT);
            this.endDate = this.localeMoment(this.startDate).endOf('month').format(DATE_FORMAT);
        }

        if (start != end) dateLabel = `${start.format('LL')} - ${end.format('LL')}`;

        if (this.behaviors.getDateLabelFunc)
            dateLabel = this.behaviors.getDateLabelFunc(this, this.viewType, this.startDate, this.endDate);

        return dateLabel;
    }

    getDateWeek(addWeek) {
        const start = moment(new Date(this.startDate));
        let dateLabel = "";
        if (addWeek) {
            dateLabel += ` ${moment.locale() === 'nb' ? 'U' : 'Week '}${start.format('w')}`;
        }
        return dateLabel;
    }

    addEvent(newEvent) {
        this._attachEvent(newEvent);
        if (this.eventGroupsAutoGenerated) this._generateEventGroups();
        this._createRenderData();
    }

    updateEventStart(event, newStart) {
        this._detachEvent(event);
        event.start = newStart;
        this._attachEvent(event);
        this._createRenderData();
    }

    updateEventEnd(event, newEnd) {
        event.end = newEnd;
        this._createRenderData();
    }

    moveEvent(event, newSlotId, newSlotName, newStart, newEnd) {
        this._detachEvent(event);
        if (this.isEventPerspective) {
            event.groupId = newSlotId;
            event.groupName = newSlotName;
        } else event.resourceId = newSlotId;
        event.end = newEnd;
        event.start = newStart;
        this._attachEvent(event);
        this._createRenderData();
    }

    isEventInTimeWindow(eventStart, eventEnd, windowStart, windowEnd) {
        return eventStart < windowEnd && eventEnd > windowStart;
    }

    removeEvent(event) {
        const index = this.events.indexOf(event);
        if (index !== -1) {
            this.events.splice(index, 1);
            this._createRenderData();
        }
    }

    removeEventById(eventId) {
        let index = -1;
        this.events.forEach((item, idx) => {
            if (item.id === eventId) index = idx;
        });
        if (index !== -1) {
            this.events.splice(index, 1);
            this._createRenderData();
        }
    }

    getResourceTableConfigWidth() {
        return this.viewType === ViewTypes.Week
            ? this.config.weekResourceTableWidth
            : this.viewType === ViewTypes.Day
                ? this.config.dayResourceTableWidth
                : this.viewType === ViewTypes.Month
                    ? this.config.monthResourceTableWidth
                    : this.viewType === ViewTypes.Year
                        ? this.config.yearResourceTableWidth
                        : this.viewType === ViewTypes.Quarter
                            ? this.config.quarterResourceTableWidth
                            : this.config.customResourceTableWidth;
    }

    getContentCellConfigWidth() {
        return this.viewType === ViewTypes.Week
            ? this.config.weekCellWidth
            : this.viewType === ViewTypes.Day
                ? this.config.dayCellWidth
                : this.viewType === ViewTypes.Month
                    ? this.config.monthCellWidth
                    : this.viewType === ViewTypes.Year
                        ? this.config.yearCellWidth
                        : this.viewType === ViewTypes.Quarter
                            ? this.config.quarterCellWidth
                            : this.config.customCellWidth;
    }

    _setDocumentWidth(documentWidth) {
        if (documentWidth >= 0) {
            this.documentWidth = documentWidth;
        }
    }

    _detachEvent(event) {
        const index = this.events.indexOf(event);
        if (index !== -1) this.events.splice(index, 1);
    }

    _attachEvent(event) {
        let pos = 0;
        const eventStart = this.localeMoment(event.start);
        this.events.forEach((item, index) => {
            const start = this.localeMoment(item.start);
            if (eventStart >= start) pos = index + 1;
        });
        this.events.splice(pos, 0, event);
    }

    _handleRecurringEvents() {
        const recurringEvents = this.events.filter((x) => !!x.rrule);
        recurringEvents.forEach((item) => {
            this._detachEvent(item);
        });

        recurringEvents.forEach((item) => {
            const windowStart = this.localeMoment(this.startDate);
            const windowEnd = this.localeMoment(this.endDate).add(1, 'days');
            const oldStart = this.localeMoment(item.start);
            const oldEnd = this.localeMoment(item.end);
            let rule = rrulestr(item.rrule);
            let oldDtstart;
            if (rule.origOptions.dtstart) {
                oldDtstart = this.localeMoment(rule.origOptions.dtstart);
            }
            // rule.origOptions.dtstart = oldStart.toDate();
            if (!rule.origOptions.until || windowEnd < this.localeMoment(rule.origOptions.until)) {
                rule.origOptions.until = windowEnd.toDate();
            }

            // reload
            rule = rrulestr(rule.toString());
            if (item.exdates || item.exrule) {
                const rruleSet = new RRuleSet();
                rruleSet.rrule(rule);
                if (item.exrule) {
                    rruleSet.exrule(rrulestr(item.exrule));
                }
                if (item.exdates) {
                    item.exdates.forEach((exdate) => {
                        rruleSet.exdate(this.localeMoment(exdate).toDate());
                    });
                }
                rule = rruleSet;
            }

            const all = rule.all();
            const newEvents = all.map((time, index) => {
                return {
                    ...item,
                    recurringEventId: item.id,
                    recurringEventStart: item.start,
                    recurringEventEnd: item.end,
                    id: `${item.id}-${index}`,
                    start: rule.origOptions.tzid
                        ? this.localeMoment
                            .utc(time)
                            .utcOffset(this.localeMoment().utcOffset(), true)
                            .format(DATETIME_FORMAT)
                        : this.localeMoment(time).format(DATETIME_FORMAT),
                    end: rule.origOptions.tzid
                        ? this.localeMoment
                            .utc(time)
                            .utcOffset(this.localeMoment().utcOffset(), true)
                            .add(oldEnd.diff(oldStart), 'ms')
                            .format(DATETIME_FORMAT)
                        : this.localeMoment(time).add(oldEnd.diff(oldStart), 'ms').format(DATETIME_FORMAT),
                };
            });
            newEvents.forEach((newEvent) => {
                const eventStart = this.localeMoment(newEvent.start);
                const eventEnd = this.localeMoment(newEvent.end);
                if (
                    this.isEventInTimeWindow(eventStart, eventEnd, windowStart, windowEnd) &&
                    (!oldDtstart || eventStart >= oldDtstart)
                ) {
                    this._attachEvent(newEvent);
                }
            });
        });
    }

    _resolveDate(num, date = undefined) {
        if (date != undefined) this.selectDate = this.localeMoment(date).format(DATE_FORMAT);

        if (this.viewType === ViewTypes.Week) {
            this.startDate =
                date != undefined
                    ? this.localeMoment(date).startOf('week').format(DATE_FORMAT)
                    : this.localeMoment(this.startDate).add(num, 'weeks').format(DATE_FORMAT);
            this.endDate = this.localeMoment(this.startDate).endOf('week').format(DATE_FORMAT);
        } else if (this.viewType === ViewTypes.Day) {
            this.startDate =
                date != undefined
                    ? this.selectDate
                    : this.localeMoment(this.startDate).add(num, 'days').format(DATE_FORMAT);
            this.endDate = this.startDate;
        } else if (this.viewType === ViewTypes.Month) {
            this.startDate =
                date != undefined
                    ? this.localeMoment(date).startOf('month').format(DATE_FORMAT)
                    : this.localeMoment(this.startDate).add(num, 'months').format(DATE_FORMAT);
            this.endDate = this.localeMoment(this.startDate).endOf('month').format(DATE_FORMAT);
        } else if (this.viewType === ViewTypes.Quarter) {
            this.startDate =
                date != undefined
                    ? this.localeMoment(date).startOf('quarter').format(DATE_FORMAT)
                    : this.localeMoment(this.startDate).add(num, 'quarters').format(DATE_FORMAT);
            this.endDate = this.localeMoment(this.startDate).endOf('quarter').format(DATE_FORMAT);
        } else if (this.viewType === ViewTypes.Year) {
            this.startDate =
                date != undefined
                    ? this.localeMoment(date).startOf('year').format(DATE_FORMAT)
                    : this.localeMoment(this.startDate).add(num, 'years').format(DATE_FORMAT);
            this.endDate = this.localeMoment(this.startDate).endOf('year').format(DATE_FORMAT);
        } else if (
            this.viewType === ViewTypes.Custom ||
            this.viewType === ViewTypes.Custom1 ||
            this.viewType === ViewTypes.Custom2
        ) {
            if (this.behaviors.getCustomDateFunc != undefined) {
                const customDate = this.behaviors.getCustomDateFunc(this, num, date);
                this.startDate = customDate.startDate;
                this.endDate = customDate.endDate;
                if (customDate.cellUnit) this.cellUnit = customDate.cellUnit;
            } else {
                throw new Error(
                    'This is custom view type, set behaviors.getCustomDateFunc func to resolve the time window(startDate and endDate) yourself'
                );
            }
        }
    }

    _createHeaders() {
        const headers = [];
        let start = this.localeMoment(this.startDate);
        let end = this.localeMoment(this.endDate);
        let header = start;

        if (this.cellUnit === CellUnits.Hour) {
            start = start.add(this.config.dayStartFrom, 'hours');
            end = end.add(this.config.dayStopTo, 'hours');
            header = start;

            while (header >= start && header <= end) {
                const minuteSteps = this.getMinuteStepsInHour();
                for (let i = 0; i < minuteSteps; i++) {
                    const hour = header.hour();
                    if (hour >= this.config.dayStartFrom && hour <= this.config.dayStopTo) {
                        const time = header.format(DATETIME_FORMAT);
                        const nonWorkingTime = this.behaviors.isNonWorkingTimeFunc(this, time);
                        headers.push({ time, nonWorkingTime });
                    }

                    header = header.add(this.config.minuteStep, 'minutes');
                }
            }
        } else {
            while (header >= start && header <= end) {
                const time = header.format(DATETIME_FORMAT);
                const dayOfWeek = header.weekday();
                if (this.config.displayWeekend || (dayOfWeek !== 0 && dayOfWeek !== 6)) {
                    const nonWorkingTime = this.behaviors.isNonWorkingTimeFunc(this, time);
                    headers.push({ time, nonWorkingTime });
                }

                header = header.add(1, 'days');
            }
        }
        this.headers = headers;
    }

    _createInitHeaderEvents(header, availabilityData, resources, slot) {
        let availabilityDataV2 = []
        availabilityData && availabilityData.map(ele => {
            resources.map(element => {
                if (element.id == ele.user_id && element.id == slot.id) {
                    if (moment(header.time).format('YYYY-MM-DD') == moment(ele.for_date).format('YYYY-MM-DD')) {
                        availabilityDataV2.push(ele)
                    }
                }
            })
        })
        const start = this.localeMoment(header.time);
        const startValue = start.format(DATETIME_FORMAT);
        const endValue = this.cellUnit === CellUnits.Hour
            ? start.add(this.config.minuteStep, 'minutes').format(DATETIME_FORMAT)
            : start.add(1, 'days').format(DATETIME_FORMAT);
        return {
            time: header.time,
            nonWorkingTime: header.nonWorkingTime,
            start: startValue,
            end: endValue,
            count: 0,
            addMore: 0,
            addMoreIndex: 0,
            events: [, , ,],
            availability: availabilityDataV2,
        };
    }

    _createHeaderEvent(render, span, eventItem) {
        return {
            render,
            span,
            eventItem,
        };
    }

    _getEventSlotId(event, isGroupView = false) {
        if (isGroupView) {
            return event.resourceId ? event.userGroup : 0;
        }
        return this.isEventPerspective ? this._getEventGroupId(event) : event.resourceId === 'Pending' ? 0 : event.resourceId;
    }

    _getEventGroupId(event) {
        return event.groupId ? event.groupId.toString() : event.id.toString();
    }

    _getEventGroupName(event) {
        return event.groupName ? event.groupName : event.title;
    }

    _generateEventGroups() {
        const eventGroups = [];
        const set = new Set();
        this.events.forEach((item) => {
            const groupId = this._getEventGroupId(item);
            const groupName = this._getEventGroupName(item);

            if (!set.has(groupId)) {
                eventGroups.push({
                    id: groupId,
                    name: groupName,
                    state: item,
                });
                set.add(groupId);
            }
        });
        this.eventGroups = eventGroups;
    }

    _getTotalsRoleWise = (data) => {
        const totals = {};
        data.forEach(item => {
            const currentViewId = (this.currentView === 2) ? item.user_group : item.employee_type;
            if (!totals[currentViewId]) {
                totals[currentViewId] = {
                    total_normal_hours: 0,
                    total_cost: 0,
                    total_overtime_hours: 0,
                    total_ovetime_cost: 0
                };
            }
            totals[currentViewId].total_normal_hours += item.total_normal_hours;
            totals[currentViewId].total_cost += item.total_cost;
            totals[currentViewId].total_overtime_hours += item.total_overtime_hours;
            totals[currentViewId].total_ovetime_cost += item.total_ovetime_cost;
        });
        this.totalsRoleWise = totals;
    }

    _createInitRenderData(isEventPerspective, eventGroups, resources, headers, availabilityData) {
        const slots = isEventPerspective ? eventGroups : resources;
        let initRenderData = [];
        slots.forEach((slot) => {
            const headerEvents = headers.map((header) => {
                return this._createInitHeaderEvents(header, availabilityData, resources, slot);
            });
            const slotRenderData = {
                slotId: slot.id,//user_id,
                companyShiftId: slot.company_shift_id || null,// for other view use the defult company shift
                slotImage: slot.image,
                slotName: slot.name,
                slotType: slot.employee_type,
                parentId: slot.parentId,
                slotTotalHours: slot.total_time,
                slotLoggedIn: slot.is_user_logged_in,
                slotPrice: slot.fixed_hourly_rate,
                slotHasShift: slot.is_shift_available,
                slotStartTime: slot.start_time,
                slotUserGroups: slot.user_groups,
                slotEmergencyId: slot.emergency_id,
                slotEmergencyFromDate: slot.emergency_from_date,
                slotEmergencyToDate: slot.emergency_to_date,
                groupOnly: slot.groupOnly,
                hasSummary: false,
                rowMaxCount: 0,
                rowHeight:
                    this.config.nonAgendaSlotMinHeight !== 0
                        ? this.config.nonAgendaSlotMinHeight
                        : this.config.eventItemLineHeight,
                headerItems: headerEvents,
                indent: 0,
                hasChildren: false,
                expanded: true,
                render: true,
            };
            initRenderData.push(slotRenderData);
        });

        if (!!this.currentView && initRenderData.length) {
            const slotMap = new Map();
            const currentViewType = this.currentView === 1 ? this.roles : this.userGroups;

            currentViewType.forEach(_slotType => {
                const headerEvents = headers.map((header) => {
                    return this._createInitHeaderEvents(header, [], null, null);
                });
                const _slotIds = this.currentView === 1 ? initRenderData.filter(ele => ele.slotType === _slotType.code || (_slotType.code === 0 && ele.slotType === 'pending')).map(ele => ele.slotId) :
                    initRenderData.filter(ele => ele.slotUserGroups && ele.slotUserGroups.includes(_slotType.code)).map(ele => ele.slotId);
                const slotData = {
                    slotId: _slotType.code,//role_code
                    slotIds: _slotIds,
                    slotImage: null,
                    slotName: _slotType.name,
                    slotType: _slotType.code,
                    parentId: null,
                    slotTotalHours: 0,
                    slotLoggedIn: null,
                    slotPrice: 0,
                    slotHasShift: null,
                    slotStartTime: null,
                    groupOnly: null,
                    hasSummary: false,
                    rowMaxCount: 0,
                    rowHeight:
                        this.config.nonAgendaSlotMinHeight !== 0
                            ? this.config.nonAgendaSlotMinHeight
                            : this.config.eventItemLineHeight,
                    headerItems: headerEvents,
                    indent: 0,
                    hasChildren: false,
                    expanded: true,
                    render: true,
                    slotTotalDetails: this.totalsRoleWise[`${_slotType.code}`]
                }
                slotMap.set(`${_slotType.code}`, slotData);
            });
            const newArr = [...slotMap.values()]
            initRenderData = newArr;
        }
        return initRenderData;
    }

    _getSpan(startTime, endTime, headers) {
        const start = this.localeMoment(startTime);
        const end = this.localeMoment(endTime);
        let span = 0;
        for (const header of headers) {
            const spanStart = this.localeMoment(header.time);
            const spanEnd =
                this.cellUnit === CellUnits.Hour
                    ? this.localeMoment(header.time).add(this.config.minuteStep, 'minutes')
                    : this.localeMoment(header.time).add(1, 'days');

            if (spanStart < end && spanEnd > start) {
                span++;
            }
        }
        return span;
    }

    _validateResource(resources) {
        if (Object.prototype.toString.call(resources) !== '[object Array]') {
            throw new Error('Resources should be Array object');
        }

        resources.forEach((item, index) => {
            if (item == undefined) {
                console.error(`Resource undefined: ${index}`);
                throw new Error(`Resource undefined: ${index}`);
            }
            if (item.id == undefined || item.name == undefined) {
                console.error('Resource property missed', index, item);
                throw new Error(`Resource property undefined: ${index}`);
            }
        });
    }

    _validateEventGroups(eventGroups) {
        if (Object.prototype.toString.call(eventGroups) !== '[object Array]') {
            throw new Error('Event groups should be Array object');
        }

        eventGroups.forEach((item, index) => {
            if (item == undefined) {
                console.error(`Event group undefined: ${index}`);
                throw new Error(`Event group undefined: ${index}`);
            }
            if (item.id == undefined || item.name == undefined) {
                console.error('Event group property missed', index, item);
                throw new Error(`Event group property undefined: ${index}`);
            }
        });
    }

    _validateEvents(events) {
        if (Object.prototype.toString.call(events) !== '[object Array]') {
            throw new Error('Events should be Array object');
        }

        events.forEach((e, index) => {
            if (e == undefined) {
                console.error(`Event undefined: ${index}`);
                throw new Error(`Event undefined: ${index}`);
            }
            if (
                e.id == undefined ||
                e.resourceId == undefined ||
                e.title == undefined ||
                e.start == undefined ||
                e.end == undefined
            ) {
                console.error('Event property missed', index, e);
                throw new Error(`Event property undefined: ${index}`);
            }
        });
    }

    _validateMinuteStep(minuteStep) {
        if (60 % minuteStep !== 0) {
            console.error('Minute step is not set properly - 60 minutes must be divisible without remainder by this number');
            throw new Error('Minute step is not set properly - 60 minutes must be divisible without remainder by this number');
        }
    }

    _compare(event1, event2) {
        const start1 = this.localeMoment(event1.start);
        const start2 = this.localeMoment(event2.start);
        if (start1 !== start2) return start1 < start2 ? -1 : 1;

        const end1 = this.localeMoment(event1.end);
        const end2 = this.localeMoment(event2.end);
        if (end1 !== end2) return end1 < end2 ? -1 : 1;

        return event1.id < event2.id ? -1 : 1;
    }

    _createRenderData() {
        const initRenderData = this._createInitRenderData(
            this.isEventPerspective,
            this.eventGroups,
            this.resources,
            this.headers
        );
        const cellMaxEventsCount = this.getCellMaxEvents();
        const cellMaxEventsCountValue = 30;
        //availability will be visible only on list view//
        if (this.currentView === 0) {
            this.availability.forEach((item) => {
                let resourceEvents = initRenderData.find((x) => x.slotId === this._getEventSlotId(item));
                if (resourceEvents) {
                    const span = this._getSpan(item.for_date, item.for_date, this.headers);
                    const eventStart = this.localeMoment(item.for_date);
                    const eventEnd = this.localeMoment(item.for_date);
                    let pos = -1;
                    resourceEvents.headerItems.forEach((header, index) => {
                        const headerStart = this.localeMoment(header.start);
                        const headerEnd = this.localeMoment(header.end);
                        if (headerEnd > eventStart && headerStart < eventEnd) {
                            if (header.count > resourceEvents.rowMaxCount) {
                                resourceEvents.rowMaxCount = header.count;
                                const rowsCount =
                                    cellMaxEventsCount <= cellMaxEventsCountValue &&
                                        resourceEvents.rowMaxCount > cellMaxEventsCount
                                        ? cellMaxEventsCount
                                        : resourceEvents.rowMaxCount;
                                const newRowHeight = rowsCount * this.config.eventItemLineHeight +
                                    (this.config.creatable && this.config.checkConflict === false ? ROWHEIGHT : 2);
                                if (newRowHeight > resourceEvents.rowHeight) resourceEvents.rowHeight = newRowHeight;
                            }

                            if (pos === -1) {
                                let tmp = 0;
                                while (header.events[tmp] !== undefined) tmp++
                                pos = tmp;
                            }
                            let render = headerStart <= eventStart || index === 0;
                            if (render === false) {
                                const previousHeader = resourceEvents.headerItems[index - 1];
                                const previousHeaderStart = this.localeMoment(previousHeader.start);
                                const previousHeaderEnd = this.localeMoment(previousHeader.end);
                                if (previousHeaderEnd <= eventStart || previousHeaderStart >= eventEnd) render = true;
                            }
                            header.events[pos] = this._createHeaderEvent(render, span, item);
                        }
                    });
                }
            });
        }

        this.events.forEach((item) => {
            let resourceEvents = null;
            if (this.currentView === 1) {
                resourceEvents = initRenderData.find(x => x.slotIds.includes(this._getEventSlotId(item)))
            } else if (this.currentView === 2) {
                resourceEvents = initRenderData.find((x) => x.slotId === this._getEventSlotId(item, true));
            } else {
                resourceEvents = initRenderData.find((x) => x.slotId === this._getEventSlotId(item));
            }
            if (resourceEvents) {
                const span = this._getSpan(item.start, item.end, this.headers);
                const eventStart = this.localeMoment(item.start);
                const eventEnd = this.localeMoment(item.end);
                let pos = -1;

                resourceEvents.headerItems.forEach((header, index) => {
                    const headerStart = this.localeMoment(header.start);
                    const headerEnd = this.localeMoment(header.end);

                    if (headerEnd > eventStart && headerStart < eventEnd) {
                        header.count += 1;
                        if (header.count > resourceEvents.rowMaxCount) {
                            resourceEvents.rowMaxCount = header.count;
                            const rowsCount =
                                cellMaxEventsCount <= cellMaxEventsCountValue &&
                                    resourceEvents.rowMaxCount > cellMaxEventsCount
                                    ? cellMaxEventsCount
                                    : resourceEvents.rowMaxCount;
                            const newRowHeight =
                                rowsCount * this.config.eventItemLineHeight +
                                (this.config.creatable && this.config.checkConflict === false ? ROWHEIGHT : 2);
                            if (newRowHeight > resourceEvents.rowHeight) resourceEvents.rowHeight = newRowHeight;
                        }

                        if (pos === -1) {
                            let tmp = 0;
                            while (header.events[tmp] !== undefined) tmp++;
                            pos = tmp;
                        }
                        let render = headerStart <= eventStart || index === 0;
                        if (render === false) {
                            const previousHeader = resourceEvents.headerItems[index - 1];
                            const previousHeaderStart = this.localeMoment(previousHeader.start);
                            const previousHeaderEnd = this.localeMoment(previousHeader.end);
                            if (previousHeaderEnd <= eventStart || previousHeaderStart >= eventEnd) render = true;
                        }
                        header.events[pos] = this._createHeaderEvent(render, span, item);
                    }
                });
            }
        });

        //the following never going tp run//
        if (cellMaxEventsCount <= cellMaxEventsCountValue || this.behaviors.getSummaryFunc !== undefined) {
            initRenderData.forEach((resourceEvents) => {
                let hasSummary = false;
                resourceEvents.headerItems.forEach((headerItem) => {
                    if (cellMaxEventsCount <= cellMaxEventsCountValue) {
                        let renderItemsCount = 0;
                        let addMoreIndex = 0;
                        let index = 0;
                        while (index < cellMaxEventsCount - 1) {
                            if (headerItem.events[index] !== undefined) {
                                renderItemsCount++;
                                addMoreIndex = index + 1;
                            }
                            index++;
                        }

                        if (headerItem.events[index] !== undefined) {
                            if (renderItemsCount + 1 < headerItem.count) {
                                headerItem.addMore = headerItem.count - renderItemsCount;
                                headerItem.addMoreIndex = addMoreIndex;
                            }
                        } else if (renderItemsCount < headerItem.count) {
                            headerItem.addMore = headerItem.count - renderItemsCount;
                            headerItem.addMoreIndex = addMoreIndex;
                        }
                    }

                    if (this.behaviors.getSummaryFunc !== undefined) {
                        const events = [];
                        headerItem.events.forEach((e) => {
                            if (!!e && !!e.eventItem) events.push(e.eventItem);
                        });

                        headerItem.summary = this.behaviors.getSummaryFunc(
                            this,
                            events,
                            resourceEvents.slotId,
                            resourceEvents.slotName,
                            headerItem.start,
                            headerItem.end
                        );
                        if (!!headerItem.summary && headerItem.summary.text != undefined) hasSummary = true;
                    }
                });

                resourceEvents.hasSummary = hasSummary;
                if (hasSummary) {
                    const rowsCount =
                        cellMaxEventsCount <= cellMaxEventsCountValue && resourceEvents.rowMaxCount > cellMaxEventsCount
                            ? cellMaxEventsCount
                            : resourceEvents.rowMaxCount;
                    const newRowHeight =
                        (rowsCount + 1) * this.config.eventItemLineHeight +
                        (this.config.creatable && this.config.checkConflict === false ? ROWHEIGHT : 2);
                    if (newRowHeight > resourceEvents.rowHeight) resourceEvents.rowHeight = newRowHeight;
                }
            });
        }
        this.renderData = initRenderData;
    }

    _startResizing() {
        this.resizing = true;
    }

    _stopResizing() {
        this.resizing = false;
    }

    _isResizing() {
        return this.resizing;
    }
}
