import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import moment from 'moment';

import withLocalization from '../../../hoc/withLocalization';
import { getMinutesFromTimeInput, minutesToTimeInput } from '../../../utils/timeCalc';

import InputTime from './InputTime';

class ShiftsWidgetInternal extends Component {
    saveChange = (index, name, newValue) => {
        const { value, onChange } = this.props;
        const _value = value ? JSON.parse(value) : {};
        const row = _value[index];
        if (name === 'start_time' || name === 'end_time') {
            newValue = getMinutesFromTimeInput(newValue);
            row[name] = newValue;
            if (row.end_time && row.start_time) row.paid_hours = (row.end_time - row.start_time) / 60;
        } else {
            row[name] = newValue;
        }
        row.dirty = true;
        onChange(JSON.stringify(_value));
    };

    addRow = () => {
        const { value, onChange } = this.props;
        const _value = value ? JSON.parse(value) : {};
        let nextDate = moment(new Date()).format('YYYY-MM-DD');
        if (_value.length) {
            const prev = _value[_value.length - 1];
            nextDate = moment(new Date(prev.for_date)).add(1, 'day').format('YYYY-MM-DD');
            _value.push({
                for_date: nextDate,
                paid_hours: prev.paid_hours,
                start_time: prev.start_time,
                end_time: prev.end_time,
            });
        } else {
            _value.push({
                for_date: nextDate,
                paid_hours: 0,
                start_time: 0,
                end_time: 0,
            });
        }
        onChange(JSON.stringify(_value));
    };

    removeRow = index => {
        const { value, onChange } = this.props;
        const _value = value ? JSON.parse(value) : {};
        _value[index].deleted = true;
        onChange(JSON.stringify(_value));
    };

    renderSingleRow(row, index) {
        const { t } = this.props;
        return (
            <>
                <td>
                    <input
                        className="form-control"
                        label={t('Date')}
                        type="date"
                        value={row.for_date}
                        onChange={e => this.saveChange(index, 'for_date', e.target.value)}
                    />
                </td>
                <td>
                    <InputTime
                        value={row.start_time ? minutesToTimeInput(row.start_time, true) : '00:00'}
                        onChange={e => this.saveChange(index, 'start_time', e)}
                    />
                </td>
                <td>
                    <InputTime
                        value={row.end_time ? minutesToTimeInput(row.end_time, true) : '00:00'}
                        onChange={e => this.saveChange(index, 'end_time', e)}
                    />
                </td>
                <td>
                    <input
                        className="form-control "
                        type="number"
                        value={row.paid_hours}
                        onChange={e => this.saveChange(index, 'paid_hours', e.target.value)}
                    />
                </td>
                <td>
                    <Button className="" onClick={() => this.removeRow(index)}>
                        <i className="pe-7s-close" />
                    </Button>
                </td>
            </>
        );
    }

    render() {
        const { value, t } = this.props;
        const _value = value ? JSON.parse(value) : [];
        return (
            <div className="custom-form">
                <table className="table table-striped table-condensed table-hover">
                    <tbody>
                        <tr>
                            <th>{t('Date')}</th>
                            <th>{t('Start time')}</th>
                            <th>{t('End time')}</th>
                            <th>{t('Paid hours')}</th>
                            <th />
                        </tr>
                        {_value.map((row, index) => (
                            <>{!row.deleted && <tr key={`${index}`}>{this.renderSingleRow(row, index)}</tr>}</>
                        ))}
                    </tbody>
                </table>
                <Button className="btn" onClick={() => this.addRow()}>
                    <i className="pe-7s-plus" /> {t('Add shift')}
                </Button>
            </div>
        );
    }
}

export default inject('commonStore')(withLocalization(observer(ShiftsWidgetInternal)));