import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment/min/moment-with-locales';

//components
import withLocalization from '~/hoc/withLocalization';

//elements
import { NumberInput } from '../../../../elements/Input.js';
import Select from '../../../../elements/Select.js';
import SwitchWidget from '../SwitchWidget.js';
import DatePicker from '../../../../components/DatePicker/DatePicker.jsx';
import TableWidget from '../TableWidget.js';

const cycle_options = [
    { value: 'half_yearly', label: "Half Yearly", title: "Half Yearly" },
    { value: 'yearly', label: "Yearly", title: "Yearly" }
]

const choose_on_expiry_options = [
    { value: 'LOG_HOURS_INTO_TIMESHEET', label: "Log hours into the timesheet into the next cycle", title: "Log hours into the timesheet into the next cycle" }
]

class TimeBankSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeBankSettings: {
                allowTimeBank: false,
                cycle: 'yearly',
                cycleStartDate: null,
                cycleEndDate: null,
                maxHourLimitInCycle: 0,
                maxMinuteLimitInCycle: 0,
                allowNegativeTimeAccumulation: false,
                onCycleExpiry: 0,
                timeBankWageTypes: props.commonStore?.config?.integration_details?.product ? [
                    { code: 'payout', name: 'Payout', filter: 'at' },
                    { code: 'compansatory_off', name: 'Compansatory Off', filter: 'ab' },
                    { code: 'flexitime', name: 'Flexitime', filter: 'at' }
                ] : []
            },
        }
        this.translateLabels = this.translateLabels.bind(this);

    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        const { timeBankSettings } = this.state;
        if (currentEntity.client?.data?.basicRules?.timeBankSettings) {
            const _timeBankSettings = Object.assign({}, currentEntity.client.data.basicRules?.timeBankSettings);
            timeBankSettings.allowTimeBank = _timeBankSettings.allowTimeBank ? _timeBankSettings.allowTimeBank : false;
            timeBankSettings.cycle = _timeBankSettings.cycle ? _timeBankSettings.cycle : 'yearly';
            timeBankSettings.cycleStartDate = _timeBankSettings.cycleStartDate ? _timeBankSettings.cycleStartDate : false;
            timeBankSettings.cycleEndDate = _timeBankSettings.cycleEndDate ? _timeBankSettings.cycleEndDate : false;
            timeBankSettings.maxHourLimitInCycle = _timeBankSettings.maxHourLimitInCycle ? _timeBankSettings.maxHourLimitInCycle : 0;
            timeBankSettings.maxMinuteLimitInCycle = _timeBankSettings.maxMinuteLimitInCycle ? _timeBankSettings.maxMinuteLimitInCycle : 0;
            timeBankSettings.allowNegativeTimeAccumulation = _timeBankSettings.allowNegativeTimeAccumulation ? _timeBankSettings.allowNegativeTimeAccumulation : false;
            timeBankSettings.onCycleExpiry = _timeBankSettings.onCycleExpiry ? _timeBankSettings.onCycleExpiry : 'LOG_HOURS_INTO_TIMESHEET';
            timeBankSettings.timeBankWageTypes = _timeBankSettings?.timeBankWageTypes?.length ? _timeBankSettings.timeBankWageTypes : timeBankSettings.timeBankWageTypes;
        }
        this.prepareCycleDate(timeBankSettings.cycle, timeBankSettings)
        this.setState({ timeBankSettings });
    }

    prepareCycleDate = (evt, timeBankSettings) => {
        switch (evt) {
            case 'yearly':
                timeBankSettings['cycleStartDate'] = moment(new Date()).startOf('year').format('YYYY-MM-DD')
                timeBankSettings['cycleEndDate'] = moment(new Date()).endOf('year').format('YYYY-MM-DD')
                break;
            case 'half_yearly':
                const currentMonth = moment().month();
                if (currentMonth < 6) {
                    timeBankSettings['cycleStartDate'] = moment(new Date()).startOf('year').format('YYYY-MM-DD');
                    timeBankSettings['cycleEndDate'] = moment(new Date()).month(5).endOf('month').format('YYYY-MM-DD');
                } else {
                    timeBankSettings['cycleStartDate'] = moment(new Date()).month(7).startOf('month').format('YYYY-MM-DD');
                    timeBankSettings['cycleEndDate'] = moment(new Date()).endOf('year').format('YYYY-MM-DD');
                }
                break;
            default:
                console.info("No event found")
        }
    }

    validateFeilds = (name, value) => {
        if (name === 'maxHourLimitInCycle') {
            if ((value).toString().length > 3)
                return 'Max hours Limit is 999 hours';
        }
        if (name === 'maxMinuteLimitInCycle') {
            if (value > 60)
                return 'Max minute Limit is 59 minute';
        }
    }

    handleCheck = (name, evt) => {
        const { commonStore, t } = this.props;
        const { timeBankSettings } = this.state;
        const isNotValid = this.validateFeilds(name, evt);
        if (isNotValid)
            return commonStore.addNotification(t(isNotValid), null, 'error');
        if (name === 'cycle')
            this.prepareCycleDate(evt, timeBankSettings);
        timeBankSettings[name] = evt;
        this.props.handleUserDataChange('timeBankSettings', timeBankSettings);
        this.setState({ timeBankSettings });
    }

    handleTimeBankWageTypes = (data) => {
        this.handleCheck('timeBankWageTypes', data)
    }

    translateLabels = () => {
        const { t } = this.props;
        cycle_options.forEach(item => {
            item.label = t(item.title)
        });
        choose_on_expiry_options.forEach(item => {
            item.label = t(item.title)
        });
    }



    render() {
        const { timeBankSettings } = this.state;
        const { t, commonStore } = this.props;
        this.translateLabels();
        return (
            <div>
                <Row>
                    <Col sm={1}>
                        <SwitchWidget
                            value={timeBankSettings.allowTimeBank}
                            onChange={checked => this.handleCheck('allowTimeBank', checked)}
                        />
                    </Col>
                    <Col sm={11} className='d-flex'>
                        <label className="control-label">{t('Allow Time Bank')}</label>
                        <div className='ml-30'>
                            <OverlayTrigger
                                key="allowTimeBank"
                                placement="right"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        {t("Time Bank")}
                                    </Tooltip>
                                }>
                                <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                            </OverlayTrigger>
                        </div>
                    </Col>
                </Row>
                <Row className={'mt-2'}>
                    <Col sm={3}>
                        <label className="control-label">{t('Cycle')}</label>
                        <Select
                            options={cycle_options}
                            value={cycle_options.find(item => item.value === timeBankSettings.cycle)}
                            onChange={evt => {
                                this.handleCheck('cycle', evt.value)
                            }}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 1 }}>
                        <label className="control-label">{t('Cycle Start And End Details')}</label>
                        <Row>
                            <Col xs={12} sm={6}>
                                <DatePicker
                                    selected={new Date(timeBankSettings.cycleStartDate)}
                                    placeholder={'From'}
                                    dateFormat="dd-MM-yyyy"
                                    disabled={true}
                                />
                            </Col>
                            <Col xs={12} sm={6} className="mt-sm-0 mt-2">
                                <DatePicker
                                    selected={new Date(timeBankSettings.cycleEndDate)}
                                    placeholder={'To'}
                                    dateFormat="dd-MM-yyyy"
                                    disabled={true}

                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={'my-4'}>
                    <Col xs={5}>
                        <label className="control-label">{t('Max Hours Limit For Individually In a Cycle')}
                            <span className="text-muted "><em> (hhh)</em></span>
                        </label>
                        <NumberInput
                            width={"50"}
                            value={timeBankSettings.maxHourLimitInCycle}
                            onChange={e => {
                                this.handleCheck('maxHourLimitInCycle', e.target.value);
                            }}
                        />
                    </Col>
                    <Col xs={5}>
                        <label className="control-label">{t('Max Minutes Limit For Individually In a Cycle')}
                            <span className="text-muted "><em> (mm)</em></span>
                        </label>
                        <NumberInput
                            width={"50"}
                            value={timeBankSettings.maxMinuteLimitInCycle}
                            onChange={e => {
                                this.handleCheck('maxMinuteLimitInCycle', e.target.value);
                            }}
                        />
                    </Col>
                </Row>
                {/* <Row className={'my-2'}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={timeBankSettings.allowNegativeTimeAccumulation}
                            onChange={checked => this.handleCheck('allowNegativeTimeAccumulation', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Enable Negative Time Accumulation Time In The Bank')}</label>
                        <OverlayTrigger
                            key="allowNegativeTimeAccumulation"
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t(`Allow Negative Time Accumulation`)}.
                                </Tooltip>
                            }
                        ><i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i></OverlayTrigger>
                    </Col>
                </Row> */}
                <Row className='mb-2'>
                    <Col sm={4}>
                        <label className="control-label">{t('Choose what happen on expiry')}</label>
                        <Select
                            options={choose_on_expiry_options}
                            value={choose_on_expiry_options.find(item => item.value === timeBankSettings.onCycleExpiry)}
                            onChange={evt => {
                                this.handleCheck('onCycleExpiry', evt.value)
                            }}
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
                {commonStore?.config?.integration_details?.product ?
                    <TableWidget
                        headers={['Name', 'Wage Type']}
                        metaData={[
                            { field: 'name', type: 'text', placeholder: 'name', required: true, unique: true },
                            { field: 'payrollWageType', type: 'select', placeholder: 'Select...', unique: true, select: true, data: filter => commonStore.getWageTypesAccordingToIntegrations(commonStore?.config?.integration_details, filter) }
                        ]}
                        data={timeBankSettings.timeBankWageTypes}
                        onChange={data => this.handleTimeBankWageTypes(data)}
                        page={"timeBank"}
                        minRemoveIndex={1}
                    />
                    :
                    <></>
                }
            </div>
        );
    }
}

export default inject('clientStore', 'commonStore',)(observer(withLocalization(TimeBankSettings)));
