export const validateRosterData = (data) => {
    const isValidDate = (date) => !isNaN(Date.parse(date));
    const isValidNumber = (num) => typeof num === 'number' && num >= 0;
    const isValidString = (str) => typeof str === 'string' && str.trim() !== '';
    const isValidBoolean = (bool) => typeof bool === 'boolean';
    const isValidArray = (arr) => Array.isArray(arr) && arr.length > 0;
    const hasAtLeastOneTrue = (days) => Object.values(days).some(value => value === true);

    if (!data || typeof data !== 'object') {
        return { valid: false, message: "Invalid data format" };
    }

    if (!data.roster || typeof data.roster !== 'object') {
        return { valid: false, message: "Please selet all the mandatory fields" };
    }

    const { roster } = data;
    if (!isValidString(roster.name)) return { valid: false, message: "Please select name" };
    if (!isValidNumber(roster.publish_type)) return { valid: false, message: "Please select publish type" };
    if (!isValidString(roster.group)) return { valid: false, message: "Please select group" };
    if (!isValidDate(roster.start_date)) return { valid: false, message: "Please select start week" };
    if (!isValidDate(roster.end_date)) return { valid: false, message: "Please select to week" };
    

    if (!data.rosterList || !Array.isArray(data.rosterList)) {
        return { valid: false, message: "Please select all the fields" };
    }
    if (!roster.selected_user_list || !Array.isArray(roster.selected_user_list) || roster.selected_user_list.length == 0) {
        return { valid: false, message: "Please select employee" };
    }

    for (const rosterItem of data.rosterList) {
        if (typeof rosterItem !== 'object') return { valid: false, message: "Invalid format" };
        if (!rosterItem.days || typeof rosterItem.days !== 'object') {
            return { valid: false, message: "Invalid days object" };
        }
        if (!hasAtLeastOneTrue(rosterItem.days)) {
            return { valid: false, message: "Please select atleast one day in a week" };
        }

        if (!isValidNumber(rosterItem.shift)) return { valid: false, message: "Pleae select shift" };
        if (!isValidNumber(rosterItem.project)) return { valid: false, message: "Pleae select project" };
        if (!isValidNumber(rosterItem.task)) return { valid: false, message: "Pleae select task" };
        // if (!isValidArray(rosterItem.additional)) return { valid: false, message: "Pleae select additional" };
    }

    return { valid: true, message: "Validation successful" };
}


export const isTwoWeeksOrMore = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
    let valid = diffInDays >= 13  // to get the two weeks is selected or not
    return { valid: valid, difference: diffInDays / 7 };
};


export const getDayValue = (start) => {
    let day = start.getDay(); //to get the day value
    return day === 0 ? 0 : day - 1;
}