import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//components
import applicationRouter from '~/hoc/applicationRouter';
import withLocalization from '~/hoc/withLocalization';
import TableWidget from '../TableWidget.js';

class SubscriptionRules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionRules: [{ days: '7' }],
        }
        this.loadData = this.loadData.bind(this);
        this.inputValidation = this.inputValidation.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.extendedRules && currentEntity.client.data.extendedRules.subscriptionRules) {
            const extendedRules = Object.assign({}, currentEntity.client.data.extendedRules);
            this.setState({
                subscriptionRules: extendedRules.subscriptionRules
            })
        }
    }

    inputValidation = () => {
        const { subRules } = this.props;
        if (subRules < 1 && subRules > 365) {
            alert("Enter data in range between 1 to 365 Days.");
        }
    }

    render() {
        const { subscriptionRules } = this.state;
        return (
            <div>
                <TableWidget
                    headers={['Reminder Before Expiry-Date(Days)']}
                    metaData={[{ field: 'days', type: 'number', placeholder: '0', required: true, default: '7', unique: true }]}
                    data={subscriptionRules}
                    onChange={data => this.props.handleUserDataChange('subscriptionRules', data)}
                    minRemoveIndex={0}
                    maxLength={3}
                />
                <div><b>{this.props.t('Note')}-</b>&nbsp;{this.props.t('You can add up to three reminders here, for example, notifications will be sent to the user after gaps of 7, 15, and 30 days')}.</div>
            </div >
        );
    }
}
export default inject('clientStore')(applicationRouter(withLocalization(observer(SubscriptionRules))));
