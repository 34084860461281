import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import { getDay } from 'date-fns';

//components
import withLocalization from '~/hoc/withLocalization';
import withRemoveDialog from '~/hoc/withRemoveDialog';
import AddShift from './addShift.js';
import TableWidget from '../../TableWidget.js';
import Button from '~/components/CustomButton/CustomButton.jsx';
import SwitchWidget from '../../SwitchWidget.js';

//elements
import Select from '~/elements/Select.js';
import LoadingSpinner from '~/elements/LoadingSpinner';
import { NumberInput } from '../../../../../elements/Input.js';
import DatePicker from '../../../../../components/DatePicker/DatePicker.jsx';

//assets
import CloseModalIcon from '~/assets/img/CloseApproveModalIcon.svg';

//utils
import { timeFormat } from '~/utils/dateFormat.js';
import { translateLabels } from '~/utils/commonMethods';
import { longDateFormat } from '../../../../../utils/dateFormat.js';
import RequiredStar from '../../../../../elements/RequiredStar.js';

const week_days = [
    { value: 0, label: "Sunday", title: "Sunday", key: "Sunday" },
    { value: 1, label: "Monday", title: "Monday", key: "Monday" },
    { value: 2, label: "Tuesday", title: "Tuesday", key: "Tuesday" },
    { value: 3, label: "Wednesday", title: "Wednesday", key: "Wednesday" },
    { value: 4, label: "Thursday", title: "Thursday", key: "Thursday" },
    { value: 5, label: "Friday", title: "Friday", key: "Friday" },
    { value: 6, label: "Saturday", title: "Saturday", key: "Saturday" }
];

const cycle_length = (t) => {
    return [
        { value: 2, label: `2 ${t('Weeks')}`, title: `2 ${t('Weeks')}` },
        { value: 3, label: `3 ${t('Weeks')}`, title: `3 ${t('Weeks')}` },
        { value: 4, label: `4 ${t('Weeks')}`, title: `4 ${t('Weeks')}` },
        { value: 5, label: `5 ${t('Weeks')}`, title: `5 ${t('Weeks')}` },
        { value: 6, label: `6 ${t('Weeks')}`, title: `6 ${t('Weeks')}` },
        { value: 7, label: `7 ${t('Weeks')}`, title: `7 ${t('Weeks')}` },
        { value: 8, label: `8 ${t('Weeks')}`, title: `8 ${t('Weeks')}` },
        { value: 9, label: `9 ${t('Weeks')}`, title: `9 ${t('Weeks')}` },
        { value: 10, label: `10 ${t('Weeks')}`, title: `10 ${t('Weeks')}` },
        { value: 11, label: `11 ${t('Weeks')}`, title: `11 ${t('Weeks')}` },
        { value: 12, label: `12 ${t('Weeks')}`, title: `12 ${t('Weeks')}` },
    ]
}

const formatData = function (data, t, dateTimeRules) {
    return data.map(item => ({
        name: item.shift_name,
        timing: `${t("From")} ${timeFormat(item.start_time, dateTimeRules)} ${t("to")} ${timeFormat(item.end_time, dateTimeRules)}`
    }));
}

const getNextWeekday = (selectedDay) => {
    let today = moment();
    let nextDay = moment().day(selectedDay);
    if (nextDay.isSameOrBefore(today, 'day'))
        nextDay.add(7, 'days');

    return nextDay.format('YYYY-MM-DD');
}

class CompanyShift extends Component {
    constructor(props) {
        super(props);
        this.state = {
            standardWeekStartDay: 1,
            maxHoursForAutoLogout: 13,
            CompanyShift: [],
            isEdit: false,
            editData: {},
            loading: false,
            allowWeeklyCalculation: false,
            weeklyCalculationSettings: {
                cycleWeekLength: 2,
                allowAverageOvertimeCalculation: false,
                averageOvertimeCycleStartDate: getNextWeekday(1),
                governmentApprovalForExceedingOvertime: false,
                mandatoryOvertimeLimitPerWeek: 30,
                averageOvertimeLimitInPerWeek: 30,
                averageOvertimePercentageId: this.props?.clientStore?.overtimeSettingList[0]?.id,
            }
        }
        this.updateData = this.updateData.bind(this);
        this.deleteShift = this.deleteShift.bind(this);
        this.handleDataDelete = this.handleDataDelete.bind(this);
    }

    updateData = async (data, saveAndReload) => {
        const { commonStore, t, clientStore } = this.props;
        if (data.hasError)
            return commonStore.addNotification(t(data.errors[0].error), null, 'warning');

        this.setState({ loading: true }, async () => {
            try {
                if (this.state?.editData?.id)
                    await clientStore.editCompanyShift(this.state.editData.id, data);
                else
                    await clientStore.saveCompanyShift(data);
                commonStore.addNotification(t('Company Shift saved successfully!'), null, 'success');
                if (!saveAndReload)
                    this.loadCompanyShifts();
                else
                    this.loadCompanyShiftById(this.state?.editData?.id);
            } catch (err) {
                console.error(err)
                this.setState({ loading: false })
            }
        });
    }

    componentDidMount() {
        this.setState({ loading: true }, this.loadData);
    }

    loadData = async () => {
        const { currentEntity } = this.props.clientStore;
        const { standardWeekStartDay, maxHoursForAutoLogout, allowWeeklyCalculation, weeklyCalculationSettings } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            const basicRules = Object.assign({}, currentEntity.client.data.basicRules);
            this.setState({
                standardWeekStartDay: basicRules.standardWeekStartDay || standardWeekStartDay,
                maxHoursForAutoLogout: basicRules?.maxHoursForAutoLogout || maxHoursForAutoLogout,
                allowWeeklyCalculation: basicRules?.allowWeeklyCalculation || allowWeeklyCalculation,
                weeklyCalculationSettings: basicRules?.weeklyCalculationSettings || weeklyCalculationSettings,
                loading: true
            }, this.loadCompanyShifts)
        }
    }

    loadCompanyShifts = async () => {
        const { clientStore, commonStore, t } = this.props;
        try {
            const result = await clientStore.getCompanyShift();
            this.setState({
                CompanyShift: result.companyShift,
                loading: false,
                isEdit: false,
                editData: {},
            });
        } catch (err) {
            console.error(err);
            commonStore.addNotification(t('Something went wrong!'), null, 'error');
            this.setState({ loading: false });
        }
    }

    handleInputRange = (e) => {
        e.persist();
        if (e.target.value < 12 || e.target.value > 23) return;
        let _maxHoursForAutoLogout = e.target.value;
        this.props.handleUserDataChange('maxHoursForAutoLogout', _maxHoursForAutoLogout);
        this.setState({ maxHoursForAutoLogout: _maxHoursForAutoLogout });
    }

    deleteShift = async (id) => {
        const { clientStore, commonStore, t } = this.props;
        try {
            await clientStore.deleteCompanyShift(id, false);
            commonStore.addNotification(t('Shift deleted successfully!'), null, 'success');
            this.loadCompanyShifts()
        } catch (err) {
            console.error(err);
            this.setState({ loading: false });
        }
    }

    handleDataDelete = (index) => {
        const data = this.state.CompanyShift[index];
        const id = data?.id;

        if (!id) return;
        this.setState({ loading: true }, () => {
            return this.props.handleDelete(id, (id) => {
                return this.deleteShift(id);
            }, null, () => this.setState({ loading: false }));
        })
    }

    editShift = (index) => {
        const data = this.state.CompanyShift[index];
        const id = data.id;
        if (!id) return;
        this.setState({ loading: true }, () => this.loadCompanyShiftById(id));
    }

    loadCompanyShiftById = async (id) => {
        try {
            const result = await this.props.clientStore.getCompanyShiftById(id);
            this.toggleModal(true, result.companyShift);
        } catch (err) {
            this.setState({ loading: false });
            console.error(err);
        }
    }

    toggleModal = (value, data) => {
        this.setState({ isEdit: value, editData: data, loading: false });
    }

    handleChangeAndValidate = (name, value) => {
        const { commonStore, t } = this.props;
        if (name === 'allowAverageOvertime' && value && !commonStore?.config?.client?.data?.basicRules?.timeBankSettings?.allowTimeBank)
            return commonStore.addNotification(t('Please activate the Time Bank before using average overtime calculation'), null, 'error');


        const __weeklyCalculationSettings = this.state.weeklyCalculationSettings;
        __weeklyCalculationSettings[name] = value;
        if (name === 'cycleWeekLength')
            __weeklyCalculationSettings["averageOvertimeCycleStartDate"] = getNextWeekday(this.state.standardWeekStartDay);


        this.setState({ weeklyCalculationSettings: __weeklyCalculationSettings }, () => {
            this.props.handleUserDataChange('weeklyCalculationSettings', __weeklyCalculationSettings);
        })

    }

    handleMaximumHoursOT = (value) => {
        const { t } = this.props;
        if (!this.state.weeklyCalculationSettings.governmentApprovalForExceedingOvertime) {
            const __weeklyCalculationSettings = this.state.weeklyCalculationSettings;
            confirmAlert({
                title: t('Are you Sure') + '?',
                message: t('Have you received government approval to use the average hours calculator for overtime payment'),
                buttons: [
                    {
                        label: t('Yes'),
                        onClick: () => {
                            __weeklyCalculationSettings["mandatoryOvertimeLimitPerWeek"] = 50;
                            __weeklyCalculationSettings["governmentApprovalForExceedingOvertime"] = true;
                            this.setState({ weeklyCalculationSettings: __weeklyCalculationSettings })
                            this.props.handleUserDataChange('weeklyCalculationSettings', __weeklyCalculationSettings);
                        },
                    },
                    {
                        label: t('No'),
                        onClick: () => {
                            __weeklyCalculationSettings["governmentApprovalForExceedingOvertime"] = false;
                            this.setState({ weeklyCalculationSettings: __weeklyCalculationSettings });
                            this.props.handleUserDataChange('weeklyCalculationSettings', __weeklyCalculationSettings);
                        }
                    },
                ],
            });
        }
        else {
            const __weeklyCalculationSettings = this.state.weeklyCalculationSettings;
            __weeklyCalculationSettings["mandatoryOvertimeLimitPerWeek"] = value;
            this.setState({ weeklyCalculationSettings: __weeklyCalculationSettings })
            this.props.handleUserDataChange('weeklyCalculationSettings', __weeklyCalculationSettings)
        }
    }

    handleWeeklyCalculation = (value) => {
        if (!value) {
            const __weeklyCalculationSettings = this.state.weeklyCalculationSettings;
            __weeklyCalculationSettings["allowAverageOvertimeCalculation"] = false;
            this.setState({ allowWeeklyCalculation: value, weeklyCalculationSettings: __weeklyCalculationSettings })
            this.props.handleUserDataChange('allowWeeklyCalculation', value)
            this.props.handleUserDataChange('weeklyCalculationSettings', __weeklyCalculationSettings)
        }
        else {
            this.setState({ allowWeeklyCalculation: value });
            this.props.handleUserDataChange('allowWeeklyCalculation', value);
        }
    }

    render() {
        const { t } = this.props;
        const { currentEntity, overtimeSettingList } = this.props.clientStore;
        const { dateTimeRules } = currentEntity.client.data;
        translateLabels(week_days, t, 'label');

        const overtimeOptions = overtimeSettingList.map((o) => ({ value: o.id, label: o.name }));
        const { standardWeekStartDay, isEdit, loading, allowWeeklyCalculation, weeklyCalculationSettings } = this.state;
        const {
            cycleWeekLength, allowAverageOvertimeCalculation, averageOvertimeCycleStartDate,
            mandatoryOvertimeLimitPerWeek, averageOvertimePercentageId, averageOvertimeLimitInPerWeek
        } = weeklyCalculationSettings;

        if (loading) return <LoadingSpinner />

        return (
            <>
                <Row>
                    <Col xs={3}>
                        <label className="control-label">{t('Week')} {t('Start')} {t('Day')}</label>
                        <Select
                            options={week_days}
                            value={week_days.find(item => item.value === standardWeekStartDay)}
                            onChange={evt => {
                                this.setState({ standardWeekStartDay: evt.value })
                                this.props.handleUserDataChange('standardWeekStartDay', evt.value)
                            }}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col xs={6}>
                        <label className="control-label">{t('Maximum allowed hours before auto finish working log')}</label>
                        <div className="slider-parent">
                            <input type="range" min="12" max="23"
                                onChange={event => this.handleInputRange(event)}
                                value={this.state.maxHoursForAutoLogout}
                            />
                            <div className="buble">
                                {this.state.maxHoursForAutoLogout} {t('Hours')}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='my-3'>
                    <Col sm={12} className='d-flex align-items-center mb-2'>
                        <Row className='w-100'>
                            <Col xs={2}>
                                <SwitchWidget
                                    value={allowWeeklyCalculation}
                                    onChange={checked => this.handleWeeklyCalculation(checked)}
                                />
                            </Col>
                            <Col xs={10} className='d-flex'>
                                <label className="control-label">{t('Allow calculation on rotation shift')}</label>
                                <div className='ml-30'>
                                    <OverlayTrigger
                                        key="allowWeeklyCalculation"
                                        placement="right"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                {t("If Enabled then one can see deviation in schedule rotation")}
                                            </Tooltip>
                                        }>
                                        <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                                    </OverlayTrigger>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6} style={!allowWeeklyCalculation ? { cursor: 'not-allowed', opacity: '0.5' } : {}}>
                        <label className="control-label">{t('Select cycle length of calculation')}</label>
                        <Select
                            options={cycle_length(t)}
                            value={cycle_length(t).find(item => item.value === cycleWeekLength)}
                            onChange={evt => this.handleChangeAndValidate('cycleWeekLength', evt.value)}
                            style={{ width: '100%' }}
                            isDisabled={!allowWeeklyCalculation}
                        />
                    </Col>
                    <Col xs={6} style={!allowWeeklyCalculation ? { cursor: 'not-allowed', opacity: '0.5' } : {}}>
                        <label className="control-label">{t('Maximum approved weekly hours')}<RequiredStar /></label>
                        <NumberInput
                            width={"auto"}
                            value={mandatoryOvertimeLimitPerWeek}
                            onChange={e => this.handleMaximumHoursOT(e.target.value)}
                            //onBlur={e => this.handleOnBlur(e.target.value)}
                            disabled={!allowWeeklyCalculation}
                        />
                    </Col>
                </Row>
                <Row className='mb-3' style={!allowWeeklyCalculation ? { cursor: 'not-allowed', opacity: '0.5' } : {}}>
                    <Col sm={1}>
                        <SwitchWidget
                            value={allowAverageOvertimeCalculation}
                            onChange={checked => this.handleChangeAndValidate('allowAverageOvertimeCalculation', checked)}
                            disabled={!allowWeeklyCalculation}
                        />
                    </Col>
                    <Col sm={5} className='d-flex'>
                        <label className="control-label">{t('Allow Average Overtime Calculation')}</label>
                        <div className='ml-30'>
                            <OverlayTrigger
                                key="allowAverageOvertimeCalculation"
                                placement="right"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        {t("Average overtime calculation refers to determining the average number of extra hours an employee works beyond regular working hours over a specific period. It’s typically calculated by dividing total overtime hours by the number of time periods")}
                                    </Tooltip>
                                }>
                                <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                            </OverlayTrigger>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3" style={!allowAverageOvertimeCalculation ? { cursor: 'not-allowed', opacity: '0.5' } : {}}>
                    <Col xs={6}>
                        <label className="control-label">{t('Select Cycle Start Date')}<RequiredStar /></label>
                        <div className="slider-parent">
                            <DatePicker
                                selected={averageOvertimeCycleStartDate && new Date(averageOvertimeCycleStartDate)}
                                onChange={evt => this.handleChangeAndValidate('averageOvertimeCycleStartDate', moment(evt).format('YYYY-MM-DD'))}
                                dateFormat={longDateFormat(dateTimeRules)}
                                disabled={!allowAverageOvertimeCalculation}
                                filterDate={date => getDay(date) === currentEntity?.client?.data?.basicRules?.standardWeekStartDay}
                                minDate={new Date()}
                            />
                        </div>
                    </Col>
                    <Col xs={6}>
                        <label className="control-label">{t('Weekly overtime limit per cycle as per regulations')}<RequiredStar /></label>
                        <NumberInput
                            width={"auto"}
                            value={averageOvertimeLimitInPerWeek}
                            onChange={e => this.handleChangeAndValidate('averageOvertimeLimitInPerWeek', e.target.value)}
                            disabled={!allowAverageOvertimeCalculation}
                        />
                    </Col>
                </Row>
                <Row style={!allowAverageOvertimeCalculation ? { cursor: 'not-allowed', opacity: '0.5' } : {}}>
                    <Col xs={6}>
                        <label className="control-label">{t('Overtime')}<RequiredStar /></label>
                        <Select
                            options={overtimeOptions}
                            value={overtimeOptions.find(item => item.value === averageOvertimePercentageId)}
                            onChange={evt => this.handleChangeAndValidate('averageOvertimePercentageId', evt.value)}
                            placeholder={`${t('Select')} ${t('Overtime')}`}
                            style={{ width: '100%' }}
                            isDisabled={!allowAverageOvertimeCalculation}
                        />
                    </Col>
                </Row>
                <hr />
                <Row className='mt-3 align-items-center'>
                    <Col xs={8}>
                        <h6>{t('Company Shifts')}</h6>
                    </Col>
                    <Col xs={4} className='d-flex justify-content-end'>
                        <Button wd fill onClick={() => this.toggleModal(true, {})}>
                            <i className="fa fa-plus-circle fa-xl margin-right-10" /> {this.props.t('Add new')}
                        </Button>
                    </Col>
                </Row>
                <TableWidget
                    page={'companyShifts'}
                    headers={['Name', 'Timing']}
                    metaData={[
                        { field: 'name', type: 'text' },
                        { field: 'timing', type: 'text' },
                    ]}
                    data={formatData(this.state.CompanyShift, t, dateTimeRules)}
                    onChange={_ => { }}
                    customDeleteHandler={(idx) => this.handleDataDelete(idx)}
                    customFormHandler={(idx) => this.editShift(idx)}
                    minRemoveIndex={0}
                />
                <Modal
                    className={"modal right fade modal_revised"}
                    backdrop="static"
                    show={isEdit}
                    onHide={() => this.toggleModal(false, {})}
                >
                    <Modal.Header>
                        <Modal.Title className="w-100 text-center m-0">{t('Company Shifts')}</Modal.Title>
                        <img
                            src={CloseModalIcon}
                            className="cursor-pointer"
                            alt='close_button'
                            onClick={() => this.toggleModal(false, {})}
                        />
                    </Modal.Header>
                    <Modal.Body className='position-relative'>
                        <AddShift
                            val={this.state.editData}
                            updateData={this.updateData}
                            edit={isEdit}
                            overtimeSettingList={overtimeOptions}
                            cancel={() => this.toggleModal(false, {})}
                            t={this.props.t}
                            dateTimeRules={dateTimeRules}
                        />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
export default inject('clientStore', 'commonStore')(withLocalization(withRemoveDialog(observer(CompanyShift))));
