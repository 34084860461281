import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';

// Assets //
import Tripletex from '../assets/img/tripletex.svg'
import Visma from '../assets/img/visma.png'

export const getIntegrationProduct = (product) => {
    switch (product) {
        case 'tripletex':
            return Tripletex;
        case 'visma':
            return Visma;
        default:
            break;
    }
}

export const getIntegrationIconWithStatus = (config, int_details, t) => {
    return (
        <>
            {
                config?.integration_details?.product && int_details.int_id && (
                    <img
                        src={getIntegrationProduct(config.integration_details.product)}
                        width={24}
                        height={24}
                        alt='int'
                        title={`${t('Synced with')} ${config?.integration_details?.product}`}
                        className='m-1'
                    />
                )
            }
            {int_details.int_status === 'failed' &&
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip-top">
                            {int_details && int_details.int_data && int_details.int_data.messages
                                && int_details.int_data.messages[0] && int_details.int_data.messages[0].message ?
                                `${int_details.int_data.messages[0].message} (${t("from")} ${config?.integration_details?.product || t('Integrations')})` :
                                `${t("Something went wrong")} (${t("from")} ${config?.integration_details?.product || t('Integrations')})`}
                        </Tooltip>
                    }
                >
                    <div className='ml-10'>
                        <i className="fa fa-exclamation m-2" />
                    </div>
                </OverlayTrigger>
            }
        </>
    )
}

export const isUserUpdatedValuesForVisma = (commonStore, add, values, t, result, callBackFun) => {
    if (commonStore.config?.integration_details?.product === 'visma' && values.user_type !== 'admin' && result?.int_user?.status === 'failed') {
        if (!add) {
            const str = t(`${result.int_user.data.validationResults[0].message ?
                result.int_user.data.validationResults[0].message
                : result.int_user.data.message ?
                    result.int_user.data.message
                    :
                    "Have you updated required User's details"
                }`);
            return confirmAlert({
                title: `${t('Have you Updated required details')} ?`,
                message: str,
                buttons: [
                    {
                        label: t('Yes'),
                        onClick: () => {
                            setTimeout(() => {
                                callBackFun(values, result, add)
                            }, 10)
                        },
                    },
                    {
                        label: t('No')
                    },
                ],
            })
        }
    }
    else {
        callBackFun(values, result, add)
    }
}