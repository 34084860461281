import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, Tab } from 'react-bootstrap'

import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import General from './General';
import OverTimeCalculation from './OverTimeCalculation';
import RulesForStartTime from './RulesForStartTime';
import RulesForBreakTime from './RulesForBreakTime';
import WorklogValidation from './WorklogValidation';
import ParentalLeaves from './ParentalLeaves';
import CheckAvailability from './CheckAvailability';
import ShiftRequestSettings from './ShiftRequestSettings';
import MessageControl from './MessageControl';
import TimeBankSettings from './TimeBankSettings';
import OvertimeSetting from './OvertimeSettings';
import CompanyShift from './CompanyShifts';

class BasicRules extends Component {
    constructor(props) {
        super(props);
        const _break = props?.clientStore?.currentEntity?.client?.data?.basicRules?.breakTimeRules?.minutes || 30;
        this.companyShiftsAllowed = !!(props?.clientStore?.currentEntity?.client?.allow_company_shifts);
        this.state = {
            subTab: 'general',
            breakInMins: _break
        }
        this.handleSubTabChange = this.handleSubTabChange.bind(this);
        this.handleUserDataChange = this.handleUserDataChange.bind(this);
    }

    handleUserDataChange = (name, value) => {
        const { currentEntity } = this.props.clientStore;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            currentEntity.client.data.basicRules[name] = value;
        } else {
            let basicRules = {};
            basicRules[name] = value;
            currentEntity.client.data.basicRules = basicRules;
        }
    }

    handleSubTabChange = (tabName) => {
        this.setState({ subTab: tabName })
    }

    render() {
        const { subTab } = this.state;
        const { t } = this.props;
        return (
            <Tab.Container activeKey={subTab} id="BasicTabContainer" onSelect={(key) => { }} >
                <div className="custom-card">
                    <Row>
                        <Col sm={3}>
                            <ul className="list-group">
                                <li className={subTab === 'general' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('general')}>{t('General')}</li>
                                {!this.companyShiftsAllowed ?
                                    <>
                                        <li className={subTab === 'overTimeCal' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                            onClick={() => this.handleSubTabChange('overTimeCal')}>{t('Overtime Calculation')}</li>
                                        <li className={subTab === 'rulesForStartTime' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                            onClick={() => this.handleSubTabChange('rulesForStartTime')}>{t('Rules for Start & Break Time')}</li>
                                    </>
                                    :
                                    <>
                                        <li className={subTab === 'overtimeSetting' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                            onClick={() => this.handleSubTabChange('overtimeSetting')}>{t('Overtime Settings')}</li>
                                        <li className={subTab === 'companyShift' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                            onClick={() => this.handleSubTabChange('companyShift')}>{t('Company Shifts')}</li>
                                    </>}
                                <li className={subTab === 'timeBankSettings' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('timeBankSettings')}>{t('Time Bank Settings')}</li>
                                <li className={subTab === 'workloadValidation' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('workloadValidation')}>{t('Worklog Validation')}</li>
                                <li className={subTab === 'parentalLeaves' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('parentalLeaves')}>{t('Child sickness')}</li>
                                <li className={subTab === 'checkavailability' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('checkavailability')}>{t('Check Availability')}</li>
                                <li className={subTab === 'shiftRequestSettings' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('shiftRequestSettings')}>{t('Schedule settings')}</li>
                                <li className={subTab === 'MessageControl' ? 'list-group-item active cursor-pointer' : 'list-group-item cursor-pointer'}
                                    onClick={() => this.handleSubTabChange('MessageControl')}>{t('Message')} {t('Control')}</li>
                            </ul>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="general">
                                    <General handleUserDataChange={this.handleUserDataChange} />
                                </Tab.Pane>
                                {!this.companyShiftsAllowed ?
                                    <>
                                        <Tab.Pane eventKey="overTimeCal">
                                            <OverTimeCalculation handleUserDataChange={this.handleUserDataChange} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="rulesForStartTime">
                                            <RulesForStartTime handleUserDataChange={this.handleUserDataChange} breakInMins={this.state.breakInMins} />
                                            <RulesForBreakTime handleUserDataChange={this.handleUserDataChange} updateBreakMins={(_break) => this.setState({ breakInMins: _break })} />
                                        </Tab.Pane>
                                    </>
                                    :
                                    <>
                                        <Tab.Pane eventKey="overtimeSetting">
                                            <OvertimeSetting handleUserDataChange={this.handleUserDataChange} />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="companyShift">
                                            <CompanyShift handleUserDataChange={this.handleUserDataChange} />
                                        </Tab.Pane>
                                    </>
                                }
                                <Tab.Pane eventKey="timeBankSettings">
                                    <TimeBankSettings handleUserDataChange={this.handleUserDataChange} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="workloadValidation">
                                    <WorklogValidation handleUserDataChange={this.handleUserDataChange} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="parentalLeaves">
                                    <ParentalLeaves handleUserDataChange={this.handleUserDataChange} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="checkavailability">
                                    <CheckAvailability handleUserDataChange={this.handleUserDataChange} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="shiftRequestSettings">
                                    <ShiftRequestSettings handleUserDataChange={this.handleUserDataChange} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="MessageControl">
                                    <MessageControl handleUserDataChange={this.handleUserDataChange} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </div>
            </Tab.Container>
        );
    }
}
export default inject('clientStore', 'commonStore', 'userStore')(applicationRouter(withLocalization(observer(BasicRules))));
