import { observable, action, makeObservable } from 'mobx';

import agent from '../agent';

class TimeBankStore {
    constructor() {
        makeObservable(this, {
            currentMemberTimeBankList: observable,
            currentMemberList: observable,
            currentEntity: observable,
            loading: observable,
            requestParams: observable,
            lastListLoadTime: observable,
            filters: observable,
            appliedFilters: observable,

            setFilter: action,
            onFilter: action,
            resetLastListLoadTime: action,
            loadTimeBankMember: action,
            loadTimeBankList: action,
            getPendingTimelogs: action,
            saveToIntegration: action,
            desyncIntegration: action
        });
    }

    currentMemberTimeBankList = {};
    currentMemberList = {};
    currentEntity = {};
    loading = false;
    requestParams = null;
    lastListLoadTime = null;

    filters = {
        currentUserTimeBankList: '',
        name: ''
    };
    appliedFilters = {
        currentUserTimeBankList: '',
        name: ''
    };

    setFilter(name, value) {
        const filters = Object.assign({}, this.filters);
        filters[name] = value;
        this.filters = filters;
    }

    onFilter() {
        this.appliedFilters = Object.assign({}, this.filters);
    }

    resetLastListLoadTime() {
        this.lastListLoadTime = new Date();
    }

    loadTimeBankMember(params = {}) {
        return agent.TimeBank.loadTimeBankMember(params = {})
            .then(
                action(list => {
                    this.requestParams = params;
                    this.currentMemberList = list.timeBankMembers;
                    return list.timeBankMembers;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    loadTimeBankList(params = {}) {
        let _params = {...params};
        if (!_params?.filters?.currentUserTimeBankList)
            _params.filters = this.filters
        return agent.TimeBank.loadTimeBankList(_params)
            .then(
                action(list => {
                    this.requestParams = _params;
                    this.currentMemberTimeBankList = list.rows;
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    getPendingTimelogs(params = {}) {
        return agent.TimeBank.getPendingTimelogs(params)
            .then(
                action(list => {
                    return list;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    saveTimeBank(params = {}) {
        return agent.TimeBank.saveTimeBank(params)
            .then(
                action(entity => {
                    return entity;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    saveToIntegration(data) {
        this.loading = true;
        return agent.TimeBank.saveToIntegration(data)
            .then(
                action(data => {
                    this.loading = false;
                    return data;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    desyncIntegration(data) {
        this.loading = true;
        return agent.TimeBank.desyncIntegration(data)
            .then(
                action(data => {
                    this.loading = false;
                    return data;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    async remove(id) {
        await agent.TimeBank.remove(id);
        this.deleteSuccess = true;
        return 1;
    }
}



const _TimeBankStore = new TimeBankStore();
export default _TimeBankStore;
